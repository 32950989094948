import {SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {
    ScrollView,
    useColorModeValue,
    VStack,
    Text,
    Center,
    Button,
    useTheme,
    HStack,
    Input,
} from "native-base";
import * as React from "react";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useCallback, useEffect, useRef, useState} from "react";
import {get, post} from "../../services/api";
import MapView from "react-native-maps";
import debounce from 'lodash.debounce';
import StyledPicker from "../../components/StyledPicker";
import {Picker} from "react-native-web";

const ContractorLocations = props => {
    const {userDetails} = useAuth();
    const {colors} = useTheme();

    const gray = useColorModeValue(colors.gray[500], colors.gray[400]);

    const [companyAreas, setCompanyAreas] = useState(userDetails.company?.contractor_locations || []);
    const companyAreasRef = useRef(companyAreas);
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)
    const mapRef = useRef(null);

    useEffect(() => {
        companyAreasRef.current = companyAreas;
    }, [companyAreas]);

    useEffect(() => {
        if (mapRef.current) {
            const region = calculateInitialRegion();
            const zoomLevel = calculateZoomLevel(region);
            mapRef.current.animateCamera({
                center: {
                    latitude: region.latitude,
                    longitude: region.longitude,
                },
                zoom: zoomLevel,
                pitch: 0,
                heading: 0,
            }, { duration: 1000 });
        }
    }, [companyAreas]);

    const saveAreas = async () => {
        setError(null);
        setSaving(true);
        await post('/contractor/areas', {areas: companyAreas}).catch(() => setError('An error occurred'));
        setSaving(false);
    }

    const fetchLatLong = async (postcode) => {
        return get('/postcode-lookup', {postcode}).then(response => {
            return {
                ...response,
                formattedPostcode: response.postcode,
            };
        });
    }

    const debouncedFetchLatLong = useCallback(debounce(async (index, postcode) => {
        const { latitude, longitude } = await fetchLatLong(postcode);
        setCompanyAreas(prevAreas => prevAreas.map((item, i) => i === index ? {
            ...item,
            latitude,
            longitude
        } : item));
    }, 1000), []);

    const handlePostcodeChange = (text, index) => {
        setCompanyAreas(prevAreas => prevAreas.map((item, i) => i === index ? {
            ...item,
            postcode: text
        } : item));
        debouncedFetchLatLong(index, text);
    };

    const calculateInitialRegion = () => {
        if (companyAreas.filter(item => item.latitude && item.longitude).length === 0) {
            return {
                latitude: 54.3781,
                longitude: -2.1228,
                latitudeDelta: 4,
                longitudeDelta: 4,
            };
        }

        const latitudes = companyAreas.filter(item => item.latitude).map(item => parseFloat(item.latitude));
        const longitudes = companyAreas.filter(item => item.longitude).map(item => parseFloat(item.longitude));

        const minLatitude = Math.min(...latitudes);
        const maxLatitude = Math.max(...latitudes);
        const minLongitude = Math.min(...longitudes);
        const maxLongitude = Math.max(...longitudes);

        const latitude = (minLatitude + maxLatitude) / 2;
        const longitude = (minLongitude + maxLongitude) / 2;

        const latitudeDelta = maxLatitude - minLatitude;
        const longitudeDelta = maxLongitude - minLongitude;

        return {
            latitude,
            longitude,
            latitudeDelta: latitudeDelta + 0.5,  // Add some padding
            longitudeDelta: longitudeDelta + 0.5,  // Add some padding
        };
    };

    const calculateZoomLevel = (region) => {
        const { latitudeDelta, longitudeDelta } = region;

        // Constants for zoom level calculation
        const WORLD_DIM = { height: 256, width: 256 };
        const ZOOM_MAX = 21;

        const latRad = (lat) => {
            const sin = Math.sin(lat * Math.PI / 180);
            const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        };

        const zoom = (mapPx, worldPx, fraction) => Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

        const latFraction = (latRad(region.latitude + latitudeDelta / 2) - latRad(region.latitude - latitudeDelta / 2)) / Math.PI;
        const lngFraction = longitudeDelta / 360;

        const latZoom = zoom(WORLD_DIM.height, WORLD_DIM.height, latFraction);
        const lngZoom = zoom(WORLD_DIM.width, WORLD_DIM.width, lngFraction);

        return Math.min(latZoom, lngZoom, ZOOM_MAX);
    };

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4}>
                    <Text color={'gray.500'}>
                        Manage the areas where you can provide services. This is not necessarily your company address,
                        it's the area where you will be able to provide services to customers.
                    </Text>

                    {(userDetails && userDetails.company?.contractor_locations?.length > 0 || companyAreas.length > 0) && (
                        <MapView
                            mapType={'standard'}
                            ref={mapRef}
                            showsMyLocationButton={false}
                            showsCompass={false}
                            style={{height: 500, width: '100%'}}
                            initialRegion={calculateInitialRegion()}
                        >
                            {companyAreas.filter(area => area.latitude && area.longitude).map((area, index) => (
                                <MapView.Circle
                                    key={index}
                                    center={{
                                        latitude: parseFloat(area.latitude),
                                        longitude: parseFloat(area.longitude)
                                    }}
                                    radius={parseFloat(area.radius_in_miles) * 1609.34}
                                    strokeColor={colors.primary[500]}
                                    fillColor={colors.primary[500]}
                                />
                            ))}
                        </MapView>
                    )}

                    {userDetails && userDetails.company?.contractor_locations?.length === 0 && companyAreas.length === 0 && (
                        <Center flex={1}>
                            <Ionicons name={'location-outline'} size={64} color={gray}/>
                            <Text mt={4} fontSize={'lg'} color={gray}>No areas added</Text>
                            <Button minW={120} mt={4}
                                    onPress={() => setCompanyAreas([...companyAreas, {
                                        postcode: '',
                                        radius_in_miles: '5'
                                    }])}>
                                Add area
                            </Button>
                        </Center>
                    )}

                    {companyAreas.map((area, index) => (
                        <VStack key={index} space={4} width={'100%'} p={4} bg={useColorModeValue('white', 'dark.50')}
                                rounded={'lg'}>
                            <HStack space={4} alignItems={'center'}>
                                <VStack flex={1}>
                                    <Text mb={1} fontSize={'xs'} color={gray}>
                                        Postcode
                                        <Text ml={1} color={'red.500'}>*</Text>
                                    </Text>
                                    <Input
                                        value={area.postcode}
                                        fontSize={'md'}
                                        size={'xl'}
                                        onChangeText={text => handlePostcodeChange(text, index)}
                                        placeholder={"Postcode"}
                                    />
                                </VStack>

                                <VStack flex={1}>
                                    <Text mb={1} fontSize={'xs'} color={gray}>
                                        Radius (miles)
                                        <Text ml={1} color={'red.500'}>*</Text>
                                    </Text>

                                    <StyledPicker
                                        selectedValue={area.radius_in_miles}
                                        onValueChange={itemValue => setCompanyAreas(companyAreas.map((item, i) => i === index ? {
                                            ...item,
                                            radius_in_miles: itemValue
                                        } : item))}
                                    >
                                        <Picker.Item label="5 miles" value={"5"}/>
                                        <Picker.Item label="10 miles" value={"10"}/>
                                        <Picker.Item label="15 miles" value={"15"}/>
                                        <Picker.Item label="20 miles" value={"20"}/>
                                        <Picker.Item label="25 miles" value={"25"}/>
                                        <Picker.Item label="30 miles" value={"30"}/>
                                        <Picker.Item label="35 miles" value={"35"}/>
                                    </StyledPicker>
                                </VStack>

                                <TouchableOpacity style={{marginTop: 18}}
                                                  onPress={() => setCompanyAreas(companyAreas.filter((item, i) => i !== index))}>
                                    <Ionicons name={'close'} size={24} color={'red'}/>
                                </TouchableOpacity>
                            </HStack>
                        </VStack>
                    ))}

                    <HStack space={4} alignItems={'center'} justifyContent={'flex-end'}>

                        {error &&
                            <Text color={'red.500'}>
                                An error occurred while saving your areas, please make sure all postcodes are valid.
                            </Text>
                        }

                        {(userDetails.company?.contractor_locations?.length > 0 || companyAreas.length > 0) && (
                            <Button
                                isDisabled={saving}
                                minW={120}
                                onPress={() => setCompanyAreas([...companyAreas, {
                                    postcode: '',
                                    radius_in_miles: '5'
                                }])}>
                                Add area
                            </Button>
                        )}

                        {companyAreas.length > 0 && (
                            <Button isLoading={saving} minW={120} onPress={saveAreas}>
                                Save
                            </Button>
                        )}
                    </HStack>
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default ContractorLocations;

import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    Checkbox,
    FormControl,
    Heading,
    HStack,
    Input,
    KeyboardAvoidingView,
    ScrollView,
    Spacer,
    Spinner,
    Text,
    TextArea,
    useColorModeValue,
    useTheme,
    VStack
} from "native-base";
import {useEffect, useState} from "react";
import {useAuth} from "../../providers/AuthProvider";
import BreakdownOfFees from "../../components/contractor/BreakdownOfFees";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";
import {get, post} from "../../services/api";
import UploadDocumentsButton from "../../components/UploadDocumentsButton";
import {appendDocumentsToFormData} from "../../services/documents";
import {useNavigation} from "@react-navigation/native";
import DateTimePicker from "../../components/DatePicker";

const SubmissionResponse = props => {
    const navigation = useNavigation();

    const [submissionId, setSubmissionId] = useState(props.route.params.submissionId);

    const {colors} = useTheme();
    const {userDetails} = useAuth();

    const [submission, setSubmission] = useState();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const [price, setPrice] = useState('');
    const [depositRequired, setDepositRequired] = useState(false);
    const [depositAmount, setDepositAmount] = useState('');
    const [commissionAmount, setCommissionAmount] = useState('');
    const [message, setMessage] = useState('');
    const [documents, setDocuments] = useState([]);
    const [terms, setTerms] = useState([]);
    const [services, setServices] = useState([]);
    const [estimatedStartDate, setEstimatedStartDate] = useState(null);

    const bg = useColorModeValue('light.50', 'dark.50');
    const chipBg = useColorModeValue('light.200', 'dark.200');
    const chipColor = useColorModeValue('black', 'white');
    const chipGreenBg = useColorModeValue('green.100', 'green.900');

    useEffect(() => {
        fetchSubmission();
    }, []);

    useEffect(() => {
        if (submission?.response) {
            onChangeNumber({nativeEvent: {text: submission.response.price.toString()}}, setPrice);
        }
    }, []);

    const fetchSubmission = async () => {
        const response = await get(`/contractor/submissions/${submissionId}`);
        setSubmission(response);

        if (response.response) {
            setPrice(response.response.price?.toString());
            setDepositRequired(response.response.deposit_amount && response.response.deposit_amount > 0);
            setDepositAmount(response.response.deposit_amount?.toString());
            setCommissionAmount(response.response.commission_amount?.toString());
            setMessage(response.response.message);
            setDocuments(response.response.documents);
            setTerms(response.response.terms);
            setServices(response.response.categories.map(category => category.id));
            setEstimatedStartDate(response.response.estimated_start_date);
        }

        setLoading(false);
    }

    const onChangeNumber = (event, setValue) => {
        const value = event.nativeEvent.text?.replace(/,/g, '');
        if (value.length === 0 || isNaN(value)) {
            setValue('');
        } else {
            const numericValue = parseFloat(value).toFixed(2); // Round to two decimal places
            const parts = numericValue.split('.');
            const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            const decimalPart = parts[1] ? `.${parts[1]}` : '';
            setValue(integerPart + decimalPart);
        }
    };

    const submit = async () => {
        setError(null);
        setSubmitting(true);

        let formData = new FormData();
        formData.append('type', 'offer');
        formData.append('message', message || '');
        formData.append('estimated_start_date', estimatedStartDate);

        if (userDetails.company.commission_only === 1) {
            formData.append('commission_amount', commissionAmount.replaceAll(',', ''));
        } else {
            formData.append('price', price.replaceAll(',', ''));
            formData.append('deposit_required', depositRequired ? 1 : 0);
            formData.append('deposit_amount', depositAmount?.replaceAll(',', ''));
        }

        for (var i = 0; i < services.length; i++) {
            formData.append('services[]', services[i]);
        }

        await appendDocumentsToFormData(formData, documents);
        await appendDocumentsToFormData(formData, terms, 'terms');

        post(`/contractor/submissions/${submission.id}/response`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            setSubmitted(true);
        }).catch(error => {
            setError(error.response.data.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const updateResponse = async () => {
        setError(null);
        setSubmitting(true);

        let formData = new FormData();
        formData.append('type', 'offer');
        formData.append('message', message || '');
        formData.append('estimated_start_date', estimatedStartDate);

        if (userDetails.company.commission_only === 1) {
            formData.append('commission_amount', commissionAmount.replaceAll(',', ''));
        } else {
            formData.append('price', price.replaceAll(',', ''));
            formData.append('deposit_required', depositRequired ? 1 : 0);
            formData.append('deposit_amount', depositAmount?.replaceAll(',', ''));
        }

        for (var i = 0; i < services.length; i++) {
            formData.append('services[]', services[i]);
        }

        await appendDocumentsToFormData(formData, documents);
        await appendDocumentsToFormData(formData, terms, 'terms');

        post(`/contractor/submissions/${submission.id}/response/${submission.response.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            setSubmitted(true);
        }).catch(error => {
            setError(error.response.data.message);
        }).finally(() => {
            setSubmitting(false);
        });
    }

    return (
        <ScrollView p={4}>
            <KeyboardAvoidingView behavior="padding" enabled>
                <VStack space={2} bg={bg} p={4} rounded={'lg'}>
                    {submission?.response && (
                        <Heading size={'md'} mb={2}>Update your response</Heading>
                    )}

                    {loading && (
                        <VStack space={4} flex={1} justifyContent={'center'} alignItems={'center'}>
                            <Spinner size={'lg'} color={colors.primary[500]}/>
                            <Text>Loading</Text>
                        </VStack>
                    )}

                    {submitted && (
                        <VStack space={4} flex={1} justifyContent={'center'} alignItems={'center'}>
                            <Ionicons name={'checkmark-circle-outline'} size={64} color={colors.green[500]}/>
                            <Text bold fontSize={'xl'}>Response submitted successfully.</Text>
                            <Button px={8} onPress={() => navigation.goBack()}>Go back</Button>
                        </VStack>
                    )}

                    {!submitted && !loading && (
                        <VStack w={'100%'}>
                            <FormControl>
                                <FormControl.Label>Select your services</FormControl.Label>

                                <HStack space={2} flexWrap={'wrap'}>
                                    {submission?.contractor_categories?.map(category => (
                                        <TouchableOpacity key={category.id} onPress={() => {
                                            if (services.includes(category.id)) {
                                                setServices(prev => prev.filter(item => item !== category.id));
                                                return;
                                            }

                                            setServices(prev => [...prev, category.id])
                                        }}>
                                            <HStack my={1} rounded={'2xl'} px={2} py={1}
                                                    bg={services.includes(category.id) ? chipGreenBg : chipBg}
                                                    borderColor={services.includes(category.id) ? 'green.500' : chipBg}
                                                    borderWidth={2}
                                                    space={1}
                                                    alignItems={'center'}
                                                    key={category.id}>
                                                <Text fontSize={'xs'} color={chipColor}>
                                                    {category.name}
                                                </Text>
                                                {services.includes(category.id) && (
                                                    <TouchableOpacity
                                                        onPress={() => setServices(prev => prev.filter(item => item !== category.id))}>
                                                        <Ionicons name={'checkmark'} size={16}
                                                                  color={colors.green[500]}/>
                                                    </TouchableOpacity>
                                                )}
                                            </HStack>
                                        </TouchableOpacity>
                                    ))}
                                </HStack>

                                <FormControl.HelperText>
                                    Please select the service(s) you would like to offer
                                </FormControl.HelperText>
                            </FormControl>

                            {userDetails.company.commission_only === 1 && (
                                <>
                                    <FormControl mt={4}>
                                        <FormControl.Label>Commission amount</FormControl.Label>
                                        <Input
                                            fontSize={'md'}
                                            size={'xl'}
                                            value={commissionAmount}
                                            onChange={(event) => setCommissionAmount(event.nativeEvent.text)}
                                            onBlur={(event) => onChangeNumber(event, setCommissionAmount)}
                                            keyboardType={'numeric'}
                                            placeholder={'Commission amount'}
                                            leftElement={
                                                <Text color={useColorModeValue('gray.500', 'gray.400')} ml={2}>
                                                    £
                                                </Text>
                                            }
                                        />
                                        <FormControl.HelperText>
                                            This is not the price you will charge the client. This is the commission you
                                            will pay upon a successful job. You can update this later if it is not known
                                            at
                                            this time.
                                        </FormControl.HelperText>
                                    </FormControl>
                                </>
                            )}

                            {userDetails.company.commission_only === 0 && (
                                <>
                                    <FormControl mt={4}>
                                        <FormControl.Label>Deposit required?</FormControl.Label>
                                        <Checkbox isChecked={depositRequired} value={depositRequired}
                                                  onChange={setDepositRequired}>
                                            {depositRequired ? 'Yes' : 'No'}
                                        </Checkbox>
                                    </FormControl>

                                    {depositRequired && (
                                        <>
                                            <FormControl mt={4}>
                                                <FormControl.Label>Deposit amount</FormControl.Label>
                                                <Input
                                                    fontSize={'md'}
                                                    size={'xl'}
                                                    value={depositAmount}
                                                    onChange={(event) => setDepositAmount(event.nativeEvent.text)}
                                                    onBlur={(event) => onChangeNumber(event, setDepositAmount)}
                                                    keyboardType={'numeric'}
                                                    placeholder={'Deposit amount'}
                                                    leftElement={<Text color={useColorModeValue('gray.500', 'gray.400')}
                                                                       ml={2}>£</Text>}/>
                                            </FormControl>
                                        </>
                                    )}

                                    <FormControl mt={4}>
                                        <FormControl.Label>
                                            {depositRequired ? 'Final price' : 'Price'}
                                        </FormControl.Label>
                                        <Input
                                            fontSize={'md'}
                                            size={'xl'}
                                            value={price}
                                            onChange={(event) => setPrice(event.nativeEvent.text)}
                                            onBlur={(event) => onChangeNumber(event, setPrice)}
                                            keyboardType={'numeric'}
                                            placeholder={depositRequired ? 'Final price' : 'Price'}
                                            leftElement={
                                                <Text color={useColorModeValue('gray.500', 'gray.400')} ml={2}>£</Text>
                                            }
                                        />
                                        <FormControl.HelperText>
                                            {depositRequired ?
                                                'Provide a final price for your services' :
                                                'Provide a price for your services'
                                            }
                                        </FormControl.HelperText>
                                    </FormControl>

                                    {price?.length > 0 && (
                                        <VStack mt={4}>
                                            <BreakdownOfFees price={price} deposit={depositRequired}
                                                             depositAmount={depositAmount}/>
                                        </VStack>
                                    )}
                                </>
                            )}

                            <FormControl mt={4}>
                                <FormControl.Label>Estimated start date</FormControl.Label>
                                <DateTimePicker value={estimatedStartDate}
                                                onChange={(e) => setEstimatedStartDate(e.target.value)}/>
                                <FormControl.HelperText>
                                    Provide an optional estimated start date, this helps the client understand your
                                    availability
                                </FormControl.HelperText>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormControl.Label>Message</FormControl.Label>
                                <TextArea
                                    value={message}
                                    fontSize={'md'}
                                    size={'xl'}
                                    onChangeText={(text) => setMessage(text)}
                                    placeholder={'Message'}
                                />
                                <FormControl.HelperText>
                                    Provide an optional message for the user
                                </FormControl.HelperText>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormControl.Label>Documents</FormControl.Label>
                                <UploadDocumentsButton documents={documents} setDocuments={setDocuments}/>
                                <FormControl.HelperText>Include an invoice/quote if available.</FormControl.HelperText>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormControl.Label>Terms</FormControl.Label>
                                <FormControl.HelperText>
                                    Terms are not required now, but they are required before the client can make
                                    payment. You can upload this later.
                                </FormControl.HelperText>
                                <UploadDocumentsButton documents={terms} setDocuments={setTerms}/>
                                <FormControl.HelperText>
                                    Please upload any relevant terms and conditions. The client will have to accept
                                    these before payment is made.
                                </FormControl.HelperText>
                            </FormControl>

                            {error && (
                                <Text mt={4} color={'red.500'}>
                                    {error}
                                </Text>
                            )}

                            <Button.Group space={2} mt={8}>
                                <Spacer/>

                                <Button px={8} onPress={submission?.response ? updateResponse : submit}
                                        isLoading={submitting}
                                        minW={32}>
                                    {submission?.response ? 'Update response' : 'Send response'}
                                </Button>
                            </Button.Group>
                        </VStack>
                    )}
                </VStack>
            </KeyboardAvoidingView>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default SubmissionResponse;

import {Linking, StyleSheet, TouchableOpacity} from 'react-native';
import {Heading, HStack, useColorModeValue, useTheme, Text, VStack, Divider, Modal, Flex} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";
import {FontAwesome} from "@expo/vector-icons";

const WhatIsPropServ = ({close}) => {
    const {colors} = useTheme();
    const cardBackground = useColorModeValue('light.50', 'dark.50');
    const gray = useColorModeValue('gray.500', 'gray.400');

    return (
        <Modal isOpen={true}>
            <Modal.Content minW={'80%'}>
                <Modal.Header>
                    <HStack space={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Heading fontSize={'lg'}>What is PropServ?</Heading>
                        <TouchableOpacity onPress={close}>
                            <Ionicons name={'close-outline'} size={24} color={colors.gray[500]}/>
                        </TouchableOpacity>
                    </HStack>
                </Modal.Header>
                <Modal.Body>
                    <VStack space={4} px={4}>
                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'information-circle-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Getting started</Heading>
                                <Text mt={2} color={gray}>
                                    PropServ is a platform that connects you with contractors in your area. You should
                                    have
                                    spoken
                                    with a member of PropServ regarding your service requirements, and your property has
                                    been
                                    submitted to the platform.
                                </Text>
                            </VStack>
                        </Flex>

                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'hammer-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Manage your services</Heading>
                                <Text mt={2} color={gray}>
                                    You should review and edit the services your agent has requested for your property.
                                    You
                                    can also explore additional services available on our platform.
                                </Text>
                            </VStack>
                        </Flex>

                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'chatbubbles-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Explore contractors</Heading>
                                <Text mt={2} color={gray}>
                                    Browse through a curated list of vetted contractors who are available for the
                                    services
                                    you've selected. Use our in-app chat to discuss your specific needs and expectations
                                    directly with contractors.
                                </Text>
                            </VStack>
                        </Flex>

                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'checkmark-done-circle-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Review and accept offers</Heading>
                                <Text mt={2} color={gray}>
                                    Receive quotes from contractors, on this page you can review their offers, and
                                    choose
                                    the best fit for your project.
                                </Text>
                            </VStack>
                        </Flex>

                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'card-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Secure payments</Heading>
                                <Text mt={2} color={gray}>
                                    Pay for the job securely through the platform, this may include a deposit payment to
                                    secure the contractor's availability.
                                </Text>
                            </VStack>
                        </Flex>

                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'home-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Completion</Heading>
                                <Text mt={2} color={gray}>
                                    Once the work is done, simply mark the work as complete to close out the job. Once
                                    you're happy and all jobs are marked as complete, you can close the submission. You
                                    can
                                    always reopen it if you need to.
                                </Text>
                            </VStack>
                        </Flex>

                        <Divider my={4}/>

                        <Flex direction={{base: 'column', md: 'row'}} gap={{base: 2, md: 4}} py={{base: 2, md: 0}} alignItems={{base: 'flex-start', md: 'center'}}>
                            <Ionicons name={'help-circle-outline'} size={32} color={colors.primary[500]}/>
                            <VStack flex={1} p={{base: 0, md: 4}} rounded={'lg'} bg={cardBackground}>
                                <Heading fontSize={'md'}>Support</Heading>
                                <Text mt={2} color={gray}>
                                    If you have any questions or need help with anything, please don't hesitate to reach
                                    out
                                    to our support team. We're here to help.
                                </Text>

                                <HStack mt={2} space={2} flexWrap={'wrap'}>
                                    <TouchableOpacity onPress={() => Linking.openURL('https://wa.me/447466197009')}>
                                        <HStack space={2} bg={'#25D366'} rounded={'full'} p={2} px={4}>
                                            <FontAwesome name={'whatsapp'} size={24} color={'white'}/>
                                            <Text selectable bold color={'white'} fontSize={'md'}>
                                                +44 7466 197009
                                            </Text>
                                        </HStack>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={() => Linking.openURL('mailto:info@propserv.co')}>
                                        <HStack space={2} bg={'primary.500'} rounded={'full'} p={2} px={4}>
                                            <FontAwesome name={'envelope'} size={24} color={'white'}/>
                                            <Text selectable bold color={'white'} fontSize={'md'}>
                                                info@propserv.co
                                            </Text>
                                        </HStack>
                                    </TouchableOpacity>
                                </HStack>
                            </VStack>
                        </Flex>
                    </VStack>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

const styles = StyleSheet.create({});

export default WhatIsPropServ;

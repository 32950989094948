import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Badge,
    Box,
    Button,
    Heading,
    HStack,
    Input,
    ScrollView,
    Select,
    Text,
    TextArea,
    useColorModeValue,
    useTheme,
    VStack
} from "native-base";
import * as React from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import PhoneInput from "react-native-international-phone-number";
import {useCallback, useEffect, useRef, useState} from "react";
import MapView from "react-native-maps";
import {get} from "../../../services/api";
import debounce from 'lodash.debounce';
import {Picker} from "react-native-web";
import StyledPicker from "../../../components/StyledPicker";
import {BlurView} from "expo-blur";

const CompanyDetails = props => {
    const {
        web,
        setCompanyName,
        userType,
        setCompanyDescription,
        categories,
        companyCategories,
        setCompanyCategories,
        companyOnPropServ,
        setCompanyOnPropServ,
        setCompanyReferralCode,
        setCompanyWebsite,
        setCompanyPhoneNumber,
        setCompanyEmail,
        companyAreas,
        setCompanyAreas,
    } = props;

    const {colors} = useTheme();

    const companyAreasRef = useRef(companyAreas);
    const mapRef = useRef(null);

    const [countryCode, setCountryCode] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const grayText = useColorModeValue('gray.500', 'gray.400');

    const addCompanyCategory = (category) => {
        if (companyCategories.find(item => item.id === category.id)) {
            return;
        }

        setCompanyCategories([...companyCategories, category])
    }

    useEffect(() => {
        companyAreasRef.current = companyAreas;
    }, [companyAreas]);

    useEffect(() => {
        if (mapRef.current) {
            const region = calculateInitialRegion();
            const zoomLevel = calculateZoomLevel(region);
            mapRef.current.animateCamera({
                center: {
                    latitude: region.latitude,
                    longitude: region.longitude,
                },
                zoom: zoomLevel,
                pitch: 0,
                heading: 0,
            }, {duration: 1000});
        }
    }, [companyAreas]);

    useEffect(() => {
        setCompanyPhoneNumber(countryCode?.callingCode + " " + phoneNumber);
    }, [countryCode, phoneNumber]);

    const fetchLatLong = async (postcode) => {
        return get('/postcode-lookup', {postcode}).then(response => {
            return {
                ...response,
                formattedPostcode: response.postcode,
            };
        });
    }

    const debouncedFetchLatLong = useCallback(debounce(async (index, postcode) => {
        const {latitude, longitude} = await fetchLatLong(postcode);
        setCompanyAreas(prevAreas => prevAreas.map((item, i) => i === index ? {
            ...item,
            latitude,
            longitude
        } : item));
    }, 1000), []);

    const handlePostcodeChange = (text, index) => {
        setCompanyAreas(prevAreas => prevAreas.map((item, i) => i === index ? {
            ...item,
            postcode: text
        } : item));
        debouncedFetchLatLong(index, text);
    };

    const calculateInitialRegion = () => {
        if (companyAreas.length === 0) {
            return {
                latitude: 54.3781,
                longitude: -2.1228,
                latitudeDelta: 15,
                longitudeDelta: 15
            };
        }

        const latitudes = companyAreas.filter(item => item.latitude).map(item => parseFloat(item.latitude));
        const longitudes = companyAreas.filter(item => item.longitude).map(item => parseFloat(item.longitude));

        const minLatitude = Math.min(...latitudes);
        const maxLatitude = Math.max(...latitudes);
        const minLongitude = Math.min(...longitudes);
        const maxLongitude = Math.max(...longitudes);

        const latitude = (minLatitude + maxLatitude) / 2;
        const longitude = (minLongitude + maxLongitude) / 2;

        const latitudeDelta = maxLatitude - minLatitude;
        const longitudeDelta = maxLongitude - minLongitude;

        return {
            latitude,
            longitude,
            latitudeDelta: latitudeDelta + 0.5,  // Add some padding
            longitudeDelta: longitudeDelta + 0.5,  // Add some padding
        };
    };

    const calculateZoomLevel = (region) => {
        const {latitudeDelta, longitudeDelta} = region;

        // Constants for zoom level calculation
        const WORLD_DIM = {height: 256, width: 256};
        const ZOOM_MAX = 21;

        const latRad = (lat) => {
            const sin = Math.sin(lat * Math.PI / 180);
            const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        };

        const zoom = (mapPx, worldPx, fraction) => Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

        const latFraction = (latRad(region.latitude + latitudeDelta / 2) - latRad(region.latitude - latitudeDelta / 2)) / Math.PI;
        const lngFraction = longitudeDelta / 360;

        const latZoom = zoom(WORLD_DIM.height, WORLD_DIM.height, latFraction);
        const lngZoom = zoom(WORLD_DIM.width, WORLD_DIM.width, lngFraction);

        return Math.min(latZoom, lngZoom, ZOOM_MAX);
    };

    return (
        <VStack space={4} width={'100%'} p={4}>
            <Heading fontSize={web ? 'sm' : 'md'} textAlign={'center'}>
                Is your company on PropServ?
            </Heading>

            <HStack space={4} justifyContent={'center'}>
                <TouchableOpacity style={{flex: 1}} onPress={() => setCompanyOnPropServ(true)}>
                    <VStack
                        space={4}
                        borderColor={companyOnPropServ ? 'primary.500' : 'dark.50'}
                        borderWidth={2}
                        bg={companyOnPropServ ? 'primary.500' : 'light.50'}
                        p={4}
                        borderRadius={8}>
                        <Heading textAlign={'center'} fontSize={'md'}
                                 color={companyOnPropServ ? 'white' : 'black'}>Yes</Heading>
                    </VStack>
                </TouchableOpacity>

                <TouchableOpacity style={{flex: 1}} onPress={() => setCompanyOnPropServ(false)}>
                    <VStack
                        space={4}
                        borderColor={companyOnPropServ === false ? 'primary.500' : 'dark.50'}
                        borderWidth={2}
                        bg={companyOnPropServ === false ? 'primary.500' : 'light.50'}
                        p={4}
                        borderRadius={8}>
                        <Heading textAlign={'center'} fontSize={'md'}
                                 color={companyOnPropServ === false ? 'white' : 'black'}>No</Heading>
                    </VStack>
                </TouchableOpacity>
            </HStack>

            {companyOnPropServ === true && (
                <VStack mt={4} space={4} width={'100%'} p={4} bg={'light.50'} rounded={'lg'}>
                    <Heading fontSize={web ? 'sm' : 'md'} textAlign={'center'} color={'black'}>
                        Please enter your company referral code
                    </Heading>

                    <Input
                        fontSize={'md'}
                        size={'xl'}
                        onChangeText={text => setCompanyReferralCode(text)}
                        placeholder="Referral code"
                        minW={300}
                    />

                    <Text fontSize={'sm'} color={'gray.500'}>
                        If you don't have a referral code, request one from another user in your company.
                    </Text>
                </VStack>
            )}

            {companyOnPropServ === false && (
                <VStack mt={4} space={4} width={'100%'} p={4} bg={'light.50'} rounded={'lg'}>
                    <Heading fontSize={'md'} textAlign={'center'}>
                        Tell us about your company
                    </Heading>

                    <Input
                        color={'black'}
                        fontSize={'md'}
                        size={'xl'}
                        onChangeText={text => setCompanyName(text)}
                        placeholder={"Company name"}
                        minW={300}
                    />

                    <Input
                        color={'black'}
                        fontSize={'md'}
                        size={'xl'}
                        onChangeText={text => setCompanyEmail(text)}
                        placeholder={"Company email"}
                        minW={300}
                    />

                    <Input
                        color={'black'}
                        fontSize={'md'}
                        size={'xl'}
                        onChangeText={text => setCompanyWebsite(text)}
                        placeholder={"Company website"}
                        minW={300}
                        textContentType={'URL'}
                        leftElement={<Text px={2} fontSize={'md'} color={'gray.500'}>https://</Text>}
                    />

                    <PhoneInput
                        placeholder={'Phone number'}
                        placeholderTextColor={'#525252'}
                        defaultCountry="GB"
                        theme="light"
                        value={phoneNumber}
                        selectedCountry={countryCode}
                        modalHeight={'80%'}
                        onChangeSelectedCountry={(code) => setCountryCode(code)}
                        onChangePhoneNumber={(number) => setPhoneNumber(number)}
                        phoneInputStyles={{
                            container: {
                                backgroundColor: null,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: colors.light[300],
                                borderRadius: 4,
                                height: 40,
                            },
                            flagContainer: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderRightWidth: 1.5,
                                borderRightColor: colors.light[300],
                                backgroundColor: null,
                            },
                            flag: {},
                            caret: {
                                color: colors.dark[400],
                                fontSize: 16,
                            },
                            divider: {
                                width: 0,
                            },
                            callingCode: {
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                color: colors.gray[500],
                            },
                            input: {
                                color: 'black',
                            },
                        }}
                        modalStyles={{
                            modal: {
                                backgroundColor: colors.light[50],
                                borderWidth: 0,
                                padding: 16,
                            },
                            backdrop: {},
                            divider: {
                                backgroundColor: 'transparent',
                            },
                            countriesList: {},
                            searchInput: {
                                borderRadius: 8,
                                borderWidth: 0,
                                color: 'black',
                                backgroundColor: colors.light[200],
                                paddingHorizontal: 16,
                                height: 46,
                            },
                            countryButton: {
                                borderWidth: 0,
                                backgroundColor: colors.light[200],
                                marginVertical: 4,
                                paddingVertical: 0,
                            },
                            noCountryText: {},
                            noCountryContainer: {},
                            flag: {
                                color: 'black',
                                fontSize: 20,
                            },
                            callingCode: {
                                color: 'black',
                            },
                            countryName: {
                                color: 'black',
                            },
                        }}
                    />

                    {userType === 'contractor' && (
                        <VStack mb={2}>
                            <HStack space={2} flexWrap={'wrap'} borderRadius={4} borderWidth={1}
                                    borderColor={colors.light[300]} p={2}>
                                {companyCategories.length === 0 && (
                                    <Text pl={1} fontSize={'md'} color={'light.400'}>
                                        Add your company categories
                                    </Text>
                                )}

                                {companyCategories && companyCategories.map((category, index) => (
                                    <TouchableOpacity
                                        key={index}
                                        onPress={() => setCompanyCategories(companyCategories.filter(item => item.id !== category.id))}>
                                        <Badge
                                            my={1}
                                            variant={'subtle'}
                                            bg={'light.300'}
                                            rightIcon={<Ionicons name={'close'} size={16} color={'black'}/>}
                                            rounded={'full'}>
                                            {category.name}
                                        </Badge>
                                    </TouchableOpacity>
                                ))}
                            </HStack>

                            <Box mt={2} borderWidth={1} borderColor={colors.light[300]} borderRadius={4}>
                                <ScrollView maxH={190}>
                                    <HStack p={4} space={2} flexWrap={'wrap'}>
                                        {categories.map((category, index) => (
                                            <TouchableOpacity
                                                key={index}
                                                onPress={() => addCompanyCategory(category)}>
                                                <Badge
                                                    variant={'subtle'}
                                                    bg={'light.300'}
                                                    mb={2}
                                                    rounded={'full'}>
                                                    {category.name}
                                                </Badge>
                                            </TouchableOpacity>
                                        ))}
                                    </HStack>
                                </ScrollView>
                            </Box>
                        </VStack>
                    )}

                    <TextArea
                        color={'black'}
                        onChangeText={text => setCompanyDescription(text)}
                        fontSize={'md'}
                        size={'xl'}
                        placeholder="Give a brief description of your company"
                        minW={300}
                        numberOfLines={4}
                    />
                </VStack>
            )}

            {companyOnPropServ === false && userType === 'contractor' && (
                <VStack mt={4} space={4} width={'100%'} p={4} bg={'light.50'} rounded={'lg'}>
                    <Heading fontSize={'md'} textAlign={'center'}>
                        Where do you operate?
                    </Heading>

                    <Text color={'gray.500'}>
                        This is not necessarily your company address, it's the area where you will be able to provide
                        services to customers.
                    </Text>

                    <MapView
                        mapType={'standard'}
                        toolbarEnabled={false}
                        ref={mapRef}
                        showsMyLocationButton={false}
                        showsCompass={false}
                        style={{height: 500, width: '100%'}}
                        initialRegion={{
                            latitude: 54.3781,
                            longitude: -2.1228,
                            latitudeDelta: 15,
                            longitudeDelta: 15
                        }}
                    >
                        {companyAreas.filter(area => area.latitude && area.longitude).map((area, index) => (
                            <MapView.Circle
                                key={index}
                                center={{
                                    latitude: parseFloat(area.latitude),
                                    longitude: parseFloat(area.longitude)
                                }}
                                radius={parseFloat(area.radius) * 1609.34}
                                strokeWidth={1}
                                strokeColor={colors.primary[500]}
                                fillColor={colors.primary[500]}
                            />
                        ))}
                    </MapView>

                    {companyAreas.map((area, index) => (
                        <VStack key={index} space={4} width={'100%'} p={4} bg={'light.100'} rounded={'lg'}>
                            <HStack space={4} alignItems={'center'}>
                                <VStack flex={1}>
                                    <Text mb={1} fontSize={'xs'} color={grayText}>
                                        Postcode
                                        <Text ml={1} color={'red.500'}>*</Text>
                                    </Text>
                                    <Input
                                        color={'black'}
                                        fontSize={'md'}
                                        size={'xl'}
                                        onChangeText={text => handlePostcodeChange(text, index)}
                                        placeholder={"Postcode"}
                                    />
                                </VStack>

                                <VStack flex={1}>
                                    <Text mb={1} fontSize={'xs'} color={grayText}>
                                        Radius (miles)
                                        <Text ml={1} color={'red.500'}>*</Text>
                                    </Text>

                                    <StyledPicker
                                        selectedValue={area.radius}
                                        onValueChange={itemValue => setCompanyAreas(companyAreas.map((item, i) => i === index ? {
                                            ...item,
                                            radius: itemValue
                                        } : item))}>
                                        <Picker.Item label="5 miles" value="5"/>
                                        <Picker.Item label="10 miles" value="10"/>
                                        <Picker.Item label="15 miles" value="15"/>
                                        <Picker.Item label="20 miles" value="20"/>
                                        <Picker.Item label="25 miles" value="25"/>
                                        <Picker.Item label="30 miles" value="30"/>
                                        <Picker.Item label="35 miles" value="35"/>
                                    </StyledPicker>

                                    {/*<Select*/}
                                    {/*    dropdownIcon={<Ionicons style={{marginRight: 8}} name={'chevron-down'} size={16}*/}
                                    {/*                            color={'black'}/>}*/}
                                    {/*    fontSize={'md'}*/}
                                    {/*    size={'xl'}*/}
                                    {/*    mt={0}*/}
                                    {/*    selectedValue={area.radius}*/}
                                    {/*    accessibilityLabel="Radius (miles)"*/}
                                    {/*    placeholder="radius"*/}
                                    {/*    onValueChange={itemValue => setCompanyAreas(companyAreas.map((item, i) => i === index ? {*/}
                                    {/*        ...item,*/}
                                    {/*        radius: itemValue*/}
                                    {/*    } : item))}>*/}
                                    {/*    <Select.Item label="5 miles" value="5"/>*/}
                                    {/*    <Select.Item label="10 miles" value="10"/>*/}
                                    {/*    <Select.Item label="15 miles" value="15"/>*/}
                                    {/*    <Select.Item label="20 miles" value="20"/>*/}
                                    {/*    <Select.Item label="25 miles" value="25"/>*/}
                                    {/*    <Select.Item label="30 miles" value="30"/>*/}
                                    {/*    <Select.Item label="35 miles" value="35"/>*/}
                                    {/*</Select>*/}
                                </VStack>

                                <TouchableOpacity style={{marginTop: 18}}
                                                  onPress={() => setCompanyAreas(companyAreas.filter((item, i) => i !== index))}>
                                    <Ionicons name={'close'} size={24} color={'red'}/>
                                </TouchableOpacity>
                            </HStack>
                        </VStack>
                    ))}

                    <Button
                        onPress={() => setCompanyAreas([...companyAreas, {postcode: '', radius: "5"}])}
                        variant={'outline'}
                        colorScheme={'primary'}
                        size={'sm'}>
                        Add another area
                    </Button>

                    <Text color={'gray.500'}>
                        Need nationwide coverage? Contact us to discuss your requirements.
                    </Text>
                </VStack>
            )}
        </VStack>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    cluster: {
        backgroundColor: 'salmon',
        width: 20,
        height: 20,
        borderRadius: 999,
        alignItems: 'center',
        justifyContent: 'center',
    },
    clusterText: {
        fontWeight: '700',
    },
});

export default CompanyDetails;

import {Dimensions, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    Heading,
    Image,
    Input,
    Spinner,
    Text,
    useTheme,
    VStack,
    View,
    HStack,
    Checkbox,
    Modal
} from "native-base";
import * as React from "react";
import PhoneInput from "react-native-international-phone-number";
import {useNavigation} from "@react-navigation/native";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useState} from "react";
import TermsOfBusiness from "../../../components/TermsOfBusiness";

const ThirdPage = props => {
    const {
        prevPage,
        userType,
        userId,
        name,
        setName,
        web,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        setPassword,
        setConfirmPassword,
        error,
        registering,
        register,
        countryCode,
        setCountryCode,
        acceptedTerms,
        setAcceptedTerms
    } = props;

    const [showTerms, setShowTerms] = useState(false);

    const styles = createStyles(web);

    const {colors} = useTheme();
    const navigation = useNavigation();

    return (
        <View style={styles.page} maxWidth={'600px'} width={'100%'} p={4}>
            <VStack space={4} width={'100%'} p={4} bg={'light.50'} rounded={'lg'}>
                {!web && (
                    <Image
                        height={150}
                        width={400}
                        maxW={'80%'}
                        mb={4}
                        alignSelf={'center'}
                        resizeMode={'contain'}
                        source={require('../../../../assets/PropServBlack.png')}
                        alt={'PropServ Logo'}
                    />
                )}

                <Heading fontSize={'md'} textAlign={'center'}>
                    Tell us about you
                </Heading>

                <Input value={name} color={'black'} fontSize={'md'} size={'xl'} onChangeText={text => setName(text)}
                       placeholder="Full name"/>

                <Input value={email} color={'black'} fontSize={'md'} size={'xl'} onChangeText={text => setEmail(text)}
                       placeholder="Email"
                       minW={300}/>

                <PhoneInput
                    placeholder={'Phone number'}
                    placeholderTextColor={'#525252'}
                    defaultCountry="GB"
                    theme="light"
                    value={phoneNumber}
                    selectedCountry={countryCode}
                    modalHeight={'80%'}
                    onChangeSelectedCountry={(code) => setCountryCode(code)}
                    onChangePhoneNumber={(number) => setPhoneNumber(number)}
                    phoneInputStyles={{
                        container: {
                            backgroundColor: null,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: colors.light[300],
                            borderRadius: 4,
                            height: 40,
                        },
                        flagContainer: {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderRightWidth: 1.5,
                            borderRightColor: colors.light[300],
                            backgroundColor: null,
                        },
                        flag: {},
                        caret: {
                            color: colors.dark[400],
                            fontSize: 16,
                        },
                        divider: {
                            width: 0,
                        },
                        callingCode: {
                            padding: 0,
                            margin: 0,
                            fontSize: 16,
                            color: 'black',
                        },
                        input: {
                            color: 'black',
                        },
                    }}
                    modalStyles={{
                        modal: {
                            backgroundColor: colors.light[50],
                            borderWidth: 0,
                            padding: 16,
                        },
                        backdrop: {},
                        divider: {
                            backgroundColor: 'transparent',
                        },
                        countriesList: {},
                        searchInput: {
                            borderRadius: 8,
                            borderWidth: 0,
                            color: 'black',
                            backgroundColor: colors.light[200],
                            paddingHorizontal: 16,
                            height: 46,
                        },
                        countryButton: {
                            borderWidth: 0,
                            backgroundColor: colors.light[200],
                            marginVertical: 4,
                            paddingVertical: 0,
                        },
                        noCountryText: {},
                        noCountryContainer: {},
                        flag: {
                            color: 'black',
                            fontSize: 20,
                        },
                        callingCode: {
                            color: 'black',
                        },
                        countryName: {
                            color: 'black',
                        },
                    }}
                />

                {userId === null && (
                    <>
                        <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'}
                               onChangeText={text => setPassword(text)}
                               placeholder="Password"/>
                        <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'}
                               onChangeText={text => setConfirmPassword(text)}
                               placeholder="Confirm password"/>
                    </>
                )}

                <Checkbox bg={'light.200'} mt={4} value={acceptedTerms} onChange={setAcceptedTerms}>
                    <Text fontSize={'sm'} color={'black'}>I accept the
                        <Button onPress={() => setShowTerms(true)} pl={1} variant={'link'}>terms and conditions</Button>
                    </Text>
                </Checkbox>
            </VStack>

            <VStack my={4} space={4} width={'100%'}>
                {error && <Text color={'error.500'}>{error}</Text>}

                <HStack mt={8} space={4} alignItems={'center'} justifyContent={'space-between'}>
                    <TouchableOpacity onPress={() => prevPage(2)}>
                        <HStack space={2} alignItems={'center'}>
                            <Ionicons name={'arrow-back'} size={24} color={colors.gray[500]}/>
                            <Text fontSize={'sm'} color={'gray.500'}>Back</Text>
                        </HStack>
                    </TouchableOpacity>
                    <Button flex={1} isDisabled={registering || !acceptedTerms} disabled={registering || !acceptedTerms}
                            onPress={register}>
                        {registering ? <Spinner size={'sm'} color={'white'}/> : 'Register'}
                    </Button>
                </HStack>

                <Button variant={'link'} onPress={() => navigation.navigate('Login')}>
                    Already have an account? Login
                </Button>
            </VStack>

            <Modal isOpen={showTerms} onClose={() => setShowTerms(false)} size={'full'}>
                <Modal.Content maxWidth="700px">
                    <Modal.CloseButton/>
                    <Modal.Header>Terms</Modal.Header>
                    <Modal.Body>
                        <TermsOfBusiness/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button onPress={() => {
                                setShowTerms(false);
                            }}>
                                Close
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </View>
    );
};

const createStyles = (web) => {
    return StyleSheet.create({
        page: {
            flex: web ? null : 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
    });
}
export default ThirdPage;

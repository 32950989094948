import {Linking, SafeAreaView, StyleSheet} from 'react-native';
import {Button, Heading, HStack, Image, ScrollView, Text, useColorModeValue, useTheme, View, VStack} from "native-base";
import * as React from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import {post} from "../services/api";
import {useEffect, useState} from "react";

const Subscribe = props => {
    const {colors} = useTheme();

    const [loading, setLoading] = useState(true);
    const [subscribeURL, setSubscribeURL] = useState(null);
    const [alreadySubscribed, setAlreadySubscribed] = useState(false);

    useEffect(() => {
        post('/contractor/subscribe/beta').then(response => {
            setSubscribeURL(response.url);
            setLoading(false);
        }).catch(error => {
            if (error.response?.data?.code === 'already_subscribed') {
                setAlreadySubscribed(true);
                setLoading(false);
            }
        })
    }, []);

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    <Image height={100} width={300} mt={4} maxW={'70%'} alignSelf={'center'} resizeMode={'contain'}
                           source={useColorModeValue(require('../../assets/PropServBlack.png'), require('../../assets/PropServ.png'))}
                           alt={'PropServ Logo'}/>

                    <Text fontSize={'xl'} textAlign={'center'}>Subscribe to PropServ to get access to all
                        features</Text>

                    <VStack space={4} bg={useColorModeValue('light.50', 'dark.50')} p={4} rounded={'xl'}>
                        <VStack space={2}>
                            <HStack space={2} alignItems={'center'}>
                                <Ionicons name={'checkmark-circle'} size={24} color={colors.primary[500]}/>
                                <Text bold>Access to all property submissions</Text>
                            </HStack>
                            <HStack space={2} alignItems={'center'}>
                                <Ionicons name={'checkmark-circle'} size={24} color={colors.primary[500]}/>
                                <Text bold>In-app chat with the estate agent and movers/landlords</Text>
                            </HStack>
                            <HStack space={2} alignItems={'center'}>
                                <Ionicons name={'checkmark-circle'} size={24} color={colors.primary[500]}/>
                                <Text bold>Quote for jobs and receive payouts via Stripe</Text>
                            </HStack>
                        </VStack>
                    </VStack>

                    <VStack space={4} bg={useColorModeValue('primary.100', 'primary.900')} p={4} rounded={'xl'}>
                        <HStack
                            bg={'primary.500'}
                            p={1}
                            px={8}
                            rounded={'full'}
                            alignSelf={'flex-start'}
                            space={1}
                            justifyContent={'center'}>
                            <Text bold color={'white'} fontSize={'sm'}>BETA</Text>
                        </HStack>

                        <Text>
                            As we are still in beta, PropServ is a one time payment of £29.99 for the duration of the
                            beta,
                            rather than a monthly subscription.
                        </Text>
                    </VStack>

                    {!alreadySubscribed && (
                        <Button mt={4} bg={'primary.500'}
                                isLoading={loading}
                                onPress={() => Linking.openURL(subscribeURL)}>Subscribe</Button>
                    )}

                    {alreadySubscribed && (
                        <VStack space={4} bg={useColorModeValue('light.50', 'dark.50')} p={4} rounded={'xl'}>
                            <Text>You are already subscribed to PropServ</Text>
                        </VStack>
                    )}
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default Subscribe;

import React, {useRef, useState} from 'react';
import {
    Input,
    IconButton,
    HStack,
    useColorModeValue,
    VStack,
    Divider,
    Box,
    Image,
    Circle,
    Text,
    Spinner,
} from 'native-base';
import Ionicons from "@expo/vector-icons/Ionicons";
import {TouchableOpacity} from "react-native";

const ChatInputToolbar = ({
                              sending,
                              onSend,
                              onUploadImage,
                              onUploadDocument,
                              imagesToSend,
                              setImagesToSend,
                              documentsToSend,
                              setDocumentsToSend
                          }) => {
    const [message, setMessage] = useState('');
    const inputBgColor = useColorModeValue('light.200', 'gray.800');

    const inputRef = useRef();

    const handleSend = () => {
        if (message.trim()) {
            onSend(message);
            setMessage('');

            inputRef.current.style.height = '40px';
        }
    };

    return (
        <VStack>
            {(imagesToSend.length > 0 || documentsToSend.length > 0) && (
                <VStack space={2}>
                    <Divider/>
                    <HStack space={2} mb={4} flexWrap={'wrap'}>
                        {imagesToSend.map(image => (
                            <Box>
                                <Image resizeMode={'contain'} source={{uri: image.uri}}
                                       style={{width: 100, height: 100}}/>
                                <TouchableOpacity
                                    onPress={() => {
                                        setImagesToSend(prevState => prevState.filter(i => i.uri !== image.uri))
                                    }}
                                    style={{position: 'absolute', top: 2, right: -2}}>
                                    <Circle size={6} bg={'gray.500'} justifyContent={'center'}
                                            alignItems={'center'}>
                                        <Ionicons name={'close'} size={16} color={'white'}/>
                                    </Circle>
                                </TouchableOpacity>
                            </Box>
                        ))}
                    </HStack>

                    <HStack space={2} mb={4} flexWrap={'wrap'}>
                        {documentsToSend.map(document => (
                            <Box bg={useColorModeValue('light.50', 'dark.50')} p={2} rounded={'xl'} mb={2}>
                                <HStack space={2} alignItems={'center'}>
                                    <Ionicons name={'document'} size={16}
                                              color={useColorModeValue('black', 'white')}/>
                                    <Text>{document.name}</Text>
                                </HStack>
                                <TouchableOpacity
                                    onPress={() => {
                                        setDocumentsToSend(prevState => prevState.filter(i => i.uri !== document.uri))
                                    }}
                                    style={{position: 'absolute', top: -12, right: -12}}>
                                    <Circle size={6} bg={'gray.500'} justifyContent={'center'}
                                            alignItems={'center'}>
                                        <Ionicons name={'close'} size={16} color={'white'}/>
                                    </Circle>
                                </TouchableOpacity>
                            </Box>
                        ))}
                    </HStack>
                </VStack>
            )}
            <HStack alignItems="center" space={2}>
                <IconButton
                    bg={'primary.500'}
                    _hover={{bg: 'primary.600'}}
                    rounded={'full'}
                    icon={<Ionicons name="image-outline" size="sm" color={'white'}/>}
                    onPress={onUploadImage}
                />
                <IconButton
                    bg={'primary.500'}
                    _hover={{bg: 'primary.600'}}
                    rounded={'full'}
                    icon={<Ionicons name="document-attach-outline" size="sm" color={'white'}/>}
                    onPress={onUploadDocument}
                />
                <Input
                    ref={inputRef}
                    borderWidth={0.5}
                    borderColor={'transparent'}
                    multiline
                    numberOfLines={1}
                    flex={1}
                    fontSize="md"
                    placeholder="Type your message..."
                    bg={inputBgColor}
                    borderRadius="xl"
                    py={2}
                    px={3}
                    onChangeText={setMessage}
                    value={message}
                    maxH={160}
                    onChange={(e) => {
                        e.nativeEvent.target.style.height = 0;
                        e.nativeEvent.target.style.height = `${e.nativeEvent.target.scrollHeight}px`;
                    }}
                />
                <IconButton
                    bg={'primary.500'}
                    _hover={{bg: 'primary.600'}}
                    rounded={'full'}
                    size={'lg'}
                    icon={sending ? <Spinner size="sm" color={'white'}/> : <Ionicons name="send" size="sm" color={'white'}/>}
                    onPress={handleSend}
                    isDisabled={!message.trim() || sending}
                />
            </HStack>
        </VStack>

    );
};

export default ChatInputToolbar;

import {Button, HStack, Input, Modal, Spinner, Text, TextArea} from "native-base";
import {post} from "../services/api";
import * as React from "react";
import {useState} from "react";

const CloseReopenSubmission = ({isOpen, close, submission, loading, setLoading, getSubmission, client = false}) => {
    const [closeReason, setCloseReason] = useState('');

    return (
        <Modal isKeyboardDismissable={false} isOpen={isOpen}
               onClose={close}>
            <Modal.Content maxWidth="500px">
                <Modal.CloseButton/>
                <Modal.Header>
                    {submission.status === 'closed' ? 'Reopen submission' : 'Close submission'}
                </Modal.Header>
                <Modal.Body>
                    {submission.status === 'closed' ? (
                        <Text fontSize={'md'}>
                            Are you sure you want to reopen this submission?
                        </Text>
                    ) : (
                        <Text fontSize={'md'}>
                            Are you sure you want to close this submission? It will become read-only and
                            contractors will not be able to respond.
                        </Text>
                    )}

                    {submission.status !== 'closed' && (
                        <>
                            <Text mt={4} fontSize={'md'}>Why are you closing this submission?</Text>
                            <TextArea
                                mt={1}
                                placeholder={'Please provide a reason'}
                                value={closeReason}
                                onChangeText={(text) => setCloseReason(text)}
                            />
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>
                        <HStack space={4} justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
                            <Button colorScheme="error" onPress={close}>
                                Cancel
                            </Button>
                            <Button isDisabled={loading || (submission.status !== 'closed' && closeReason == '')}
                                    colorScheme="primary" onPress={async () => {
                                setLoading(true);
                                if (submission.status === 'closed') {
                                    await post(client ? `/client/submissions/${submission.id}/reopen` : `/submissions/${submission.id}/reopen`);
                                } else {
                                    await post(client ? `/client/submissions/${submission.id}/close` : `/submissions/${submission.id}/close`, {
                                        close_reason: closeReason
                                    });
                                }
                                await getSubmission();
                                setLoading(false);
                                close();
                            }}>
                                {
                                    loading ? <Spinner style={{paddingHorizontal: 8}} size={'sm'}
                                                       color={'white'}/> : 'Confirm'
                                }
                            </Button>
                        </HStack>
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    );
};

export default CloseReopenSubmission;

import {ActivityIndicator, ImageBackground, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Badge,
    Box,
    Button,
    Center, Checkbox,
    CheckIcon,
    Circle,
    Divider,
    Heading,
    HStack,
    Input,
    Modal,
    ScrollView,
    Spacer,
    Spinner,
    Stack,
    Text,
    TextArea,
    useColorModeValue,
    useTheme,
    View,
    VStack,
} from "native-base";
import {useEffect, useState} from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import {get, post} from "../../services/api";
import UploadDocumentsButton from "../../components/UploadDocumentsButton";
import {appendDocumentsToFormData, appendImagesToFormData} from "../../services/documents";
import * as React from "react";
import Collapsible from "react-native-collapsible";
import UploadImageSection from "../../components/UploadImageSection";
import {Picker} from "react-native-web";

const NewSubmission = (props) => {
    const {colors} = useTheme();

    const submissionId = props.route.params?.submissionId || null;
    const [submission, setSubmission] = useState(null);

    const [addressSearch, setAddressSearch] = useState('');
    const [debouncedAddressSearch, setDebouncedAddressSearch] = useState(addressSearch);

    const [addressResults, setAddressResults] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [addressDetails, setAddressDetails] = useState({address_components: []});

    const [description, setDescription] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [externalDetailsUrl, setExternalDetailsUrl] = useState(null);
    const [newBuild, setNewBuild] = useState(false);
    const [urgent, setUrgent] = useState(false);

    const [contactAgent, setContactAgent] = useState(false);
    const [clientName, setClientName] = useState(null);
    const [clientEmail, setClientEmail] = useState(null);
    const [clientPhone, setClientPhone] = useState(null);

    const [easeOfUseRating, setEaseOfUseRating] = useState(null);
    const [suggestionsForImprovement, setSuggestionsForImprovement] = useState(null);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const [gettingAiSuggestions, setGettingAiSuggestions] = useState(false);
    const [contractorCategories, setContractorCategories] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [aiReasoning, setAiReasoning] = useState(null);
    const [showReasoningModal, setShowReasoningModal] = useState(false);
    const [showAiErrorModal, setShowAiErrorModal] = useState(false);

    const [loadingAddress, setLoadingAddress] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);

    const [openCollapsible, setOpenCollapsible] = useState('addressDetails');

    const [manualAddressEntry, setManualAddressEntry] = useState(false);

    const [submissionError, setSubmissionError] = useState(null);

    const [clientJourney, setClientJourney] = useState('purchasing');
    const clientJourneyOptions = [
        {
            name: 'Purchasing a property',
            value: 'purchasing'
        },
        {
            name: 'Selling a property',
            value: 'selling'
        },
        {
            name: 'Renting a property (tenant)',
            value: 'renting_tenant'
        },
        {
            name: 'Letting a property (landlord)',
            value: 'letting_landlord'
        },
        {
            name: 'Other',
            value: 'other'
        }
    ]

    const cardBackground = useColorModeValue('light.50', 'dark.50');
    const contractorCardBackground = useColorModeValue('light.100', 'dark.100');

    const addressComponentsTemplate = [
        {
            type: 'address_line_1',
            placeholder: 'Address Line 1'
        },
        {
            type: 'postal_town',
            placeholder: 'City'
        },
        {
            type: 'administrative_area_level_2',
            placeholder: 'County'
        },
        {
            type: 'country',
            placeholder: 'Country'
        },
        {
            type: 'postal_code',
            placeholder: 'Postal Code'
        }
    ];

    const purchasingOffPlanCategories = ['furniture_package', 'home_removals', 'snagging_report', 'solicitor', 'surveyor', 'utilities_switch', 'weekly_domestic_cleaning', 'window_dressing'];
    const purchasingNotOffPlanCategories = ['bathroom_fitting', 'electrical_work', 'flooring', 'furniture_package', 'home_removals', 'mortgage_broker', 'painting_decorating', 'solicitor', 'surveyor', 'utilities_switch', 'weekly_domestic_cleaning', 'window_dressing'];
    const rentingCategories = ['furniture_package', 'home_removals', 'solicitor', 'utilities_switch', 'weekly_domestic_cleaning', 'window_dressing'];
    const sellingCategories = ['epc_report', 'floorplan', 'home_staging', 'mortgage_broker', 'painting_decorating', 'photography', 'solicitor'];
    const lettingCategories = ['electrical_work', 'epc_report', 'furniture_package', 'gas_safety_certificate', 'handyman', 'inventory_clerk', 'painting_decorating', 'pre_post_tenancy_cleaning'];

    const grayText = useColorModeValue('gray.500', 'gray.400');
    const pickerBg = useColorModeValue(colors.light[50], colors.dark[50]);
    const pickerBorder = useColorModeValue('rgb(212, 212, 212)', 'rgb(64, 64, 64)');
    const blackWhiteColor = useColorModeValue('black', 'white');
    const lightDarkColor = useColorModeValue('light', 'dark');
    const lightDark200Color = useColorModeValue('light.200', 'dark.200');

    useEffect(() => {
        setDefaultCategories();
    }, [clientJourney, newBuild]);

    useEffect(() => {
        setContractorCategories(availableCategories.map(category => ({
            ...category,
            selected: submission ? submission.contractor_categories.some(subCategory => subCategory.id === category.id) : false
        })));

        setDefaultCategories();
    }, [availableCategories, submission]);

    useEffect(() => {
        get('/company-categories').then(response => {
            setAvailableCategories(response);
        });

        if (submissionId) {
            get(`/submissions/${submissionId}`).then(response => {
                setSubmission(response);

                setManualAddressEntry(true);
                setAddressDetails({
                    address_components: [
                        {
                            long_name: response.address.line_1,
                            short_name: response.address.line_1,
                            types: ['address_line_1']
                        },
                        {
                            long_name: response.address.city,
                            short_name: response.address.city,
                            types: ['postal_town']
                        },
                        {
                            long_name: response.address.county,
                            short_name: response.address.county,
                            types: ['administrative_area_level_2']
                        },
                        {
                            long_name: response.address.country,
                            short_name: response.address.country,
                            types: ['country']
                        },
                        {
                            long_name: response.address.postcode,
                            short_name: response.address.postcode,
                            types: ['postal_code']
                        }
                    ]
                });
                setDescription(response.description);
                setExternalDetailsUrl(response.external_details_url);
                setNewBuild(response.new_build);
                setClientJourney(response.client_journey);
                setDocuments(response.documents);
                setImages(response.images);
            });
        }
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedAddressSearch(addressSearch);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [addressSearch]);

    useEffect(() => {
        const addressLookup = async () => {
            setLoadingAddress(true);
            const response = await post('/address-lookup', {
                searchTerm: debouncedAddressSearch
            });
            setAddressResults(response.results);
            setLoadingAddress(false);
        };

        if (debouncedAddressSearch) {
            addressLookup();
        }
    }, [debouncedAddressSearch]);

    useEffect(() => {
        const addressDetails = async () => {
            if (selectedAddress) {
                setAddressResults([]);
                let response = await get(`/address-details/${selectedAddress.place_id}`);

                const streetNumber = response.result.address_components?.find(comp => comp.types.includes('street_number'))?.long_name || '';
                const route = response.result.address_components?.find(comp => comp.types.includes('route'))?.long_name || '';

                // merge streetNumber and route into address_line_1
                response.result.address_components = response.result.address_components.filter(comp => !comp.types.includes('street_number') && !comp.types.includes('route'));
                response.result.address_components.push({
                    long_name: `${streetNumber} ${route}`.trim(),
                    short_name: `${streetNumber} ${route}`.trim(),
                    types: ['address_line_1']
                });

                setAddressDetails(response.result);
            }
        };

        addressDetails();
    }, [selectedAddress]);

    const setDefaultCategories = () => {
        if (submissionId === null) {
            if (clientJourney === 'purchasing') {
                if (newBuild === 'true') {
                    setContractorCategories(prevState => prevState.map(category => ({
                        ...category,
                        selected: purchasingOffPlanCategories.includes(category.key)
                    })));
                } else {
                    setContractorCategories(prevState => prevState.map(category => ({
                        ...category,
                        selected: purchasingNotOffPlanCategories.includes(category.key)
                    })));
                }
            }
            if (clientJourney === 'renting_tenant') {
                setContractorCategories(prevState => prevState.map(category => ({
                    ...category,
                    selected: rentingCategories.includes(category.key)
                })));
            }
            if (clientJourney === 'selling') {
                setContractorCategories(prevState => prevState.map(category => ({
                    ...category,
                    selected: sellingCategories.includes(category.key)
                })));
            }
            if (clientJourney === 'letting_landlord') {
                setContractorCategories(prevState => prevState.map(category => ({
                    ...category,
                    selected: lettingCategories.includes(category.key)
                })));
            }
            if (clientJourney === 'other') {
                setContractorCategories(prevState => prevState.map(category => ({
                    ...category,
                    selected: false
                })));
            }
        }
    }

    const validate = () => {
        let isValid = true;

        if (!getAddressComponentValue('address_line_1')) {
            setValidationErrors(prevState => [...prevState, 'Address line 1 is required']);
            isValid = false;
        }

        if (!getAddressComponentValue('postal_town')) {
            setValidationErrors(prevState => [...prevState, 'City is required']);
            isValid = false;
        }

        if (!getAddressComponentValue('administrative_area_level_2')) {
            setValidationErrors(prevState => [...prevState, 'County is required']);
            isValid = false;
        }

        if (!getAddressComponentValue('postal_code')) {
            setValidationErrors(prevState => [...prevState, 'Postal code is required']);
            isValid = false;
        }

        if (!getAddressComponentValue('country')) {
            setValidationErrors(prevState => [...prevState, 'Country is required']);
            isValid = false;
        }

        if (!description) {
            setValidationErrors(prevState => [...prevState, 'Description is required']);
            isValid = false;
        }

        if (!clientJourney) {
            setValidationErrors(prevState => [...prevState, 'Client journey is required']);
            isValid = false;
        }

        if (contractorCategories.filter(category => category.selected).length === 0) {
            setValidationErrors(prevState => [...prevState, 'At least one contractor service is required']);
            isValid = false;
        }

        return isValid;
    }

    const getAiSuggestions = async () => {
        setGettingAiSuggestions(true);
        post('/ai-suggestions', {
            description: description,
            city: getAddressComponentValue('postal_town') ?? null,
            client_journey: clientJourneyOptions.find(option => option.value === clientJourney).name,
            off_plan: newBuild,
        }).then(response => {
            setAiReasoning(response.reasoning);
            setContractorCategories(prevState => prevState.map(category => ({
                ...category,
                selected: response.suggestions.includes(category.name)
            })));
        }).catch(error => {
            setShowAiErrorModal(true);
        }).finally(() => {
            setGettingAiSuggestions(false);
        });
    }

    const submit = async () => {
        setValidationErrors([]);
        setSubmitting(true);

        if (!validate()) {
            setSubmitting(false);
            return;
        }

        const formattedAddress = {
            line_1: getAddressComponentValue('address_line_1'),
            line_2: getAddressComponentValue('address_line_2') || '',
            city: getAddressComponentValue('postal_town'),
            county: getAddressComponentValue('administrative_area_level_2') || '',
            postcode: getAddressComponentValue('postal_code'),
            country: getAddressComponentValue('country')
        };

        let formData = new FormData();
        formData.append('address', JSON.stringify(formattedAddress));
        formData.append('description', description);
        formData.append('contractor_categories', JSON.stringify(contractorCategories.filter(category => category.selected).map(category => category.id)));
        formData.append('external_details_url', externalDetailsUrl ?? '');
        formData.append('new_build', newBuild);
        formData.append('client_journey', clientJourney);
        formData.append('handled_by_agent', contactAgent);
        formData.append('urgent', urgent);

        if (clientName && clientEmail && clientPhone) {
            formData.append('client', JSON.stringify({
                name: clientName,
                email: clientEmail,
                phone: clientPhone
            }));
        }

        await appendDocumentsToFormData(formData, documents);
        await appendImagesToFormData(formData, images);

        if (submission) {
            post(`/submissions/${submission.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                setSubmissionSuccessful(true);
            }).catch(error => {
                if (error.response.status === 413) {
                    setSubmissionError('The total size of all files/images exceeds the maximum allowed size of 64MB');
                } else if (error.response.data.message) {
                    setValidationErrors([error.response.data.message]);
                }
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            post('/submissions', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                setSubmissionSuccessful(true);
            }).catch(error => {
                if (error.response.status === 413) {
                    setSubmissionError('The total size of all files/images exceeds the maximum allowed size of 64MB');
                } else if (error.response.data.message) {
                    setValidationErrors([error.response.data.message]);
                }
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }

    const getAddressComponentValue = (type) => {
        return addressDetails?.address_components?.find(comp => comp.types.includes(type))?.long_name || '';
    };

    const updateAddressComponent = (type, value) => {
        if (addressDetails.address_components.find(comp => comp.types.includes(type))) {
            setAddressDetails(prevState => ({
                ...prevState,
                address_components: prevState.address_components.map(comp => comp.types.includes(type) ? {
                    ...comp,
                    long_name: value
                } : comp)
            }));
        } else {
            setAddressDetails(prevState => ({
                ...prevState,
                address_components: [...prevState.address_components, {
                    long_name: value,
                    short_name: value,
                    types: [type]
                }]
            }));
        }
    };

    const submitFeedback = async () => {
        setFeedbackSubmitted(true);

        await post('/feedback', {
            event: 'submission',
            rating: easeOfUseRating,
            comment: suggestionsForImprovement
        });
    }

    const canGetAiSuggestions = () => {
        return description != null &&
            clientJourney != null &&
            getAddressComponentValue('postal_town') != null &&
            getAddressComponentValue('postal_town') !== '' &&
            addressDetails != null;
    }

    const addressDetailsSectionValid = () => {
        return getAddressComponentValue('address_line_1') != null &&
            getAddressComponentValue('postal_town') != null &&
            getAddressComponentValue('administrative_area_level_2') != null &&
            getAddressComponentValue('postal_code') != null &&
            getAddressComponentValue('country') != null &&
            getAddressComponentValue('address_line_1').trim().length > 0 &&
            getAddressComponentValue('postal_town').trim().length > 0 &&
            getAddressComponentValue('administrative_area_level_2').trim().length > 0 &&
            getAddressComponentValue('postal_code').trim().length > 0 &&
            getAddressComponentValue('country').trim().length > 0;
    }

    const propertyDetailsSectionValid = () => {
        return description != null &&
            description.trim().length > 0;
    }

    const clientInformationSectionValid = () => {
        return clientName != null && clientName.trim().length > 0 && clientEmail != null && clientEmail.trim().length > 0 && clientPhone != null && clientPhone.trim().length > 0;
    }

    const contractorServicesSectionValid = () => {
        return contractorCategories.filter(category => category.selected).length > 0;
    }

    if (submitting) {
        return (
            <VStack flex={1} style={{alignSelf: 'center'}}>
                <Center flex={1}>
                    <Heading mb={4}>Submitting</Heading>
                    <ActivityIndicator size="large" color={colors.gray[500]}/>
                </Center>
            </VStack>
        );
    }

    if (submissionSuccessful) {
        return (
            <VStack flex={1} style={{alignSelf: 'center'}}>
                <Center flex={1}>
                    <Heading>
                        {submission ? 'Changes saved' : 'Submission successful'}
                    </Heading>
                    <Text mt={2}>
                        {submission ? 'Your changes have been saved successfully.' : 'Your submission has been successfully submitted.'}
                    </Text>

                    {!submission && (
                        <Box mt={8} p={4} background={cardBackground} rounded={'xl'} borderWidth={2}
                             borderColor={'primary.500'}>
                            <Heading size={'sm'}>Feedback</Heading>
                            <View mt={4}>
                                <Stack>
                                    <Text>How easy was it to submit this property?</Text>
                                    <HStack mt={2} space={2} alignItems={'center'}>
                                        {Array.from({length: 10}, (_, i) => i + 1).map((rating, index) => (
                                            <TouchableOpacity disabled={feedbackSubmitted} key={index}
                                                              onPress={() => setEaseOfUseRating(rating)}>
                                                <Circle size={7}
                                                        bg={easeOfUseRating === rating ? 'primary.500' : 'gray.500'}
                                                        justifyContent={'center'} alignItems={'center'}>
                                                    <Text fontSize={'xs'}
                                                          color={easeOfUseRating === rating ? 'white' : 'gray.100'}>{rating}</Text>
                                                </Circle>
                                            </TouchableOpacity>
                                        ))}
                                    </HStack>
                                    <HStack mt={2} space={2} alignItems={'center'} justifyContent={'space-between'}
                                            maxW={'100%'}>
                                        <Text italic fontSize={'xs'} color={grayText}>Very difficult</Text>
                                        <Text pr={8} italic fontSize={'xs'} color={grayText}>Okay</Text>
                                        <Text italic fontSize={'xs'} color={grayText}>Very easy</Text>
                                    </HStack>
                                </Stack>
                            </View>

                            <View mt={4}>
                                <Stack>
                                    <Text mb={1} fontSize={'xs'} color={grayText}>Suggestions
                                        for improvement</Text>
                                    <TextArea isDisabled={feedbackSubmitted} fontSize={'md'} size={'xl'}
                                              value={suggestionsForImprovement}
                                              onChangeText={text => setSuggestionsForImprovement(text)} minW={300}/>
                                </Stack>
                            </View>

                            {feedbackSubmitted && (
                                <HStack mt={8} alignItems={'center'}>
                                    <CheckIcon size={6} color={'primary.500'}/>
                                    <Text ml={2} color={'primary.500'}>Feedback submitted, thanks!</Text>
                                </HStack>
                            )}

                            {!feedbackSubmitted && (
                                <Button isDisabled={!easeOfUseRating} mt={8} onPress={submitFeedback}>
                                    Submit feedback
                                </Button>
                            )}
                        </Box>
                    )}

                    <Button mt={8} onPress={() => {
                        setEaseOfUseRating(null);
                        setSuggestionsForImprovement(null);
                        setFeedbackSubmitted(false);
                        props.navigation.navigate('Submissions Dashboard')
                    }}>
                        Go to dashboard
                    </Button>
                </Center>
            </VStack>
        );
    }

    if (submissionId && !submission) {
        return (
            <VStack flex={1} style={{alignSelf: 'center'}}>
                <Center flex={1}>
                    <Heading fontSize={'xl'} mb={4}>Loading submission</Heading>
                    <ActivityIndicator size="large"/>
                </Center>
            </VStack>
        );
    }

    return (
        <ScrollView p={4}>
            <VStack w={'100%'} style={{alignSelf: 'center'}} mb={8}>
                <VStack w={'100%'} style={{alignSelf: 'center'}} p={4} mb={4} rounded={'xl'}
                        bg={cardBackground}>
                    <TouchableOpacity
                        onPress={() => setOpenCollapsible(openCollapsible === 'addressDetails' ? null : 'addressDetails')}>
                        <HStack alignItems={'center'}>
                            <Heading size={'sm'}>Property address</Heading>
                            <Text pl={1} color={'red.500'}>*</Text>

                            <Spacer/>

                            <Ionicons
                                style={{marginRight: 8}}
                                name={addressDetailsSectionValid() ? 'checkmark-circle' : 'ellipse-outline'}
                                size={24}
                                color={addressDetailsSectionValid() ? colors.primary[500] : colors.gray[500]}
                            />

                            <Ionicons name={openCollapsible !== 'addressDetails' ? 'chevron-down' : 'chevron-up'}
                                      size={24}
                                      color={'rgb(90, 90, 90)'}/>
                        </HStack>
                    </TouchableOpacity>

                    <Collapsible collapsed={openCollapsible !== 'addressDetails'}>
                        {manualAddressEntry ? (
                            // Display manual entry fields
                            addressComponentsTemplate.map((component, index) => (
                                <View mt={4} key={index} isRequired>
                                    <Stack>
                                        <Text mb={1} fontSize={'xs'} color={grayText}>
                                            {component.placeholder}
                                            <Text ml={1} color={'red.500'}>*</Text>
                                        </Text>
                                        <Input
                                            fontSize={'md'} size={'xl'}
                                            key={index}
                                            value={getAddressComponentValue(component.type)}
                                            onChangeText={(text) => updateAddressComponent(component.type, text)}
                                            placeholder={component.placeholder}
                                            minW={300}
                                        />
                                    </Stack>
                                </View>
                            ))
                        ) : (
                            <View mt={4}>
                                <Stack>
                                    <Text mb={1} fontSize={'xs'} color={grayText}>Address
                                        search</Text>
                                    <Input
                                        fontSize={'md'} size={'xl'}
                                        leftElement={<Ionicons style={{marginLeft: 8}} name={'search'} size={16}
                                                               color={colors.dark[400]}/>}
                                        rightElement={loadingAddress ?
                                            <ActivityIndicator style={{marginRight: 8}} size="small"
                                                               color={colors.gray[500]}/> : null}
                                        onChangeText={text => setAddressSearch(text)} placeholder="Address search"
                                        minW={300}/>
                                </Stack>
                            </View>
                        )}

                        {!selectedAddress && (
                            <Button alignSelf={'left'} variant={'link'} p={0} pt={4}
                                    onPress={() => setManualAddressEntry(!manualAddressEntry)}>
                                {manualAddressEntry ? 'Use address search' : 'Enter address manually'}
                            </Button>
                        )}

                        {addressResults.length > 0 && (
                            <VStack space={1} mt={2} p={2} rounded={'xl'} bg={contractorCardBackground}>
                                <Text p={2} color={grayText}>Select an
                                    address</Text>
                                {addressResults.map((result, index) => (
                                    <View key={result.place_id}>
                                        <TouchableOpacity onPress={() => setSelectedAddress(result)}>
                                            <Text p={2}>{result.description}</Text>
                                        </TouchableOpacity>
                                        {index !== addressResults.length - 1 && <Divider/>}
                                    </View>
                                ))}
                            </VStack>
                        )}

                        {selectedAddress && addressDetails && addressComponentsTemplate.map((component, index) => (
                            <View mt={4} key={index} isRequired>
                                <Stack>
                                    <Text mb={1} fontSize={'xs'} color={grayText}>{component.placeholder}
                                        <Text ml={1} color={'red.500'}>*</Text>
                                    </Text>
                                    <Input
                                        fontSize={'md'} size={'xl'}
                                        key={index}
                                        value={getAddressComponentValue(component.type)}
                                        onChangeText={(text) => updateAddressComponent(component.type, text)}
                                        placeholder={component.placeholder}
                                        minW={300}
                                    />
                                </Stack>
                            </View>
                        ))}
                    </Collapsible>
                </VStack>

                <VStack w={'100%'} style={{alignSelf: 'center'}} p={4} mb={4} rounded={'xl'}
                        bg={cardBackground}>
                    <TouchableOpacity
                        onPress={() => setOpenCollapsible(openCollapsible === 'propertyDetails' ? null : 'propertyDetails')}>
                        <HStack alignItems={'center'}>
                            <Heading size={'sm'}>Property details</Heading>
                            <Text pl={1} color={'red.500'}>*</Text>

                            <Spacer/>

                            <Ionicons
                                style={{marginRight: 8}}
                                name={propertyDetailsSectionValid() ? 'checkmark-circle' : 'ellipse-outline'}
                                size={24}
                                color={propertyDetailsSectionValid() ? colors.primary[500] : colors.gray[500]}
                            />

                            <Ionicons name={openCollapsible !== 'propertyDetails' ? 'chevron-down' : 'chevron-up'}
                                      size={24}
                                      color={'rgb(90, 90, 90)'}/>
                        </HStack>
                    </TouchableOpacity>

                    <Collapsible collapsed={openCollapsible !== 'propertyDetails'}>
                        <View mt={4} isRequired>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    My client is
                                    <Text ml={1} color={'red.500'}>*</Text>
                                </Text>

                                <Picker style={{
                                    minWidth: 300,
                                    height: 40,
                                    borderRadius: 4,
                                    backgroundColor: pickerBg,
                                    borderColor: pickerBorder,
                                    padding: 8,
                                    color: blackWhiteColor
                                }}
                                        selectedValue={clientJourney} onValueChange={setClientJourney}
                                        placeholder="Select an option">
                                    {clientJourneyOptions.map((option, index) => (
                                        <Picker.Item key={index} label={option.name} value={option.value}/>
                                    ))}
                                </Picker>
                            </Stack>
                        </View>

                        <View mt={4} isRequired>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    Description
                                    <Text ml={1} color={'red.500'}>*</Text>
                                </Text>
                                <TextArea key={'description'} fontSize={'md'} size={'xl'} value={description}
                                          onChangeText={setDescription} minW={300}/>
                                <Text mt={1} fontSize={'xs'} color={grayText}>
                                    Include any details that may be of interest to potential contractors.
                                </Text>
                            </Stack>
                        </View>

                        <View mt={4}>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    External details URL (optional)
                                </Text>

                                <Input fontSize={'md'}
                                       size={'xl'}
                                       value={externalDetailsUrl}
                                       onChangeText={text => setExternalDetailsUrl(text)}
                                       textContentType={'URL'}
                                       minW={100}
                                />

                                <Text mt={1} fontSize={'xs'} color={grayText}>
                                    Provide a link for potential contractors to view more details about the
                                    property. E.g. a property listing on a website like Rightmove or Zoopla.
                                </Text>
                            </Stack>
                        </View>

                        <View mt={4}>
                            <Stack>
                                <HStack space={2} alignItems={'center'}>
                                    <Checkbox isChecked={newBuild} value={newBuild} onChange={setNewBuild}
                                              aria-label={'Is this property off-plan?'}/>
                                    <Text fontSize={'xs'} color={grayText}>
                                        Is this property off-plan?
                                    </Text>
                                </HStack>
                            </Stack>
                        </View>

                        <View mt={4}>
                            <Stack>
                                <HStack space={2} alignItems={'center'}>
                                    <Checkbox colorScheme={'danger'} isChecked={urgent} value={urgent} onChange={setUrgent}
                                              aria-label={'Is this an urgent enquiry?'}/>
                                    <Text fontSize={'xs'} color={grayText}>
                                        Is this an urgent enquiry?
                                    </Text>
                                    {urgent && (
                                        <Badge colorScheme={'danger'}>URGENT</Badge>
                                    )}
                                </HStack>
                            </Stack>
                        </View>
                    </Collapsible>
                </VStack>

                <VStack w={'100%'} style={{alignSelf: 'center'}} p={4} mb={4} rounded={'xl'}
                        bg={cardBackground}>
                    <TouchableOpacity
                        onPress={() => setOpenCollapsible(openCollapsible === 'clientInformation' ? null : 'clientInformation')}>
                        <HStack alignItems={'center'}>
                            <Heading size={'sm'}>Client information</Heading>

                            <Spacer/>

                            <Ionicons
                                style={{marginRight: 8}}
                                name={clientInformationSectionValid() ? 'checkmark-circle' : 'ellipse-outline'}
                                size={24}
                                color={clientInformationSectionValid() ? colors.primary[500] : colors.gray[500]}
                            />

                            <Ionicons
                                name={openCollapsible !== 'clientInformation' ? 'chevron-down' : 'chevron-up'}
                                size={24}
                                color={'rgb(90, 90, 90)'}/>
                        </HStack>
                    </TouchableOpacity>

                    <Collapsible collapsed={openCollapsible !== 'clientInformation'}>
                        <Text mt={4} fontSize={'xs'} color={grayText}>
                            Your client's information will be used by contractors to get in touch regarding the
                            property.
                            You can provide this at a later stage if you prefer.
                        </Text>

                        <View mt={4}>
                            <HStack space={2} alignItems={'center'}>
                                <Checkbox
                                    isChecked={contactAgent}
                                    value={contactAgent}
                                    onChange={setContactAgent}
                                    aria-label={'Do you want contractors to contact you?'}
                                />
                                <Text fontSize={'xs'} color={grayText}>
                                    Do you want contractors to contact you? Tick this box if you would like contractors
                                    to contact you directly rather than the client. If you leave this unticked, your
                                    contact details will not be shared with contractors.
                                </Text>
                            </HStack>
                        </View>

                        <View mt={4}>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    Client name
                                </Text>

                                <Input fontSize={'md'}
                                       size={'xl'}
                                       value={clientName}
                                       onChangeText={text => setClientName(text)}
                                       textContentType={'name'}
                                       minW={100}
                                />
                            </Stack>
                        </View>

                        <View mt={4}>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    Client email
                                </Text>

                                <Input fontSize={'md'}
                                       size={'xl'}
                                       value={clientEmail}
                                       onChangeText={text => setClientEmail(text)}
                                       textContentType={'emailAddress'}
                                       minW={100}
                                />
                            </Stack>
                        </View>

                        <View mt={4}>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    Client phone number
                                </Text>

                                <Input fontSize={'md'}
                                       size={'xl'}
                                       value={clientPhone}
                                       onChangeText={text => setClientPhone(text)}
                                       textContentType={'telephoneNumber'}
                                       minW={100}
                                />
                            </Stack>
                        </View>
                    </Collapsible>
                </VStack>

                <VStack w={'100%'} style={{alignSelf: 'center'}} p={4} mb={4} rounded={'xl'}
                        bg={cardBackground}>
                    <TouchableOpacity
                        onPress={() => setOpenCollapsible(openCollapsible === 'contractorServices' ? null : 'contractorServices')}>
                        <HStack alignItems={'center'}>
                            <Heading size={'sm'}>Contractor services</Heading>
                            <Text pl={1} color={'red.500'}>*</Text>

                            <Spacer/>

                            <Ionicons
                                style={{marginRight: 8}}
                                name={contractorServicesSectionValid() ? 'checkmark-circle' : 'ellipse-outline'}
                                size={24}
                                color={contractorServicesSectionValid() ? colors.primary[500] : colors.gray[500]}
                            />

                            <Ionicons
                                name={openCollapsible !== 'contractorServices' ? 'chevron-down' : 'chevron-up'}
                                size={24}
                                color={'rgb(90, 90, 90)'}/>
                        </HStack>
                    </TouchableOpacity>

                    <Collapsible collapsed={openCollapsible !== 'contractorServices'}>
                        <View mt={4} isRequired>
                            <Stack>
                                <Text mb={1} fontSize={'xs'} color={grayText}>
                                    Contractor services
                                    <Text ml={1} color={'red.500'}>*</Text>
                                </Text>

                                <HStack space={2} flexWrap={'wrap'} borderRadius={4} borderWidth={1}
                                        borderColor={'rgb(64, 64, 64)'} p={2}>
                                    {contractorCategories.filter(category => category.selected).length === 0 && (
                                        <Text fontSize={'md'} color={'#525252'}>
                                            Add your company categories
                                        </Text>
                                    )}

                                    {contractorCategories.filter(category => category.selected).map((category, index) => (
                                        <HStack my={1} rounded={'2xl'} px={2} py={1}
                                                bg={lightDark200Color} space={1}
                                                alignItems={'center'} key={index}>
                                            <Text fontSize={'xs'} color={blackWhiteColor}>
                                                {category.name}
                                            </Text>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setContractorCategories(prevState => prevState.map(item => item.id === category.id ? {
                                                        ...item,
                                                        selected: false
                                                    } : item));
                                                }}>
                                                <Ionicons name={'close'} size={16}
                                                          color={blackWhiteColor}
                                                          style={{marginLeft: 4}}/>
                                            </TouchableOpacity>
                                        </HStack>
                                    ))}
                                </HStack>

                                <Text mt={1} fontSize={'xs'} color={grayText}>
                                    Select the contractor services you would like to target for this submission. Use
                                    the AI suggestions button to get some recommendations.
                                </Text>

                                <Box mt={2} bg={contractorCardBackground} p={4} rounded={'xl'}>
                                    <ScrollView maxH={180}>
                                        <HStack flexWrap={'wrap'}>
                                            {availableCategories.map((category, index) => (
                                                <TouchableOpacity
                                                    style={{marginRight: 4}}
                                                    key={index}
                                                    onPress={() => {
                                                        setContractorCategories(prevState => prevState.map(item => item.id === category.id ? {
                                                            ...item,
                                                            selected: true
                                                        } : item));
                                                    }}>
                                                    <Badge
                                                        variant={'subtle'}
                                                        bg={lightDark200Color}
                                                        colorScheme={lightDarkColor}
                                                        mb={2}
                                                        rounded={'2xl'}>
                                                        {category.name}
                                                    </Badge>
                                                </TouchableOpacity>
                                            ))}
                                        </HStack>
                                    </ScrollView>
                                </Box>

                                <TouchableOpacity style={{marginTop: 16}}
                                                  disabled={!canGetAiSuggestions() || gettingAiSuggestions}
                                                  onPress={() => aiReasoning ? setShowReasoningModal(true) : getAiSuggestions()}>
                                    <ImageBackground
                                        blurRadius={4}
                                        borderRadius={100}
                                        resizeMode={'cover'}
                                        resizeMethod={'scale'}
                                        source={require('../../../assets/gradient.webp')}
                                        imageStyle={{
                                            borderRadius: 100
                                        }}
                                        style={{
                                            borderRadius: 100,
                                            width: '100%',
                                            height: 45,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            opacity: !canGetAiSuggestions() ? 0.3 : 1
                                        }}
                                    >
                                        {gettingAiSuggestions ? (
                                            <HStack space={2} alignItems={'center'}>
                                                <Spinner size={'sm'} color={'white'}/>
                                                <Heading color={'white'} size={'sm'}>
                                                    This usually takes a few seconds
                                                </Heading>
                                            </HStack>
                                        ) : (
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons
                                                    name={aiReasoning ? 'information-circle-outline' : 'color-wand-outline'}
                                                    size={24} color={'white'}/>
                                                <Heading color={'white'} size={'sm'}>
                                                    {aiReasoning ? 'Show reasoning' : 'Get AI suggestions'}
                                                </Heading>
                                            </HStack>
                                        )}
                                    </ImageBackground>
                                </TouchableOpacity>

                                {!canGetAiSuggestions() && (
                                    <Text mt={1} fontSize={'xs'} color={grayText}>
                                        You must fill in all the required property details to get AI suggestions.
                                    </Text>
                                )}
                            </Stack>
                        </View>
                    </Collapsible>
                </VStack>

                <VStack w={'100%'} style={{alignSelf: 'center'}} p={4} mb={4} rounded={'xl'}
                        bg={cardBackground}>
                    <TouchableOpacity
                        onPress={() => setOpenCollapsible(openCollapsible === 'documents' ? null : 'documents')}>
                        <HStack alignItems={'center'}>
                            <Heading size={'sm'}>Documents</Heading>
                            <Text fontSize={'sm'} color={'gray.500'} ml={2}>(optional)</Text>

                            <Spacer/>

                            <Ionicons
                                style={{marginRight: 8}}
                                name={documents.length > 0 ? 'checkmark-circle' : 'ellipse-outline'}
                                size={24}
                                color={documents.length > 0 ? colors.primary[500] : colors.gray[500]}
                            />

                            <Ionicons name={openCollapsible !== 'documents' ? 'chevron-down' : 'chevron-up'}
                                      size={24}
                                      color={'rgb(90, 90, 90)'}/>
                        </HStack>
                    </TouchableOpacity>

                    <Collapsible collapsed={openCollapsible !== 'documents'}>
                        <Text my={4} color={grayText}>
                            Upload any documents that may be of interest to potential contractors.
                        </Text>

                        <UploadDocumentsButton documents={documents} setDocuments={setDocuments}/>
                    </Collapsible>
                </VStack>

                <VStack w={'100%'} style={{alignSelf: 'center'}} p={4} mb={4} rounded={'xl'}
                        bg={cardBackground}>
                    <TouchableOpacity
                        onPress={() => setOpenCollapsible(openCollapsible === 'images' ? null : 'images')}>
                        <HStack alignItems={'center'}>
                            <Heading size={'sm'}>Images</Heading>
                            <Text fontSize={'sm'} color={'gray.500'} ml={2}>(optional)</Text>

                            <Spacer/>

                            <Ionicons
                                style={{marginRight: 8}}
                                name={images.length > 0 ? 'checkmark-circle' : 'ellipse-outline'}
                                size={24}
                                color={images.length > 0 ? colors.primary[500] : colors.gray[500]}
                            />

                            <Ionicons name={openCollapsible !== 'images' ? 'chevron-down' : 'chevron-up'} size={24}
                                      color={'rgb(90, 90, 90)'}/>
                        </HStack>
                    </TouchableOpacity>

                    <Collapsible collapsed={openCollapsible !== 'images'}>
                        <Text my={4} color={grayText}>
                            Upload any images that may be of interest to potential contractors.
                        </Text>

                        <UploadImageSection images={images} setImages={setImages}/>
                    </Collapsible>
                </VStack>

                <VStack>
                    {validationErrors.map((error, index) => (
                        <Text mb={2} key={index} color={'red.500'}>{error}</Text>
                    ))}
                </VStack>

                {submissionError && (
                    <Text mb={4} color={'red.400'} fontSize={'md'}>
                        {submissionError}
                    </Text>
                )}

                <Button mt={4} onPress={submit}>
                    {submission ? 'Save changes' : 'Submit'}
                </Button>

                <Modal isOpen={showReasoningModal} onClose={() => setShowReasoningModal(false)}>
                    <Modal.Content maxWidth="400px">
                        <Modal.CloseButton/>
                        <Modal.Header>AI Reasoning</Modal.Header>
                        <Modal.Body>
                            <Text>{aiReasoning}</Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <Button onPress={() => {
                                    setShowReasoningModal(false);
                                }}>
                                    Close
                                </Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>

                <Modal isOpen={showAiErrorModal} onClose={() => setShowAiErrorModal(false)}>
                    <Modal.Content maxWidth="400px">
                        <Modal.CloseButton/>
                        <Modal.Header>AI Suggestions Failed</Modal.Header>
                        <Modal.Body>
                            <Text>
                                AI suggestions failed. Include as much detail as possible in the description field
                                and try again. If the problem persists, please try again later.
                            </Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <Button onPress={() => {
                                    setShowAiErrorModal(false);
                                }}>
                                    Close
                                </Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            </VStack>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default NewSubmission;

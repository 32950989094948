import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Badge,
    Button,
    Heading,
    HStack,
    Input,
    ScrollView,
    Spinner,
    Text,
    useColorModeValue,
    useTheme,
    View,
    VStack
} from 'native-base';
import * as React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Ionicons from "@expo/vector-icons/Ionicons";
import NewSubmission from "./NewSubmission";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {useCallback, useEffect, useState} from "react";
import {get} from "../../services/api";
import SubmissionDetails from "./SubmissionDetails";
import StatusBadge from "../../components/StatusBadge";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";
import Constants from "expo-constants";

const Stack = createNativeStackNavigator();

function EstateAgentHomeStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Submissions Dashboard" component={EstateAgentHome}/>
            <Stack.Screen name="Edit submission" component={NewSubmission}/>
            <Stack.Screen name="New submission" component={NewSubmission}/>
            <Stack.Screen name="Submission" component={SubmissionDetails}/>
        </Stack.Navigator>
    );
}

const EstateAgentHome = props => {
    const navigation = useNavigation();

    const {colors} = useTheme();

    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState('');

    const cardBackground = useColorModeValue('light.50', 'dark.50');
    const responseCardBackground = useColorModeValue('light.100', 'dark.100');
    const grayText = useColorModeValue('gray.500', 'gray.400');

    const getSubmissions = async () => {
        const response = await get('/submissions');
        setSubmissions(response);
        setLoading(false);
    }

    const filteredSubmissions = () => {
        if (search === '') {
            return submissions;
        }

        return submissions.filter(submission => {
            return submission.address.line_1.toLowerCase().includes(search.toLowerCase()) ||
                submission.address.line_2.toLowerCase().includes(search.toLowerCase()) ||
                submission.address.city.toLowerCase().includes(search.toLowerCase()) ||
                submission.address.postcode.toLowerCase().includes(search.toLowerCase()) ||
                (submission.client && submission.client.name.toLowerCase().includes(search.toLowerCase()));
        });
    }

    const exportSubmissions = async () => {
        get('/export/submissions/generate').then(response => {
            if (response.file) {
                window.open(Constants?.manifest?.extra?.env?.EXPO_PUBLIC_API_URL + '/export/submissions/download?file=' + response.file, '_blank');
            }
        });
    }

    useEffect(() => {
        getSubmissions();
    }, []);

    useFocusEffect(
        useCallback(() => {
            getSubmissions();
        }, [])
    );

    return (
        <ScrollView p={4}>
            <VStack space={4} alignSelf={'center'} w={'100%'}>
                <Button onPress={() => navigation.navigate('New submission')}>
                    New submission
                </Button>

                {/*<Button onPress={exportSubmissions}>*/}
                {/*    Export*/}
                {/*</Button>*/}

                {loading && (
                    <Spinner mt={4} size={'lg'}/>
                )}

                {submissions.length > 0 && (
                    <VStack space={2} p={4} bg={cardBackground} rounded={'xl'}>
                        <Heading fontSize={'xl'}>Total potential referral fees</Heading>
                        <HStack space={4}>
                            <Ionicons name={'cash-outline'} size={24} color={colors.green[500]}/>
                            <Heading fontSize={'2xl'}>
                                £{submissions.reduce((total, submission) => total + submission.estate_agent_referral_fees, 0).toLocaleString(undefined, {minimumFractionDigits: 2})}
                            </Heading>
                        </HStack>
                        <Text color={grayText} fontSize={'md'}>
                            Based
                            on {submissions.reduce((total, submission) => total + submission.responses.length, 0)} responses
                            from {submissions.length} {submissions.length === 1 ? 'submission' : 'submissions'}
                        </Text>
                    </VStack>
                )}

                <VStack mb={2} space={2} p={4} bg={cardBackground} rounded={'xl'}>
                    <Input
                        leftElement={<Ionicons style={{marginLeft: 8}} name={'search'} size={16}
                                               color={colors.dark[400]}/>}
                        placeholder="Search submissions"
                        value={search}
                        onChangeText={setSearch}
                    />

                    <Text color={grayText} fontSize={'md'}>
                        Search for submissions by address or client name.
                    </Text>
                </VStack>

                {!loading && submissions.length === 0 && (
                    <Text fontSize={'md'} color={grayText} textAlign={'center'} mt={4}>
                        You have no submissions.
                    </Text>
                )}

                {filteredSubmissions().map(submission => (
                    <TouchableOpacity key={submission.id}
                                      onPress={() => navigation.navigate('Submission', {submissionId: submission.uuid})}>
                        <View p={4} bg={cardBackground} rounded={'xl'} borderWidth={submission.urgent ? '3' : null} borderColor={submission.urgent ? 'danger.200' : null}>
                            <VStack space={2}>
                                {submission.urgent === 1 && (
                                    <StatusBadge status="urgent"/>
                                )}
                                <StatusBadge status={submission.status}/>
                                <Heading>{submission.address.line_1}</Heading>
                                <HStack alignItems={'center'}>
                                    <Ionicons name={'location-outline'} size={24} color={colors.dark[400]}/>
                                    <Text ml={2} fontSize={'md'}>
                                        {submission.address.line_1},{' '}
                                        {submission.address.line_2 && submission.address.line_2 + ', '}
                                        {submission.address.city},{' '}
                                        {submission.address.postcode}
                                    </Text>
                                </HStack>
                                {submission.client != null && (
                                    <HStack alignItems={'center'}>
                                        <Ionicons name={'person-outline'} size={24} color={colors.dark[400]}/>
                                        <Text ml={2} fontSize={'md'}>
                                            {submission.client.name}
                                        </Text>
                                    </HStack>
                                )}

                                {submission.responses.length > 0 && (
                                    <HStack
                                        mt={2}
                                        alignItems={'center'}
                                        bg={responseCardBackground}
                                        px={4}
                                        py={2}
                                        rounded={'md'}
                                        space={2}>
                                        <Ionicons name={'cash-outline'} size={24} color={colors.green[500]}/>
                                        <VStack flex={1}>
                                            <Heading ml={2} fontSize={'2xl'}>
                                                £{submission.estate_agent_referral_fees.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                            </Heading>
                                            <Text flex={1} ml={2} fontSize={'md'}>
                                                estimated referral fees
                                                from {submission.responses.length} {submission.responses.length === 1 ? 'response' : 'responses'}
                                            </Text>
                                        </VStack>
                                    </HStack>
                                )}

                                {submission.responses.length === 0 && (
                                    <HStack alignItems={'center'}>
                                        <Ionicons name={'cash-outline'} size={24} color={colors.dark[400]}/>
                                        <Text ml={2} fontSize={'md'}>{submission.responses.length} {'responses'}</Text>
                                    </HStack>
                                )}
                            </VStack>
                        </View>
                    </TouchableOpacity>
                ))}
            </VStack>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default EstateAgentHomeStack;

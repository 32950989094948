import * as React from 'react';
import {DarkTheme, DefaultTheme, NavigationContainer} from '@react-navigation/native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Ionicons from '@expo/vector-icons/Ionicons';
import {useAuth} from "../providers/AuthProvider";
import {
    Box,
    Button, Circle,
    Heading,
    HStack,
    Image,
    ScrollView,
    Text,
    Tooltip,
    useColorMode, useColorModeValue,
    useTheme,
    View,
    VStack
} from "native-base";
import EstateAgentHome from "./estateAgent/EstateAgentHome";
import ContractorHome from "./contractor/ContractorHome";
import Messages from "./messages/Messages";
import {createDrawerNavigator, DrawerContentScrollView, DrawerItemList} from "@react-navigation/drawer";
import {Dimensions} from "react-native";
import ClientHome from "./client/ClientHome";
import Profile from "./Profile";
import {clientLinking, contractorLinking, estateAgentLinking} from "./Linking";
import TermsOfBusiness from "../components/TermsOfBusiness";
import {post} from "../services/api";
import {useChatNotificationsCount} from "../providers/ChatNotificationsProvider";
import Blog from "./Blog";
import {useNotifications} from "../providers/NotificationsProvider";
import Notifications from "./Notifications";
import {useEffect, useState} from "react";
import Analytics from "./estateAgent/Analytics";
import ManageUsers from "./estateAgent/ManageUsers";
import ContractorManageUsers from "./contractor/ContractorManageUsers";

const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

const CustomDrawerContent = (props) => {
    const {colors} = useTheme();
    const {colorMode} = useColorMode();
    const {userDetails} = useAuth();

    const [lightLogoAspectRatio, setLightLogoAspectRatio] = useState(null);
    const [darkLogoAspectRatio, setDarkLogoAspectRatio] = useState(null);

    const [lightLogoPath, setLightLogoPath] = useState(null);
    const [darkLogoPath, setDarkLogoPath] = useState(null);

    useEffect(() => {
        if (userDetails.company?.light_logo_path) {
            setLightLogoPath(userDetails.company.light_logo_path);
            Image.getSize(userDetails.company?.light_logo_path, (width, height) => {
                setLightLogoAspectRatio(height / width);
            });
        }

        if (userDetails.company?.dark_logo_path) {
            setDarkLogoPath(userDetails.company.dark_logo_path);
            Image.getSize(userDetails.company?.dark_logo_path, (width, height) => {
                setDarkLogoAspectRatio(height / width);
            });
        }
    }, [userDetails.company?.light_logo_path, userDetails.company?.dark_logo_path]);

    useEffect(() => {
        if (userDetails.estate_agent?.light_logo_path) {
            setLightLogoPath(userDetails.estate_agent.light_logo_path);
            Image.getSize(userDetails.estate_agent?.light_logo_path, (width, height) => {
                setLightLogoAspectRatio(height / width);
            });
        }

        if (userDetails.estate_agent?.dark_logo_path) {
            setDarkLogoPath(userDetails.estate_agent.dark_logo_path);
            Image.getSize(userDetails.estate_agent?.dark_logo_path, (width, height) => {
                setDarkLogoAspectRatio(height / width);
            });
        }
    }, [userDetails.estate_agent?.light_logo_path, userDetails.estate_agent?.dark_logo_path]);


    return (
        <DrawerContentScrollView {...props} style={{
            backgroundColor: useColorModeValue('rgb(242, 242, 242)', 'rgb(1, 1, 1)'),
            borderRightWidth: 0
        }} contentContainerStyle={{borderRightWidth: 0}}>
            {colorMode === 'dark' && (
                <VStack space={2}>
                    {darkLogoPath ? (
                        <>
                            <Image mt={4} width={200} height={200 * darkLogoAspectRatio}
                                   maxW={'80%'} alignSelf={'center'} resizeMode={'contain'}
                                   source={{uri: darkLogoPath}}
                                   alt={'Company Logo'}/>
                            <Image my={4} height={'50px'} width={'200px'} maxW={'80%'} alignSelf={'center'}
                                   resizeMode={'contain'}
                                   source={require('../../assets/PoweredByPropServWhite.png')}
                                   alt={'PropServ Logo'}/>
                        </>
                    ) : (
                        <Image my={4} height={'50px'}
                               width={'200px'} maxW={'80%'}
                               alignSelf={'center'} resizeMode={'contain'}
                               source={require('../../assets/PropServ.png')}
                               alt={'PropServ Logo'}/>
                    )}
                </VStack>
            )}

            {colorMode === 'light' && (
                <VStack space={2}>
                    {lightLogoPath ? (
                        <>
                            <Image mt={4} width={200} height={200 * lightLogoAspectRatio}
                                   maxW={'80%'} alignSelf={'center'} resizeMode={'contain'}
                                   source={{uri: lightLogoPath}}
                                   alt={'Company Logo'}/>
                            <Image my={4} height={'50px'} width={'200px'} maxW={'80%'} alignSelf={'center'}
                                   resizeMode={'contain'}
                                   source={require('../../assets/PoweredByPropServBlack.png')}
                                   alt={'PropServ Logo'}/>
                        </>
                    ) : (
                        <Image my={4} height={'50px'}
                               width={'200px'} maxW={'80%'}
                               alignSelf={'center'} resizeMode={'contain'}
                               source={require('../../assets/PropServBlack.png')}
                               alt={'PropServ Logo'}/>
                    )}
                </VStack>
            )}

            <DrawerItemList {...props} />

            <Tooltip label="PropServ is currently in beta. Please report any issues to info@propserv.co" openDelay={100}
                     placement={'right'}>
                <HStack
                    bg={'primary.500'}
                    p={1}
                    px={4}
                    rounded={'full'}
                    alignSelf={'center'}
                    m={4}
                    space={1}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <Text bold color={'white'} fontSize={'xs'}>BETA</Text>
                    <Ionicons name={'information-circle-outline'} size={16} color={'white'}/>
                </HStack>
            </Tooltip>
        </DrawerContentScrollView>
    );
};

export default function AuthenticatedStack() {
    const {userDetails, setUserDetails} = useAuth();
    const {notificationsCount: chatNotificationsCount} = useChatNotificationsCount();
    const {notificationsCount} = useNotifications();

    const padding = Dimensions.get('screen').width > 768 ? 4 : 0;

    const {colors} = useTheme();

    const {colorMode, setColorMode} = useColorMode();

    // let Navigator = Dimensions.get('window').width > 500 ? Drawer : Tab;
    //
    // if (Platform.OS === 'web' && Dimensions.get('window').width < 768) {
    //     Navigator = Tab;
    // }

    let Navigator = Drawer;

    const theme = colorMode === 'dark' ? DarkTheme : DefaultTheme;

    const myTheme = {
        ...theme,
        colors: {
            ...theme.colors,
            primary: colors.primary[500],
        },
    }

    const homeTabBarOnPress = ({navigation, defaultHandler}) => {
        if (navigation.isFocused()) {
            navigation.navigate('Home', {screen: 'Home'});
        } else {
            defaultHandler();
        }
    }

    const messagesTabBarOnPress = ({navigation, defaultHandler}) => {
        if (navigation.isFocused()) {
            navigation.navigate('Messages', {screen: 'Messages'});
        } else {
            defaultHandler();
        }
    }

    const profileTabBarOnPress = ({navigation, defaultHandler}) => {
        console.log('profileTabBarOnPress');
        if (navigation.isFocused()) {
            navigation.navigate('Profile', {screen: 'Profile'});
        } else {
            defaultHandler();
        }
    }

    const acceptTerms = async () => {
        await post('/accept-terms-of-business');
        setUserDetails({...userDetails, accepted_terms_of_business_at: new Date().toISOString()});
    }

    if (userDetails?.accepted_terms_of_business_at == null) {
        return (
            <NavigationContainer theme={myTheme}>
                <ScrollView>
                    <VStack style={{flex: 1, alignItems: 'center'}} bg={useColorModeValue('light.50', 'dark.50')} p={4}
                            space={4} py={16}>
                        <Image source={require('../../assets/PropServBlack.png')} alt={'PropServ Logo'} height={150}
                               width={400} maxW={'80%'} alignSelf={'center'} resizeMode={'contain'}/>
                        <Heading textAlign={'center'}>We have updated our terms of business</Heading>
                        <Text textAlign={'center'}>Please read and accept the updated terms of business to continue
                            using
                            the app</Text>

                        <Box mt={8} rounded={'xl'} p={4} bg={useColorModeValue('light.100', 'dark.100')}>
                            <TermsOfBusiness/>
                        </Box>

                        <Text mt={8} bold fontSize={'lg'} textAlign={'center'}>
                            By clicking the button below, you confirm you have read, understood and accept the terms of
                            business.
                        </Text>

                        <Button onPress={acceptTerms} colorScheme={'primary'}>
                            Accept terms of business
                        </Button>
                    </VStack>
                </ScrollView>
            </NavigationContainer>
        );
    }

    if (userDetails.type === 'client') {
        return (
            <View bg={useColorModeValue('rgb(242, 242, 242)', 'rgb(1, 1, 1)')} flex={1} justifyContent={'center'}
                  alignItems={'center'}>
                <View px={padding} maxW={1500} pt={2} w={'100%'} flex={1} alignSelf={'center'}>
                    <NavigationContainer linking={clientLinking} theme={myTheme}>
                        <Navigator.Navigator
                            drawerContent={(props) => <CustomDrawerContent {...props}/>}
                            screenOptions={{
                                headerShown: false,
                                drawerType: Dimensions.get('window').width > 768 ? 'permanent' : 'front',
                                drawerStyle: {
                                    width: 220,
                                    borderRightWidth: 0,
                                }
                            }}>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="home" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <Ionicons name="home" color={color} size={size}/>
                                ),
                            }} name="Home" component={ClientHome}/>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="chatbubbles-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <>
                                        <Ionicons name="chatbubbles-outline" color={color} size={size}/>
                                        {chatNotificationsCount > 0 && (
                                            <Circle size={5} bg={'red.500'} position={'absolute'} top={1} left={8}>
                                                <Text color={'white'} fontSize={'xs'}>{chatNotificationsCount}</Text>
                                            </Circle>
                                        )}
                                    </>
                                ),
                                tabBarBadge: chatNotificationsCount > 0 ? chatNotificationsCount : null,
                            }} name="Messages" component={Messages}/>
                            <Navigator.Screen
                                options={{
                                    tabBarIcon: ({color, size}) => (
                                        <Ionicons name="newspaper-outline" color={color} size={size}/>
                                    ),
                                    drawerIcon: ({color, size}) => (
                                        <Ionicons name="newspaper-outline" color={color} size={size}/>
                                    ),
                                    drawerLabel: () => {
                                        return (
                                            <VStack alignItems={'flex-start'}>
                                                <Text>Blog</Text>
                                                <Text fontSize={'xs'} color={'gray.500'}>Coming soon</Text>
                                            </VStack>
                                        )
                                    },
                                }} name="Blog" component={Blog}/>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="notifications-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <>
                                        <Ionicons name="notifications-outline" color={color} size={size}/>
                                        {notificationsCount > 0 && (
                                            <Circle size={5} bg={'red.500'} position={'absolute'} top={1} left={8}>
                                                <Text color={'white'} fontSize={'xs'}>{notificationsCount}</Text>
                                            </Circle>
                                        )}
                                    </>
                                ),
                                tabBarBadge: notificationsCount > 0 ? notificationsCount : null,
                            }} name="Notifications" component={Notifications}/>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="person" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <Ionicons name="person" color={color} size={size}/>
                                ),
                            }} name="Profile" component={Profile}/>
                        </Navigator.Navigator>
                    </NavigationContainer>
                </View>
            </View>
        );
    }

    if (userDetails.type === 'contractor') {
        return (
            <View bg={useColorModeValue('rgb(242, 242, 242)', 'rgb(1, 1, 1)')} flex={1} justifyContent={'center'}
                  alignItems={'center'}>
                <View px={padding} maxW={1500} pt={2} w={'100%'} flex={1} alignSelf={'center'}>
                    <NavigationContainer linking={contractorLinking} theme={myTheme}>
                        <Navigator.Navigator
                            drawerContent={(props) => <CustomDrawerContent {...props}/>}
                            screenOptions={{
                                headerShown: false,
                                drawerType: Dimensions.get('window').width > 768 ? 'permanent' : 'front',
                                drawerStyle: {
                                    width: 220,
                                    borderRightWidth: 0,
                                }
                            }}>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="home" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <Ionicons name="home" color={color} size={size}/>
                                ),
                                tabBarOnPress: homeTabBarOnPress,
                            }} name="Home" component={ContractorHome}/>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="chatbubbles-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <>
                                        <Ionicons name="chatbubbles-outline" color={color} size={size}/>
                                        {chatNotificationsCount > 0 && (
                                            <Circle size={5} bg={'red.500'} position={'absolute'} top={1} left={8}>
                                                <Text color={'white'} fontSize={'xs'}>{chatNotificationsCount}</Text>
                                            </Circle>
                                        )}
                                    </>
                                ),
                                tabBarBadge: chatNotificationsCount > 0 ? chatNotificationsCount : null,
                                tabBarOnPress: messagesTabBarOnPress,
                            }} name="Messages" component={Messages}/>
                            <Navigator.Screen
                                options={{
                                    tabBarIcon: ({color, size}) => (
                                        <Ionicons name="newspaper-outline" color={color} size={size}/>
                                    ),
                                    drawerIcon: ({color, size}) => (
                                        <Ionicons name="newspaper-outline" color={color} size={size}/>
                                    ),
                                    drawerLabel: () => {
                                        return (
                                            <VStack alignItems={'flex-start'}>
                                                <Text>Blog</Text>
                                                <Text fontSize={'xs'} color={'gray.500'}>Coming soon</Text>
                                            </VStack>
                                        )
                                    },
                                }} name="Blog" component={Blog}/>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="notifications-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <>
                                        <Ionicons name="notifications-outline" color={color} size={size}/>
                                        {notificationsCount > 0 && (
                                            <Circle size={5} bg={'red.500'} position={'absolute'} top={1} left={8}>
                                                <Text color={'white'} fontSize={'xs'}>{notificationsCount}</Text>
                                            </Circle>
                                        )}
                                    </>
                                ),
                                tabBarBadge: notificationsCount > 0 ? notificationsCount : null,
                            }} name="Notifications" component={Notifications}/>

                            {/*{userDetails.roles?.some(role => role.name === 'admin') && (*/}
                            {/*    <Navigator.Screen options={{*/}
                            {/*        tabBarIcon: ({color, size}) => (*/}
                            {/*            <Ionicons name="people-outline" color={color} size={size}/>*/}
                            {/*        ),*/}
                            {/*        drawerIcon: ({color, size}) => (*/}
                            {/*            <Ionicons name="people-outline" color={color} size={size}/>*/}
                            {/*        ),*/}
                            {/*    }} name="Manage users" component={ContractorManageUsers}/>*/}
                            {/*)}*/}

                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="person" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <Ionicons name="person" color={color} size={size}/>
                                ),
                                tabBarOnPress: profileTabBarOnPress,
                            }} name="Profile" component={Profile}/>
                        </Navigator.Navigator>
                    </NavigationContainer>
                </View>
            </View>
        );
    }

    if (userDetails.type === 'estate_agent') {
        return (
            <View bg={useColorModeValue('rgb(242, 242, 242)', 'rgb(1, 1, 1)')} flex={1} justifyContent={'center'}
                  alignItems={'center'}>
                <View px={padding} maxW={1500} pt={2} w={'100%'} flex={1} alignSelf={'center'}>
                    <NavigationContainer linking={estateAgentLinking} theme={myTheme}>
                        <Navigator.Navigator
                            initialRouteName={'Submissions Dashboard'}
                            drawerContent={(props) => <CustomDrawerContent {...props}/>}
                            screenOptions={{
                                headerShown: false,
                                drawerType: Dimensions.get('window').width > 768 ? 'permanent' : 'front',
                                drawerStyle: {
                                    width: 220,
                                    borderRightWidth: 0,
                                }
                            }}>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="home-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <Ionicons name="home-outline" color={color} size={size}/>
                                ),
                            }} name="Home" component={EstateAgentHome}/>
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="chatbubbles-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <>
                                        <Ionicons name="chatbubbles-outline" color={color} size={size}/>
                                        {chatNotificationsCount > 0 && (
                                            <Circle size={5} bg={'red.500'} position={'absolute'} top={1} left={8}>
                                                <Text color={'white'} fontSize={'xs'}>{chatNotificationsCount}</Text>
                                            </Circle>
                                        )}
                                    </>
                                ),
                                tabBarBadge: chatNotificationsCount > 0 ? chatNotificationsCount : null,
                            }} name="Messages" component={Messages}/>
                            <Navigator.Screen
                                options={{
                                    tabBarIcon: ({color, size}) => (
                                        <Ionicons name="newspaper-outline" color={color} size={size}/>
                                    ),
                                    drawerIcon: ({color, size}) => (
                                        <Ionicons name="newspaper-outline" color={color} size={size}/>
                                    ),
                                    drawerLabel: ({color}) => {
                                        return (
                                            <VStack alignItems={'flex-start'}>
                                                <Text color={color}>Blog</Text>
                                                <Text fontSize={'xs'} color={'gray.500'}>Coming soon</Text>
                                            </VStack>
                                        )
                                    },
                                }} name="Blog" component={Blog}/>
                            {/*<Navigator.Screen*/}
                            {/*    options={{*/}
                            {/*        tabBarIcon: ({color, size}) => (*/}
                            {/*            <Ionicons name="trail-sign-outline" color={color} size={size}/>*/}
                            {/*        ),*/}
                            {/*        drawerIcon: ({color, size}) => (*/}
                            {/*            <Ionicons name="trail-sign-outline" color={color} size={size}/>*/}
                            {/*        ),*/}
                            {/*        drawerLabel: ({color}) => {*/}
                            {/*            return (*/}
                            {/*                <VStack m={0} alignItems={'flex-start'}>*/}
                            {/*                    <Text color={color}>SmooveCaddy</Text>*/}
                            {/*                    <Text fontSize={'xs'} color={'gray.500'}>Coming soon</Text>*/}
                            {/*                </VStack>*/}
                            {/*            )*/}
                            {/*        },*/}
                            {/*    }} name="SmooveCaddy" component={SmooveCaddy}/>*/}
                            <Navigator.Screen
                                options={{
                                    drawerItemStyle: {height: 0},
                                    tabBarIcon: ({color, size}) => (
                                        <Ionicons name="bar-chart-outline" color={color} size={size}/>
                                    ),
                                    drawerIcon: ({color, size}) => (
                                        <Ionicons name="bar-chart-outline" color={color} size={size}/>
                                    ),
                                }}
                                name="Analytics"
                                component={Analytics}
                            />
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="notifications-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <>
                                        <Ionicons name="notifications-outline" color={color} size={size}/>
                                        {notificationsCount > 0 && (
                                            <Circle size={5} bg={'red.500'} position={'absolute'} top={1} left={8}>
                                                <Text color={'white'} fontSize={'xs'}>{notificationsCount}</Text>
                                            </Circle>
                                        )}
                                    </>
                                ),
                                tabBarBadge: notificationsCount > 0 ? notificationsCount : null,
                            }} name="Notifications" component={Notifications}/>
                            {/*{userDetails.roles?.some(role => role.name === 'admin') && (*/}
                            {/*    <Navigator.Screen options={{*/}
                            {/*        tabBarIcon: ({color, size}) => (*/}
                            {/*            <Ionicons name="people-outline" color={color} size={size}/>*/}
                            {/*        ),*/}
                            {/*        drawerIcon: ({color, size}) => (*/}
                            {/*            <Ionicons name="people-outline" color={color} size={size}/>*/}
                            {/*        ),*/}
                            {/*    }} name="Manage users" component={ManageUsers}/>*/}
                            {/*)}*/}
                            <Navigator.Screen options={{
                                tabBarIcon: ({color, size}) => (
                                    <Ionicons name="person-outline" color={color} size={size}/>
                                ),
                                drawerIcon: ({color, size}) => (
                                    <Ionicons name="person-outline" color={color} size={size}/>
                                ),
                            }} name="Profile" component={Profile}/>
                        </Navigator.Navigator>
                    </NavigationContainer>
                </View>
            </View>
        );
    }

    return (
        <NavigationContainer theme={myTheme}>
            <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Heading color={'error.500'}>User type not found</Heading>
                <Text color={'error.500'}>{userDetails.type}</Text>
                <Text>Please contact support</Text>
            </View>
        </NavigationContainer>
    );
}

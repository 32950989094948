import React, {useEffect, useMemo, useState} from 'react';
import {Text, View, FlatList, useTheme, useColorModeValue, HStack, Spinner} from 'native-base';
import {Image, Modal} from 'react-native';
import {Linking, TouchableOpacity} from 'react-native';
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import ZoomableImage from "./ZoomableImage";

const ChatMessages = ({messages}) => {
    const {userDetails} = useAuth();
    const {colors} = useTheme();

    const [loading, setLoading] = useState(true)

    const [showImage, setShowImage] = useState(false);
    const [imageToShow, setImageToShow] = useState(null);

    const gray = useColorModeValue('gray.500', 'gray.400');

    const [groupedMessages, setGroupedMessages] = useState([
        {
            date: 'Today',
            messages: []
        }
    ]);

    useEffect(() => {
        const grouped = groupMessagesByDay(messages);
        setGroupedMessages(grouped);
        setLoading(false);
    }, [messages]);

    const renderSystemMessage = (date) => (
        <View alignSelf={'center'} my={4}>
            <Text fontSize={'xs'} color={gray}>{date}</Text>
        </View>
    );

    const renderItem = ({item}) => (
        <View mb={2} maxW={'80%'} alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}>
            <View maxW={'100%'} p={2} px={4} rounded={'xl'} alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}
                  bg={item.user._id === userDetails.id ? 'primary.500' : 'gray.200'}>
                <Text selectable color={item.user._id === userDetails.id ? 'white' : 'black'}>{item.text}</Text>
            </View>
            <Text color={gray} fontSize={'2xs'} mx={2}
                  alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}>{formatTime(item.createdAt)}</Text>
        </View>
    );

    const renderDocument = ({item}) => (
        <TouchableOpacity onPress={() => {
            Linking.openURL(item.document)
        }}>
            <View mb={2} maxW={'80%'} alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}>
                <HStack
                    alignItems={'center'}
                    space={2}
                    p={2}
                    px={4}
                    rounded={'xl'}
                    alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}
                    bg={item.user._id === userDetails.id ? 'primary.500' : 'gray.200'}>
                    <Ionicons name={'document'} size={24} color={'white'}/>
                    <Text underline
                          color={item.user._id === userDetails.id ? 'white' : 'black'}>{item.document_name}</Text>
                </HStack>
                <Text color={gray} fontSize={'2xs'} mx={2}
                      alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}>{formatTime(item.createdAt)}</Text>
            </View>
        </TouchableOpacity>
    );

    if (loading) {
        return (
            <View flex={1} justifyContent={'center'} alignItems={'center'}>
                <Text bold fontSize={'md'}>Loading conversation...</Text>
                <Spinner mt={4} size={'lg'}/>
            </View>
        )
    }

    return (
        <View flex={1} pb={2}>
            <FlatList
                showsVerticalScrollIndicator={false}
                data={groupedMessages}
                extraData={messages.length}
                keyExtractor={item => `${item.date}-${item.messages.length}`}
                renderItem={({item}) => (
                    <>
                        {item.messages.map(msg => {
                            if (msg.system) {
                                return renderSystemMessage(msg.text);
                            }

                            if (msg.image) {
                                return <RenderImage onPress={(image) => {
                                    setImageToShow(image);
                                    setShowImage(true);
                                }} item={msg} userDetails={userDetails}/>
                            }

                            if (msg.document) {
                                return renderDocument({item: msg})
                            }

                            return renderItem({item: msg})
                        })}
                        {renderSystemMessage(item.date)}
                    </>
                )}
                inverted
            />

            {showImage &&
                <Modal
                    animationType="slide"
                    transparent={false}
                    visible={showImage}
                    onRequestClose={() => setShowImage(false)}
                >
                    <ZoomableImage image={imageToShow}/>
                    <TouchableOpacity onPress={() => setShowImage(false)} style={{position: 'absolute', top: 16, right: 16}}>
                        <Ionicons name="close" size={32} color={colors.primary[500]} style={{margin: 16}}/>
                    </TouchableOpacity>
                </Modal>
            }
        </View>
    );
};

const RenderImage = ({item, userDetails, onPress}) => {
    const [width, setWidth] = useState(null);
    const fixedHeight = 150;

    useEffect(() => {
        Image.getSize(item.image, (width, height) => {
            const aspectRatio = width / height;
            const calculatedWidth = fixedHeight * aspectRatio;
            setWidth(calculatedWidth);
        });
    }, []);

    return (
        <TouchableOpacity onPress={() => onPress(item.image)} style={{maxWidth: '80%', alignSelf: item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}}>
            <View mb={2}>
                <Image
                    source={{uri: item.image}}
                    alt="Image"
                    style={{
                        maxWidth: '100%',
                        height: fixedHeight,
                        width: width || '100%',
                        borderRadius: 10
                    }}
                    resizeMode="contain"
                    alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}
                />
                <Text color="gray" fontSize="2xs" mx={2}
                      alignSelf={item.user._id === userDetails.id ? 'flex-end' : 'flex-start'}>
                    {formatTime(item.createdAt)}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

const groupMessagesByDay = messages => {
    const groups = messages.reduce((acc, message) => {
        const date = new Date(message.createdAt).toDateString();
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(message);
        return acc;
    }, {});

    return Object.keys(groups).map(date => ({
        date,
        messages: groups[date].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    })).sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort to ensure dates are in chronological order
};

const formatTime = dateString => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
};

export default ChatMessages;

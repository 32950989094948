import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from "@react-native-async-storage/async-storage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [token, setToken] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const init = async () => {
        let storedUser = await AsyncStorage.getItem('user');
        let storedToken = await AsyncStorage.getItem('token');

        if (storedUser === 'null' || storedUser === 'undefined') storedUser = null;
        if (storedToken === 'null' || storedToken === 'undefined') storedToken = null;

        setUserDetails(JSON.parse(storedUser));
        setToken(storedToken);
        setIsAuthenticated(!!storedUser && !!storedToken);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (userDetails) {
            AsyncStorage.setItem('user', JSON.stringify(userDetails)).then(() => {
                setIsAuthenticated(!!userDetails && !!token);
            });
        }
    }, [userDetails]);

    useEffect(() => {
        if (token) {
            AsyncStorage.setItem('token', token).then(() => {
                setIsAuthenticated(!!userDetails && !!token);
            });
        }
    }, [token]);

    const clearAuth = async () => {
        await AsyncStorage.removeItem('user');
        await AsyncStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserDetails(null);
        setToken(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userDetails, setUserDetails: setUserDetails, token, setToken: setToken, clearAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const { isAuthenticated, userDetails, setUserDetails, token, setToken, clearAuth } = React.useContext(AuthContext);
    return { isAuthenticated, userDetails, setUserDetails, token, setToken, clearAuth };
}

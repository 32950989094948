import {StyleSheet} from 'react-native';
import {Button, Heading, useColorModeValue, Text, VStack} from "native-base";
import * as React from "react";
import {useState} from "react";

const Description = props => {
    const {submission} = props;
    const cardBackground = useColorModeValue('light.50', 'dark.50');

    const descriptionLengthLimit = 200;
    const [showFullDescription, setShowFullDescription] = useState(false);

    return (
        <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
            <Heading fontSize={'md'}>Submission description</Heading>
            {submission.description === null && <Text color={useColorModeValue('gray.500', 'gray.400')}>No description</Text>}
            {submission.description !== null && (
                // Step 3: Check the length of the description and show the appropriate text and button
                <>
                    <Text fontSize={'md'} selectable>
                        {showFullDescription || submission.description.length <= descriptionLengthLimit
                            ? submission.description
                            : submission.description.substring(0, descriptionLengthLimit) + "..."}
                    </Text>
                    {submission.description.length > descriptionLengthLimit && (
                        <Button p={0} alignSelf={'flex-start'} variant={'link'}
                                onPress={() => setShowFullDescription(!showFullDescription)}>
                            {showFullDescription ? "Show less" : "Show more"}
                        </Button>
                    )}
                </>
            )}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default Description;

import * as React from 'react';
import {
    View,
    Text,
    NativeBaseProvider,
    Spinner,
    Heading,
    extendTheme,
    Image,
    Button,
    useColorModeValue, useColorMode
} from 'native-base';
import AuthStack from "./src/screens/auth/AuthStack";
import AuthenticatedStack from "./src/screens/AuthenticatedStack";
import {AuthProvider, useAuth} from "./src/providers/AuthProvider";
import {useEffect, useState} from "react";
import {ActivityIndicator, Platform, StatusBar} from "react-native";
import * as Sentry from 'sentry-expo';

import 'react-native-gesture-handler';
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import Constants from "expo-constants";
import {get, post} from "./src/services/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import WebLandingPage from "./src/screens/landingPages/WebLandingPage";
import {ChatNotificationsProvider} from "./src/providers/ChatNotificationsProvider";
import {NotificationsProvider} from "./src/providers/NotificationsProvider";
import {generateShades} from "./src/services/color";

Sentry.init({
    dsn: "https://1e8ebf8d3333c778012c9ed375785e38@o4507109094588416.ingest.de.sentry.io/4507109097799760",
    environment: Constants?.manifest?.extra?.env?.EXPO_PUBLIC_SENTRY_ENV || 'production',
    tracesSampleRate: 1.0,
    _experiments: {
        profilesSampleRate: 1.0,
    },
    enableInExpoDevelopment: true,
    debug: false,
    enabled: Constants?.manifest?.extra?.env?.EXPO_PUBLIC_SENTRY_ENABLED ? Constants?.manifest?.extra?.env?.EXPO_PUBLIC_SENTRY_ENABLED === 'true' : true,
});

function App() {
    Object.entries(Constants.expoConfig?.extra?.env).forEach(([key, value]) => {
        if (!key.startsWith('EXPO_PUBLIC_') || process.env[key]) return;
        process.env[key] = `${value}`;
    });

    return (
        <AuthProvider>
            <StatusBar style="light"/>
            <Main/>
        </AuthProvider>
    )
}

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

function Main() {
    const {isAuthenticated, user, token, setUserDetails, userDetails, clearAuth} = useAuth();
    const [loading, setLoading] = useState(true);
    const [darkMode, setDarkMode] = useState('false');
    const [theme, setTheme] = useState(null);
    const {setColorMode} = useColorMode();

    const [expoPushToken, setExpoPushToken] = useState('');

    useEffect(() => {
        const getDarkMode = async () => {
            let storedDarkMode = await AsyncStorage.getItem('darkMode');
            console.log('Stored Dark Mode', storedDarkMode);
            setDarkMode(storedDarkMode)

            if (storedDarkMode === null || storedDarkMode === undefined) {
                await AsyncStorage.setItem('darkMode', 'false');
                storedDarkMode = 'false';
                setDarkMode('false');
                setColorMode('light');
            }

            let shades = null;

            if (userDetails?.company?.brand_color) {
                const baseColorHex = userDetails?.company?.brand_color;
                shades = generateShades(baseColorHex);
            }

            if (userDetails?.estate_agent?.brand_color) {
                const baseColorHex = userDetails?.estate_agent?.brand_color;
                shades = generateShades(baseColorHex);
            }

            const newTheme = extendTheme({
                colors: {
                    primary: {
                        50: shades ? shades[50] : '#dbeeff',
                        100: shades ? shades[100] : '#d1e8ff',
                        200: shades ? shades[200] : '#c6e2ff',
                        300: shades ? shades[300] : '#b8ddff',
                        400: shades ? shades[400] : '#aad8ff',
                        500: shades ? shades[500] : '#47A9DA',
                        600: shades ? shades[600] : '#007AB8',
                        700: shades ? shades[700] : '#006BA1',
                        800: shades ? shades[800] : '#005885',
                        900: shades ? shades[900] : '#00496A',
                    },
                },
                config: {
                    initialColorMode: storedDarkMode === 'true' ? 'dark' : 'light',
                },
                components: {
                    Button: {
                        variants: {
                            solid: ({colorScheme}) => {
                                return {
                                    bg: `${colorScheme}.500`,
                                    rounded: "full"
                                };
                            },
                            ghost: ({colorScheme}) => {
                                return {
                                    bg: `${colorScheme}.50`,
                                    rounded: "full"
                                };
                            }
                        }
                    }
                }
            });

            setTheme(newTheme);
        }

        getDarkMode();
    }, [userDetails]);

    async function registerForPushNotificationsAsync() {
        let token;

        if (Platform.OS === 'android') {
            await Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }

        if (Device.isDevice && Platform.OS !== 'web') {
            const {status: existingStatus} = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const {status} = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }

            if (finalStatus !== 'granted') {
                return;
            }

            token = (await Notifications.getExpoPushTokenAsync({projectId: Constants.expoConfig.extra.eas.projectId})).data;
            await post('/users/push-token', {token})
        }

        return token;
    }

    useEffect(() => {
        registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            get('/my-information').then(response => {
                setUserDetails(response);
            });
        }
    }, [isAuthenticated]);

    setTimeout(() => {
        setLoading(false);
    }, 500);

    const bgColor = useColorModeValue('light.50', 'dark.50');

    if (darkMode === null || theme === null) {
        return <ActivityIndicator/>; // Or any other loading state
    }

    if (loading) {
        return (
            <NativeBaseProvider theme={theme}>
                <View bg={bgColor} flex={1} justifyContent={'center'}
                      alignItems={'center'}>
                    <Heading mb={2}>PropServ</Heading>
                    <Text fontSize={'md'} mb={4}>Please wait while we load your data.</Text>
                    <Spinner size={'lg'}/>
                </View>
            </NativeBaseProvider>
        );
    }

    if (isAuthenticated && !userDetails.active) {
        return (
            <NativeBaseProvider theme={theme}>
                <View bg={bgColor} flex={1} justifyContent={'center'}
                      alignItems={'center'} p={8}>
                    <Image height={150} width={400} maxW={'80%'} alignSelf={'center'} resizeMode={'contain'}
                           source={require('./assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                    <Heading textAlign={'center'} mt={4}>Thanks for signing up to PropServ</Heading>
                    <Text textAlign={'center'} fontSize={'md'} mt={4}>Your account is under review, once approved you
                        will be able to access
                        your account.</Text>

                    <Button
                        position={'absolute'}
                        px={8}
                        bottom={8} mt={4} onPress={clearAuth} colorScheme={'danger'}>Logout</Button>
                </View>
            </NativeBaseProvider>
        );
    }

    if (isAuthenticated) {
        return (
            <NativeBaseProvider theme={theme}>
                <ChatNotificationsProvider>
                    <NotificationsProvider>
                        <AuthenticatedStack/>
                    </NotificationsProvider>
                </ChatNotificationsProvider>
            </NativeBaseProvider>
        );
    }

    if (Platform.OS === 'web') {
        return (
            <NativeBaseProvider theme={theme}>
                <WebLandingPage/>
            </NativeBaseProvider>
        );
    }

    return (
        <NativeBaseProvider theme={theme}>
            <AuthStack/>
        </NativeBaseProvider>
    );
}

export default App;

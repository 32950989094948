import {StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Heading, HStack, ScrollView, Spacer, Text, useColorModeValue, useTheme, View, VStack} from "native-base";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";
import * as React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {get} from "../../services/api";
import {useCallback, useEffect, useState} from "react";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useFocusEffect} from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Stack = createNativeStackNavigator();

function ManageUsersStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Manage users" component={ManageUsers}/>
        </Stack.Navigator>
    );
}

const ManageUsers = props => {
    const {userDetails} = useAuth();
    const {colors} = useTheme();

    const [companyUsers, setCompanyUsers] = useState([]);
    const [showRolesInformation, setShowRolesInformation] = useState(false);

    const roles = [
        {
            name: "Admin",
            permissions: [
                {can: true, permission: "All of User permissions"},
                {can: true, permission: "Can view the submissions of all users in the company"},
                {can: true, permission: "Can manage users in the company"},
                {can: true, permission: "Receive notifications for all submissions"},
            ]
        },
        {
            name: "User",
            permissions: [
                {can: true, permission: "Can view and respond to their own submissions only"},
                {can: true, permission: "Can message clients and agents"},
                {can: true, permission: "Receive notifications for their own submissions"},
            ]
        },
        {
            name: "Reader",
            permissions: [
                {can: true, permission: "Can view all submissions in the company"},
                {can: true, permission: "Receive notifications for all submissions"},
                {can: false, permission: "Can't respond to submissions"},
                {can: false, permission: "Can't message clients and agents"},
            ]
        },
    ];

    const gray = useColorModeValue(colors.gray[500], colors.gray[400]);
    const bg = useColorModeValue('light.50', 'dark.50');
    const cardBg = useColorModeValue('light.100', 'dark.100');

    useEffect(() => {
        AsyncStorage.getItem('showRolesInformation').then(value => {
            if (value === null) {
                setShowRolesInformation(true);
                AsyncStorage.setItem('showRolesInformation', 'false');
            }
        });

        get('/manage-users/company/users').then(response => {
            setCompanyUsers(response);
        });
    }, []);

    return (
        <ScrollView p={4} showsVerticalScrollIndicator={false}>
            <VStack space={4} w={'100%'}>
                <HStack justifyContent={'flex-end'} alignItems={'center'}>
                    <TouchableOpacity onPress={() => setShowRolesInformation(true)}>
                        <Ionicons name={'help-circle-outline'} size={24} color={colors.gray[500]}/>
                    </TouchableOpacity>
                </HStack>

                {showRolesInformation && (
                    <VStack space={4} w={'100%'} p={4} bg={bg} rounded={'xl'}>
                        <HStack space={4} alignItems={'center'} justifyContent={'space-between'}>
                            <Heading fontSize={'lg'}>Roles</Heading>

                            <TouchableOpacity onPress={() => setShowRolesInformation(false)}>
                                <Ionicons name={'close'} size={24} color={colors.gray[500]}/>
                            </TouchableOpacity>
                        </HStack>

                        {/*<HStack space={4} alignItems={'center'} flexWrap={'wrap'} style={{rowGap: 8}}>*/}
                            {roles.map(role => (
                                <VStack space={2} key={role.name} bg={useColorModeValue('primary.50', 'primary.900')}
                                        p={4} rounded={'xl'} borderColor={'primary.500'} borderWidth={2}>
                                    <Text bold fontSize={'lg'}>{role.name}</Text>

                                    <VStack space={1}>
                                        {role.permissions.map(permission => (
                                            <HStack key={permission.permission} space={2} alignItems={'center'}>
                                                <Ionicons name={permission.can ? 'checkmark' : 'close'} size={18}
                                                            color={permission.can ? colors.green[500] : colors.danger[500]}/>
                                                <Text>{permission.permission}</Text>
                                            </HStack>
                                        ))}
                                    </VStack>
                                </VStack>
                            ))}
                        {/*</HStack>*/}
                    </VStack>
                )}

                <VStack space={4} w={'100%'} p={4} bg={bg} rounded={'xl'}>
                    <Heading fontSize={'lg'}>{userDetails.company.name} users</Heading>

                    <VStack space={2}>
                        {companyUsers.map(user => (
                            <VStack key={user.id} bg={cardBg} p={4} rounded={'xl'}>
                                <HStack space={2} flexWrap={'wrap'} style={{rowGap: 8}}>
                                    {user.roles.map(role => (
                                        <Box bg={'primary.100'} borderColor={'primary.500'} borderWidth={1} px={2}
                                             rounded={'md'} key={role.id}>
                                            <Text bold color={'primary.500'}>{role.name.toUpperCase()}</Text>
                                        </Box>
                                    ))}
                                    {user.approved_by_company ? (
                                        <HStack space={2} bg={'green.100'} borderColor={'green.500'} borderWidth={1}
                                                px={2}
                                                rounded={'md'} alignItems={'center'}>
                                            <Ionicons name={'checkmark'} size={18} color={colors.green[500]}/>
                                            <Text bold color={'green.500'}>Approved</Text>
                                        </HStack>
                                    ) : (
                                        <HStack space={2} bg={'warning.100'} borderColor={'warning.500'} borderWidth={1}
                                                px={2} rounded={'md'} alignItems={'center'}>
                                            <Ionicons name={'timer-outline'} size={18} color={colors.warning[500]}/>
                                            <Text bold color={'warning.500'}>Waiting for approval</Text>
                                        </HStack>
                                    )}
                                    {user.stripe_onboarding_complete ? (
                                        <HStack space={2} bg={'green.100'} borderColor={'green.500'} borderWidth={1}
                                                px={2}
                                                rounded={'md'} alignItems={'center'}>
                                            <Ionicons name={'checkmark'} size={18} color={colors.green[500]}/>
                                            <Text bold color={'green.500'}>Stripe onboarding complete</Text>
                                        </HStack>
                                    ) : (
                                        <HStack space={2} bg={'danger.100'} borderColor={'danger.500'} borderWidth={1}
                                                px={2} rounded={'md'} alignItems={'center'}>
                                            <Ionicons name={'close'} size={18} color={colors.danger[500]}/>
                                            <Text bold color={'danger.500'}>Stripe onboarding incomplete</Text>
                                        </HStack>
                                    )}
                                </HStack>
                                <Text mt={2} fontSize={'lg'} bold>{user.name}</Text>
                                <Text color={gray}>{user.email}</Text>
                            </VStack>
                        ))}
                    </VStack>
                </VStack>
            </VStack>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default ManageUsersStack;

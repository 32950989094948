import {Dimensions, Linking, StyleSheet, TouchableOpacity} from 'react-native';
import * as React from "react";
import {Button, Divider, Heading, HStack, Image, Input, Spinner, Text, View, VStack} from "native-base";
import {useState} from "react";
import {post} from "../../services/api";
import {useAuth} from "../../providers/AuthProvider";
import Constants from "expo-constants";
import {BlurView} from "expo-blur";

const Login = props => {
    const {setUserDetails, setToken} = useAuth();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);

    const [loggingIn, setLoggingIn] = useState(false);

    const login = (provider) => {
        if (provider === 'azure') {
            Linking.openURL(Constants?.manifest?.extra?.env?.EXPO_PUBLIC_AZURE_URL);
            return;
        }

        setLoggingIn(true);
        setError(null);

        post('/login', {
            email: email,
            password: password
        }).then(response => {
            setUserDetails(response.user);
            setToken(response.token);
        }).catch(error => {
            if (error.response.data.errors?.email) setError(error.response.data.errors.email[0]);
            else if (error.response.data.errors?.password) setError(error.response.data.errors.password[0]);
            else setError(error.response.data.error);
        }).finally(() => {
            setLoggingIn(false);
        });
    }

    return (
        <>
            <View>
                <Image
                    resizeMode={'cover'}
                    style={{
                        height: Dimensions.get('window').height,
                        width: Dimensions.get('window').width,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        filter: 'hue-rotate(150deg) blur(5px)'
                    }}
                    source={require('../../../assets/bg1.png')}
                />
            </View>
            <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <BlurView tint={'light'} intensity={100} space={4} style={{padding: 32, borderRadius: 16, gap: 16, maxWidth: '100%'}}>
                    <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                           source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                    <Heading textAlign={'center'} color={'black'}>Welcome back</Heading>
                    <Input color={'black'} fontSize={'md'} size={'xl'} onChangeText={text => setEmail(text)}
                           borderColor={'gray.400'} placeholder="Email"/>
                    <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'} borderColor={'gray.400'}
                           onChangeText={text => setPassword(text)} placeholder="Password" onSubmitEditing={login}/>

                    {error && <Text color={'error.500'}>{error}</Text>}

                    <Button disabled={loggingIn} onPress={login}>
                        {loggingIn ? <Spinner size={'sm'} color={'white'}/> : 'Login'}
                    </Button>

                    {/*<HStack my={4} space={4} alignItems={'center'} justifyContent={'space-between'}>*/}
                    {/*    <Divider w={'43%'}/>*/}
                    {/*    <Text color={'gray.400'}>Or</Text>*/}
                    {/*    <Divider w={'43%'}/>*/}
                    {/*</HStack>*/}

                    {/*<TouchableOpacity style={{alignSelf: 'center'}} onPress={() => login('azure')} disabled={loggingIn}>*/}
                    {/*    <Image source={require('../../../assets/signInWithMicrosoft.png')} alt={'Azure Logo'}*/}
                    {/*           height={37} width={195}/>*/}
                    {/*</TouchableOpacity>*/}

                    <Button variant={'link'} onPress={() => props.navigation.navigate('Register')}>
                        Don't have an account? Register
                    </Button>
                    <Button p={0} variant={'link'} onPress={() => props.navigation.navigate('Forgot password')}>
                        Forgot password?
                    </Button>
                </BlurView>
            </View>
        </>
    );
};

const styles = StyleSheet.create({});

export default Login;

import {Dimensions} from 'react-native';
import * as React from "react";
import {Image, ScrollView, View} from "native-base";
import {useEffect, useRef, useState} from "react";
import SecondPage from "./RegisterPages/SecondPage";
import ThirdPage from "./RegisterPages/ThirdPage";
import {get, post} from "../../services/api";
import {useAuth} from "../../providers/AuthProvider";
import FirstPage from "./RegisterPages/FirstPage";

const Register = props => {
    const {setUserDetails, setToken} = useAuth();

    console.log(props.route?.params);

    const [userId, setUserId] = useState(props.route?.params?.id || null);
    const [provider, setProvider] = useState(props.route?.params?.provider || null)
    const [userType, setUserType] = useState(null);
    const [name, setName] = useState(props.route?.params?.name || null);
    const [referralCode, setReferralCode] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyDescription, setCompanyDescription] = useState(null);
    const [email, setEmail] = useState(props.route?.params?.email || null);
    const [countryCode, setCountryCode] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState(null);
    const [companyCategories, setCompanyCategories] = useState([]);
    const [companyReferralCode, setCompanyReferralCode] = useState(props.route?.params?.referralCode || null);
    const [companyOnPropServ, setCompanyOnPropServ] = useState(props.route?.params?.referralCode ? true : null);
    const [companyWebsite, setCompanyWebsite] = useState(null);
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState(null);
    const [companyEmail, setCompanyEmail] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [companyAreas, setCompanyAreas] = useState([{
        postcode: '',
        radius: '5'
    }]);

    const [categories, setCategories] = useState(null);

    const pagerRef = useRef(null);

    const [registering, setRegistering] = useState(false);

    useEffect(() => {
        get('/company-categories').then(response => {
            setCategories(response);
        });
    }, []);

    const register = () => {
        setRegistering(true);
        setError(null);

        post('/register', {
            provider,
            provider_id: userId,
            referral_code: referralCode,
            name: name,
            account_type: userType,
            email,
            phone_number: countryCode?.callingCode + " " + phoneNumber,
            password,
            password_confirmation: confirmPassword,
            company_referral_code: companyReferralCode,
            company_name: companyName,
            company_email: companyEmail,
            company_website: companyWebsite,
            company_phone_number: companyPhoneNumber,
            company_description: companyDescription,
            company_categories: companyCategories.map(item => item.id),
            company_on_propserv: companyOnPropServ,
            company_areas: companyAreas,
            accepted_terms: acceptedTerms,
        }).then(response => {
            setUserDetails(response.user);
            setToken(response.token);
        }).catch(error => {
            if (error.response && error.response.status === 422) {
                setError(error.response.data.message);
            }
        })
            .finally(() => {
                setRegistering(false);
            });
    }

    const nextPage = (currentPage) => {
        pagerRef.current?.setPage(currentPage + 1);
    }

    return (
        <>
            <View>
                <Image
                    resizeMode={'cover'}
                    style={{
                        height: Dimensions.get('window').height,
                        width: Dimensions.get('window').width,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        filter: 'hue-rotate(150deg) blur(5px)'
                    }}
                    source={require('../../../assets/bg1.png')}
                />
            </View>
            <ScrollView>
                <FirstPage
                    key="1"
                    web
                    userId={userId}
                    name={name}
                    email={email}
                    nextPage={nextPage}
                    userType={userType}
                    setUserType={setUserType}
                />
                {userType !== null && (
                    <SecondPage
                        web={true}
                        key="2"
                        nextPage={nextPage}
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        companyReferralCode={companyReferralCode}
                        setCompanyReferralCode={setCompanyReferralCode}
                        referralCode={referralCode}
                        setReferralCode={setReferralCode}
                        companyDescription={companyDescription}
                        setCompanyDescription={setCompanyDescription}
                        error={error}
                        registering={registering}
                        userType={userType}
                        setUserType={setUserType}
                        categories={categories}
                        companyCategories={companyCategories}
                        setCompanyCategories={setCompanyCategories}
                        companyOnPropServ={companyOnPropServ}
                        setCompanyOnPropServ={setCompanyOnPropServ}
                        companyWebsite={companyWebsite}
                        setCompanyWebsite={setCompanyWebsite}
                        companyPhoneNumber={companyPhoneNumber}
                        setCompanyPhoneNumber={setCompanyPhoneNumber}
                        companyEmail={companyEmail}
                        setCompanyEmail={setCompanyEmail}
                        companyAreas={companyAreas}
                        setCompanyAreas={setCompanyAreas}
                        register={register}
                    />
                )}
                {userType !== null && companyOnPropServ !== null && (
                    <ThirdPage
                        web={true}
                        key="3"
                        userType={userType}
                        userId={userId}
                        name={name}
                        setName={setName}
                        email={email}
                        setEmail={setEmail}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        setPassword={setPassword}
                        setConfirmPassword={setConfirmPassword}
                        error={error}
                        registering={registering}
                        acceptedTerms={acceptedTerms}
                        setAcceptedTerms={setAcceptedTerms}
                        register={register}
                    />
                )}
            </ScrollView>
        </>
    );
};

export default Register;

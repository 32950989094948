import {SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    Center,
    Heading,
    HStack,
    Input,
    Modal,
    ScrollView,
    Spinner,
    Text,
    View,
    VStack
} from "native-base";
import {useEffect, useState} from "react";
import {get, post} from "../../services/api";
import * as React from "react";
import Ionicons from "@expo/vector-icons/Ionicons";

const PaymentSettings = props => {
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState([]);

    const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);

    const [accountName, setAccountName] = useState('');
    const [sortCode, setSortCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    useEffect(() => {
        getPaymentDetails();
    }, []);

    const getPaymentDetails = async () => {
        get('/payments/details').then(response => {
            setPaymentDetails(response);
            setLoading(false);
            setRefreshing(false);
        });
    }

    const addBankAccount = () => {
        post('/payments/details', {
            name: accountName,
            sort_code: sortCode,
            account_number: accountNumber,
        }).then(() => {
            setShowAddBankAccountModal(false);
            getPaymentDetails();
        });
    }

    const handleSortCodeChange = (text) => {
        // Remove non-numeric characters
        const numericText = text.replace(/[^0-9]/g, '');

        // Format the text with dashes
        let formattedText = '';
        for (let i = 0; i < numericText.length; i++) {
            if (i === 2 || i === 4) {
                formattedText += '-';
            }

            formattedText += numericText[i];
        }

        // Update the state
        setSortCode(formattedText);
    }

    if (loading) {
        return (
            <View p={4} rounded={'xl'} flex={1}>
                <Center flex={1}>
                    <Spinner size={'lg'}/>
                    <Text mt={4} fontSize={'md'}>
                        Securely loading your payment settings
                    </Text>
                </Center>
            </View>
        )
    }

    return (
        <SafeAreaView>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    <Heading>Payment Settings</Heading>

                    <VStack space={2} bg={'dark.100'} rounded={'xl'} p={4}>
                        <Heading mb={2} fontSize={'md'}>Bank accounts</Heading>

                        {paymentDetails.length === 0 && (
                            <Text color={'gray.400'}>
                                You have not added any bank accounts yet.
                            </Text>
                        )}

                        <VStack space={2}>
                            {paymentDetails.map(paymentDetail => (
                                <HStack space={4} alignItems={'center'} bg={'dark.200'} rounded={'xl'} p={4}>
                                    <Ionicons name={'card-outline'} size={24} color={'white'}/>
                                    <VStack>
                                        <Text bold fontSize={'md'}>{paymentDetail.name}</Text>
                                        <HStack>
                                            <Text fontSize={'md'}>{paymentDetail.sort_code}</Text>
                                            <Text fontSize={'md'}> • </Text>
                                            <Text fontSize={'md'}>{paymentDetail.account_number}</Text>
                                        </HStack>
                                    </VStack>
                                </HStack>
                            ))}
                        </VStack>

                        <Text color={'gray.400'}>
                            We use this information to pay referral fees to you.
                        </Text>

                        <Button mt={2} onPress={() => setShowAddBankAccountModal(true)}>
                            Add bank account
                        </Button>
                    </VStack>
                </VStack>
            </ScrollView>

            <Modal isKeyboardDismissable={false} isOpen={showAddBankAccountModal}
                   onClose={() => setShowAddBankAccountModal(false)}>
                <Modal.Content maxWidth="500px">
                    <Modal.CloseButton/>
                    <Modal.Header>Bank account details</Modal.Header>
                    <Modal.Body>
                        <VStack space={1}>
                            <Text fontSize={'sm'} color={'gray.400'}>Account name</Text>
                            <Input placeholder={'Account name'}
                                   onChangeText={(text) => setAccountName(text)}/>
                            <Text fontSize={'sm'} color={'gray.400'}>
                                Give your bank account a name so you can easily identify it.
                            </Text>

                            <Text mt={3} fontSize={'sm'} color={'gray.400'}>Sort code</Text>
                            <Input placeholder={'Sort code'} value={sortCode} onChangeText={handleSortCodeChange}
                                   keyboardType={'numeric'} maxLength={8}/>

                            <Text mt={3} fontSize={'sm'} color={'gray.400'}>Account number</Text>
                            <Input placeholder={'Account number'} value={accountNumber}
                                   onChangeText={(text) => setAccountNumber(text)} keyboardType={'numeric'}
                                   maxLength={8}/>
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <HStack space={4} justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
                                <Button colorScheme="error" onPress={() => {
                                    setShowAddBankAccountModal(false);
                                }}>
                                    Cancel
                                </Button>
                                <Button colorScheme="primary" onPress={addBankAccount}>
                                    Add bank account
                                </Button>
                            </HStack>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default PaymentSettings;

import {Linking, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Box,
    Button,
    Circle,
    Image,
    Heading,
    HStack,
    ScrollView,
    Spacer,
    Spinner,
    Text,
    useColorMode,
    useColorModeValue,
    useTheme,
    VStack,
} from "native-base";
import StatusBadge from "../../components/StatusBadge";
import {get, post} from "../../services/api";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useCallback, useState} from "react";
import SubmissionResponseDetailsModal from "../../components/client/SubmissionResponseDetailsModal";
import * as React from "react";
import SubmissionMapView from "../../components/SubmissionMapView";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import StarRating from "../../components/StarRating";
import ContractorCategories from "../../components/submissionDetails/ContractorCategories";
import CloseReopenSubmission from "../../components/CloseReopenSubmission";
import {useAuth} from "../../providers/AuthProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import WhatIsPropServ from "../../components/client/WhatIsPropserv";

const SubmissionDetails = props => {
    const {colors} = useTheme();
    const navigation = useNavigation();
    const {colorMode} = useColorMode();
    const {userDetails} = useAuth();

    const [showWhatIsPropServ, setShowWhatIsPropServ] = useState(false);

    const [submission, setSubmission] = useState(null);
    const [availableContractors, setAvailableContractors] = useState([]);
    const [loading, setLoading] = useState(true);

    const [response, setResponse] = useState(null);
    const [clientResponse, setClientResponse] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);

    const [showCloseModal, setShowCloseModal] = useState(false);
    const [closing, setClosing] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedAvailableCategory, setSelectedAvailableCategory] = useState(null);

    const cardBackground = useColorModeValue('light.50', 'dark.50');
    const responseCardBackground = useColorModeValue('light.100', 'dark.100');
    const lightDark200 = useColorModeValue('light.200', 'dark.200');
    const blackWhite = useColorModeValue('black', 'white');
    const gray = useColorModeValue('gray.500', 'gray.400');

    useFocusEffect(
        useCallback(() => {
            AsyncStorage.getItem('showWhatIsPropServ').then(value => {
                if (value === null) {
                    setShowWhatIsPropServ(true);
                    AsyncStorage.setItem('showWhatIsPropServ', 'false');
                }
            });

            fetchData();
        }, [])
    );

    const fetchData = async () => {
        get(`/client/submission`).then(response => {
            setSubmission(response);

            get(`/client/submissions/${response.id}/available-contractors`).then(response => {
                setAvailableContractors(response);
                setLoading(false);
            });
        });
    };

    const chatWithEstateAgent = async () => {
        const response = await post(`/chats`, {recipient_id: submission.user.id});

        navigation.navigate('Messages', {
            screen: 'Chat',
            params: {
                chatId: response.id
            },
            initial: false,
        });
    }

    const updateCategories = async (categories) => {
        await post(`/client/submissions/${submission.id}/categories`, {categories});
        fetchData();
    }

    const showResponse = (response, clientResponse = null) => {
        setResponse(response);
        setClientResponse(clientResponse);
        setShowResponseModal(true);
    }

    const acceptResponse = async (response) => {
        await post(`/client/submissions/${submission.id}/responses/${response.id}/accept`);
        setShowResponseModal(false);
        fetchData();
    }

    const getPaymentLink = async () => {
        return await get(`/client/pay/${clientResponse.id}`).then(response => {
            return response;
        });
    }

    const getDepositLink = async () => {
        return await get(`/client/deposit/${clientResponse.id}`).then(response => {
            return response;
        });
    }

    const filteredResponses = () => {
        if (selectedCategory === null) {
            return submission?.client_submission_responses;
        }

        return submission?.client_submission_responses.filter(response => response.submission_response.categories?.map(cat => cat.id).includes(selectedCategory));
    }

    const filteredAvailableContractors = () => {
        if (selectedAvailableCategory === null) {
            return availableContractors;
        }

        return availableContractors.filter(contractor => contractor.company_categories?.map(cat => cat.id).includes(selectedAvailableCategory));
    }

    const chatWithContractor = async (userId) => {
        const chatResponse = await post(`/chats`, {recipient_id: userId});

        navigation.navigate('Messages', {
            screen: 'Chat',
            params: {
                chatId: chatResponse.id
            },
            initial: false,
        });
    }

    if (loading) {
        return (
            <VStack flex={1} justifyContent={'center'} alignItems={'center'}>
                <Spinner size={'lg'}/>
                <Text mt={2}>Loading property details</Text>
            </VStack>
        );
    }

    return (
        <ScrollView p={4}>
            <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                <HStack my={4} justifyContent={'space-between'} alignItems={'center'}>
                    <Heading>{submission.address.line_1}</Heading>
                    {!showWhatIsPropServ && (
                        <TouchableOpacity onPress={() => setShowWhatIsPropServ(true)}>
                            <Ionicons name={'help-circle-outline'} size={24} color={colors.gray[500]}/>
                        </TouchableOpacity>
                    )}
                </HStack>

                {submission.status === 'closed' && (
                    <VStack borderColor={'danger.200'} borderWidth={2} bg={cardBackground}
                            p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Ionicons name={'lock-closed-outline'} size={32} color={colors.danger[500]}/>

                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>This submission is closed</Heading>

                                <Text color={gray} fontSize={'md'}>
                                    This submission is now read-only and most functionality is restricted. It will not
                                    be visible to new contractors. You can reopen the submission at any time.
                                </Text>

                                <Box p={2} bg={lightDark200} rounded={'lg'} alignSelf={'flex-start'}>
                                    <Text color={gray} fontSize={'xs'}>Reason</Text>

                                    <Text color={blackWhite}>
                                        {submission.close_reason}
                                    </Text>
                                </Box>

                                <Button alignSelf={'flex-start'} onPress={() => setShowCloseModal(true)}>
                                    Reopen submission
                                </Button>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {showWhatIsPropServ && <WhatIsPropServ close={() => setShowWhatIsPropServ(false)}/>}

                <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
                    <Heading fontSize={'md'}>Responses</Heading>
                    <Text color={gray}>
                        These contractors have reviewed your property and given a response. You can accept or decline
                        responses and chat with contractors to discuss further.
                    </Text>

                    <HStack flexWrap={'wrap'} alignItems={'center'}>
                        <TouchableOpacity onPress={() => setSelectedCategory(null)}>
                            <HStack mr={2} my={1} rounded={'2xl'} px={3} py={1}
                                    bg={lightDark200}
                                    space={2} alignItems={'center'} borderColor={'primary.500'}
                                    borderWidth={selectedCategory === null ? 2 : 0}>
                                <Text fontSize={'xs'} color={blackWhite}>
                                    All Responses
                                </Text>

                                <Text bold fontSize={'md'} color={'primary.500'}>
                                    {submission.client_submission_responses.length}
                                </Text>
                            </HStack>
                        </TouchableOpacity>
                        {submission.contractor_categories.map((category, index) => (
                            <TouchableOpacity onPress={() => setSelectedCategory(category.id)} key={index}>
                                <HStack mr={2} my={1} rounded={'2xl'} px={3} py={1}
                                        bg={lightDark200}
                                        space={2} alignItems={'center'} borderColor={'primary.500'}
                                        borderWidth={selectedCategory === category.id ? 2 : 0}>
                                    <Text fontSize={'xs'} color={blackWhite}>
                                        {category.name}
                                    </Text>
                                    <Text bold fontSize={'md'} color={'primary.500'}>
                                        {submission.client_submission_responses.filter(response => response.submission_response.categories.map(cat => cat.id).includes(category.id)).length}
                                    </Text>
                                </HStack>
                            </TouchableOpacity>
                        ))}
                    </HStack>

                    {submission.client_submission_responses.length === 0 && selectedCategory === null &&
                        <Text color={gray}>
                            No responses yet. Your estate agent will forward you responses as they come in.
                            They will appear here.
                        </Text>
                    }

                    {selectedCategory !== null && filteredResponses().length === 0 && (
                        <Text color={gray}>No responses for this category.</Text>
                    )}

                    {filteredResponses().map(response => (
                        <TouchableOpacity
                            key={response.id}
                            onPress={() => showResponse(response.submission_response, response)}>
                            <VStack
                                borderColor={response.status === 'accepted' ? 'green.500' : 'danger.500'}
                                borderWidth={['accepted', 'declined'].includes(response.status) ? 2 : 0}
                                space={2}
                                p={4}
                                bg={responseCardBackground}
                                rounded={'xl'}>
                                {['accepted', 'declined', 'paid', 'paid_by_estate_agent', 'deposit_paid', 'deposit_paid_by_estate_agent', 'complete'].includes(response.status) &&
                                    <StatusBadge status={response.status}/>}
                                <HStack space={2} justifyContent={'space-between'}>
                                    <VStack space={2} flex={1}>
                                        <HStack alignItems={'center'} space={4} mb={2}>
                                            {response.submission_response.user.company.light_logo_path && response.submission_response.user.company.dark_logo_path && (
                                                <Image
                                                    source={{uri: colorMode === 'light' ? response.submission_response.user.company.light_logo_path : response.submission_response.user.company.dark_logo_path}}
                                                    alt={response.submission_response.user.company.name}
                                                    width={'70px'}
                                                    height={'70px'}
                                                    resizeMode={'contain'}
                                                />
                                            )}
                                            <Text fontSize={'md'} bold>
                                                {response.submission_response.user.company.name}
                                            </Text>
                                            <StarRating rating={response.submission_response.user.company.star_rating}/>
                                        </HStack>

                                        {response.submission_response.categories.length > 0 && (
                                            <HStack alignItems={'center'} space={2}>
                                                <Ionicons name={'hammer-outline'} size={24} color={colors.gray[500]}/>
                                                <HStack ml={2} space={2} alignItems={'center'} flex={1}
                                                        flexWrap={'wrap'}>
                                                    {response.submission_response.categories.map((service, index) => (
                                                        <Box my={1} rounded={'2xl'} px={2} py={1}
                                                             bg={lightDark200} space={1}
                                                             alignItems={'center'}
                                                             key={index}>
                                                            <Text fontSize={'xs'}
                                                                  color={blackWhite}>
                                                                {service.name}
                                                            </Text>
                                                        </Box>
                                                    ))}
                                                </HStack>
                                            </HStack>
                                        )}

                                        <HStack alignItems={'center'} space={2}>
                                            <Ionicons name={'person-circle-outline'} size={24}
                                                      color={colors.gray[500]}/>
                                            <Text ml={2} fontSize={'md'}>{response.submission_response.user.name}</Text>
                                        </HStack>

                                        {response.submission_response.estimated_start_date && (
                                            <HStack alignItems={'center'} space={2}>
                                                <Ionicons name={'calendar-outline'} size={24} color={colors.gray[500]}/>
                                                <Text ml={2} fontSize={'md'}>
                                                    Available start date:{' '}
                                                    {new Date(response.submission_response.estimated_start_date).toLocaleDateString()}
                                                </Text>
                                            </HStack>
                                        )}

                                        {response.submission_response.user.company.commission_only !== 1 ? (
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons name={'cash-outline'} size={24} color={colors.green[500]}/>
                                                <Heading ml={2} fontSize={'xl'}>
                                                    £{response.submission_response.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                </Heading>
                                            </HStack>
                                        ) : (
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons name={'cash-outline'} size={24} color={colors.green[500]}/>
                                                <VStack>
                                                    <Text ml={2} fontSize={'md'}>Price on request</Text>
                                                    <Text ml={2} fontSize={'sm'} color={gray}>
                                                        Get in touch with the contractor for a quote
                                                    </Text>
                                                </VStack>
                                            </HStack>
                                        )}

                                        {response.status === 'accepted' && response.submission_response.deposit_amount !== null && response.submission_response.user.company.commission_only !== 1 && (
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons name={'timer-outline'} size={24} color={colors.warning[500]}/>
                                                <Text ml={2} fontSize={'md'} color={colors.warning[500]}>
                                                    Awaiting deposit payment
                                                </Text>
                                            </HStack>
                                        )}

                                        {response.status === 'accepted' && response.submission_response.deposit_amount === null && response.submission_response.user.company.commission_only !== 1 && (
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons name={'timer-outline'} size={24} color={colors.warning[500]}/>
                                                <Text ml={2} fontSize={'md'} color={colors.warning[500]}>
                                                    Awaiting payment
                                                </Text>
                                            </HStack>
                                        )}

                                        {['paid', 'paid_by_estate_agent', 'deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status) && (
                                            <HStack space={2} alignItems={'center'} flex={1}>
                                                <Ionicons name={'timer-outline'} size={24} color={colors.warning[500]}/>
                                                {['deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status) && (
                                                    <Text flex={1} ml={2} fontSize={'md'} color={colors.warning[500]}>
                                                        The deposit has been paid, come back here to make the final
                                                        payment and mark the job as complete
                                                    </Text>
                                                )}
                                                {['paid', 'paid_by_estate_agent'].includes(response.status) && (
                                                    <Text flex={1} ml={2} fontSize={'md'} color={colors.warning[500]}>
                                                        Awaiting completion, when the job is done, come back here to
                                                        mark it as complete
                                                    </Text>
                                                )}
                                            </HStack>
                                        )}
                                    </VStack>
                                </HStack>
                            </VStack>
                        </TouchableOpacity>
                    ))}
                </VStack>

                <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
                    <Heading fontSize={'md'}>Available contractors</Heading>
                    <Text color={gray}>
                        The following contractors are available in your area for the services you require.
                    </Text>

                    <HStack flexWrap={'wrap'} alignItems={'center'}>
                        <TouchableOpacity onPress={() => setSelectedAvailableCategory(null)}>
                            <HStack mr={2}
                                    my={1}
                                    rounded={'2xl'}
                                    px={3}
                                    py={1}
                                    bg={lightDark200}
                                    space={2}
                                    alignItems={'center'}
                                    borderColor={'primary.500'}
                                    borderWidth={selectedAvailableCategory === null ? 2 : 0}
                            >
                                <Text fontSize={'xs'} color={blackWhite}>
                                    All Contractors
                                </Text>

                                <Text bold fontSize={'md'} color={'primary.500'}>
                                    {availableContractors.length}
                                </Text>
                            </HStack>
                        </TouchableOpacity>

                        {submission.contractor_categories.map((category, index) => (
                            <TouchableOpacity onPress={() => setSelectedAvailableCategory(category.id)} key={index}>
                                <HStack mr={2}
                                        my={1}
                                        rounded={'2xl'}
                                        px={3}
                                        py={1}
                                        bg={lightDark200}
                                        space={2}
                                        alignItems={'center'}
                                        borderColor={'primary.500'}
                                        borderWidth={selectedAvailableCategory === category.id ? 2 : 0}
                                >
                                    <Text fontSize={'xs'} color={blackWhite}>
                                        {category.name}
                                    </Text>
                                    <Text bold fontSize={'md'} color={'primary.500'}>
                                        {availableContractors.filter(contractor => contractor.company_categories.map(cat => cat.id).includes(category.id)).length}
                                    </Text>
                                </HStack>
                            </TouchableOpacity>
                        ))}
                    </HStack>

                    {availableContractors.length === 0 && selectedAvailableCategory === null &&
                        <Text color={gray}>
                            No contractors available.
                        </Text>
                    }

                    {selectedAvailableCategory !== null && filteredAvailableContractors().length === 0 && (
                        <Text color={gray}>No contractors available for this category.</Text>
                    )}

                    {filteredAvailableContractors().map(contractor => (
                        <VStack
                            key={contractor.id}
                            borderColor={submission.client_submission_responses.map(response => response.submission_response.user.company.id).includes(contractor.id) ? 'green.500' : 'primary.500]'}
                            borderWidth={2}
                            space={2}
                            p={4}
                            bg={responseCardBackground}
                            rounded={'xl'}>
                            <HStack space={2} justifyContent={'space-between'}>
                                <VStack space={2} flex={1}>
                                    <HStack alignItems={'center'} space={4} mb={2}>
                                        {contractor.light_logo_path && contractor.dark_logo_path && (
                                            <Image
                                                source={{uri: colorMode === 'light' ? contractor.light_logo_path : contractor.dark_logo_path}}
                                                alt={contractor.name}
                                                width={'70px'}
                                                height={'70px'}
                                                resizeMode={'contain'}
                                            />
                                        )}
                                        <Text fontSize={'md'} bold>{contractor.name}</Text>
                                        <StarRating rating={contractor.star_rating}/>
                                        <Button
                                            onPress={() => chatWithContractor(contractor.created_by)}
                                            py={1}
                                            px={4}
                                            leftIcon={<Ionicons name={'chatbubble-outline'} size={18} color={'white'}/>}
                                        >
                                            Chat
                                        </Button>
                                    </HStack>

                                    {contractor.company_categories.length > 0 && (
                                        <HStack alignItems={'center'} space={4}>
                                            <Ionicons name={'hammer-outline'} size={24} color={colors.gray[500]}/>
                                            <VStack flex={1}>
                                                <HStack flexWrap={'wrap'} space={2} alignItems={'center'}>
                                                    {contractor.company_categories.map((service, index) => (
                                                        <Box my={1} rounded={'2xl'} px={2} py={1}
                                                             bg={lightDark200} space={1}
                                                             alignItems={'center'}
                                                             key={index}>
                                                            <Text fontSize={'xs'}
                                                                  color={blackWhite}>
                                                                {service.name}
                                                            </Text>
                                                        </Box>
                                                    ))}
                                                </HStack>
                                            </VStack>
                                        </HStack>
                                    )}
                                </VStack>
                            </HStack>
                        </VStack>
                    ))}
                </VStack>

                <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
                    <Heading fontSize={'md'}>Estate agent</Heading>

                    <HStack space={2} alignItems={'center'}>
                        <VStack space={2}>
                            <HStack space={2} alignItems={'center'}>
                                <Ionicons name={'person-circle-outline'} size={32} color={'gray'}/>

                                <VStack>
                                    <Text fontSize={'md'}>{submission.user.name}</Text>
                                    <Text fontSize={'md'}
                                          color={gray}>{submission.user.email}</Text>
                                </VStack>
                            </HStack>
                        </VStack>

                        <Spacer/>

                        <TouchableOpacity onPress={chatWithEstateAgent}>
                            <Circle size={8} bg={'primary.500'} justifyContent={'center'} alignItems={'center'}>
                                <Ionicons name={'chatbubble-outline'} size={18} color={'white'}/>
                            </Circle>
                        </TouchableOpacity>
                    </HStack>
                </VStack>

                <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
                    <Heading fontSize={'md'}>Location</Heading>
                    <HStack space={1} flexWrap={'wrap'}>
                        <Text fontSize={'md'}>{submission.address.line_1},</Text>
                        {submission.address.line_2 && <Text>{submission.address.line_2},</Text>}
                        <Text fontSize={'md'}>{submission.address.city},</Text>
                        <Text fontSize={'md'}>{submission.address.county},</Text>
                        <Text fontSize={'md'}>{submission.address.postcode},</Text>
                        <Text fontSize={'md'}>{submission.address.country}</Text>
                    </HStack>

                    <SubmissionMapView submission={submission}/>
                </VStack>

                <ContractorCategories submission={submission} client={true} updateCategories={updateCategories}/>

                {submission.status !== 'closed' && (
                    <VStack>
                        <Button w={'100%'} onPress={() => setShowCloseModal(true)}>
                            Close submission
                        </Button>
                    </VStack>
                )}
            </VStack>

            {showCloseModal && (
                <CloseReopenSubmission
                    client
                    submission={submission}
                    isOpen={showCloseModal}
                    close={() => setShowCloseModal(false)}
                    loading={closing}
                    setLoading={setClosing}
                    getSubmission={fetchData}
                />
            )}

            {showResponseModal && response && (
                <SubmissionResponseDetailsModal
                    refresh={fetchData}
                    accept={() => acceptResponse(response)}
                    getPaymentLink={getPaymentLink}
                    getDepositLink={getDepositLink}
                    submission={submission}
                    response={response}
                    isOpen={showResponseModal}
                    onClose={() => setShowResponseModal(false)}/>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default SubmissionDetails;

import {SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Heading, HStack, ScrollView, Switch, Text, useColorMode, useColorModeValue, VStack} from "native-base";
import * as React from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useEffect, useState} from "react";
import {EventRegister} from "react-native-event-listeners";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Appearance = props => {
    const {
        toggleColorMode,
        colorMode,
    } = useColorMode();

    const [darkMode, setDarkMode] = useState(colorMode === 'dark');

    useEffect(() => {
        const getDarkMode = async () => {
            let storedDarkMode = await AsyncStorage.getItem('darkMode');
            setDarkMode(storedDarkMode === 'true');
        }

        getDarkMode();
    }, []);

    useEffect(() => {
        setDarkMode(colorMode === 'dark');
    }, [colorMode]);

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    <HStack
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        bg={useColorModeValue('light.50', 'dark.50')}
                        rounded={'xl'}
                        p={4}>
                        <HStack alignItems={'center'} space={4}>
                            <Box bg={'black'} rounded={'lg'} p={2}>
                                <Ionicons name={'contrast-outline'} size={24} color={'white'}/>
                            </Box>
                            <Text fontSize={'md'}>Dark mode</Text>
                        </HStack>
                        <Switch isChecked={darkMode}
                                onValueChange={(value) => {
                                    toggleColorMode();
                                    AsyncStorage.setItem('darkMode', value.toString());
                                    setDarkMode(value);
                                }}
                        />
                    </HStack>
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default Appearance;

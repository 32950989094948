import {StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Button, Heading, HStack, Text, View, useColorModeValue, useTheme} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import StatusBadge from "../StatusBadge";
import Collapsible from "react-native-collapsible";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import {useAuth} from "../../providers/AuthProvider";

const SubmissionCollapsible = props => {
    const navigation = useNavigation();
    const {userDetails} = useAuth();
    const {submission} = props;
    const {colors} = useTheme();

    const gray = useColorModeValue(colors.gray[500], colors.gray[400]);

    const [submissionCollapsed, setSubmissionCollapsed] = React.useState(true);

    return (
        <View>
            <TouchableOpacity onPress={() => setSubmissionCollapsed(!submissionCollapsed)}>
                <HStack mt={4} space={2} alignItems={'center'}>
                    <Ionicons
                        name={submissionCollapsed ? 'chevron-forward' : 'chevron-up'}
                        size={24}
                        color={gray}
                    />
                    <Text color={gray}>Submission</Text>
                    <StatusBadge alignSelf={'flex-start'} status={submission.status}/>
                </HStack>
            </TouchableOpacity>

            <Collapsible collapsed={submissionCollapsed}>
                <Heading mt={2}>{submission.address.line_1}</Heading>

                <Text color={gray} my={2}>
                    {submission.address.line_1},{' '}
                    {submission.address.line_2 && `${submission.address.line_2}, `}
                    {submission.address.city},{' '}
                    {submission.address.county},{' '}
                    {submission.address.postcode},{' '}
                    {submission.address.country}{' '}
                </Text>

                <HStack flexWrap={'wrap'} space={2}>
                    {submission.contractor_categories.filter(category => userDetails.company.company_categories.map(c => c.id).includes(category.id)).map(category => (
                        <HStack alignSelf={'flex-start'} my={1} rounded={'2xl'} px={2} py={1}
                                bg={useColorModeValue('light.200', 'dark.200')} space={1} alignItems={'center'}
                                key={category.id}>
                            <Text fontSize={'xs'} color={useColorModeValue('black', 'white')}>
                                {category.name}
                            </Text>
                        </HStack>
                    ))}
                </HStack>

                <Text mt={2} color={gray}>{submission.description}</Text>

                {submission.response && (
                    <Box mt={2} rounded={'lg'} borderColor={'primary.500'} borderWidth={1} p={2}>
                        <Text color={gray} bold>Your offer</Text>
                        {submission.response.depost_amount && (
                            <Text color={gray}>Deposit:
                                £{submission.response.deposit_amount.toLocaleString()}</Text>
                        )}
                        <Text color={gray}>Final price:
                            £{submission.response.price.toLocaleString()}</Text>

                        {submission.response.message && (
                            <Text color={gray}>Final price:
                                £{submission.response.price.toLocaleString()}</Text>
                        )}
                    </Box>
                )}

                {!submission.response && submission.company_response && (
                    <Box mt={2} rounded={'lg'} borderColor={'primary.500'} borderWidth={1} p={2}>
                        <Text color={gray} bold>{submission.company_response.user.name}'s offer</Text>
                        {submission.company_response.depost_amount && (
                            <Text color={gray}>Deposit:
                                £{submission.company_response.deposit_amount.toLocaleString()}</Text>
                        )}
                        <Text color={gray}>Final price:
                            £{submission.company_response.price.toLocaleString()}</Text>

                        {submission.company_response.message && (
                            <Text color={gray}>Final price:
                                £{submission.company_response.price.toLocaleString()}</Text>
                        )}
                    </Box>
                )}

                <Button pl={0} mt={2} alignSelf={'flex-start'} variant={'link'}
                        onPress={() => navigation.navigate('Home', {
                            screen: 'Submission',
                            params: {
                                submissionId: submission.uuid
                            },
                            initial: false,
                        })}>
                    Go to submission
                </Button>
            </Collapsible>
        </View>
    );
};

const styles = StyleSheet.create({});

export default SubmissionCollapsible;

// api.js

import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";

// Create an Axios instance with a base URL
export const api = axios.create({
    // baseURL: Constants?.manifest?.extra?.env?.EXPO_PUBLIC_API_URL || 'https://api.propfees.co/api',
    baseURL: Constants?.manifest?.extra?.env?.EXPO_PUBLIC_API_URL || 'https://dev.api.propfees.co/api',
});

// Request interceptor for attaching token
api.interceptors.request.use(
    async config => {
        const token = await AsyncStorage.getItem('token');
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

// Intercept 401 and logout user
api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log(error);
        if (error?.response?.status === 401) {
            AsyncStorage.clear();
        }

        return Promise.reject(error);
    },
);

export const get = async (endpoint, params, config = {}) => {
    const response = await api.get(endpoint, {params, ...config});
    return response.data;
};

export const post = async (endpoint, data, config = {}) => {
    const response = await api.post(endpoint, data, config);
    return response.data;
};

export const put = async (endpoint, data, config = {}) => {
    const response = await api.put(endpoint, data, config);
    return response.data;
};

export const del = async (endpoint, data) => {
    const response = await api.delete(endpoint, {data});
    return response.data;
};

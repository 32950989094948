import {StyleSheet} from 'react-native';
import * as React from "react";
import {Image, Spinner, Text, useTheme, View, VStack} from "native-base";
import {useEffect, useState} from "react";
import {get} from "../../services/api";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";

const MagicAzureLink = props => {
    const {setUserDetails, setToken} = useAuth();

    const {colors} = useTheme();

    const {token} = props.route.params;

    const [loading, setLoading] = useState(true);
    const [tokenExpired, setTokenExpired] = useState(false);

    useEffect(() => {
        if (token) {
            get('/my-information', {}, {headers: {Authorization: `Bearer ${token}`}}).then(response => {
                setUserDetails(response);
                setToken(token);
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    setTokenExpired(true);
                }
                setLoading(false);
            });
        }
    }, []);

    if (tokenExpired) {
        return (
            <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <VStack space={4} alignItems={'center'}>
                    <Image height={100} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                           source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>

                    <Ionicons name={'alert-circle-outline'} size={64} color={colors.danger[500]}/>

                    <Text color={'gray.500'}>It looks like this link has expired.</Text>
                    <Text color={'gray.500'}>Please try again.</Text>
                </VStack>
            </View>
        )
    }

    return (
        <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <VStack space={4} alignItems={'center'}>
                <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                       source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>

                <Spinner size={'lg'}/>

                <Text color={'gray.500'}>Fetching your data</Text>
            </VStack>
        </View>
    );
};

const styles = StyleSheet.create({});

export default MagicAzureLink;

import {Linking, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Badge,
    Box,
    Button, Checkbox,
    Circle,
    Divider,
    Heading,
    HStack,
    Modal,
    Spinner,
    Text,
    useColorModeValue,
    useTheme,
    VStack
} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import {downloadDocument} from "../../services/documents";
import StatusBadge from "../StatusBadge";
import * as React from "react";
import {post} from "../../services/api";
import {useNavigation} from "@react-navigation/native";
import {useEffect, useRef} from "react";
import * as Ably from "ably";
import {useAuth} from "../../providers/AuthProvider";
import {FontAwesome} from "@expo/vector-icons";

const SubmissionResponseDetailsModal = props => {
    const navigation = useNavigation();
    const {
        isOpen,
        onClose,
        accept,
        response,
        submission,
        getPaymentLink,
        getDepositLink,
        refresh,
    } = props;
    const {colors} = useTheme();
    const {userDetails} = useAuth();

    const [loadingPayment, setLoadingPayment] = React.useState(false);
    const [showAcceptModal, setShowAcceptModal] = React.useState(false);
    const [showMarkAsCompleteModal, setShowMarkAsCompleteModal] = React.useState(false);
    const [clientPaymentLink, setClientPaymentLink] = React.useState(null);
    const [clientDepositPaymentLink, setClientDepositPaymentLink] = React.useState(null);
    const [showPaymentReceivedModal, setShowPaymentReceivedModal] = React.useState(false);
    const [showPayModal, setShowPayModal] = React.useState(false);
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);

    const clientRef = useRef(null);

    useEffect(() => {
        if (response.status === 'accepted' && response.deposit_amount == null && response.user.company.custom_payment_link == null) {
            getPaymentLink().then(response => {
                setClientPaymentLink(response.url);
            });
        }
        if (['deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status) && response.user.company.custom_payment_link == null) {
            getPaymentLink().then(response => {
                setClientPaymentLink(response.url);
            });
        }

        if (response.status === 'accepted' && response.deposit_amount != null && response.user.company.custom_payment_link == null) {
            getDepositLink().then(response => {
                setClientDepositPaymentLink(response.url);
            });
        }

        if (response.status === 'accepted' && response.user.company.custom_payment_link != null) {
            setClientPaymentLink(response.user.company.custom_payment_link);
        }
    }, []);

    const chatWithContractor = async () => {
        const chatResponse = await post(`/chats`, {recipient_id: response.user.id});

        onClose();

        navigation.navigate('Messages', {
            screen: 'Chat',
            params: {
                chatId: chatResponse.id
            },
            initial: false,
        });
    }

    const markAsComplete = async () => {
        setShowMarkAsCompleteModal(false);

        await post(`/client/submissions/${submission.id}/responses/${response.id}/complete`);

        onClose();
        refresh();
    }

    const initAbly = () => {
        if (clientRef.current) {
            return;
        }

        const client = new Ably.Realtime('rBU8kg.UhrKZQ:04TkMQxE4iAg0WDlHXcb3Kof60NR1eN_5OFFo9Vl6BA');
        clientRef.current = client;

        const channel = client.channels.get(`private:client-${userDetails.id}-submission-response-${response.id}`);

        channel.subscribe(function (message) {
            setShowPaymentReceivedModal(true);
        });
    }

    const pay = async (deposit = false) => {
        setLoadingPayment(true);
        initAbly();
        Linking.openURL(deposit ? clientDepositPaymentLink : clientPaymentLink);
    }

    return (
        <>
            <Modal isKeyboardDismissable={false} isOpen={isOpen} onClose={onClose}>
                <Modal.Content maxWidth="500px">
                    <Modal.CloseButton/>
                    <Modal.Header>Response</Modal.Header>
                    <Modal.Body>
                        <VStack space={3}>
                            <StatusBadge status={response.status}/>
                            <Divider mt={2}/>

                            <HStack justifyContent={'space-between'} alignItems={'center'}>
                                <VStack>
                                    <Text fontSize={'sm'}
                                          color={useColorModeValue('gray.500', 'gray.400')}>Contractor</Text>
                                    <HStack alignItems={'center'} space={2}>
                                        <Ionicons name={'person-outline'} size={20} color={colors.gray[400]}/>
                                        <Text fontSize={'md'}>{response.user.name}</Text>
                                    </HStack>
                                    {response.user.phone_number && (
                                        <HStack alignItems={'center'} space={2}>
                                            <Ionicons name={'call-outline'} size={20} color={colors.gray[400]}/>
                                            <Text fontSize={'md'}>{response.user.phone_number}</Text>
                                        </HStack>
                                    )}
                                    <HStack alignItems={'center'} space={2}>
                                        <Ionicons name={'business-outline'} size={20} color={colors.gray[400]}/>
                                        <Text fontSize={'md'}>{response.user.company.name}</Text>
                                    </HStack>
                                </VStack>

                                <TouchableOpacity onPress={chatWithContractor}>
                                    <Circle size={8} bg={'primary.500'} justifyContent={'center'} alignItems={'center'}>
                                        <Ionicons name={'chatbubble-outline'} size={18} color={'white'}/>
                                    </Circle>
                                </TouchableOpacity>
                            </HStack>
                            <Divider/>

                            {response.categories.length > 0 && (
                                <>
                                    <VStack>
                                        <Text fontSize={'sm'} color={useColorModeValue('gray.500', 'gray.400')}>
                                            Services
                                        </Text>
                                        <HStack space={2} alignItems={'center'} flexWrap={'wrap'}>
                                            {response.categories.map((service, index) => (
                                                <Box my={1} rounded={'2xl'} px={2} py={1}
                                                     bg={useColorModeValue('light.200', 'dark.200')} space={1}
                                                     alignItems={'center'}
                                                     key={index}>
                                                    <Text fontSize={'xs'} color={useColorModeValue('black', 'white')}>
                                                        {service.name}
                                                    </Text>
                                                </Box>
                                            ))}
                                        </HStack>
                                    </VStack>
                                    <Divider/>
                                </>
                            )}

                            {response.status !== 'declined' && response.user.company.commission_only === 1 && (
                                <>
                                    <VStack>
                                        <Text fontSize={'sm'}
                                              color={useColorModeValue('gray.500', 'gray.400')}>Price</Text>
                                        <Text fontSize={'md'}>Price on request</Text>
                                        <Text mt={2} fontSize={'sm'} color={useColorModeValue('gray.500', 'gray.400')}>
                                            Get in touch with the contractor for a quote
                                        </Text>
                                    </VStack>
                                    <Divider/>
                                </>
                            )}

                            {response.status !== 'declined' && response.user.company.commission_only !== 1 && (
                                <>
                                    <VStack>
                                        <Text fontSize={'sm'}
                                              color={useColorModeValue('gray.500', 'gray.400')}>Price</Text>
                                        <Text
                                            fontSize={'md'}>£{response.price.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                                    </VStack>
                                    <Divider/>
                                </>
                            )}

                            {response.status !== 'declined' && response.deposit_amount !== null && response.user.company.commission_only !== 1 && (
                                <>
                                    <VStack>
                                        <Text fontSize={'sm'}
                                              color={useColorModeValue('gray.500', 'gray.400')}>Deposit</Text>
                                        <Text
                                            fontSize={'md'}>£{response.deposit_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                                    </VStack>
                                    <Divider/>
                                </>
                            )}

                            <VStack>
                                <Text fontSize={'sm'} color={useColorModeValue('gray.500', 'gray.400')}>
                                    Estimated start date
                                </Text>
                                {response.estimated_start_date == null ? (
                                    <Text fontSize={'md'}>No estimated start date provided</Text>
                                ) : (
                                    <Text
                                        fontSize={'md'}>{new Date(response.estimated_start_date).toLocaleDateString()}</Text>
                                )}
                            </VStack>
                            <Divider/>

                            <VStack>
                                <Text fontSize={'sm'} color={useColorModeValue('gray.500', 'gray.400')}>
                                    {response.status === 'declined' ? 'Decline reason' : 'Message'}
                                </Text>
                                {response.message == null ? (
                                    <Text fontSize={'md'}>No message</Text>
                                ) : (
                                    <Text fontSize={'md'}>{response.message}</Text>
                                )}
                            </VStack>

                            {response.status !== 'declined' && (<Divider/>)}

                            {response.status !== 'declined' && (
                                <VStack>
                                    <Text fontSize={'sm'}
                                          color={useColorModeValue('gray.500', 'gray.400')}>Documents</Text>
                                    <VStack mt={response.documents.length === 0 ? 0 : 3} flex={1} space={1}>
                                        {response.documents.length === 0 && (
                                            <Text fontSize={'md'}>No documents</Text>
                                        )}

                                        {response.documents.map(document => (
                                            <HStack alignItems={'center'} key={document.id} flex={1} py={2} px={4}
                                                    bg={useColorModeValue('light.50', 'dark.50')}
                                                    rounded={'xl'}
                                                    justifyContent={'space-between'}>
                                                <Text fontSize={'md'} flex={1}>{document.name}</Text>
                                                <TouchableOpacity onPress={() => downloadDocument(document)}>
                                                    <Ionicons name={'download-outline'} size={24}
                                                              color={colors.primary[500]}/>
                                                </TouchableOpacity>
                                            </HStack>
                                        ))}
                                    </VStack>
                                </VStack>
                            )}

                            {response.status !== 'declined' && (<Divider/>)}

                            {response.status !== 'declined' && (
                                <VStack>
                                    <Text fontSize={'sm'}
                                          color={useColorModeValue('gray.500', 'gray.400')}>Terms</Text>
                                    <VStack mt={response.terms.length === 0 ? 0 : 3} flex={1} space={1}>
                                        {response.terms.length === 0 && (
                                            <VStack space={1} mt={2}>
                                                <Text fontSize={'md'}>No terms attached yet</Text>
                                                <Text color={'danger.500'} fontSize={'md'}>
                                                    The contractor needs to upload terms before this response is
                                                    payable.
                                                </Text>
                                            </VStack>
                                        )}

                                        {response.terms.map(document => (
                                            <HStack alignItems={'center'} key={document.id} flex={1} py={2} px={4}
                                                    bg={useColorModeValue('light.50', 'dark.50')}
                                                    rounded={'xl'}
                                                    justifyContent={'space-between'}>
                                                <Text fontSize={'md'} flex={1}>{document.name}</Text>
                                                <TouchableOpacity onPress={() => downloadDocument(document)}>
                                                    <Ionicons name={'download-outline'} size={24}
                                                              color={colors.primary[500]}/>
                                                </TouchableOpacity>
                                            </HStack>
                                        ))}
                                    </VStack>
                                </VStack>
                            )}
                        </VStack>
                    </Modal.Body>
                    {response.status === 'offered' && submission.status !== 'closed' && (
                        <Modal.Footer justifyContent={'flex-start'}>
                            <Button.Group space={2} flex={1}>
                                <Button
                                    flex={1}
                                    onPress={chatWithContractor}
                                    isDisabled={submission.client_id === null}
                                    leftIcon={submission.client_id === null ?
                                        <Ionicons name={'warning-outline'} size={24} color={'white'}/> : null}>
                                    Chat
                                </Button>
                                <Button
                                    flex={1}
                                    onPress={() => setShowAcceptModal(true)}
                                    isDisabled={submission.client_id === null}
                                    leftIcon={submission.client_id === null ?
                                        <Ionicons name={'warning-outline'} size={24} color={'white'}/> : null}>
                                    Accept
                                </Button>
                            </Button.Group>
                        </Modal.Footer>
                    )}
                    {response.terms.length > 0 && response.user.company.commission_only !== 1 && ['accepted', 'deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status) && submission.status !== 'closed' && (
                        <Modal.Footer>
                            <VStack space={4} flex={1}>
                                {response.user.company.custom_payment_link != null && (
                                    <Text fontSize={'sm'} color={useColorModeValue('gray.500', 'gray.400')}>
                                        {response.user.company.name} have provided their own payment link, you will be
                                        redirected to their website to make payment. The payment may not be reflected
                                        immediately on PropServ.
                                    </Text>
                                )}

                                <HStack space={2} alignItems={'center'}>
                                    <Checkbox
                                        isChecked={acceptedTerms}
                                        value={acceptedTerms}
                                        onChange={setAcceptedTerms}
                                        aria-label={'Have you read and accepted the contractor\'s terms?'}
                                    />
                                    <Text fontSize={'xs'} color={useColorModeValue('gray.500', 'gray.400')}>
                                        Have you read and accepted the contractor's terms?
                                    </Text>
                                </HStack>

                                {response.deposit_amount !== null && !['deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status) && (
                                    <Button flex={1}
                                            isDisabled={!acceptedTerms || clientDepositPaymentLink === null || loadingPayment}
                                            onPress={() => setShowPayModal(true)}>
                                        {(clientDepositPaymentLink === null || loadingPayment) ? (
                                            <Spinner color={'white'}/>
                                        ) : (
                                            <Text bold color={'white'}>
                                                Pay{' '}
                                                £{response.deposit_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                {' '}deposit
                                            </Text>
                                        )}
                                    </Button>
                                )}

                                {(response.deposit_amount === null || ['deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status)) && (
                                    <Button flex={1}
                                            isDisabled={!acceptedTerms || clientPaymentLink === null || loadingPayment}
                                            onPress={() => setShowPayModal(true)}>
                                        {(clientPaymentLink === null || loadingPayment) ? (
                                            <Spinner color={'white'}/>
                                        ) : (
                                            <Text bold color={'white'}>
                                                Pay
                                                £{response.price.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                            </Text>
                                        )}
                                    </Button>
                                )}
                            </VStack>
                        </Modal.Footer>
                    )}

                    {(response.user.company.commission_only === 1 || response.status === 'paid' || response.status === 'paid_by_estate_agent') && submission.status !== 'closed' && response.status !== 'complete' && (
                        <Modal.Footer>
                            <Button flex={1} onPress={() => setShowMarkAsCompleteModal(true)}>
                                Mark as complete
                            </Button>
                        </Modal.Footer>
                    )}
                </Modal.Content>
            </Modal>

            {showPayModal && (
                <Modal isKeyboardDismissable={false} isOpen={showPayModal} onClose={() => setShowPayModal(false)}>
                    <Modal.Content maxWidth="500px">
                        <Modal.CloseButton/>
                        <Modal.Header>Pay</Modal.Header>
                        <Modal.Body>
                            <Badge
                                rounded={'full'}
                                alignSelf={'flex-start'}
                                colorScheme={'danger'}
                                leftIcon={<Ionicons name={'alert-circle-outline'} size={24}
                                                    color={colors.danger[800]}/>}
                            >
                                IMPORTANT
                            </Badge>
                            <Text fontSize={'md'} mt={2}>
                                Before proceeding with payment, please ensure you have thoroughly communicated with the
                                contractor and are satisfied with both the services they are offering and the terms
                                attached in their response.
                            </Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <HStack space={4} justifyContent={'space-between'} alignItems={'center'}
                                        w={'100%'}>
                                    <Button px={4} colorScheme="error" onPress={() => {
                                        setShowPayModal(false)
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button px={8} colorScheme="primary" onPress={() => {
                                        response.deposit_amount !== null && !['deposit_paid', 'deposit_paid_by_estate_agent'].includes(response.status) ? pay(true) : pay(false);
                                    }}>
                                        Pay
                                    </Button>
                                </HStack>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            )}

            {showAcceptModal && (
                <Modal isKeyboardDismissable={false} isOpen={showAcceptModal}
                       onClose={() => setShowAcceptModal(false)}>
                    <Modal.Content maxWidth="500px">
                        <Modal.CloseButton/>
                        <Modal.Header>Accept offer</Modal.Header>
                        <Modal.Body>
                            <Text fontSize={'md'}>
                                Are you sure you want to accept this offer?
                            </Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <HStack space={4} justifyContent={'space-between'} alignItems={'center'}
                                        w={'100%'}>
                                    <Button colorScheme="error" onPress={() => {
                                        setShowAcceptModal(false)
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="primary" onPress={() => {
                                        accept();
                                    }}>
                                        Accept
                                    </Button>
                                </HStack>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            )}

            {showMarkAsCompleteModal && (
                <Modal isKeyboardDismissable={false} isOpen={showMarkAsCompleteModal}
                       onClose={() => setShowMarkAsCompleteModal(false)}>
                    <Modal.Content maxWidth="500px">
                        <Modal.CloseButton/>
                        <Modal.Header>Mark as complete</Modal.Header>
                        <Modal.Body>
                            <Text fontSize={'md'}>
                                Are you sure you want to mark this job as complete?
                            </Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <HStack space={4} justifyContent={'space-between'} alignItems={'center'}
                                        w={'100%'}>
                                    <Button colorScheme="error" onPress={() => {
                                        setShowMarkAsCompleteModal(false)
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="primary" onPress={markAsComplete}>
                                        Mark as complete
                                    </Button>
                                </HStack>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            )}

            {showPaymentReceivedModal && (
                <Modal isKeyboardDismissable={false} isOpen={showPaymentReceivedModal}
                       onClose={() => setShowPaymentReceivedModal(false)}>
                    <Modal.Content maxWidth="500px">
                        <Modal.CloseButton/>
                        <Modal.Body>
                            <VStack p={4} alignItems={'center'}>
                                <Ionicons name={'checkmark-circle'} size={64} color={colors.green[500]}/>
                                <Heading mt={4} textAlign={'center'}>Payment received</Heading>
                                <Text mt={4} textAlign={'center'} fontSize={'md'}>
                                    We have received your payment successfully.
                                </Text>
                                <Text textAlign={'center'} fontSize={'md'}>
                                    The contractor will be notified.
                                </Text>
                                <Button px={8} mt={4} colorScheme="primary" onPress={() => {
                                    setShowPaymentReceivedModal(false);
                                    onClose();
                                    refresh();
                                }}>
                                    Done
                                </Button>
                            </VStack>
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

const styles = StyleSheet.create({});

export default SubmissionResponseDetailsModal;

import React, { createContext, useState, useEffect } from 'react';
import {get} from "../services/api";

export const ChatNotificationsContext = createContext();

export const ChatNotificationsProvider = ({ children }) => {
    const [notificationsPerConversation, setNotificationsPerConversation] = useState({});
    const [notificationsCount, setNotificationsCount] = useState(0);

    const init = async () => {
        get('/chats/notifications/count').then(response => {
            setNotificationsCount(response.count);
            setNotificationsPerConversation(response.conversations)
        })
    };

    useEffect(() => {
        init();
    }, []);

    const refreshCount = async () => {
        init();
    };

    return (
        <ChatNotificationsContext.Provider value={{ notificationsCount, setNotificationsCount, notificationsPerConversation, refreshCount }}>
            {children}
        </ChatNotificationsContext.Provider>
    );
};

export const useChatNotificationsCount = () => {
    const { notificationsCount, setNotificationsCount, notificationsPerConversation, refreshCount } = React.useContext(ChatNotificationsContext);
    return { notificationsCount, setNotificationsCount, notificationsPerConversation, refreshCount };
}

import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    HStack,
    Input,
    ScrollView,
    Text,
    useTheme,
    VStack
} from "native-base";
import * as React from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useNavigation} from "@react-navigation/native";
import CompanyDetails from "./CompanyDetails";

const SecondPage = props => {
    const {
        web,
        companyName,
        setCompanyName,
        referralCode,
        setReferralCode,
        userType,
        error,
        nextPage,
        prevPage,
        companyDescription,
        setCompanyDescription,
        categories,
        companyCategories,
        setCompanyCategories,
        companyReferralCode,
        setCompanyReferralCode,
        companyOnPropServ,
        setCompanyOnPropServ,
        companyWebsite,
        setCompanyWebsite,
        companyPhoneNumber,
        setCompanyPhoneNumber,
        companyEmail,
        setCompanyEmail,
        companyAreas,
        setCompanyAreas,
    } = props;

    const {colors} = useTheme();
    const styles = createStyles(web);

    const navigation = useNavigation();

    const canGoNext = () => {
        if (userType === 'client') {
            return referralCode != null && referralCode.length > 0;
        }

        if (companyOnPropServ === true) {
            return companyReferralCode != null && companyReferralCode.length > 0;
        }

        if (companyOnPropServ === false) {
            if (userType === 'contractor') {
                if (companyCategories.length === 0) {
                    return false;
                }
            }

            return companyName != null &&
                companyName.length > 0 &&
                companyDescription != null &&
                companyDescription.length > 0 &&
                companyWebsite != null &&
                companyWebsite.length > 0 &&
                companyPhoneNumber != null &&
                companyPhoneNumber.length > 0 &&
                companyEmail != null &&
                companyEmail.length > 0;
        }

        return false;
    }

    return (
        <ScrollView contentContainerStyle={styles.page} maxWidth={'600px'} width={'100%'} alignSelf={'center'}>
            <VStack space={4} width={'100%'}>
                {userType === 'client' && (
                    <VStack space={2}>
                        <Input
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            fontSize={'md'}
                            size={'xl'}
                            onChangeText={text => setReferralCode(text)}
                            placeholder="Referral code"
                            minW={300}
                        />

                        <Text fontSize={'sm'} color={'gray.500'}>
                            A referral code is required to sign up. If you don't have one, request one from
                            your estate agent.
                        </Text>
                    </VStack>
                )}

                {userType !== 'client' && (
                    <CompanyDetails
                        userType={userType}
                        companyCategories={companyCategories}
                        setCompanyCategories={setCompanyCategories}
                        categories={categories}
                        setCompanyDescription={setCompanyDescription}
                        error={error}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        setCompanyName={setCompanyName}
                        setCompanyReferralCode={setCompanyReferralCode}
                        companyOnPropServ={companyOnPropServ}
                        setCompanyOnPropServ={setCompanyOnPropServ}
                        setCompanyWebsite={setCompanyWebsite}
                        setCompanyPhoneNumber={setCompanyPhoneNumber}
                        setCompanyEmail={setCompanyEmail}
                        companyAreas={companyAreas}
                        setCompanyAreas={setCompanyAreas}
                    />
                )}

                {error && <Text color={'error.500'}>{error}</Text>}

                {!web && (
                    <VStack space={4} mt={4}>
                        <HStack space={4} alignItems={'center'} justifyContent={'space-between'}>
                            <TouchableOpacity onPress={() => prevPage(1)}>
                                <HStack space={2} alignItems={'center'}>
                                    <Ionicons name={'arrow-back'} size={24} color={colors.gray[500]}/>
                                    <Text fontSize={'sm'} color={'gray.500'}>Back</Text>
                                </HStack>
                            </TouchableOpacity>
                            <Button flex={1} onPress={() => nextPage(1)} isDisabled={!canGoNext()}>
                                Next
                            </Button>
                        </HStack>
                        <Button variant={'link'} onPress={() => navigation.navigate('Login')}>
                            Already have an account? Login
                        </Button>
                    </VStack>
                )}
            </VStack>
        </ScrollView>
    );
};

const createStyles = (web) => {
    return StyleSheet.create({
        page: {
            flex: web ? null : 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            width: '100%',
        },
    });
}
export default SecondPage;

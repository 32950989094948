import {StyleSheet, TouchableOpacity, View} from 'react-native';
import * as DocumentPicker from "expo-document-picker";
import {Button, HStack, ScrollView, useTheme, VStack, Text, useColorModeValue} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";

const UploadDocumentsButton = props => {
    const {documents, setDocuments, label = 'Upload files'} = props;
    const {colors} = useTheme();

    const documentBackground = useColorModeValue('light.100', 'dark.100');

    const uploadFile = async () => {
        const result = await DocumentPicker.getDocumentAsync({
            multiple: true,
        });

        result?.assets?.forEach(asset => {
            setDocuments(prevState => [...prevState, {
                ...asset, last_modified: asset.lastModified, mime_type: asset.mimeType,
            }]);
        });
    }

    return (
        <VStack space={2} {...props}>
            <VStack>
                {documents.map((document, index) => (
                    <HStack
                        mb={2}
                        flexDir={'row'}
                        alignItems={'center'}
                        key={index}
                        space={2}
                        bg={documentBackground}
                        p={2}
                        rounded={'xl'}>
                        <Ionicons name={'document'} size={24} color={colors.gray[500]}/>
                        <Text flex={1}>{document.name}</Text>
                        <TouchableOpacity
                            onPress={() => setDocuments(prevState => prevState.filter((_, i) => i !== index))}>
                            <Ionicons name={'close'} size={24} color={colors.primary[500]}/>
                        </TouchableOpacity>
                    </HStack>))}
            </VStack>

            <Button variant={'outline'} onPress={uploadFile}>{label}</Button>
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default UploadDocumentsButton;

import {Modal, SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {Button, Heading, HStack, Icon, ScrollView, Spinner, Text, useTheme, View, VStack} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";
import {useEffect, useState} from "react";
import Purchases from "react-native-purchases";
import {AntDesign, EvilIcons, FontAwesome} from "@expo/vector-icons";

const SubscriptionsModal = props => {
    const {colors} = useTheme();

    const [loading, setLoading] = useState(true);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [offerings, setOfferings] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [subscribing, setSubscribing] = useState(false);

    useEffect(() => {
        getRevenueCatData();
    }, []);

    const getRevenueCatData = async () => {
        const rcOfferings = await Purchases.getOfferings();
        setOfferings(rcOfferings);
        setSelectedSubscription(rcOfferings.all?.Bronze.availablePackages[0])

        const customerInfo = await Purchases.getCustomerInfo();
        setCustomerInfo(customerInfo);

        setLoading(false);
    }

    const subscribe = async () => {
        setSubscribing(true);
        try {
            console.log('subscribing');
            const {customerInfo, productIdentifier} = await Purchases.purchasePackage(selectedSubscription);

            console.log('done');
            console.log(JSON.stringify(customerInfo));
            console.log(productIdentifier);

            setSubscribing(false);

            if (customerInfo.entitlements.active.premium) {
                console.log('unlocked premium content');
            }
        } catch (e) {
            console.log(e);
            setSubscribing(false);
        }
    }

    return (
        <Modal
            presentationStyle="pageSheet"
            animationType="slide"
            visible={props.visible}
            onRequestClose={props.onClose}>
            <View flex={1} bg={"dark.50"}>
                <View w={'100%'} flex={1} bg={"dark.50"} maxW={500} alignSelf={'center'}>
                    <SafeAreaView style={{flex: 1}} edges={["right", "top", "left"]}>
                        <VStack space={4} p={4} roundedTop={"xl"}>
                            <HStack pt={4} space={2} alignItems={"center"} justifyContent={"space-between"}>
                                <View rounded={'full'} bg={'primary.500:alpha.30'}>
                                    <HStack py={1} px={4} alignItems={"center"}>
                                        <Icon as={FontAwesome} name={"diamond"} size={4} pr={6} color={"primary.400"}/>
                                        <Text>PropServ Premium</Text>
                                    </HStack>
                                </View>
                                <TouchableOpacity onPress={props.onClose}>
                                    <Ionicons name={"close-circle"} size={24} color={"rgb(90, 90, 90)"}/>
                                </TouchableOpacity>
                            </HStack>

                            <Heading>Boost your success with PropServ Premium.</Heading>

                            <Text color={"gray.400"}>
                                Choose a plan that works for you. PropServ Premium is a subscription that unlocks
                                premium features to help you succeed.
                            </Text>

                            {loading && (
                                <View mt={8} justifyContent={'center'} alignItems={'center'}>
                                    <Spinner size={'lg'}/>
                                    <Text mt={2}>Loading</Text>
                                </View>
                            )}

                            <ScrollView showsVerticalScrollIndicator={false} mt={4}>
                                <TouchableOpacity
                                    onPress={() => setSelectedSubscription(offerings.all?.Bronze.availablePackages[0])}>
                                    <VStack px={6} py={4} bg={'dark.100'} rounded={'xl'} space={2}
                                            borderColor={'primary.500'}
                                            borderWidth={selectedSubscription === offerings.all?.Bronze.availablePackages[0] ? 3 : 0}>
                                        <HStack space={6} alignItems={'center'}>
                                            {selectedSubscription === offerings.all?.Bronze.availablePackages[0] ? (
                                                <Ionicons name={'checkmark-circle'} size={32}
                                                          color={colors.primary[500]}/>
                                            ) : (
                                                <Ionicons name={'ellipse-outline'} size={32} color={'gray'}/>
                                            )}

                                            <VStack space={2}>
                                                <Heading>Bronze</Heading>
                                                <Text fontSize={'sm'} color={'gray.400'}>
                                                    Access bronze features.
                                                </Text>
                                                <Text fontSize={'sm'} color={'gray.400'}>
                                                    {offerings.all?.Bronze.monthly.product.priceString} per month
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </VStack>
                                </TouchableOpacity>

                                <TouchableOpacity style={{marginTop: 24}}
                                                  onPress={() => setSelectedSubscription(offerings.all?.Silver.availablePackages[0])}>
                                    <VStack px={6} py={4} bg={'dark.100'} rounded={'xl'} space={2}
                                            borderColor={'primary.500'}
                                            borderWidth={selectedSubscription === offerings.all?.Silver.availablePackages[0] ? 3 : 0}>
                                        <HStack space={6} alignItems={'center'}>
                                            {selectedSubscription === offerings.all?.Silver.availablePackages[0] ? (
                                                <Ionicons name={'checkmark-circle'} size={32}
                                                          color={colors.primary[500]}/>
                                            ) : (
                                                <Ionicons name={'ellipse-outline'} size={32} color={'gray'}/>
                                            )}

                                            <VStack space={2}>
                                                <Heading>Silver</Heading>
                                                <Text fontSize={'sm'} color={'gray.400'}>
                                                    Access silver features.
                                                </Text>
                                                <Text fontSize={'sm'} color={'gray.400'}>
                                                    {offerings.all?.Silver?.monthly?.product?.priceString} per month
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </VStack>
                                </TouchableOpacity>

                                <TouchableOpacity style={{marginTop: 24}}
                                                  onPress={() => setSelectedSubscription(offerings.all?.Gold.availablePackages[0])}>
                                    <VStack px={6} py={4} bg={'dark.100'} rounded={'xl'} space={2}
                                            borderColor={'primary.500'}
                                            borderWidth={selectedSubscription === offerings.all?.Gold.availablePackages[0] ? 3 : 0}>
                                        <HStack space={6} alignItems={'center'}>
                                            {selectedSubscription === offerings.all?.Gold.availablePackages[0] ? (
                                                <Ionicons name={'checkmark-circle'} size={32}
                                                          color={colors.primary[500]}/>
                                            ) : (
                                                <Ionicons name={'ellipse-outline'} size={32} color={'gray'}/>
                                            )}

                                            <VStack space={2}>
                                                <Heading>Gold</Heading>
                                                <Text fontSize={'sm'} color={'gray.400'}>
                                                    Access gold features.
                                                </Text>
                                                <Text fontSize={'sm'} color={'gray.400'}>
                                                    {offerings.all?.Gold?.monthly?.product?.priceString} per month
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </VStack>
                                </TouchableOpacity>
                            </ScrollView>

                            <Button mt={4} onPress={subscribe}>
                                {subscribing ? (
                                    <Spinner color={'white'}/>
                                ) : (
                                    <Text>Subscribe</Text>
                                )}
                            </Button>
                        </VStack>
                    </SafeAreaView>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({});

export default SubscriptionsModal;

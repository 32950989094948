import * as React from 'react';
import {Image, NativeBaseProvider, useTheme} from 'native-base';
import {DarkTheme, DefaultTheme, NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import {unauthenticatedLinking} from "../Linking";

const Stack = createNativeStackNavigator();

function AuthStack() {
    const {colors} = useTheme();

    const myTheme = {
        ...DarkTheme,
        colors: {
            ...DarkTheme.colors,
            primary: colors.primary[500],
        },
    }

    return (
        <NavigationContainer linking={unauthenticatedLinking} theme={myTheme}>
            <Stack.Navigator screenOptions={{headerShown: false}}>
                <Stack.Screen name="Login" component={Login}/>
                <Stack.Screen name="Forgot password" component={ForgotPassword}/>
                <Stack.Screen name="Register" component={Register}/>
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default AuthStack;

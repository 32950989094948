import {ScrollView} from "native-base";
import TermsOfBusiness from "../components/TermsOfBusiness";

const TermsOfBusinessPage = props => {
    return (
        <ScrollView>
            <TermsOfBusiness />
        </ScrollView>
    );
};

export default TermsOfBusinessPage;

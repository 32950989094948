import {StyleSheet, TouchableOpacity} from 'react-native';
import {Heading, HStack, Text, useColorModeValue, useTheme, VStack} from "native-base";
import {downloadDocument} from "../../services/documents";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";

const Documents = props => {
    const {submission} = props;
    const {colors} = useTheme();
    const cardBackground = useColorModeValue('light.50', 'dark.50');

    return (
        <VStack space={4} bg={cardBackground} p={4} rounded={'lg'}>
            <Heading fontSize={'md'}>Documents</Heading>
            <VStack space={1}>
                {submission.documents.length === 0 && (
                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>No documents</Text>
                )}

                {submission.documents.length > 0 && (
                    <VStack mt={3} flex={1} space={3}>
                        {submission.documents.map(document => (
                            <HStack alignItems={'center'} key={document.id} flex={1} px={4} py={2}
                                    bg={useColorModeValue('light.100', 'dark.100')}
                                    rounded={'xl'}
                                    justifyContent={'space-between'} borderColor={'primary.300'}
                                    borderWidth={2}>
                                <Text fontSize={'md'} flex={1}>{document.name}</Text>
                                <TouchableOpacity onPress={() => downloadDocument(document)}>
                                    <Ionicons name={'download-outline'} size={24} color={colors.primary[500]}/>
                                </TouchableOpacity>
                            </HStack>
                        ))}
                    </VStack>
                )}
            </VStack>
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default Documents;

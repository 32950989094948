import {StyleSheet} from 'react-native';
import {Badge, HStack, Text, View} from "native-base";
import * as React from "react";

const StatusBadge = props => {
    const {status} = props;

    const getBadgeColor = (status) => {
        switch (status) {
            case 'in_progress':
            case 'accepted':
            case 'complete':
                return 'success';
            case 'requested':
                return 'primary';
            case 'offered':
                return 'amber';
            case 'rejected':
            case 'declined':
            case 'closed':
            case 'urgent':
                return 'danger';
            default:
                return 'primary';
        }
    }

    return (
        <View {...props}>
            <Badge _text={{fontSize: 14, fontWeight: "bold"}} rounded={'md'} colorScheme={getBadgeColor(status)}>
                <HStack space={1} alignItems={'center'}>
                    <Text color={'gray.700'} bold>{status.toUpperCase().replaceAll('_', ' ')}</Text>
                </HStack>
            </Badge>
        </View>
    );
};

const styles = StyleSheet.create({});

export default StatusBadge;

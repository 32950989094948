import * as React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import SubmissionDetails from "./SubmissionDetails";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";

const Stack = createNativeStackNavigator();

function ClientHomeStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Home" component={SubmissionDetails}/>
        </Stack.Navigator>
    );
}

export default ClientHomeStack;

import {StyleSheet} from 'react-native';
import * as React from "react";
import {Button, Heading, Image, Input, Spinner, Text, useTheme, View, VStack} from "native-base";
import {useEffect, useState} from "react";
import {get, post} from "../../services/api";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";

const MagicLink = props => {
    const {setUserDetails, setToken} = useAuth();

    const {colors} = useTheme();

    const {name, email, token} = props.route.params;

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tokenExpired, setTokenExpired] = useState(false);

    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState(null);

    const [loggingIn, setLoggingIn] = useState(false);

    useEffect(() => {
        if (token) {
            get('/my-information', {}, {headers: {Authorization: `Bearer ${token}`}}).then(response => {
                setUser(response);
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    setTokenExpired(true);
                }
                setLoading(false);
            });
        }
    }, []);


    const resetClientPassword = () => {
        setLoggingIn(true);
        setError(null);

        post('/client/set-password', {
            password: password,
            password_confirmation: confirmPassword,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            setUserDetails(response.user);
            setToken(response.token);
        }).catch(error => {
            if (error.response.data.errors?.password) setError(error.response.data.errors.password[0]);
            else if (error.response.data.errors?.password_confirmation) setError(error.response.data.errors.password_confirmation[0]);
            else setError(error.response.data.error);
        }).finally(() => {
            setLoggingIn(false);
        });
    }

    if (loading) {
        return (
            <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <VStack space={4} alignItems={'center'}>
                    <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                           source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>

                    <Spinner size={'lg'}/>

                    <Text color={'gray.500'}>Fetching your data</Text>
                </VStack>
            </View>
        )
    }

    if (tokenExpired) {
        return (
            <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                <VStack space={4} alignItems={'center'}>
                    <Image height={100} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                           source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>

                    <Ionicons name={'alert-circle-outline'} size={64} color={colors.danger[500]}/>

                    <Text color={'gray.500'}>It looks like this Magic Link has expired.</Text>
                    <Text color={'gray.500'}>Please ask your agent to resend the link.</Text>
                </VStack>
            </View>
        )
    }

    return (
        <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <VStack space={4}>
                <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                       source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                <Heading textAlign={'center'} color={'black'}>Welcome, {name}</Heading>

                <Text textAlign={'center'} color={'gray.500'}>You have been invited to join PropServ. Please create a password to continue.</Text>

                <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'}
                       onChangeText={text => setPassword(text)} placeholder="Password"/>

                <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'}
                       onChangeText={text => setConfirmPassword(text)} placeholder="Confirm Password"/>

                {error && <Text color={'error.500'}>{error}</Text>}

                <Button disabled={loggingIn} onPress={resetClientPassword}>
                    {loggingIn ? <Spinner size={'sm'} color={'white'}/> : 'Continue'}
                </Button>
            </VStack>
        </View>
    );
};

const styles = StyleSheet.create({});

export default MagicLink;

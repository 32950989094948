import {StyleSheet} from 'react-native';
import {Image, Heading, HStack, Text, useColorModeValue, useTheme, VStack} from "native-base";
import * as React from "react";
import Lightbox from "react-native-lightbox-v2";

const Images = props => {
    const {submission} = props;
    const {colors} = useTheme();
    const cardBackground = useColorModeValue('light.50', 'dark.50');

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <VStack space={4} bg={cardBackground} p={4} rounded={'lg'}>
            <Heading fontSize={'md'}>Images</Heading>
            <VStack space={1}>
                {submission.images.length === 0 && (
                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>No images</Text>
                )}

                {submission.images.length > 0 && (
                    <HStack mt={3} flex={1} space={3}>
                        {submission.images.map(image => (
                            <Lightbox onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} activeProps={{flex: 1}} key={image.id}>
                                <Image resizeMode={'contain'} source={{uri: image.url}} height={isOpen ? '100%' : 75} width={isOpen ? '100%' : 75}/>
                            </Lightbox>
                        ))}
                    </HStack>
                )}
            </VStack>
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default Images;

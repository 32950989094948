import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Box,
    Button,
    Center,
    Circle,
    FormControl,
    Heading,
    HStack,
    Modal,
    ScrollView,
    Spacer,
    Spinner,
    Text,
    TextArea,
    useColorModeValue,
    useTheme,
    View,
    VStack
} from "native-base";
import StatusBadge from "../../components/StatusBadge";
import {get, post} from "../../services/api";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useCallback, useEffect, useState} from "react";
import * as React from "react";
import {useAuth} from "../../providers/AuthProvider";
import PropertyDetails from "../../components/submissionDetails/PropertyDetails";
import Location from "../../components/submissionDetails/Location";
import Description from "../../components/submissionDetails/Description";
import Documents from "../../components/submissionDetails/Documents";
import ContractorCategories from "../../components/submissionDetails/ContractorCategories";
import {useFocusEffect} from "@react-navigation/native";
import Images from "../../components/submissionDetails/Images";
import ResponseDetails from "../../components/contractor/ResponseDetails";

const SubmissionDetails = props => {
    const [submissionId, setSubmissionId] = useState(props.route.params.submissionId);
    const [submission, setSubmission] = useState();
    const [loading, setLoading] = useState(true);

    const {userDetails} = useAuth();
    const {colors} = useTheme();
    const navigation = props.navigation;

    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const [declineReason, setDeclineReason] = useState(null);
    const [declining, setDeclining] = useState(false);
    const [declined, setDeclined] = useState(false);

    const grayText = useColorModeValue('gray.500', 'gray.400');
    const backgroundColor = useColorModeValue('light.50', 'dark.50');

    const fetchSubmission = async () => {
        const response = await get(`/contractor/submissions/${submissionId}`);
        setSubmission(response);
        setLoading(false);
    }

    const decline = async () => {
        setDeclining(true);

        post(`/contractor/submissions/${submission.id}/response`, {
            type: 'decline',
            message: declineReason
        }).then(response => {
            setDeclined(true);
        }).catch(error => {
            // console.log(error);
        }).finally(() => {
            setDeclining(false);
        });
    }

    const closeDeclineModal = () => {
        setShowDeclineModal(false);
        setDeclined(false);
        setDeclining(false);
        setDeclineReason(null);
        fetchSubmission();
    }

    const chatWithEstateAgent = async () => {
        const response = await post(`/chats`, {recipient_id: submission.user.id});

        navigation.navigate('Messages', {
            screen: 'Chat',
            params: {
                chatId: response.id
            },
            initial: false,
        });
    }

    const chatWithClient = async () => {
        const response = await post(`/chats`, {recipient_id: submission.client.id});

        navigation.navigate('Messages', {
            screen: 'Chat',
            params: {
                chatId: response.id
            },
            initial: false,
        });
    }

    useEffect(() => {
        fetchSubmission();
    }, [submissionId]);

    useFocusEffect(
        useCallback(() => {
            setSubmissionId(props.route.params.submissionId);
            fetchSubmission();
        }, [props.route])
    );

    if (loading) {
        return (
            <View flex={1}>
                <Center flex={1}>
                    <Spinner size={'lg'} color={colors.primary[500]}/>
                    <Text mt={2} fontSize={'md'} color={grayText}>
                        Loading submission
                    </Text>
                </Center>
            </View>
        );
    }

    if (userDetails.company.has_active_subscription === false) {
        return (
            <View p={4} rounded={'xl'} flex={1}>
                <Center flex={1}>
                    <Ionicons name={'alert-circle-outline'} size={48} color={colors.dark[400]}/>
                    <Heading>No active subscription</Heading>
                    <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')} textAlign={'center'}>
                        You do not have an active subscription. Please subscribe to view client submissions.
                    </Text>
                    <Button mt={4} colorScheme={'primary'} onPress={() => navigation.navigate('Profile')}>
                        Subscribe now
                    </Button>
                </Center>
            </View>
        );
    }

    return (
        <ScrollView p={4} showsVerticalScrollIndicator={false}>
            <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                    <Heading>{submission.address.line_1}</Heading>
                    <VStack space={2}>
                        {submission.urgent === 1 && (
                            <StatusBadge status="urgent"/>
                        )}
                        <StatusBadge status={submission.status}/>
                    </VStack>
                </HStack>

                {submission.status === 'closed' && (
                    <VStack borderColor={'danger.200'} borderWidth={2} bg={backgroundColor}
                            p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Ionicons name={'lock-closed-outline'} size={32} color={colors.danger[500]}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>This submission is closed</Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    This submission has been closed and is now read-only, most functionality is
                                    restricted. You can still view the details of the submission and download any
                                    documents.
                                </Text>
                                <Box p={2} bg={useColorModeValue('light.200', 'dark.200')} rounded={'lg'}
                                     alignSelf={'flex-start'}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'xs'}>Reason</Text>

                                    <Text>
                                        {submission.close_reason}
                                    </Text>
                                </Box>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {(submission.status === 'paid' || submission.status === 'paid_by_estate_agent') && (
                    <VStack bg={backgroundColor} p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Spinner size={'lg'}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>Waiting for the estate agent or client to mark as
                                    complete</Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    The {submission.status === 'paid' ? 'client' : 'estate agent'} has paid for your
                                    services. Please get in touch with the estate agent or client to arrange the next
                                    steps. You will receive the payment from PropServ once the estate agent or client
                                    has confirmed that the work has been completed.
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {(submission.status === 'deposit_paid' || submission.status === 'deposit_paid_by_estate_agent') && (
                    <VStack bg={backgroundColor} p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Spinner size={'lg'}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>
                                    Waiting for the estate agent or client to mark as complete
                                </Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    The {submission.status === 'deposit_paid' ? 'client' : 'estate agent'} has paid the
                                    deposit for your services. Please get in touch with the estate agent or client to
                                    arrange the next steps.
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {submission.status === 'accepted' && userDetails.company.commission_only !== 1 && (
                    <VStack bg={backgroundColor} p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Spinner size={'lg'}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>Waiting for payment from the client</Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    Your offer has been accepted. We are awaiting payment from the client.
                                    Once the client has paid, you will be notified and the work can begin.
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {submission.status === 'accepted' && userDetails.company.commission_only === 1 && (
                    <VStack bg={backgroundColor} p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Spinner size={'lg'}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>The client has accepted your offer</Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    Your offer has been accepted. Please get in touch to discuss the next steps.
                                    Make sure to update the commission amount as soon as it is known.
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {submission.status === 'complete' && (
                    <VStack bg={backgroundColor} p={4} rounded={'lg'} borderColor={'green.500'}
                            borderWidth={2}>
                        <HStack space={4} alignItems={'center'}>
                            <Ionicons name={'checkmark-circle-outline'} size={48} color={colors.green[500]}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>Job complete!</Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    Your job has been marked as complete! You will receive the payment from
                                    PropServ shortly.
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {submission.status === 'declined' && (
                    <VStack
                        space={4}
                        bg={backgroundColor}
                        p={4}
                        rounded={'lg'}
                        borderColor={'danger.500'}
                        borderWidth={2}
                    >
                        <Heading size={'md'}>
                            {submission.response && 'You declined this submission'}
                            {!submission.response && submission.company_response && `${submission.company_response.user.name} declined this submission`}
                        </Heading>

                        <VStack space={1}>
                            <Text fontSize={'md'} color={grayText}>
                                Decline reason
                            </Text>

                            {submission.response && (
                                <Text fontSize={'md'}>
                                    {submission.response.message == null ? 'No reason provided' : submission.response.message}
                                </Text>
                            )}

                            {!submission.response && submission.company_response && (
                                <Text fontSize={'md'}>
                                    {submission.company_response.message == null ? 'No reason provided' : submission.company_response.message}
                                </Text>
                            )}
                        </VStack>
                    </VStack>
                )}

                {['offered', 'accepted'].includes(submission.status) && (submission.response?.terms?.length === 0 || submission.company_response?.terms?.length === 0) && (
                    <VStack bg={backgroundColor} borderWidth={2} borderColor={'amber.300'} p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Ionicons name={'alert-circle-outline'} size={32} color={colors.amber[300]}/>
                            <VStack flex={1} space={2}>
                                <Heading size={'md'}>No terms provided</Heading>
                                <Text color={grayText} fontSize={'md'}>
                                    The client will be unable to proceed with payment for your services until terms for
                                    the job are provided. You can attach the terms to your offer, please edit your
                                    current response and upload your terms.
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {(['offered', 'accepted', 'paid', 'paid_by_estate_agent', 'deposit_paid', 'deposit_paid_by_estate_agent', 'complete'].includes(submission.status)
                    || (submission.status === 'closed' && submission.response !== null)) && (
                    <ResponseDetails
                        submission={submission}
                        response={submission.response ?? submission.company_response}
                        navigation={navigation}
                    />
                )}

                {submission.client != null && !submission.client?.force_password_reset && (
                    <VStack p={4} rounded={'lg'} bg={backgroundColor} space={4}>
                        <Heading fontSize={'md'}>Client</Heading>

                        <HStack space={2} alignItems={'center'}>
                            <Ionicons name={'person-circle-outline'} size={32} color={'gray'}/>

                            <VStack>
                                <Text mb={1} fontSize={'md'}>{submission.client.name}</Text>
                                <HStack space={2} alignItems={'center'}>
                                    <Ionicons name={'mail-outline'} size={16} color={'gray'}/>
                                    <Text color={grayText}
                                          fontSize={'sm'}>{submission.client.email}</Text>
                                </HStack>
                                {submission.client.phone_number != null && (
                                    <HStack space={2} alignItems={'center'}>
                                        <Ionicons name={'call-outline'} size={16} color={'gray'}/>
                                        <Text color={grayText}
                                              fontSize={'sm'}>{submission.client.phone_number}</Text>
                                    </HStack>
                                )}
                            </VStack>

                            <Spacer/>

                            {userDetails.company?.stripe_onboarding_complete === 1 && (
                                <TouchableOpacity onPress={chatWithClient}>
                                    <Circle size={8} bg={'primary.500'} justifyContent={'center'} alignItems={'center'}>
                                        <Ionicons name={'chatbubble-outline'} size={18} color={'white'}/>
                                    </Circle>
                                </TouchableOpacity>
                            )}
                        </HStack>

                        <Text fontSize={'md'} color={grayText}>
                            Chat with the client to get more information about the job, arrange the work and discuss
                            payment.
                        </Text>
                    </VStack>
                )}

                {submission.handled_by_agent === 1 && (
                    <VStack p={4} rounded={'lg'} bg={backgroundColor} space={4}>
                        <Heading fontSize={'md'}>Estate agent</Heading>

                        <HStack space={2} alignItems={'center'}>
                            <Ionicons name={'person-circle-outline'} size={32} color={'gray'}/>

                            <VStack>
                                <Text mb={1} fontSize={'md'}>{submission.user.name}</Text>
                                <HStack space={2} alignItems={'center'}>
                                    <Ionicons name={'mail-outline'} size={16} color={'gray'}/>
                                    <Text color={grayText}
                                          fontSize={'sm'}>{submission.user.email}</Text>
                                </HStack>
                                {submission.user.phone_number != null && (
                                    <HStack space={2} alignItems={'center'}>
                                        <Ionicons name={'call-outline'} size={16} color={'gray'}/>
                                        <Text color={grayText}
                                              fontSize={'sm'}>{submission.user.phone_number}</Text>
                                    </HStack>
                                )}
                            </VStack>

                            <Spacer/>

                            {userDetails.company?.stripe_onboarding_complete === 1 && (
                                <TouchableOpacity onPress={chatWithEstateAgent}>
                                    <Circle size={8} bg={'primary.500'} justifyContent={'center'} alignItems={'center'}>
                                        <Ionicons name={'chatbubble-outline'} size={18} color={'white'}/>
                                    </Circle>
                                </TouchableOpacity>
                            )}
                        </HStack>

                        {submission.client === null && (
                            <Text fontSize={'md'} color={grayText}>
                                Chat with the estate agent to get more information about the property and the job.
                            </Text>
                        )}
                    </VStack>
                )}

                <PropertyDetails submission={submission}/>

                <Location submission={submission}/>

                <Description submission={submission}/>

                <ContractorCategories submission={submission}/>

                <Documents submission={submission}/>

                <Images submission={submission}/>

                {submission.status === 'requested' && !userDetails.stripe_onboarding_complete && !userDetails.company?.stripe_onboarding_complete && (
                    <VStack space={4}>
                        <Text fontSize={'md'} color={'danger.500'}>
                            You need to complete your Stripe onboarding before you can respond to this submission.
                        </Text>
                        <Button colorScheme={'danger'} onPress={() => navigation.navigate('Profile')}>
                            Complete Stripe onboarding
                        </Button>
                    </VStack>
                )}

                {submission.status === 'requested' && (
                    <Button
                        isDisabled={!userDetails.stripe_onboarding_complete && !userDetails.company?.stripe_onboarding_complete}
                        onPress={() => navigation.navigate('Submission Response', {submissionId: submission.uuid})}>Respond</Button>
                )}

                {submission.status === 'requested' && (
                    <Button
                        isDisabled={!userDetails.stripe_onboarding_complete && !userDetails.company?.stripe_onboarding_complete}
                        onPress={() => setShowDeclineModal(true)}>Decline</Button>
                )}
            </VStack>

            <Modal isKeyboardDismissable={false} isOpen={showDeclineModal} onClose={closeDeclineModal}>
                {declined && (
                    <Modal.Content maxWidth="400px">
                        <Modal.CloseButton/>
                        <Modal.Header>Declined</Modal.Header>
                        <Modal.Body>
                            <View flex={1} justifyContent={'center'} alignItems={'center'}>
                                <Text fontSize={'md'}>Submission declined.</Text>
                            </View>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <Button variant="ghost" colorScheme="gray" onPress={() => {
                                    closeDeclineModal();
                                }}>
                                    Close
                                </Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                )}

                {declining && (
                    <Modal.Content maxWidth="400px">
                        <Modal.Header>Respond</Modal.Header>
                        <Modal.Body>
                            <View flex={1} justifyContent={'center'} alignItems={'center'}>
                                <Text fontSize={'md'}>Declining submission...</Text>
                                <Spinner size={'lg'} mt={4}/>
                            </View>
                        </Modal.Body>
                    </Modal.Content>
                )}

                {!declining && !declined && (
                    <Modal.Content maxWidth="400px">
                        <Modal.CloseButton/>
                        <Modal.Header>Decline</Modal.Header>
                        <Modal.Body>
                            <FormControl>
                                <FormControl.Label>Decline reason</FormControl.Label>
                                <TextArea
                                    value={declineReason}
                                    fontSize={'md'}
                                    size={'xl'}
                                    onChange={e => setDeclineReason(e.currentTarget.value)}
                                    onChangeText={text => setDeclineReason(text)}
                                    placeholder={'Message'}
                                />
                                <FormControl.HelperText>Provide a reason for declining this
                                    submission</FormControl.HelperText>
                            </FormControl>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button.Group space={2}>
                                <Button variant="ghost" colorScheme="gray" onPress={() => {
                                    closeDeclineModal();
                                }}>
                                    Cancel
                                </Button>
                                <Button onPress={decline}>Decline</Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                )}
            </Modal>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default SubmissionDetails;

import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Box, Button,
    Heading,
    HStack, Input,
    Menu, Modal,
    ScrollView,
    Spacer,
    Text,
    useColorModeValue,
    useTheme,
    VStack
} from "native-base";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";
import * as React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {get, post} from "../../services/api";
import {useEffect, useState} from "react";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Picker} from "react-native-web";
import StyledPicker from "../../components/StyledPicker";

const Stack = createNativeStackNavigator();

function ManageUsersStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Manage users" component={ContractorManageUsers}/>
        </Stack.Navigator>
    );
}

const ContractorManageUsers = props => {
    const {userDetails} = useAuth();
    const {colors} = useTheme();

    const [companyUsers, setCompanyUsers] = useState([]);
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [showRolesInformationModal, setShowRolesInformationModal] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null)
    const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const [invitingUser, setInvitingUser] = useState(false);
    const [inviteUserError, setInviteUserError] = useState(null);

    const [newUserRole, setNewUserRole] = useState('admin');
    const [newUserName, setNewUserName] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');

    const roles = [
        {
            name: "Admin",
            permissions: [
                {can: true, permission: "All of User permissions"},
                {can: true, permission: "Can view the submissions of all users in the company"},
                {can: true, permission: "Can manage users in the company"},
                {can: true, permission: "Receive notifications for all submissions"},
            ]
        },
        {
            name: "User",
            permissions: [
                {can: true, permission: "Can view and respond to their own submissions only"},
                {can: true, permission: "Can message clients and agents"},
                {can: true, permission: "Receive notifications for their own submissions"},
            ]
        },
        {
            name: "Reader",
            permissions: [
                {can: true, permission: "Can view all submissions in the company"},
                {can: true, permission: "Receive notifications for all submissions"},
                {can: false, permission: "Can't respond to submissions"},
                {can: false, permission: "Can't message clients and agents"},
            ]
        },
    ];

    const gray = useColorModeValue(colors.gray[500], colors.gray[400]);
    const bg = useColorModeValue('light.50', 'dark.50');
    const cardBg = useColorModeValue('light.100', 'dark.100');

    const approveUser = (userId) => {
        post(`/contractor/manage-users/company/users/${userId}/approve`).then(() => {
            setCompanyUsers(companyUsers.map(user => user.id === userId ? {...user, approved_by_company: 1} : user));
        });
    }

    const revokeUser = (userId) => {
        post(`/contractor/manage-users/company/users/${userId}/revoke`).then(() => {
            setCompanyUsers(companyUsers.map(user => user.id === userId ? {
                ...user,
                approved_by_company: 0
            } : user));
        });
    }

    const inviteUser = () => {
        setInviteUserError(null);
        setInvitingUser(true);

        post('/contractor/manage-users/company/invite', {
            name: newUserName,
            email: newUserEmail,
            role: newUserRole
        }).then(response => {
            setNewUserEmail('');
            setNewUserName('');
            setNewUserRole('admin');
            setShowInviteUserModal(false);
            getUsers();
        }).catch(error => {
            setInviteUserError(error.response?.data?.error || 'An error occurred');
        }).finally(() => {
            setInvitingUser(false);
        });
    }

    const getUsers = async () => {
        const response = await get('/contractor/manage-users/company/users');
        setCompanyUsers(response.users);
        setInvitedUsers(response.invited);
    }

    useEffect(() => {
        AsyncStorage.getItem('showRolesInformationModal').then(value => {
            if (value === null) {
                setShowRolesInformationModal(true);
                AsyncStorage.setItem('showRolesInformationModal', 'false');
            }
        });

        getUsers();
    }, []);

    return (
        <ScrollView p={4} showsVerticalScrollIndicator={false}>
            <VStack space={4} w={'100%'}>
                <HStack justifyContent={'flex-end'} alignItems={'center'} space={4}>
                    <Button onPress={() => setShowInviteUserModal(true)}>Invite user</Button>
                    <TouchableOpacity onPress={() => setShowRolesInformationModal(true)}>
                        <Ionicons name={'help-circle-outline'} size={24} color={colors.gray[500]}/>
                    </TouchableOpacity>
                </HStack>

                <VStack space={4} w={'100%'} p={4} bg={bg} rounded={'xl'}>
                    <Heading fontSize={'lg'}>{userDetails.company.name} users</Heading>

                    <VStack space={2}>
                        {companyUsers.map(user => (
                            <HStack key={user.id} alignItems={'center'} justifyContent={'space-between'} bg={cardBg}
                                    p={4} rounded={'xl'}>
                                <VStack>
                                    <HStack space={2} flexWrap={'wrap'} style={{rowGap: 8}}>
                                        {user.roles.map(role => (
                                            <Box bg={'primary.100'} borderColor={'primary.500'} borderWidth={1} px={2}
                                                 rounded={'md'} key={role.id}>
                                                <Text bold color={'primary.500'}>{role.name.toUpperCase()}</Text>
                                            </Box>
                                        ))}
                                        {user.approved_by_company ? (
                                            <HStack space={2} bg={'green.100'} borderColor={'green.500'} borderWidth={1}
                                                    px={2}
                                                    rounded={'md'} alignItems={'center'}>
                                                <Ionicons name={'checkmark'} size={18} color={colors.green[500]}/>
                                                <Text bold color={'green.500'}>Approved</Text>
                                            </HStack>
                                        ) : (
                                            <HStack space={2} bg={'warning.100'} borderColor={'warning.500'}
                                                    borderWidth={1}
                                                    px={2} rounded={'md'} alignItems={'center'}>
                                                <Ionicons name={'timer-outline'} size={18} color={colors.warning[500]}/>
                                                <Text bold color={'warning.500'}>Waiting for approval</Text>
                                            </HStack>
                                        )}
                                    </HStack>
                                    <Text mt={2} fontSize={'lg'} bold>{user.name}</Text>
                                    <Text color={gray}>{user.email}</Text>
                                </VStack>

                                <Menu w="190" placement={'bottom right'} trigger={triggerProps => {
                                    return <TouchableOpacity {...triggerProps}>
                                        <Ionicons name={'ellipsis-vertical'} size={24} color={colors.dark[400]}/>
                                    </TouchableOpacity>;
                                }}>
                                    {user.approved_by_company === 1 && (
                                        <Menu.Item bgColor={useColorModeValue('light.50', 'dark.50')}
                                                   onPress={() => {
                                                       setSelectedUser(user)
                                                       setSelectedRole(user.roles[0]?.name)
                                                       setShowChangeRoleModal(true)
                                                   }}>
                                            Change role
                                        </Menu.Item>
                                    )}

                                    {user.approved_by_company === 1 && (
                                        <Menu.Item bgColor={useColorModeValue('light.50', 'dark.50')}
                                                   onPress={() => revokeUser(user.id)}>
                                            Revoke
                                        </Menu.Item>
                                    )}

                                    {user.approved_by_company === 0 && (
                                        <Menu.Item bgColor={useColorModeValue('light.50', 'dark.50')}
                                                   onPress={() => approveUser(user.id)}>
                                            Approve
                                        </Menu.Item>
                                    )}
                                </Menu>
                            </HStack>
                        ))}
                    </VStack>
                </VStack>

                <VStack space={4} w={'100%'} p={4} bg={bg} rounded={'xl'}>
                    <Heading fontSize={'lg'}>Invited users</Heading>

                    <VStack space={2}>
                        {invitedUsers.map(user => (
                            <HStack key={user.id} alignItems={'center'} justifyContent={'space-between'} bg={cardBg}
                                    p={4} rounded={'xl'}>
                                <VStack>
                                    <HStack space={2} flexWrap={'wrap'} style={{rowGap: 8}}>
                                        <Box bg={'primary.100'} borderColor={'primary.500'} borderWidth={1} px={2}
                                             rounded={'md'}>
                                            <Text bold color={'primary.500'}>{user.role.name.toUpperCase()}</Text>
                                        </Box>
                                        <HStack
                                            alignSelf={'flex-start'}
                                            space={2} bg={'warning.100'}
                                            borderColor={'warning.500'}
                                            borderWidth={1}
                                            px={2}
                                            rounded={'md'}
                                            alignItems={'center'}
                                        >
                                            <Ionicons name={'timer-outline'} size={18} color={colors.warning[500]}/>
                                            <Text bold color={'warning.500'}>Pending</Text>
                                        </HStack>
                                    </HStack>
                                    <Text mt={2} fontSize={'lg'} bold>{user.name}</Text>
                                    <Text color={gray}>{user.email}</Text>
                                </VStack>
                            </HStack>
                        ))}
                    </VStack>
                </VStack>
            </VStack>

            <Modal isOpen={showChangeRoleModal} onClose={() => setShowChangeRoleModal(false)}>
                <Modal.Content>
                    <Modal.Header>Change role</Modal.Header>
                    <Modal.Body>
                        <VStack space={4}>
                            <Text>Select a role for {selectedUser?.name}</Text>
                            <HStack space={4}>
                                {roles.map(role => (
                                    <TouchableOpacity key={role.name} onPress={() => {
                                        setSelectedRole(role.name);
                                    }}>
                                        <Box bg={selectedRole === role.name ? 'green.100' : 'primary.100'}
                                             borderColor={selectedRole === role.name ? 'green.500' : 'primary.500'}
                                             borderWidth={1} px={2}
                                             rounded={'md'}>
                                            <Text bold
                                                  color={selectedRole === role.name ? 'green.500' : 'primary.500'}>{role.name.toUpperCase()}</Text>
                                        </Box>
                                    </TouchableOpacity>
                                ))}
                            </HStack>
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Spacer/>
                        <Button onPress={() => {
                            post(`/contractor/manage-users/company/users/${selectedUser.id}/role`, {
                                role_id: role.id
                            }).then(() => {
                                setCompanyUsers(companyUsers.map(user => user.id === selectedUser.id ? {
                                    ...user,
                                    roles: [role]
                                } : user));
                                setShowChangeRoleModal(false);
                            });
                        }}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>

            <Modal isOpen={showInviteUserModal} onClose={() => setShowInviteUserModal(false)}>
                <Modal.Content>
                    <Modal.Header>Invite user</Modal.Header>
                    <Modal.Body>
                        <VStack space={4}>
                            <VStack>
                                <Text mb={1} fontSize={'xs'} color={useColorModeValue('gray.500', 'gray.400')}>
                                    Role
                                </Text>

                                <StyledPicker
                                    selectedValue={newUserRole}
                                    onValueChange={setNewUserRole}
                                    placeholder="Select a role"
                                >
                                    <Picker.Item label="Admin" value="admin"/>
                                    <Picker.Item label="User" value="user"/>
                                    <Picker.Item label="Reader" value="reader"/>
                                </StyledPicker>
                            </VStack>

                            <VStack>
                                <Text mb={1} fontSize={'xs'} color={useColorModeValue('gray.500', 'gray.400')}>
                                    Name
                                </Text>

                                <Input
                                    autoComplete={'name'}
                                    fontSize={'md'}
                                    size={'xl'}
                                    value={newUserName}
                                    onChangeText={setNewUserName}
                                    placeholder="Name"
                                />
                            </VStack>

                            <VStack>
                                <Text mb={1} fontSize={'xs'} color={useColorModeValue('gray.500', 'gray.400')}>
                                    Email
                                </Text>

                                <Input
                                    autoComplete={'email'}
                                    keyboardType={'email-address'}
                                    fontSize={'md'}
                                    size={'xl'}
                                    value={newUserEmail}
                                    onChangeText={setNewUserEmail}
                                    placeholder="Email"
                                />
                            </VStack>

                            {inviteUserError && (
                                <Text color={colors.danger[500]}>{inviteUserError}</Text>
                            )}
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Spacer/>
                        <Button
                            isLoading={invitingUser}
                            px={8}
                            onPress={inviteUser}
                            isDisabled={newUserName === '' || newUserEmail === ''}
                        >
                            Invite
                        </Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>

            <Modal isOpen={showRolesInformationModal} onClose={() => setShowRolesInformationModal(false)}>
                <Modal.Content maxW={{base: '90vw', md: '80vw'}}>
                    <Modal.Header>Roles</Modal.Header>
                    <Modal.CloseButton/>
                    <Modal.Body>
                        <VStack flex={1} space={4} w={'100%'} p={4} bg={bg} rounded={'xl'}>
                            {roles.map(role => (
                                <VStack space={2} key={role.name} bg={useColorModeValue('primary.50', 'primary.900')}
                                        p={4} rounded={'xl'} borderColor={'primary.500'} borderWidth={2}>
                                    <Text bold fontSize={'lg'}>{role.name}</Text>

                                    <VStack space={1}>
                                        {role.permissions.map(permission => (
                                            <HStack key={permission.permission} space={2} alignItems={'center'}>
                                                <Ionicons name={permission.can ? 'checkmark' : 'close'} size={18}
                                                          color={permission.can ? colors.green[500] : colors.danger[500]}/>
                                                <Text>{permission.permission}</Text>
                                            </HStack>
                                        ))}
                                    </VStack>
                                </VStack>
                            ))}
                        </VStack>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default ManageUsersStack;

import {StyleSheet} from 'react-native';
import {Button, Center, Heading, Text, useColorModeValue, useTheme, View} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";
import {useNavigation} from "@react-navigation/native";

const Subscribed = props => {
    const {colors} = useTheme();
    const navigation = useNavigation();

    return (
        <View p={4} rounded={'xl'} flex={1}>
            <Center flex={1}>
                <Ionicons name={'checkmark-circle-outline'} size={48} color={colors.primary[500]}/>
                <Heading>Thanks for joining PropServ!</Heading>
                <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')} textAlign={'center'}>
                    You are now subscribed to PropServ. Head to the Home tab to get started.
                </Text>
                <Button mt={4} px={8} colorScheme={'primary'} onPress={() => navigation.navigate('Home')}>
                    Home
                </Button>
            </Center>
        </View>
    );
};

const styles = StyleSheet.create({});

export default Subscribed;

import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Button, HStack, useTheme, VStack, Image, ScrollView} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as ImagePicker from "expo-image-picker";

const UploadImageSection = props => {
    const {images, setImages} = props;
    const {colors} = useTheme();

    const uploadImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images, quality: 1, allowsMultipleSelection: true
        });

        if (!result.canceled) {
            setImages(prevState => [...prevState, ...result.assets]);
        }
    }

    return (
        <VStack space={2} {...props}>
            {images.length > 0 && (
                <ScrollView py={3} horizontal>
                    <HStack space={2} pr={3}>
                        {images.map((image, index) => (
                            <View>
                                <Image resizeMode={'contain'} source={{uri: image.uri ?? image.url}} size={'sm'}/>

                                <TouchableOpacity
                                    style={{position: 'absolute', top: -12, right: -8}}
                                    onPress={() => setImages(prevState => prevState.filter((_, i) => i !== index))}>
                                    <Ionicons name={'close-circle'} size={24} color={colors.gray[300]}/>
                                </TouchableOpacity>
                            </View>
                        ))}
                    </HStack>
                </ScrollView>
            )}

            <Button variant={'outline'} onPress={uploadImage}>Upload image</Button>
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default UploadImageSection;

import * as React from 'react';
import {
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    Pressable, ScrollView,
    Spacer,
    Text,
    useTheme,
    View,
    VStack,
} from 'native-base';
import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from "expo-linear-gradient";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useEffect, useState} from "react";
import {get} from "../../services/api";
import {Dimensions} from "react-native";
import {createElement} from "react-native-web";
import {BlurView} from "expo-blur";
import {PrimaryPressable, Section} from "./WebLandingPage";

function EstateAgentLandingPage() {
    const {colors} = useTheme();
    const navigation = useNavigation();

    const [categories, setCategories] = useState([]);

    const WebVideo = (props) => {
        const attrs = {
            src: props.source,
            poster: props.poster,
            controls: "controls",
            style: {
                borderRadius: 8,
                backgroundColor: "black",
            }
        }
        return createElement("video", attrs)
    }

    const cards = [
        {
            title: 'Movers and Landlords',
            body: 'Utilise our user-friendly tracker to monitor your property\'s journey and connect seamlessly with relevant contractors through intelligent AI recommendations at every step.',
            icon: 'people',
        },
        {
            title: 'Contractors',
            body: 'Become the trusted contractor in your area, appearing in front of potential clients for their property needs.',
            icon: 'hammer',
        },
        {
            title: 'Estate Agents',
            body: 'Give your clients peace of mind with the ability to track their journey using PropServ\'s tracker, whilst enjoying the benefits of our affiliate program.',
            icon: 'home',
        },
        {
            title: 'Connected',
            body: 'PropServ connects estate agents, contractors, and movers/landlords seamlessly.',
            icon: 'git-network',
        },
    ];

    const tiers = [
        {
            name: 'Movers and Landlords',
            features: [
                'Track the status of your transaction in real-time - coming soon',
                'In-app chat with estate agent and contractors',
                'Seamless payment integration with Stripe',
                'Review and rate contractors',
            ],
            free: true
        },
        {
            name: 'Estate Agents',
            features: [
                'Smart property search',
                'AI powered contractor suggestions',
                'Review and respond to contractor quotes',
            ],
            free: true
        },
        {
            name: 'Contractors',
            features: [
                'Access to all property submissions',
                'In-app chat with the estate agent and client',
                'Quote for jobs and receive payouts via Stripe'
            ],
            price: '£29.99'
        },
    ];

    useEffect(() => {
        get('/company-categories').then(response => {
            setCategories(response);
        });
    }, []);

    return (
        <ScrollView>
            <Section mt={32}>
                <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                       source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                <Heading textAlign={'center'} fontSize={{base: '4xl', md: '7xl'}} color={'black'}>
                    Helping to make your move seamless
                </Heading>

                <VStack mb={16} mt={24} width={'100%'} height={500} maxW={'90vw'} overflowX={'hidden'} flex={1}
                        space={4}>
                    <Heading textAlign={'center'} color={'black'} fontSize={{base: '2xl', md: '3xl'}}>
                        What is PropServ?
                    </Heading>
                    <WebVideo
                        source={'https://propfees.blob.core.windows.net/videos/Property Video.mp4'}
                    />
                </VStack>
            </Section>

            <Section>
                <Flex direction={{base: 'column', md: 'row'}} wrap="wrap" justifyContent={'center'}>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8} rounded={'2xl'}
                            borderRightWidth={1} borderBottomWidth={1} borderColor={'gray.500'}>
                        <Ionicons name={cards[0].icon} size={64} color={colors.primary[500]}/>
                        <Heading textAlign={'center'} size="lg" color={'black'}>{cards[0].title}</Heading>
                        <Text textAlign={'center'} fontSize={'lg'} color={'gray.500'}>{cards[0].body}</Text>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8} rounded={'2xl'}
                            borderLeftWidth={1} borderBottomWidth={1} borderColor={'gray.500'}>
                        <Ionicons name={cards[1].icon} size={64} color={colors.primary[500]}/>
                        <Heading size="lg" color={'black'}>{cards[1].title}</Heading>
                        <Text textAlign={'center'} fontSize={'lg'} color={'gray.500'}>{cards[1].body}</Text>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8} rounded={'2xl'}
                            borderRightWidth={1} borderTopWidth={1} borderColor={'gray.500'}>
                        <Ionicons name={cards[2].icon} size={64} color={colors.primary[500]}/>
                        <Heading size="lg" color={'black'}>{cards[2].title}</Heading>
                        <Text textAlign={'center'} fontSize={'lg'} color={'gray.500'}>{cards[2].body}</Text>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8} rounded={'2xl'}
                            borderLeftWidth={1} borderTopWidth={1} borderColor={'gray.500'}>
                        <Ionicons name={cards[3].icon} size={64} color={colors.primary[500]}/>
                        <Heading size="lg" color={'black'}>{cards[3].title}</Heading>
                        <Text textAlign={'center'} fontSize={'lg'} color={'gray.500'}>{cards[3].body}</Text>
                    </VStack>
                    {/*))}*/}
                </Flex>

                <Text textAlign={'center'} fontSize={'xl'} color={'gray.500'}>
                    PropServ supports you at every step of your property transaction, providing education
                    throughout
                    the process and facilitating seamless introductions to trusted contractors.
                </Text>
            </Section>

            <BlurView intensity={100}>
                <Section maxW={10000}>
                    <VStack alignItems={'center'}>
                        <Heading color={'black'} fontSize={{base: '2xl', md: '3xl'}}>
                            Some of our partners
                        </Heading>

                        <Flex direction={{base: 'column', md: 'row'}} wrap="wrap" justifyContent={'center'}
                              alignItems={'center'}>
                            <Image m={4} height={'80px'} width={'130px'} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/Johns&Co.png')} alt={'Johns & Co'}/>
                            <Image m={4} height={'60px'} width={'112px'} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/RICS.png')} alt={'RICS'}/>
                            <Box m={4} p={2} bg={'white'} rounded={'md'}>
                                <Image height={'70px'} width={'142px'} resizeMode={'contain'}
                                       source={require('../../../assets/company-logos/PropertyProgressors.png')}
                                       alt={'Property Progressors'}/>
                            </Box>
                            <Image m={4} height={'80px'} width={'142px'} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/Monteo.png')} alt={'Monteo'}/>
                            <Image m={4} height={37} width={150} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/APLiving.webp')} alt={'AP Living'}/>
                            <Box m={4} p={4} bg={'black'} rounded={'md'}>
                                <Image height={37} width={120} resizeMode={'contain'}
                                       source={require('../../../assets/company-logos/HuttonsPartners.png')}
                                       alt={'Huttons & Partners'}/>
                            </Box>
                            <Image m={4} height={75} width={75} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/SwiftExchange.png')}
                                   alt={'Swift Exchange'}/>
                            <Image m={4} height={37} width={150} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/Butler&StagLogo.png')}
                                   alt={'Swift Exchange'}/>
                        </Flex>
                    </VStack>
                </Section>
            </BlurView>

            <BlurView intensity={50}>
                <Section>
                    <Heading textAlign={'center'} fontSize={{base: '4xl', md: '6xl'}} color={'black'}>
                        A contractor for every service required
                    </Heading>

                    <Flex direction={{base: 'row', md: 'row'}} wrap="wrap" justifyContent={'center'}>
                        {categories.map((category, index) => (
                            <LinearGradient
                                key={index}
                                colors={[colors.light[100], colors.white]}
                                style={{
                                    paddingHorizontal: 16,
                                    paddingVertical: 7,
                                    borderRadius: 100,
                                    borderColor: colors.dark[500],
                                    borderWidth: 1,
                                    margin: 4
                                }}
                                start={[1, 0]}
                                end={[0, 1]}
                            >
                                <Text bold fontSize={{base: 'sm', md: 'md'}} color={'black'}>{category.name}</Text>
                            </LinearGradient>
                        ))}
                    </Flex>
                </Section>
            </BlurView>

            <BlurView intensity={100}>
                <Section>
                    <Heading color={'black'} textAlign={'center'} fontSize={{base: '4xl', md: '6xl'}}>
                        Choose your plan
                    </Heading>

                    <Flex direction={{base: 'column', lg: 'row'}} wrap="wrap" width={'100%'}>
                        {tiers.map((tier, index) => (
                            <Box key={index} w={{base: '100%', lg: '30%'}} m={{base: 0, lg: 2}}
                                 my={{base: 2, lg: 0}}
                                 borderBottomWidth={1} borderRightWidth={1} borderColor={'gray.500'} rounded={'2xl'}
                                 p={8}>

                                <VStack flex={1} space={4}>
                                    <Heading color={'black'} fontSize={'2xl'} mb={4}>{tier.name}</Heading>

                                    {tier.features.map((feature, index) => (
                                        <React.Fragment key={index}>
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons name={'checkmark-circle'} size={24}
                                                          color={colors.primary[500]}/>
                                                <Text fontSize={'lg'} color={'gray.700'}>{feature}</Text>
                                            </HStack>
                                        </React.Fragment>
                                    ))}

                                    <Spacer/>

                                    {tier.free ?
                                        <Heading my={4} fontSize={'2xl'} color={'primary.600'}>Free</Heading>
                                        :
                                        <HStack my={4} space={1} alignItems={'baseline'}>
                                            <Heading fontSize={'2xl'} color={'primary.600'}>{tier.price}</Heading>
                                            <Text fontSize={'md'} color={'gray.500'}>monthly</Text>
                                        </HStack>
                                    }

                                    <PrimaryPressable onPress={() => navigation.navigate('Register')}>
                                        <Text textAlign={'center'} bold fontSize={'lg'} color={'white'}>
                                            Get started
                                        </Text>
                                    </PrimaryPressable>
                                </VStack>
                            </Box>
                        ))}
                    </Flex>
                </Section>
            </BlurView>

            <BlurView intensity={50}>
                <Box position={'absolute'} top={0} left={0} right={0} bottom={0} bg={'purple.500:alpha.10'}/>
                <Section>
                    <VStack space={4} w="100%" padding={8} borderRightWidth={4} borderBottomWidth={4}
                            borderColor={'#9063cd'} rounded={'2xl'}>
                        <HStack space={4} alignItems={'center'} justifyContent={'center'}>
                            <Heading textAlign={'center'} size="xl" color={'black'}>
                                Secure payments with Stripe
                            </Heading>
                        </HStack>
                        <Text textAlign={'center'} fontSize={'xl'} color={'gray.600'}>
                            We use Stripe as our payment gateway to ensure your transactions are secure and
                            seamless. Stripe is a globally recognized payment processor trusted by millions of
                            businesses of all sizes. Stripe provides powerful fraud prevention and ensures your
                            payments are safe. With Stripe, you can make payments with confidence knowing that
                            your financial information is protected.
                        </Text>
                    </VStack>
                </Section>
            </BlurView>

            <VStack bg={'gray.900'} p={4} pb={6} alignItems={'center'}>
                <Image height={50} width={150} maxW={'80%'} resizeMode={'contain'}
                       source={require('../../../assets/PropServ.png')}
                       alt={'PropServ Logo'}/>
                <Text fontSize={'sm'} color={'gray.500'} textAlign={'center'}>
                    PropServ is a trading name of PropFees Ltd.
                </Text>
                <Text fontSize={'sm'} color={'gray.500'} textAlign={'center'}>
                    Copyright © 2023 PropFees Ltd.
                </Text>
                <Text fontSize={'sm'} color={'gray.500'} textAlign={'center'}>
                    All rights reserved.
                </Text>
            </VStack>
        </ScrollView>
    );
}

export default EstateAgentLandingPage;

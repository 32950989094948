import {SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {Button, HStack, ScrollView, Skeleton, Spinner, Text, useColorModeValue, useTheme, VStack} from 'native-base';
import * as React from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {get, post} from "../services/api";
import {useEffect, useState} from "react";
import CustomNavigationHeader from "../components/CustomNavigationHeader";
import {format} from "timeago.js";
import {useNotifications} from "../providers/NotificationsProvider";

const Stack = createNativeStackNavigator();

function NotificationsStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Notifications" component={Notifications}/>
        </Stack.Navigator>
    );
}

const Notifications = props => {
    const {colors} = useTheme();

    const {refreshCount} = useNotifications();

    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [markingAsRead, setMarkingAsRead] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = async () => {
        await get('/notifications').then(response => {
            setNotifications(response.data);
            setNumberOfPages(response.last_page);
            setCurrentPage(response.current_page);
            setLoading(false)
        })
    }

    const getMoreNotifications = async () => {
        if (currentPage < numberOfPages && !loadingMore) {
            setLoadingMore(true)
            await get(`/notifications?page=${currentPage + 1}`).then(response => {
                setNotifications([...notifications, ...response.data]);
                setNumberOfPages(response.last_page);
                setCurrentPage(response.current_page);
                setLoadingMore(false);
            })
        }
    }

    const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
    };

    const getNotificationMessage = (notification) => {
        switch (notification.type) {
            case 'estate-agent-new-response':
                return {
                    title: `You have a new response from ${notification.data.contractor}`,
                    subtitle: `Submission: ${notification.data.address.line_1}`,
                    icon: 'chatbox-outline'
                }
            case 'estate-agent-response-updated':
                return {
                    title: `${notification.data.contractor} have updated their response`,
                    subtitle: `Submission: ${notification.data.address.line_1}`,
                    icon: 'chatbox-outline'
                }
            case 'estate-agent-client-accepted-response':
                return {
                    title: `Your client ${notification.data.client} has accepted a response from ${notification.data.contractor}`,
                    subtitle: `Submission: ${notification.data.address.line_1}`,
                    icon: 'checkmark-circle-outline'
                }
            case 'estate-agent-client-paid-response':
                return {
                    title: `Your client ${notification.data.client} has paid for a response from ${notification.data.contractor}`,
                    subtitle: `Submission: ${notification.data.address.line_1}`,
                    icon: 'cash-outline'
                }
            case 'estate-agent-client-paid-response-deposit':
                return {
                    title: `Your client ${notification.data.client} has paid the deposit for a response from ${notification.data.contractor}`,
                    subtitle: `Submission: ${notification.data.address.line_1}`,
                    icon: 'cash-outline'
                }
            case 'estate-agent-client-marked-as-complete':
                return {
                    title: `Your client ${notification.data.client} has marked a job as complete`,
                    subtitle: `Submission: ${notification.data.address.line_1}`,
                    icon: 'checkmark-done-circle-outline'
                }
            case 'client-new-response':
                return {
                    title: `You have a new response from ${notification.data.contractor}`,
                    subtitle: null,
                    icon: 'chatbox-outline'
                }
            case 'client-response-updated':
                return {
                    title: `${notification.data.contractor} have updated their response`,
                    subtitle: null,
                    icon: 'chatbox-outline'
                }
            case 'contractor-new-submission':
                return {
                    title: `${notification.data.estate_agent} have made a new submission`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'chatbox-outline'
                }
            case 'contractor-submission-updated':
                return {
                    title: `${notification.data.estate_agent} have updated their submission`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'chatbox-outline'
                }
            case 'contractor-offer-accepted':
                return {
                    title: `Your offer has been accepted by the client`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'checkmark-circle-outline'
                }
            case 'contractor-client-paid':
                return {
                    title: `The client has paid for a job`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'cash-outline'
                }
            case 'contractor-client-paid-deposit':
                return {
                    title: `The client has paid the deposit for a job`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'cash-outline'
                }
            case 'contractor-estate-agent-paid':
                return {
                    title: `The estate agent has paid for a job`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'cash-outline'
                }
            case 'contractor-estate-agent-paid-deposit':
                return {
                    title: `The estate agent has paid the deposit for a job`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}`,
                    icon: 'cash-outline'
                }
            case 'contractor-job-complete':
                return {
                    title: `Your job has been marked as complete`,
                    subtitle: `Address: ${notification.data.address.line_1}, ${notification.data.address.city}, ${notification.data.address.postcode}. You will receive payment shortly.`,
                    icon: 'checkmark-done-circle-outline'
                }
            default:
                return {
                    title: notification.type, subtitle: notification.message
                }
        }
    }

    const navigateToNotification = (notification) => {
        post(`/notifications/${notification.id}/mark-as-read`).then(() => {
            refreshCount();
            getNotifications();
            switch (notification.type) {
                case 'estate-agent-new-response':
                case 'estate-agent-response-updated':
                case 'estate-agent-client-accepted-response':
                case 'estate-agent-client-paid-response':
                case 'estate-agent-client-paid-response-deposit':
                case 'estate-agent-client-marked-as-complete':
                case 'contractor-new-submission':
                case 'contractor-submission-updated':
                case 'contractor-offer-accepted':
                case 'contractor-estate-agent-paid':
                case 'contractor-estate-agent-paid-deposit':
                case 'contractor-client-paid':
                case 'contractor-client-paid-deposit':
                case 'contractor-job-complete':
                    props.navigation.navigate('Home', {
                        screen: 'Submission', params: {submissionId: notification.data.submission_uuid}
                    })
                    break;
                case 'client-response-updated':
                case 'client-new-response':
                    props.navigation.navigate('Home')
                    break;
                default:
                    props.navigation.navigate('Home', {
                        screen: 'Submission', params: {submissionId: notification.data.submission_uuid}
                    })
                    break;
            }
        });
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false} onScroll={({nativeEvent}) => {
                if (isCloseToBottom(nativeEvent)) {
                    getMoreNotifications();
                }
            }} scrollEventThrottle={400}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    {loading && (
                        <HStack
                            space={4}
                            alignItems={'center'}
                            p={4}
                            borderRadius={8}
                            bg={useColorModeValue('light.50', 'dark.50')}
                        >
                            <Skeleton height={'30px'} width={'30px'} borderRadius={8}/>
                            <VStack flex={1} space={2}>
                                <Skeleton height={'30px'} width={'80%'} borderRadius={8}/>
                                <Skeleton height={'20px'} width={'60%'} borderRadius={8}/>
                                <Skeleton height={'20px'} width={'20%'} borderRadius={8}/>
                            </VStack>
                            <Skeleton height={'30px'} width={'30px'} borderRadius={8}/>
                        </HStack>
                    )}

                    {!loading && notifications.length === 0 && (
                        <Text fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')} textAlign={'center'}
                              mt={4}>
                            You have no notifications.
                        </Text>
                    )}

                    {notifications.filter(notification => notification.read_at == null).length > 0 && (
                        <Button
                            minW={160}
                            alignSelf={'flex-end'}
                            variant={'ghost'}
                            leftIcon={markingAsRead ? null :
                                <Ionicons name={'checkmark-circle-outline'} size={24} color={colors.primary[500]}/>
                            }
                            onPress={() => {
                                setMarkingAsRead(true)
                                post('/notifications/mark-all-as-read').then(() => {
                                    setNotifications(notifications.map(notification => {
                                        notification.read_at = new Date();
                                        return notification;
                                    }))
                                    refreshCount();
                                    setMarkingAsRead(false)
                                })
                            }}
                            colorScheme={'primary'}>
                            {markingAsRead ? <Spinner/> : 'Mark all as read'}
                        </Button>
                    )}

                    {notifications.map(notification => (
                        <TouchableOpacity key={notification.id} onPress={() => navigateToNotification(notification)}>
                            <HStack space={4} alignItems={'center'} p={4} bg={useColorModeValue('light.50', 'dark.50')}
                                    borderRadius={8}
                                    borderLeftWidth={notification.read_at == null ? 4 : 0} borderColor={'primary.500'}>
                                <Ionicons name={getNotificationMessage(notification).icon} size={24}
                                          color={notification.read_at == null ? colors.primary[500] : useColorModeValue(colors.gray[500], colors.gray[400])}/>
                                <VStack flex={1}>
                                    <Text
                                        color={notification.read_at == null ? null : useColorModeValue('gray.500', 'gray.400')}
                                        fontSize={'lg'} bold>{getNotificationMessage(notification).title}</Text>
                                    <Text
                                        color={notification.read_at == null ? null : useColorModeValue('gray.500', 'gray.400')}
                                        fontSize={'sm'}>{getNotificationMessage(notification).subtitle}</Text>
                                    <Text mt={1} color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'xs'}>{format(notification.created_at)}</Text>
                                </VStack>
                                <Ionicons name={'chevron-forward'} size={24}
                                          color={notification.read_at == null ? colors.primary[500] : useColorModeValue(colors.gray[500], colors.gray[400])}/>
                            </HStack>
                        </TouchableOpacity>
                    ))}

                    {loadingMore && (<Spinner mt={4} size={'lg'}/>)}
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default NotificationsStack;

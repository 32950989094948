import {SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Avatar,
    Center,
    Circle,
    HStack,
    ScrollView,
    Spinner,
    Text,
    useColorModeValue,
    useTheme,
    View,
    VStack
} from "native-base";
import {useCallback, useEffect, useState} from "react";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import Ionicons from "@expo/vector-icons/Ionicons";
import {get} from "../../services/api";
import {useAuth} from "../../providers/AuthProvider";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as React from "react";
import {Chat} from "../Chat";
import {useChatNotificationsCount} from "../../providers/ChatNotificationsProvider";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";

const Stack = createNativeStackNavigator();

function MessagesStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Messages" component={Messages}/>
            <Stack.Screen name="Chat" component={Chat}/>
        </Stack.Navigator>
    );
}

const Messages = props => {
    const navigation = useNavigation();
    const {colors} = useTheme();
    const {userDetails} = useAuth();
    const {notificationsPerConversation} = useChatNotificationsCount();

    const [loading, setLoading] = useState(true);
    const [chats, setChats] = useState([]);

    const [refreshing, setRefreshing] = useState(false);

    const messageBackground = useColorModeValue('light.50', 'dark.50');

    const getChats = async (refreshing = false) => {
        if (refreshing) {
            setRefreshing(true);
        }

        const response = await get('/chats');
        setChats(response.data);
        setLoading(false);
        setRefreshing(false);
    }

    const getConversationName = (chat) => {
        if (chat.conversation.participants[0].messageable.id === userDetails.id) {
            if (chat.conversation.participants[1].messageable.company?.name) {
                return chat.conversation.participants[1].messageable.name + ' • ' + chat.conversation.participants[1].messageable.company.name;
            }
            return chat.conversation.participants[1].messageable.name;
        }

        if (chat.conversation.participants[0].messageable.company?.name) {
            return chat.conversation.participants[0].messageable.name + ' • ' + chat.conversation.participants[0].messageable.company.name;
        }
        return chat.conversation.participants[0].messageable.name;
    }

    useFocusEffect(
        useCallback(() => {
            getChats();
        }, [])
    );

    useEffect(() => {
        getChats();
    }, []);

    const getConversationTimestamp = (lastMessageDateTime) => {
        const messageDate = new Date(lastMessageDateTime);
        const currentDate = new Date();

        const messageDay = messageDate.getDate();
        const currentDay = currentDate.getDate();

        const messageWeekDay = messageDate.getDay();
        const currentWeekDay = currentDate.getDay();

        const messageMonth = messageDate.getMonth();
        const currentMonth = currentDate.getMonth();

        const messageYear = messageDate.getFullYear();
        const currentYear = currentDate.getFullYear();

        if (messageDay === currentDay && messageMonth === currentMonth && messageYear === currentYear) {
            return messageDate.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'});
        } else if (messageDay === currentDay - 1 && messageMonth === currentMonth && messageYear === currentYear) {
            return 'Yesterday';
        } else if (messageWeekDay < currentWeekDay && messageMonth === currentMonth && messageYear === currentYear) {
            return messageDate.toLocaleDateString(undefined, {weekday: 'long'});
        } else {
            return messageDate.toLocaleDateString();
        }
    }

    if (loading) {
        return (
            <View flex={1}>
                <Center flex={1}>
                    <Spinner size={'lg'} color={colors.primary[500]}/>
                    <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')}>Loading
                        messages</Text>
                </Center>
            </View>
        )
    }


    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    {chats.length === 0 && (
                        <View>
                            <Text fontSize={'md'}>You have no messages.</Text>
                        </View>
                    )}

                    {chats.map(chat => (
                        <View key={`chat-${chat.id}`}>
                            <TouchableOpacity onPress={() => navigation.navigate('Chat', {chatId: chat.id})}>
                                <HStack space={2} justifyContent={'space-between'} alignItems={'center'} p={4}
                                        bg={messageBackground}
                                        borderLeftColor={notificationsPerConversation[chat.id] > 0 ? 'primary.500' : 'transparent'}
                                        borderLeftWidth={4}
                                        borderRadius={8}>
                                    <HStack space={4} alignItems={'center'} flex={1}>
                                        <Avatar size={'md'} bg={'primary.500'}>
                                            <Ionicons name={'person'} size={32} color={'white'}/>
                                        </Avatar>

                                        <VStack flex={1}>
                                            <Text bold fontSize={'md'}>{getConversationName(chat)}</Text>
                                            <Text color={useColorModeValue('gray.500', 'gray.400')}
                                                  numberOfLines={1}
                                                  ellipsizeMode='tail'
                                                  fontSize={'md'}>
                                                {chat.conversation.last_message?.body ?? 'No messages.'}
                                            </Text>
                                        </VStack>
                                    </HStack>
                                    {chat.conversation.last_message && (
                                        <VStack>
                                            <Text textAlign={'center'}
                                                  color={notificationsPerConversation[chat.id] > 0 ? 'primary.500' : 'gray.400'}
                                                  fontSize={'sm'}>
                                                {getConversationTimestamp(chat.conversation.last_message.created_at)}
                                            </Text>
                                            {notificationsPerConversation[chat.id] > 0 && (
                                                <Circle mt={2} alignSelf={'flex-end'} size={5} bg={'primary.500'}>
                                                    <Text color={'white'} fontSize={'xs'}>
                                                        {notificationsPerConversation[chat.id]}
                                                    </Text>
                                                </Circle>
                                            )}
                                        </VStack>
                                    )}
                                </HStack>
                            </TouchableOpacity>
                        </View>
                    ))}
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default MessagesStack;

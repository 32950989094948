import {StyleSheet} from 'react-native';
import {Box, Heading, HStack, Spinner, Text, useColorModeValue, VStack} from 'native-base';
import Ionicons from '@expo/vector-icons/Ionicons';
import * as React from 'react';

const StatusItem = ({complete, text, subtext, active}) => {
    return (
        <HStack space={4} alignItems="center">
            {active ? (
                <Spinner/>
            ) : (
                <Ionicons
                    name={complete ? 'checkmark-circle' : 'ellipse-outline'}
                    size={24}
                    color={complete ? 'green' : 'gray'}
                />
            )}

            <VStack space={1}>
                <Heading
                    size={active ? 'md' : 'sm'}
                    color={!complete && active ? useColorModeValue('black', 'white') : 'gray.500'}
                >
                    {text}
                </Heading>
                {subtext && <Text fontSize="sm" color="gray.500">{subtext()}</Text>}
            </VStack>
        </HStack>
    );
};

const StatusLine = ({complete}) => {
    return <Box my={-4} w={0.5} h={8} bg={complete ? 'green.500' : 'gray.500'} ml="10px"/>;
};

const SubmissionTracker = ({submission}) => {
    const createdAt = new Date(submission.created_at).toLocaleDateString();

    const clientResponsesSent = () => {
        let sent = [];

        submission.responses.forEach(response => {
            response.client_submission_responses.forEach(clientResponse => {
                if (clientResponse.status === 'sent') sent.push(clientResponse);
            });
        });

        return sent;
    };

    const clientResponsesResponded = () => {
        let responded = [];

        submission.responses.forEach(response => {
            response.client_submission_responses.forEach(clientResponse => {
                if (['declined', 'accepted', 'paid', 'complete'].includes(clientResponse.status)) responded.push(clientResponse);
            });
        });

        return responded;
    };

    const responseSingularOrPlural = (length) => {
        return length === 1 ? 'response' : 'responses';
    }

    const steps = [
        {
            text: 'Property submitted',
            complete: true,
            subtext: () => `Submitted on ${createdAt}`
        },
        {
            text: 'Contractor response',
            complete: submission.responses.length > 0,
            subtext: () => {
                if (submission.responses.length === 0) return 'Awaiting response from contractors';
                return `${submission.responses.length} ${responseSingularOrPlural(submission.responses.length)} from contractors`;
            }
        },
        {
            text: 'Onboard your client',
            complete: submission.client_id !== null,
            subtext: () => {
                if (submission.client_id === null) return 'Invite your client to PropServ';
                return 'Client onboarded';
            }
        },
        {
            text: 'Accept a response',
            complete: clientResponsesResponded().filter(response => ['accepted', 'paid', 'complete'].includes(response.status)).length > 0,
            subtext: () => {
                if (clientResponsesResponded().length === 0) return 'Waiting for client to respond';
                return `${clientResponsesResponded().filter(response => ['accepted', 'paid', 'complete'].includes(response.status)).length} ${responseSingularOrPlural(clientResponsesResponded().filter(response => response.status === 'accepted').length)} accepted`;
            }
        },
        {
            text: 'Job paid for',
            complete: clientResponsesResponded().filter(response => ['paid', 'complete'].includes(response.status)).length > 0,
            subtext: () => {
                if (clientResponsesResponded().length === 0) return 'Waiting for client to pay';
                return `${clientResponsesResponded().filter(response => ['paid', 'complete'].includes(response.status)).length} ${responseSingularOrPlural(clientResponsesResponded().filter(response => response.status === 'paid').length)} paid`;
            }
        },
        {
            text: 'Job complete',
            complete: clientResponsesResponded().filter(response => response.status === 'complete').length > 0,
            subtext: () => {
                if (clientResponsesResponded().length === 0) return 'Waiting for client to mark job as complete';
                return `${clientResponsesResponded().filter(response => response.status === 'complete').length} ${responseSingularOrPlural(clientResponsesResponded().filter(response => response.status === 'complete').length)} completed`;
            }
        }
    ];

    let activeFound = false;

    return (
        <VStack space={4}>
            {steps.map((step, index) => {
                const isActive = !activeFound && !step.complete;
                if (isActive) activeFound = true;

                return (
                    <React.Fragment key={index}>
                        <StatusItem
                            complete={step.complete}
                            active={isActive}
                            text={step.text}
                            subtext={step.subtext}
                        />
                        {index < steps.length - 1 && <StatusLine complete={step.complete}/>}
                    </React.Fragment>
                );
            })}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default SubmissionTracker;

import {StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Divider, FormControl, HStack, Text, useColorModeValue, VStack} from "native-base";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";

const BreakdownOfFees = props => {
    const {deposit = false, depositAmount, price} = props;
    const {userDetails} = useAuth();

    const priceFloat = () => {
        return parseFloat(price.replaceAll(',', ''));
    }

    const depositFloat = () => {
        return parseFloat(depositAmount?.replaceAll(',', ''));
    }

    const platformFee = () => {
        return round(priceFloat() * (userDetails.company.platform_fee_percentage / 100));
    }

    const estateAgentFee = () => {
        return round(priceFloat() * (userDetails.company.estate_agent_fee_percentage / 100));
    }

    const contractorReceive = () => {
        return round(priceFloat() - platformFee() - estateAgentFee());
    }

    const platformFeeDeposit = () => {
        return round(depositFloat() * (userDetails.company.platform_fee_percentage / 100));
    }

    const estateAgentFeeDeposit = () => {
        return round(depositFloat() * (userDetails.company.estate_agent_fee_percentage / 100));
    }

    const contractorReceiveDeposit = () => {
        return round(depositFloat() - platformFeeDeposit() - estateAgentFeeDeposit());
    }

    const round = (value) => {
        return Math.round(value * 100) / 100;
    }

    return (
        <VStack space={2} borderColor={'gray.500'} borderWidth={1} p={4} rounded={'xl'}>
            {deposit && depositAmount?.length > 0 && (
                <>
                    <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                        <Text bold>Deposit</Text>
                        <Text>£{depositFloat().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </HStack>
                    <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                        <Text flex={1} color={useColorModeValue('gray.500', 'gray.400')}>Estate agent fee
                            ({userDetails.company.estate_agent_fee_percentage}%)</Text>
                        <Text>£{estateAgentFeeDeposit().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </HStack>
                    <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                        <Text flex={1} color={useColorModeValue('gray.500', 'gray.400')}>Platform fee
                            ({userDetails.company.platform_fee_percentage}%)</Text>
                        <Text>£{platformFeeDeposit().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </HStack>

                    <Divider mt={2} mb={1}/>
                </>
            )}

            <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                <Text bold>{deposit ? 'Final price' : 'Price'}</Text>
                <Text>£{priceFloat().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
            </HStack>
            <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                <Text flex={1} color={useColorModeValue('gray.500', 'gray.400')}>Estate agent fee
                    ({userDetails.company.estate_agent_fee_percentage}%)</Text>
                <Text>£{estateAgentFee().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
            </HStack>
            <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                <Text flex={1} color={useColorModeValue('gray.500', 'gray.400')}>Platform fee
                    ({userDetails.company.platform_fee_percentage}%)</Text>
                <Text>£{platformFee().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
            </HStack>

            <Divider mt={2} mb={1}/>

            {deposit ? (
                <HStack space={4} justifyContent={'space-between'} alignItems={'center'}>
                    <VStack alignItems={'flex-start'}>
                        <Text bold>Deposit</Text>
                        <Text bold>Final price</Text>
                        <Text mt={4} bold>You'll receive</Text>
                    </VStack>
                    <VStack alignItems={'flex-end'}>
                        <Text bold>
                            £{contractorReceiveDeposit().toLocaleString(undefined, {minimumFractionDigits: 2})}
                        </Text>
                        <Text bold>
                            £{contractorReceive().toLocaleString(undefined, {minimumFractionDigits: 2})}
                        </Text>

                        <Divider mt={2} mb={1}/>

                        <Text fontSize={'lg'} bold>
                            £{(contractorReceiveDeposit() + contractorReceive()).toLocaleString(undefined, {minimumFractionDigits: 2})}
                        </Text>
                    </VStack>
                </HStack>
            ) : (
                <HStack flex={1} space={4} justifyContent={'space-between'} alignItems={'center'}>
                    <Text flex={1} bold>You'll receive</Text>
                    <Text fontSize={'lg'}
                          bold>£{contractorReceive().toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                </HStack>
            )}

            <Divider mt={2}/>

            {deposit && (
                <Text mt={2} fontSize={'xs'} color={useColorModeValue('gray.500', 'gray.400')}>
                    Deposits are paid out as soon as we receive them. The final price will be paid out after the client
                    marks the job as
                    completed.
                </Text>
            )}

            <Text mt={2} fontSize={'xs'} color={useColorModeValue('gray.500', 'gray.400')}>
                All prices are inclusive of VAT.
            </Text>
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default BreakdownOfFees;

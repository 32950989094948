import {SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {
    AlertDialog,
    Box, Button,
    Heading,
    HStack,
    ScrollView,
    Text, useColorModeValue,
    VStack
} from "native-base";
import * as React from "react";
import {useAuth} from "../../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import {get, post} from "../../services/api";
import {useState} from "react";

const ManageSubscription = props => {
    const {userDetails, setUserDetails} = useAuth();

    const [showCancelAlert, setShowCancelAlert] = useState(false)
    const [showRenewAlert, setShowRenewAlert] = useState(false)

    const cancelSubscription = async () => {
        setShowCancelAlert(false);
        post('/contractor/subscriptions/cancel', {}).then(response => {
            get('/my-information').then(response => {
                setUserDetails(response);
                props.navigation.navigate('Profile')
            });
        });
    }

    const renewSubscription = () => {
        setShowRenewAlert(false);
        post('/contractor/subscriptions/renew', {subscription_id: userDetails?.company?.active_subscription?.stripe_id}).then(response => {
            get('/my-information').then(response => {
                setUserDetails(response);
                props.navigation.navigate('Profile')
            });
        });
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    <Heading>Subscription</Heading>

                    <VStack space={4} p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                        <VStack>
                            <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Subscribed since</Text>
                            <Text bold
                                  fontSize={'lg'}>{userDetails.company.active_subscription.stripe_created_at}</Text>
                        </VStack>

                        <VStack>
                            <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Price</Text>
                            <Text bold fontSize={'lg'}>£29.99</Text>
                        </VStack>

                        <VStack>
                            <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Current period ends</Text>
                            <Text bold
                                  fontSize={'lg'}>{userDetails.company.active_subscription.current_period_end}</Text>
                        </VStack>
                    </VStack>

                    {userDetails?.company?.active_subscription?.stripe_cancel_at && (
                        <HStack p={4} alignItems={'center'} space={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                            <Box bg={'red.500'} p={2} rounded={'full'}>
                                <Ionicons name={'timer-outline'} size={24} color={'white'}/>
                            </Box>
                            <VStack>
                                <Text bold fontSize={'md'}>Your subscription is ending</Text>
                                <Text color={'red.400'} fontSize={'md'}>
                                    Your subscription will end
                                    on {userDetails.company.active_subscription.stripe_cancel_at}
                                </Text>

                                <Button mt={2} colorScheme="primary" onPress={() => setShowRenewAlert(true)}>
                                    Renew subscription
                                </Button>
                            </VStack>
                        </HStack>
                    )}

                    {userDetails?.company?.active_subscription?.stripe_cancel_at === null && (
                        <TouchableOpacity onPress={() => setShowCancelAlert(true)}>
                            <HStack p={4} alignItems={'center'} space={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                                <Box bg={'red.500'} p={2} rounded={'full'}>
                                    <Ionicons name={'ios-close'} size={24} color={'white'}/>
                                </Box>
                                <VStack>
                                    <Text bold fontSize={'md'}>Cancel</Text>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Cancel your subscription</Text>
                                </VStack>
                            </HStack>
                        </TouchableOpacity>
                    )}
                </VStack>

                <AlertDialog isOpen={showCancelAlert} onClose={() => setShowCancelAlert(false)}>
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton/>
                        <AlertDialog.Header>Cancel Subscription</AlertDialog.Header>
                        <AlertDialog.Body>
                            Are you sure you want to cancel your subscription?<br/>
                            You will lose access to PropServ at the end of your current billing period.
                        </AlertDialog.Body>
                        <AlertDialog.Footer>
                            <Button.Group space={2}>
                                <Button px={8} variant="unstyled" colorScheme="coolGray"
                                        onPress={() => setShowCancelAlert(false)}>
                                    Go back
                                </Button>
                                <Button colorScheme="danger" onPress={cancelSubscription}>
                                    Confirm cancellation
                                </Button>
                            </Button.Group>
                        </AlertDialog.Footer>
                    </AlertDialog.Content>
                </AlertDialog>

                <AlertDialog isOpen={showRenewAlert} onClose={() => setShowRenewAlert(false)}>
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton/>
                        <AlertDialog.Header>Renew Subscription</AlertDialog.Header>
                        <AlertDialog.Body>
                            Are you sure you want to renew your subscription?<br/>
                            You will continue to be charged £29.99 every month.
                        </AlertDialog.Body>
                        <AlertDialog.Footer>
                            <Button.Group space={2}>
                                <Button variant="unstyled" colorScheme="coolGray"
                                        onPress={() => setShowRenewAlert(false)}>
                                    Go back
                                </Button>
                                <Button colorScheme="primary" onPress={renewSubscription}>
                                    Confirm renewal
                                </Button>
                            </Button.Group>
                        </AlertDialog.Footer>
                    </AlertDialog.Content>
                </AlertDialog>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default ManageSubscription;

import React, { createContext, useState, useEffect } from 'react';
import {get} from "../services/api";

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState({});
    const [notificationsCount, setNotificationsCount] = useState(0);

    const init = async () => {
        get('/notifications/unread').then(response => {
            setNotificationsCount(response.length);
            setNotifications(response)
        })
    };

    useEffect(() => {
        init();
    }, []);

    const refreshCount = async () => {
        init();
    };

    return (
        <NotificationsContext.Provider value={{ notificationsCount, setNotificationsCount, notifications, refreshCount }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => {
    const { notificationsCount, setNotificationsCount, notifications, refreshCount } = React.useContext(NotificationsContext);
    return { notificationsCount, setNotificationsCount, notifications, refreshCount };
}

import React, {useState, useCallback, useEffect, useRef} from 'react'
import {Text, View, Spinner, useColorModeValue, Box, Heading, useTheme} from "native-base";
import {get, post} from "../services/api";
import {useAuth} from "../providers/AuthProvider";
import * as Ably from 'ably';
import {useChatNotificationsCount} from "../providers/ChatNotificationsProvider";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";
import {appendDocumentToFormData, appendImageToFormData} from "../services/documents";
import ChatMessages from "../components/chat/ChatMessages";
import ChatInputToolbar from "../components/chat/ChatInputToolbar";
import SubmissionCollapsible from "../components/chat/SubmissionCollapsible";

export const Chat = props => {
    const {userDetails} = useAuth();
    const {refreshCount} = useChatNotificationsCount();
    const {navigation} = props;
    const {colors} = useTheme();

    const gray = useColorModeValue(colors.gray[500], colors.gray[400]);
    const bg = useColorModeValue('light.50', 'dark.50');

    const [loading, setLoading] = useState(true)
    const [messages, setMessages] = useState(null)
    const [chat, setChat] = useState(null)
    const [submission, setSubmission] = useState(null)

    const [imagesToSend, setImagesToSend] = useState([])
    const [documentsToSend, setDocumentsToSend] = useState([])

    const [sending, setSending] = useState(false)

    const clientRef = useRef(null);

    const {chatId} = props.route.params;

    useEffect(() => {
        get(`/chats/${chatId}`).then(conversation => {
            setChat(conversation);

            if (conversation.participants.some(participant => participant.messageable.type === 'client') && userDetails.type === 'contractor') {
                const client = conversation.participants.find(participant => participant.messageable.type === 'client').messageable;
                get(`/clients/${client.id}/submission`).then(response => {
                    setSubmission(response);
                });
            }

            get(`/chats/${chatId}/messages`).then(response => {
                refreshCount();

                if (response.data.length === 0) {
                    setMessages([{
                        _id: 1, text: 'Send a message to get started', createdAt: new Date(), system: true,
                    }])
                } else {
                    setMessages(response.data.map(message => ({
                        _id: message.id,
                        text: message.body === "Attachment" && message.data?.image ? null : message.body,
                        createdAt: new Date(message.created_at),
                        user: {
                            _id: message.sender.id,
                            name: message.sender.name,
                        },
                        image: message.data?.image ?? null,
                        document: message.data?.document ?? null,
                        document_name: message.data?.document_name ?? null,
                    })).sort((a, b) => b.createdAt - a.createdAt));
                }

                navigation.setOptions({
                    title: getConversationName(conversation),
                })

                initAbly(conversation);

                setLoading(false);

                setTimeout(() => {
                    setMessages(prev => [...prev]);
                }, 200);
            });
        });

        return () => {
            if (clientRef.current) {
                clientRef.current.close();
                clientRef.current = null;
            }
        }
    }, [chatId]);

    const getConversationName = (conversation) => {
        if (conversation.participants[0].messageable.id === userDetails.id) {
            return conversation.participants[1].messageable.name;
        }

        return conversation.participants[0].messageable.name;
    }

    const getParticipant = (conversation) => {
        if (conversation.participants[0].messageable.id === userDetails.id) {
            return conversation.participants[1].messageable;
        }

        return conversation.participants[0].messageable;
    }

    const initAbly = (conversation) => {
        if (clientRef.current) {
            return;
        }

        const client = new Ably.Realtime('rBU8kg.UhrKZQ:04TkMQxE4iAg0WDlHXcb3Kof60NR1eN_5OFFo9Vl6BA');
        clientRef.current = client;

        const channel = client.channels.get(`private:mc-chat-conversation.${conversation.id}`);

        channel.subscribe(function (message) {
            if (message.data.message.sender.id !== userDetails.id) {
                setMessages(previousMessages => [...previousMessages, {
                    _id: message.data.message.id,
                    text: message.data.message.body,
                    createdAt: new Date(message.data.message.created_at),
                    user: {
                        _id: message.data.message.sender.id, name: message.data.message.sender.name,
                    },
                    image: message.data.message.data?.image ?? null,
                    document: message.data.message.data?.document ?? null,
                    document_name: message.data.message.data?.document_name ?? null,
                }]);
            }
        });
    }

    const onSend = useCallback(async (message) => {
        setSending(true);

        if (imagesToSend.length > 0) {
            for (const image of imagesToSend) {
                const formData = new FormData();
                await appendImageToFormData(formData, image);
                const {url} = await post(`/chats/${chat.id}/images`, formData);
                await post(`/chats/${chat.id}/messages`, {image: url});
                setMessages(previousMessages => [...previousMessages, {
                    _id: Math.random(),
                    image: url,
                    createdAt: new Date(),
                    user: {
                        _id: userDetails.id,
                    },
                }]);
            }
        }

        setImagesToSend([]);

        if (documentsToSend.length > 0) {
            for (const document of documentsToSend) {
                const formData = new FormData();
                await appendDocumentToFormData(formData, document)
                const {url} = await post(`/chats/${chat.id}/documents`, formData);
                await post(`/chats/${chat.id}/messages`, {document: url, document_name: document.name});
                setMessages(previousMessages => [...previousMessages, {
                    _id: Math.random(),
                    document_name: document.name,
                    document: url,
                    createdAt: new Date(),
                    user: {
                        _id: userDetails.id,
                    },
                }]);
            }
        }

        setDocumentsToSend([]);

        post(`/chats/${chat.id}/messages`, {message: message})

        setMessages(previousMessages => [...previousMessages, {
            _id: Math.random(),
            text: message,
            user: {_id: userDetails.id},
            createdAt: new Date(),
        }]);
        setSending(false);
    }, [chat, imagesToSend, documentsToSend, userDetails.id, setMessages]);

    const uploadImages = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images, quality: 1, allowsMultipleSelection: true
        });

        if (!result.canceled) {
            setImagesToSend(prevState => [...prevState, ...result.assets]);
        }
    }

    const uploadDocuments = async () => {
        const result = await DocumentPicker.getDocumentAsync({
            type: 'application/pdf', multiple: true,
        });

        if (result.canceled === false) {
            setDocumentsToSend(prevState => [...prevState, ...result.assets]);
        }
    }

    if (loading) {
        return (<View flex={1} justifyContent={'center'} alignItems={'center'}>
            <Text bold fontSize={'md'}>Loading conversation...</Text>
            <Spinner mt={4} size={'lg'}/>
        </View>)
    }

    return (
        <View flex={1} alignSelf={'center'} width={'100%'} mb={4} px={4}>
            <Box my={4} p={4} bg={bg} rounded={'xl'}>
                <Text color={gray}>Chat with</Text>
                <Heading>{getConversationName(chat)}</Heading>
                {getParticipant(chat).company && (<Text>{getParticipant(chat).company.name}</Text>)}

                {submission && userDetails.type === 'contractor' && (
                    <SubmissionCollapsible submission={submission}/>
                )}
            </Box>

            <ChatMessages messages={messages}/>

            <ChatInputToolbar
                sending={sending}
                onSend={onSend}
                onUploadImage={uploadImages}
                onUploadDocument={uploadDocuments}
                imagesToSend={imagesToSend}
                setImagesToSend={setImagesToSend}
                documentsToSend={documentsToSend}
                setDocumentsToSend={setDocumentsToSend}
            />
        </View>
    )
}

import {StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Button, Divider, Heading, HStack, Text, useColorModeValue, useTheme, VStack} from "native-base";
import StatusBadge from "../StatusBadge";
import BreakdownOfFees from "./BreakdownOfFees";
import {downloadDocument} from "../../services/documents";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";
import {useAuth} from "../../providers/AuthProvider";
import UploadDocumentsButton from "../UploadDocumentsButton";
import {useState} from "react";
import {post} from "../../services/api";
import {appendDocumentsToFormData} from "../../services/documents";

const ResponseDetails = ({submission, response, navigation}) => {
    const grayText = useColorModeValue('gray.500', 'gray.400');
    const backgroundColor = useColorModeValue('light.50', 'dark.50');
    const lightDark200 = useColorModeValue('light.200', 'dark.200');
    const lightDark100 = useColorModeValue('light.100', 'dark.100');
    const blackWhite = useColorModeValue('black', 'white');

    const {userDetails} = useAuth();
    const {colors} = useTheme();

    const [terms, setTerms] = useState(response.terms);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [savingTerms, setSavingTerms] = useState(false);

    const canEditTerms = !['paid', 'paid_by_estate_agent', 'deposit_paid', 'deposit_paid_by_estate_agent', 'complete', 'closed'].includes(submission.status);

    const handleTermsChange = (newTerms) => {
        setTerms(newTerms);
        setShowSaveButton(true);
    };

    const handleSaveTerms = async () => {
        setSavingTerms(true);
        setShowSaveButton(false);

        let formData = new FormData();
        await appendDocumentsToFormData(formData, terms, 'terms');

        try {
            await post(`/contractor/submissions/${submission.id}/response/${response.id}/terms`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Terms saved successfully
            console.log("Terms saved successfully");
        } catch (error) {
            console.error("Error saving terms:", error);
            // Handle error (e.g., show error message to user)
            setShowSaveButton(true);
        } finally {
            setSavingTerms(false);
        }
    };

    return (
        <VStack space={4} bg={backgroundColor} p={4} rounded={'lg'}
                borderColor={submission.status === 'offered' ? 'amber.300' : 'green.500'}
                borderWidth={2}>
            <HStack space={4} alignItems={'center'} justifyContent={'space-between'}>
                {submission.response && (
                    <Heading size={'md'}>Your offer</Heading>
                )}
                {!submission.response && submission.company_response && (
                    <Heading size={'md'}>
                        {response.user.name}'s offer
                    </Heading>
                )}
                {submission.status === 'closed' && (
                    <StatusBadge status={response.status}/>
                )}
            </HStack>

            {submission.status === 'offered' && (
                <Text color={grayText} fontSize={'md'}>
                    Your offer is with the client. They will get back to you shortly.
                </Text>
            )}

            {userDetails.company.commission_only !== 1 && (
                <BreakdownOfFees
                    showPrice
                    price={response.price.toString()}
                    deposit={response.deposit_amount !== null}
                    depositAmount={response.deposit_amount?.toString()}
                />
            )}

            {userDetails.company.commission_only === 1 && (
                <>
                    <VStack space={1}>
                        <Text fontSize={'md'} color={grayText}>Commission amount</Text>
                        {response.commission_amount == null ? (
                            <Text fontSize={'md'}>Commission amount not provided yet</Text>
                        ) : (
                            <Text fontSize={'md'}>£{response.commission_amount}</Text>
                        )}
                    </VStack>
                    <Divider/>
                </>
            )}

            <VStack space={1}>
                <Text fontSize={'md'} color={grayText}>Estimated start date</Text>
                <Text fontSize={'md'}>
                    {response.estimated_start_date == null ? 'No start date provided' : new Date(response.estimated_start_date).toLocaleDateString()}
                </Text>
            </VStack>
            <Divider/>

            <VStack space={1}>
                <Text fontSize={'md'} color={grayText}>Services</Text>
                <HStack space={2} alignItems={'center'} flexWrap={'wrap'}>
                    {response?.categories.map((service, index) => (
                        <Box my={1} rounded={'2xl'} px={2} py={1}
                             bg={lightDark200} space={1}
                             alignItems={'center'}
                             key={index}>
                            <Text fontSize={'xs'} color={blackWhite}>
                                {service.name}
                            </Text>
                        </Box>
                    ))}
                </HStack>
            </VStack>
            <Divider/>

            <VStack space={1}>
                <Text fontSize={'md'} color={grayText}>Message</Text>
                <Text fontSize={'md'}>
                    {response.message == null ? 'No message' : response.message}
                </Text>
            </VStack>
            <Divider/>

            <VStack space={1}>
                <Text fontSize={'md'} color={grayText}>Documents</Text>
                {response.documents.length === 0 ? (
                    <Text fontSize={'md'}>No documents</Text>
                ) : (
                    <VStack mt={3} flex={1} space={1}>
                        {response.documents.map(document => (
                            <HStack alignItems={'center'} key={document.id} flex={1} px={4} py={2}
                                    bg={lightDark100}
                                    rounded={'xl'}
                                    justifyContent={'space-between'}>
                                <Text fontSize={'md'} flex={1}>{document.name}</Text>
                                <TouchableOpacity onPress={() => downloadDocument(document)}>
                                    <Ionicons name={'download-outline'} size={24}
                                              color={colors.primary[500]}/>
                                </TouchableOpacity>
                            </HStack>
                        ))}
                    </VStack>
                )}
            </VStack>
            <Divider/>

            <VStack space={1}>
                <Text fontSize={'md'} color={grayText}>Terms</Text>
                {canEditTerms ? (
                    <>
                        {terms.length === 0 && (
                            <Text fontSize={'md'}>No terms uploaded yet</Text>
                            )}
                        <UploadDocumentsButton 
                            documents={terms} 
                        setDocuments={handleTermsChange}
                            label={'Upload terms'}
                        />
                    </>
                ) : (
                    terms.length === 0 ? (
                        <Text fontSize={'md'}>No terms uploaded</Text>
                    ) : (
                        <VStack mt={3} flex={1} space={1}>
                            {terms.map(document => (
                                <HStack alignItems={'center'} key={document.id} flex={1} px={4} py={2}
                                        bg={lightDark100}
                                        rounded={'xl'}
                                        justifyContent={'space-between'}>
                                    <Text fontSize={'md'} flex={1}>{document.name}</Text>
                                    <TouchableOpacity onPress={() => downloadDocument(document)}>
                                        <Ionicons name={'download-outline'} size={24}
                                                  color={colors.primary[500]}/>
                                    </TouchableOpacity>
                                </HStack>
                            ))}
                        </VStack>
                    )
                )}
            </VStack>

            {showSaveButton && canEditTerms && (
                <Button mt={2} onPress={handleSaveTerms} isLoading={savingTerms}>
                    Save Terms
                </Button>
            )}

            {(userDetails.company.commission_only === 1 || ![
                'accepted',
                'paid',
                'paid_by_estate_agent',
                'deposit_paid',
                'deposit_paid_by_estate_agent',
                'complete',
                'closed'
            ].includes(submission.status)) && submission.response && (
                <Button mt={2}
                        onPress={() => navigation.navigate('Submission Response', {submissionId: submission.uuid})}>
                    Edit your offer
                </Button>
            )}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default ResponseDetails;

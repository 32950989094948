import {StyleSheet, Text, View} from 'react-native';
import {Picker} from "react-native-web";
import {useColorMode, useTheme} from "native-base";

const StyledPicker = props => {
    const {colors} = useTheme();
    const {colorMode} = useColorMode();
    const styles = createStyles(colors, colorMode);

    return (
        <Picker {...props} style={{...props.style, ...styles.picker}}>
            {props.children}
        </Picker>
    );
};

const createStyles = (colors, colorMode) => StyleSheet.create({
    picker: {
        backgroundColor: 'transparent',
        borderRadius: 4,
        padding: 8,
        borderWidth: 1,
        borderColor: colorMode === 'dark' ? 'rgb(64, 64, 64)' : colors.gray[300],
        color: colorMode === 'dark' ? colors.gray[100] : colors.gray[800],
    },
});

export default StyledPicker;

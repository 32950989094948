import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Heading,
    HStack,
    useColorModeValue,
    Text,
    VStack,
    Button,
    Modal,
    Box,
    ScrollView,
    Badge,
    useTheme
} from "native-base";
import * as React from "react";
import {useEffect, useState} from "react";
import {get} from "../../services/api";
import Ionicons from "@expo/vector-icons/Ionicons";

const ContractorCategories = props => {
    const {submission, client = false} = props;
    const cardBackground = useColorModeValue('light.50', 'dark.50');

    const {colors} = useTheme();

    const [availableCategories, setAvailableCategories] = useState([]);
    const [showAddCategoriesModal, setShowAddCategoriesModal] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        get('/company-categories').then(response => {
            setAvailableCategories(response);
        });
    }, []);

    useEffect(() => {
        if (submission.contractor_categories) {
            setSelectedCategories(submission.contractor_categories);
        }
    }, [submission]);

    return (
        <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
            <Heading fontSize={'md'}>Contractor categories</Heading>

            <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                This submission has requested the following contractor categories
            </Text>

            <HStack space={2} flexWrap={'wrap'}>
                {submission.contractor_categories.map((category, index) => (
                    <HStack my={1} rounded={'2xl'} px={2} py={1} bg={useColorModeValue('light.200', 'dark.200')} space={1} alignItems={'center'}
                            key={index}>
                        <Text fontSize={'xs'} color={useColorModeValue('black', 'white')}>
                            {category.name}
                        </Text>
                    </HStack>
                ))}
            </HStack>

            {client && submission.status !== 'closed' && (
                <Button onPress={() => setShowAddCategoriesModal(true)}>
                    Add more categories
                </Button>
            )}

            {showAddCategoriesModal && (
                <Modal isOpen={showAddCategoriesModal} onClose={() => setShowAddCategoriesModal(false)}>
                    <Modal.Content>
                        <Modal.Header>Add contractor categories</Modal.Header>
                        <Modal.Body>
                            <VStack space={4}>
                                <Box mt={2} bg={useColorModeValue('light.100', 'dark.100')} p={4} rounded={'xl'}>
                                    <ScrollView maxH={180}>
                                        <HStack flexWrap={'wrap'}>
                                            {availableCategories.map((category, index) => (
                                                <TouchableOpacity
                                                    style={{marginRight: 4}}
                                                    key={index}
                                                    onPress={() => {
                                                        if (!selectedCategories.find(item => item.id === category.id)) {
                                                            setSelectedCategories([...selectedCategories, category]);
                                                        }
                                                    }}>
                                                    <Badge
                                                        variant={'subtle'}
                                                        borderColor={selectedCategories.find(item => item.id === category.id) ? 'primary.500' : 'transparent'}
                                                        borderWidth={2}
                                                        bg={useColorModeValue('light.200', 'dark.200')}
                                                        colorScheme={useColorModeValue('light', 'dark')}
                                                        rightIcon={selectedCategories.find(item => item.id === category.id) ? <Ionicons name={'checkmark'} color={colors.primary[500]} size={16}/> : null}
                                                        mb={2}
                                                        rounded={'2xl'}>
                                                        {category.name}
                                                    </Badge>
                                                </TouchableOpacity>
                                            ))}
                                        </HStack>
                                    </ScrollView>
                                </Box>
                            </VStack>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <Button variant={'subtle'} rounded={'full'} onPress={() => {
                                    setSelectedCategories(submission.contractor_categories);
                                    setShowAddCategoriesModal(false)
                                }}>Cancel</Button>
                                <Button onPress={() => {
                                    setShowAddCategoriesModal(false);
                                    props.updateCategories(selectedCategories);
                                }}>Save</Button>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            )}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default ContractorCategories;

import {Linking, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    Heading, HStack,
    Image,
    Select, useTheme,
    View,
    VStack
} from "native-base";
import * as React from "react";
import {useNavigation} from "@react-navigation/native";
import Ionicons from "@expo/vector-icons/Ionicons";
import Constants from "expo-constants";

const AccountTypeOption = props => {
    const {optionType, description, icon, userType, setUserType} = props;
    const {colors} = useTheme();

    return (
        <TouchableOpacity onPress={() => setUserType(optionType)}>
            <VStack
                space={4}
                borderColor={userType === optionType ? 'primary.500' : 'dark.50'}
                borderWidth={2}
                bg={userType === optionType ? 'primary.500' : 'light.50'}
                p={4}
                borderRadius={8}>
                <HStack space={4} alignItems={'center'}>
                    <Ionicons name={icon} size={24} color={userType === optionType ? colors.white : colors.black}/>
                    <Heading flex={1} fontSize={'md'}
                             color={userType === optionType ? colors.white : colors.black}>{description}</Heading>
                </HStack>
            </VStack>
        </TouchableOpacity>
    );
}

const FirstPage = props => {
    const {
        web,
        userId,
        name,
        email,
        userType,
        setUserType,
        nextPage,
    } = props;

    const {colors} = useTheme();

    const styles = createStyles(web);

    const navigation = useNavigation();

    return (
        <View style={styles.page} maxWidth={'600px'} width={'100%'} p={4}>
            <VStack space={4} width={'100%'}>
                <Image
                    height={150}
                    maxW={'80%'}
                    width={400}
                    mb={4}
                    alignSelf={'center'}
                    resizeMode={'contain'}
                    source={require('../../../../assets/PropServBlack.png')}
                    alt={'PropServ Logo'}
                />

                {userId && name && email && (
                    <VStack mb={4} space={1} p={4} borderColor={'primary.500'} borderWidth={2} bg={'light.50'} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Image source={require('../../../../assets/microsoft.png')} alt={'Microsoft Logo'}
                                   height={'35px'} width={'35px'}/>
                            <Heading fontSize={'md'} color={'black'}>Signed in with Microsoft</Heading>
                        </HStack>
                        <HStack mt={2} space={4} alignItems={'center'}>
                            <Ionicons style={{paddingHorizontal: 5}} name={'checkmark-circle-outline'} size={'25px'} color={colors.gray[400]}/>
                            <Heading fontSize={'sm'} color={'gray.400'}>{name}</Heading>
                        </HStack>
                        <HStack space={4} alignItems={'center'}>
                            <Ionicons style={{paddingHorizontal: 5}} name={'checkmark-circle-outline'} size={'25px'} color={colors.gray[400]}/>
                            <Heading fontSize={'sm'} color={'gray.400'}>{email}</Heading>
                        </HStack>
                    </VStack>
                )}

                <Heading textAlign={'center'} color={'black'}>Let's get started</Heading>

                <Heading mt={4} fontSize={'md'} textAlign={'center'}>
                    Why are you here?
                </Heading>

                <AccountTypeOption
                    optionType={'contractor'}
                    description={'I am a contractor and want to offer my services'}
                    userType={userType}
                    setUserType={setUserType}
                    icon={'hammer-outline'}
                />

                <AccountTypeOption
                    optionType={'estate_agent'}
                    description={'I want to refer my client'}
                    userType={userType}
                    setUserType={setUserType}
                    icon={'home-outline'}
                />

                {!web && (
                    <VStack space={4} mt={4}>
                        <Button onPress={() => nextPage(0)} isDisabled={userType === null}>
                            Next
                        </Button>
                        <Button variant={'link'} onPress={() => navigation.navigate('Login')}>
                            Already have an account? Login
                        </Button>
                    </VStack>
                )}
            </VStack>
        </View>
    );
};

const createStyles = (web) => {
    return StyleSheet.create({
        page: {
            flex: web ? null : 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
    });
}
export default FirstPage;

import {Dimensions, StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Heading, HStack, useColorModeValue, useTheme} from "native-base";
import {getHeaderTitle} from '@react-navigation/elements';
import Ionicons from "@expo/vector-icons/Ionicons";
import {useSafeAreaInsets} from "react-native-safe-area-context";

const CustomNavigationHeader = ({navigation, route, options, back}) => {
    const {colors} = useTheme();
    const title = getHeaderTitle(options, route.name);
    const {top} = useSafeAreaInsets();
    const permanent = Dimensions.get('window').width > 768;

    return (
        <Box mx={4} mt={4 + top} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'} p={4}>
            <HStack space={4} alignItems={'center'}>
                {!permanent && !back && (
                    <TouchableOpacity onPress={navigation.toggleDrawer}>
                        <Ionicons color={useColorModeValue(colors.dark[50], colors.light[50])} name={'menu-outline'}
                                  size={24}/>
                    </TouchableOpacity>
                )}

                {back ?
                    <TouchableOpacity onPress={navigation.goBack}>
                        <Ionicons color={useColorModeValue(colors.dark[50], colors.light[50])} name={'chevron-back'}
                                  size={24}/>
                    </TouchableOpacity>
                    : null
                }

                {title ? <Heading fontSize={'2xl'}>{title}</Heading> : null}
            </HStack>
        </Box>
    );
};

const styles = StyleSheet.create({});

export default CustomNavigationHeader;

import {StyleSheet} from 'react-native';
import {HStack, useTheme} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";

const StarRating = ({ rating }) => {
    const {colors} = useTheme();

    if (rating == null) {
        return null;
    }

    return (
        <HStack space={1}>
            {[...Array(5)].map((_, i) => (
                <Ionicons
                    key={i}
                    name={i < rating ? 'star' : 'star-outline'}
                    size={16}
                    color={colors.primary[500]}
                />
            ))}
        </HStack>
    );
};

const styles = StyleSheet.create({});

export default StarRating;

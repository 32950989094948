import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Box,
    Button,
    Center,
    Divider,
    Heading,
    HStack,
    Input,
    Menu,
    Modal,
    ScrollView, Spacer,
    Spinner,
    Text,
    useColorModeValue,
    useTheme,
    View,
    VStack
} from 'native-base';
import * as React from "react";
import {useAuth} from "../../providers/AuthProvider";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {useCallback, useState} from "react";
import {get, post} from "../../services/api";
import SubmissionDetails from "./SubmissionDetails";
import StatusBadge from "../../components/StatusBadge";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";
import SubmissionResponse from "./SubmissionResponse";

const Stack = createNativeStackNavigator();

function ContractorHomeStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Submissions Dashboard" component={ContractorHome}/>
            <Stack.Screen name="Submission" component={SubmissionDetails}/>
            <Stack.Screen name="Submission Response" component={SubmissionResponse}/>
        </Stack.Navigator>
    );
}

const ContractorHome = props => {
    const {userDetails} = useAuth();
    const {colors} = useTheme();
    const navigation = useNavigation();

    const [loading, setLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);

    const [search, setSearch] = useState('');

    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeSubmission, setRemoveSubmission] = useState(null);

    const statuses = ['requested', 'offered', 'accepted', 'declined', 'deposit_paid', 'deposit_paid_by_estate_agent', 'paid', 'paid_by_estate_agent', 'complete', 'closed', 'hidden'];
    const [selectedStatus, setSelectedStatus] = useState(null);

    const filteredSubmissions = () => {
        if (search === '') {
            return submissions;
        }

        return submissions.filter(submission => {
            return submission.address.line_1.toLowerCase().includes(search.toLowerCase()) ||
                (submission.address.line_2 && submission.address.line_2.toLowerCase().includes(search.toLowerCase())) ||
                submission.address.city.toLowerCase().includes(search.toLowerCase()) ||
                submission.address.postcode.toLowerCase().includes(search.toLowerCase()) ||
                (submission.client && submission.client.name.toLowerCase().includes(search.toLowerCase())) ||
                (submission.user && submission.user.name.toLowerCase().includes(search.toLowerCase()));
        });
    }

    const getSubmissions = async () => {
        setLoading(true)
        get('/contractor/submissions', {status: selectedStatus}).then(response => {
            setSubmissions(response);
            setLoading(false)
        }).catch(error => {
            console.log(error);
        });
    }

    useFocusEffect(
        useCallback(() => {
            getSubmissions();
        }, [selectedStatus])
    );

    if (loading) {
        return (
            <View p={4} rounded={'xl'} flex={1}>
                <Center flex={1}>
                    <Spinner size={'lg'}/>
                    <Text mt={4} fontSize={'md'}>
                        Please wait while we load your submissions.
                    </Text>
                </Center>
            </View>
        );
    }

    if (userDetails.company.has_active_subscription === false) {
        return (
            <View p={4} rounded={'xl'} flex={1}>
                <Center flex={1}>
                    <Ionicons name={'alert-circle-outline'} size={48} color={colors.dark[400]}/>
                    <Heading>No active subscription</Heading>
                    <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')} textAlign={'center'}>
                        You do not have an active subscription. Please subscribe to view client submissions.
                    </Text>
                    <Button mt={4} colorScheme={'primary'} onPress={() => navigation.navigate('Profile')}>
                        Subscribe now
                    </Button>
                </Center>
            </View>
        );
    }

    if (submissions.length === 0 && selectedStatus === null) {
        return (
            <View p={4} rounded={'xl'} flex={1}>
                <Center flex={1}>
                    <Heading>No submissions</Heading>
                    <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')} textAlign={'center'}>
                        There are no submissions yet. Submissions that apply to your company will appear here.
                    </Text>
                </Center>
            </View>
        );
    }

    return (
        <ScrollView p={4}>
            <VStack space={4} alignSelf={'center'} w={'100%'}>
                <VStack space={2} px={4} pt={4} pb={2} bg={useColorModeValue('light.50', 'dark.50')} rounded={'lg'}>
                    <Text bold>Filter by status</Text>
                    <HStack flexWrap={'wrap'} space={2}>
                        {statuses.map(status => (
                            <TouchableOpacity
                                style={{marginBottom: 8}} key={status}
                                onPress={() => selectedStatus === status ? setSelectedStatus(null) : setSelectedStatus(status)}>
                                <Box
                                    p={1}
                                    px={4}
                                    bg={selectedStatus === status ? 'primary.500' : useColorModeValue('light.200', 'dark.100')}
                                    rounded={'full'}>
                                    <Text
                                        color={selectedStatus === status ? 'white' : useColorModeValue('black', 'white')}>
                                        {status.charAt(0).toUpperCase() + status.slice(1).replaceAll('_', ' ')}
                                    </Text>
                                </Box>
                            </TouchableOpacity>
                        ))}
                    </HStack>
                </VStack>

                <VStack mb={4} space={2} p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                    <Input
                        leftElement={<Ionicons style={{marginLeft: 8}} name={'search'} size={16}
                                               color={colors.dark[400]}/>}
                        placeholder="Search submissions"
                        value={search}
                        onChangeText={setSearch}
                    />

                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                        Search for submissions by address, client name, or agent name.
                    </Text>
                </VStack>

                <Divider mb={4}/>

                {submissions.length === 0 && selectedStatus !== null && (
                    <View rounded={'xl'} flex={1}>
                        <Heading fontSize={'lg'}>No submissions found</Heading>
                        <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')}>
                            Try changing the filters or updating the location.
                        </Text>
                    </View>
                )}

                {filteredSubmissions().map(submission => (
                    <TouchableOpacity
                        key={submission.id}
                        onPress={() => navigation.navigate('Submission', {submissionId: submission.uuid})}>
                        <View p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'} borderWidth={submission.urgent ? '3' : null} borderColor={submission.urgent ? useColorModeValue('danger.200', 'danger.500') : null}>
                            <VStack space={2}>
                                <HStack space={2} alignItems={'center'}>
                                    <HStack flex={1} space={2}>
                                        {submission.urgent === 1 && (
                                            <StatusBadge alignSelf={'flex-start'} status="urgent"/>
                                        )}
                                        <StatusBadge alignSelf={'flex-start'} status={submission.status}/>
                                    </HStack>

                                    <Menu w="190" placement={'bottom right'} trigger={triggerProps => {
                                        return <TouchableOpacity {...triggerProps}>
                                            <Ionicons name={'ellipsis-vertical'} size={24} color={colors.dark[400]}/>
                                        </TouchableOpacity>;
                                    }}>
                                        {selectedStatus !== 'hidden' && (
                                            <Menu.Item bgColor={useColorModeValue('light.50', 'dark.50')} onPress={() => {
                                                setRemoveSubmission(submission);
                                                setShowRemoveModal(true);
                                            }}>Hide</Menu.Item>
                                        )}
                                        {selectedStatus === 'hidden' && (
                                            <Menu.Item onPress={() => {
                                                post(`/submissions/${submission.id}/unhide`);
                                                getSubmissions();
                                            }}>Unhide</Menu.Item>
                                        )}
                                    </Menu>
                                </HStack>
                                <Heading>{submission.address.line_1}</Heading>
                                <HStack alignItems={'center'}>
                                    <Ionicons name={'location-outline'} size={24} color={colors.dark[400]}/>
                                    <Text ml={2} fontSize={'md'}>
                                        {submission.address.line_1},{' '}
                                        {submission.address.line_2 && submission.address.line_2 + ', '}
                                        {submission.address.city},{' '}
                                        {submission.address.postcode}
                                    </Text>
                                </HStack>
                                {submission.client != null && (
                                    <HStack alignItems={'center'}>
                                        <Ionicons name={'person-outline'} size={24} color={colors.dark[400]}/>
                                        <Text ml={2} fontSize={'md'}>
                                            {submission.client.name}
                                        </Text>
                                    </HStack>
                                )}
                                {submission.company_response && (
                                    <HStack alignItems={'center'}>
                                        <Ionicons name={'person-outline'} size={24} color={colors.dark[400]}/>
                                        <Text ml={2} fontSize={'md'}>
                                            Responded to by {submission.company_response.user.name}
                                        </Text>
                                    </HStack>
                                )}
                            </VStack>
                        </View>
                    </TouchableOpacity>
                ))}
            </VStack>

            {showRemoveModal && (
                <Modal isKeyboardDismissable={false} isOpen={showRemoveModal}
                       onClose={() => setShowRemoveModal(false)}>
                    <Modal.Content maxWidth="500px">
                        <Modal.CloseButton/>
                        <Modal.Header>Hide submission</Modal.Header>
                        <Modal.Body>
                            <Text fontSize={'md'}>
                                We will hide this submission from your dashboard. You can access it again by filtering
                                for hidden submissions.
                            </Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <HStack space={4} justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
                                    <Button colorScheme="error" onPress={() => {
                                        setShowRemoveModal(false)
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="primary" onPress={async () => {
                                        await post(`/submissions/${removeSubmission.id}/hide`);
                                        setShowRemoveModal(false);
                                        getSubmissions();
                                    }}>
                                        Hide
                                    </Button>
                                </HStack>
                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default ContractorHomeStack;

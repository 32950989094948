import {Platform, StyleSheet} from 'react-native';
import {Heading, HStack, useColorModeValue, Text, VStack} from "native-base";
import SubmissionMapView from "../SubmissionMapView";
import * as React from "react";

const Location = props => {
    const {submission} = props;
    const cardBackground = useColorModeValue('light.50', 'dark.50');

    return (
        <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
            <Heading fontSize={'md'}>Location</Heading>
            <HStack space={1} flexWrap={'wrap'}>
                <Text selectable fontSize={'md'}>
                    {submission.address.line_1},{' '}
                    {submission.address.line_2 && `${submission.address.line_2}, `}
                    {submission.address.city},{' '}
                    {submission.address.county},{' '}
                    {submission.address.postcode},{' '}
                    {submission.address.country}{' '}
                </Text>
            </HStack>

            {Platform.OS !== 'web' && (
                <SubmissionMapView submission={submission}/>
            )}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default Location;

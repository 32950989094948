import * as React from 'react';
import {
    Box,
    HStack,
    Image,
    Pressable,
    Text,
    useTheme,
    View,
    VStack,
} from 'native-base';
import {DefaultTheme, NavigationContainer, useNavigation} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Login from "../auth/Login";
import Register from "../auth/Register.web";
import {LinearGradient} from "expo-linear-gradient";
import {useState} from "react";
import ForgotPassword from "../auth/ForgotPassword";
import {unauthenticatedLinking} from "../Linking";
import MagicLink from "../auth/MagicLink";
import {Dimensions} from "react-native";
import MagicAdminLink from "../auth/MagicAdminLink";
import {BlurView} from "expo-blur";
import MagicAzureLink from "../auth/MagicAzureLink";
import EstateAgentLandingPage from "./EstateAgentLandingPage";
import ClientLandingPage from "./ClientLandingPage";
import ContractorLandingPage from "./ContractorLandingPage";


const Stack = createNativeStackNavigator();

function WebLandingPageStack() {
    const {colors} = useTheme();

    const myTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            primary: colors.primary[500],
        },
    }

    return (
        <NavigationContainer linking={unauthenticatedLinking} theme={myTheme}>
            <Stack.Navigator screenOptions={{headerShown: false}}>
                <Stack.Screen name="Welcome To PropServ" component={WebLandingPage}/>
                <Stack.Screen name="Login" component={Login}/>
                <Stack.Screen name="Forgot password" component={ForgotPassword}/>
                <Stack.Screen name="Register" component={Register}/>
                <Stack.Screen name="Magic link" component={MagicLink}/>
                <Stack.Screen name="Magic admin link" component={MagicAdminLink}/>
                <Stack.Screen name="Magic azure link" component={MagicAzureLink}/>
                <Stack.Screen name="Estate agent" component={EstateAgentLandingPage}/>
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export function PrimaryPressable(props) {
    const {colors} = useTheme();

    return (
        <Pressable borderRadius={8} onPress={props.onPress}>
            <LinearGradient
                colors={[colors.primary[500], colors.primary[600]]}
                style={{
                    flex: 1,
                    borderRadius: 100,
                }}
                start={[1, 0]}
                end={[0, 1]}>
                <Box px={{base: 4, md: 8}} py={{base: 1, md: 2}}>
                    {props.children}
                </Box>
            </LinearGradient>
        </Pressable>
    );
}

export const Section = props => {
    return (
        <View width={'100%'} bg={props.bg ? props.bg : null} {...props}>
            <View maxW={props.maxW ?? 1000} alignSelf={'center'}>
                <VStack m={{base: 4, md: 16}} my={16} space={16} alignItems={'center'}>
                    {props.children}
                </VStack>
            </View>
        </View>
    )
}

function WebLandingPage() {
    const navigation = useNavigation();

    const [page, setPage] = useState('client');

    return (
        <>
            <HStack bg={'transparent'} position={'absolute'} top={0} zIndex={1000} w={'full'} borderBottomWidth={1}
                    borderBottomColor={'gray.200'}>
                <BlurView intensity={30}
                          style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', padding: 16}}>
                    <HStack space={8} alignItems={'center'}>
                        <Image height={50} width={150} maxW={'80%'} resizeMode={'contain'}
                               display={{base: 'none', md: 'block'}}
                               source={require('../../../assets/PropServBlack.png')}
                               alt={'PropServ Logo'}/>

                        <Image height={50} width={50} maxW={'80%'} resizeMode={'contain'}
                               display={{base: 'block', md: 'none'}}
                               source={require('../../../assets/PropServLogoBlack.png')}
                               alt={'PropServ Logo'}/>

                        {/*<TouchableOpacity onPress={() => setPage('client')}>*/}
                        {/*    <Text bold={page === 'client'} letterSpacing={1.5} fontSize={{base: 'sm', md: 'sm'}} color={'gray.500'}>Movers/Landlords</Text>*/}
                        {/*</TouchableOpacity>*/}

                        {/*<TouchableOpacity onPress={() => setPage('estate_agent')}>*/}
                        {/*    <Text bold={page === 'estate_agent'} letterSpacing={1.5} fontSize={{base: 'sm', md: 'sm'}} color={'gray.500'}>Estate Agents</Text>*/}
                        {/*</TouchableOpacity>*/}

                        {/*<TouchableOpacity onPress={() => setPage('contractor')}>*/}
                        {/*    <Text bold={page === 'contractor'} letterSpacing={1.5} fontSize={{base: 'sm', md: 'sm'}} color={'gray.500'}>Contractors</Text>*/}
                        {/*</TouchableOpacity>*/}
                    </HStack>

                    <HStack space={4} alignItems={'center'}>
                        <PrimaryPressable onPress={() => navigation.navigate('Register')}>
                            <Text bold fontSize={{base: 'sm', md: 'lg'}} color={'white'}>Create an account</Text>
                        </PrimaryPressable>

                        <Pressable px={{base: 4, md: 8}} py={{base: 1, md: 2}} bg={'primary.900:alpha.40'}
                                   borderRadius={100}
                                   onPress={() => navigation.navigate('Login')}
                                   _hover={{bg: 'primary.900:alpha.60'}} _pressed={{bg: 'primary.900:alpha.80'}}>
                            <Text bold fontSize={{base: 'sm', md: 'lg'}} color={'white'}>Login</Text>
                        </Pressable>
                    </HStack>
                </BlurView>
            </HStack>

            <View>
                <Image
                    resizeMode={'cover'}
                    style={{
                        height: Dimensions.get('window').height,
                        width: Dimensions.get('window').width,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        filter: 'hue-rotate(150deg) blur(5px)'
                    }}
                    source={require('../../../assets/bg1.png')}
                />
            </View>

            {page === 'client' && <ClientLandingPage/>}
            {page === 'estate_agent' && <EstateAgentLandingPage/>}
            {page === 'contractor' && <ContractorLandingPage/>}
        </>
    );
}

export default WebLandingPageStack;

import {StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Button, Circle, Heading, HStack, Text, useColorModeValue, useTheme, VStack} from "native-base";
import StatusBadge from "../StatusBadge";
import StarRating from "../StarRating";
import Ionicons from "@expo/vector-icons/Ionicons";
import {format} from "timeago.js";
import * as React from "react";
import {useState} from "react";

const Responses = ({submission, showResponse}) => {
    const {colors} = useTheme();

    const cardBackground = useColorModeValue('light.50', 'dark.50');
    const responseCardBackground = useColorModeValue('light.50', 'dark.100');

    const [selectedCategory, setSelectedCategory] = useState(null);

    const filteredResponses = () => {
        if (selectedCategory === null) {
            return submission.responses;
        }

        return submission.responses.filter(response => response.categories?.map(cat => cat.id).includes(selectedCategory));
    }

    const getBorderColor = (status) => {
        switch (status) {
            case 'accepted':
            case 'complete':
                return 'green.500';
            case 'declined':
                return 'danger.500';
            case 'offered':
                return 'gray.500';
            case 'paid':
            case 'paid_by_estate_agent':
                return 'primary.500';
            default:
                return 'warning.500';
        }
    };

    return (
        <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
            <Heading fontSize={'md'}>Responses</Heading>

            <HStack flexWrap={'wrap'} alignItems={'center'}>
                <TouchableOpacity onPress={() => setSelectedCategory(null)}>
                    <HStack mr={2} my={1} rounded={'2xl'} px={3} py={1} bg={useColorModeValue('light.200', 'dark.200')}
                            space={2} alignItems={'center'} borderColor={'primary.500'}
                            borderWidth={selectedCategory === null ? 2 : 0}>
                        <Text fontSize={'xs'} color={useColorModeValue('black', 'white')}>
                            All Responses
                        </Text>

                        <Text bold fontSize={'md'} color={'primary.500'}>
                            {submission.responses.length}
                        </Text>
                    </HStack>
                </TouchableOpacity>
                {submission.contractor_categories.map((category, index) => (
                    <TouchableOpacity onPress={() => setSelectedCategory(category.id)} key={index}>
                        <HStack mr={2} my={1} rounded={'2xl'} px={3} py={1}
                                bg={useColorModeValue('light.200', 'dark.200')}
                                space={2} alignItems={'center'} borderColor={'primary.500'}
                                borderWidth={selectedCategory === category.id ? 2 : 0}>
                            <Text fontSize={'xs'} color={useColorModeValue('black', 'white')}>
                                {category.name}
                            </Text>
                            <Text bold fontSize={'md'} color={'primary.500'}>
                                {submission.responses.filter(response => response.categories.map(cat => cat.id).includes(category.id)).length}
                            </Text>
                        </HStack>
                    </TouchableOpacity>
                ))}
            </HStack>

            {submission.responses.length === 0 && selectedCategory === null && (
                <Text color={useColorModeValue('gray.500', 'gray.400')}>No responses yet.</Text>
            )}

            {selectedCategory !== null && filteredResponses().length === 0 && (
                <Text color={useColorModeValue('gray.500', 'gray.400')}>No responses for this category.</Text>
            )}

            {filteredResponses().map(response => (
                <TouchableOpacity key={response.id} onPress={() => showResponse(response)}>
                    <VStack
                        borderColor={getBorderColor(response.status)}
                        borderWidth={2}
                        space={2}
                        p={4}
                        bg={responseCardBackground}
                        rounded={'xl'}>
                        <StatusBadge mb={2} status={response.status}/>

                        <HStack space={2} justifyContent={'space-between'}>
                            <VStack space={2}>
                                <HStack alignItems={'center'} space={4} mb={2}>
                                    <Text fontSize={'md'} bold>{response.user.company.name}</Text>
                                    <StarRating rating={response.user.company.star_rating}/>
                                </HStack>

                                {response.categories.length > 0 && (
                                    <HStack alignItems={'center'} space={2}>
                                        <Ionicons name={'hammer-outline'} size={24} color={'gray'}/>
                                        <HStack ml={2} space={2} alignItems={'center'} flexWrap={'wrap'}>
                                            {response.categories.map((service, index) => (
                                                <Box my={1} rounded={'2xl'} px={2} py={1}
                                                     bg={useColorModeValue('light.200', 'dark.200')} space={1}
                                                     alignItems={'center'}
                                                     key={index}>
                                                    <Text fontSize={'xs'} color={useColorModeValue('black', 'white')}>
                                                        {service.name}
                                                    </Text>
                                                </Box>
                                            ))}
                                        </HStack>
                                    </HStack>
                                )}

                                <HStack alignItems={'center'} space={2}>
                                    <Ionicons name={'person-circle-outline'} size={24} color={'gray'}/>
                                    <Text ml={2} fontSize={'md'}>{response.user.name}</Text>
                                </HStack>

                                <HStack alignItems={'center'} space={2}>
                                    <Ionicons name={'calendar-outline'} size={24} color={'gray'}/>
                                    <Text ml={2} fontSize={'md'}>Responded {format(response.created_at)}</Text>
                                </HStack>

                                {response.status !== 'declined' && response.user.company.commission_only !== 1 && (
                                    <HStack space={2} alignItems={'center'}>
                                        <Ionicons name={'cash-outline'} size={24} color={colors.green[500]}/>
                                        <Heading ml={2} fontSize={'xl'}>
                                            £{response.estate_agent_fee.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                        </Heading>
                                        <Text fontSize={'md'}>referral fee</Text>
                                    </HStack>
                                )}

                                {response.status !== 'declined' && response.user.company.commission_only === 1 && (
                                    <HStack space={2} alignItems={'center'}>
                                        <Ionicons name={'cash-outline'} size={24} color={colors.green[500]}/>
                                        {response.estate_agent_fee === null ? (
                                            <Text ml={2} fontSize={'md'}>Commission amount not provided yet</Text>
                                        ) : (
                                            <>
                                                <Heading ml={2} fontSize={'xl'}>
                                                    £{response.estate_agent_fee.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                                </Heading>
                                                <Text fontSize={'md'}>commission amount</Text>
                                            </>
                                        )}
                                    </HStack>
                                )}

                                {response.status === 'complete' && (
                                    <HStack space={2} alignItems={'center'}>
                                        <Ionicons name={'checkmark'} size={24} color={colors.green[500]}/>
                                        <Text fontSize={'md'} ml={2}>Marked as complete</Text>
                                    </HStack>
                                )}
                            </VStack>

                            <Button onPress={() => showResponse(response)} variant={'link'}>View</Button>
                        </HStack>
                    </VStack>
                </TouchableOpacity>
            ))}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default Responses;

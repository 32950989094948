import {Linking, Platform, SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {Box, Heading, HStack, ScrollView, Text, useColorModeValue, VStack} from 'native-base';
import * as React from "react";
import {useAuth} from "../providers/AuthProvider";
import Ionicons from "@expo/vector-icons/Ionicons";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import PaymentSettings from "./estateAgent/PaymentSettings";
import {get, post} from "../services/api";
import MyInformation from "./auth/MyInformation";
import {useEffect, useRef, useState} from "react";
import Appearance from "./Appearance";
import SubscriptionsModal from "../components/contractor/SubscriptionsModal";
import ManageSubscription from "./contractor/ManageSubscription";
import Subscribed from "./contractor/Subscribed";
import Constants from "expo-constants";
import TermsOfBusinessPage from "./TermsOfBusinessPage";
import Subscribe from "./Subscribe";
import CustomNavigationHeader from "../components/CustomNavigationHeader";
import ContractorLocations from "./contractor/ContractorLocations";
import {FontAwesome} from "@expo/vector-icons";
import MyCompany from "./MyCompany";

const Stack = createNativeStackNavigator();

function ProfileStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Profile" component={Profile}/>
            <Stack.Screen name="Payment settings" component={PaymentSettings}/>
            <Stack.Screen name="My Information" component={MyInformation}/>
            <Stack.Screen name="My Company" component={MyCompany}/>
            <Stack.Screen name="Manage subscription" component={ManageSubscription}/>
            <Stack.Screen name="Appearance" component={Appearance}/>
            <Stack.Screen name="Subscribed" component={Subscribed}/>
            <Stack.Screen name="Terms of business" component={TermsOfBusinessPage}/>
            <Stack.Screen name="Subscribe" component={Subscribe}/>
            <Stack.Screen name="Operating areas" component={ContractorLocations}/>
        </Stack.Navigator>
    );
}

const Profile = props => {
    const {userDetails, clearAuth} = useAuth();

    const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false);
    const [stripeSetupLink, setStripeSetupLink] = useState(null);

    useEffect(() => {
        if ((userDetails.type === 'contractor' || userDetails.type === 'estate_agent') && !userDetails.stripe_onboarding_complete && !userDetails.company?.stripe_onboarding_complete) {
            get('/stripe-setup-link').then(response => {
                setStripeSetupLink(response.url);
            });
        }
    }, []);

    const completeStripeOnboarding = async () => {
        get('/stripe-setup-link').then(response => {
            Linking.openURL(response.url);
        });
    }

    const subscribe = async () => {
        post('/contractor/subscribe').then(response => {
            Linking.openURL(response.url);
        }).catch(error => {
            if (error.response?.data?.code === 'already_subscribed') {
                alert('You are already subscribed, please refresh the page');
            }
        })
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    {(userDetails.type === 'contractor' || userDetails.type === 'estate_agent') && !userDetails.stripe_onboarding_complete && !userDetails.company?.stripe_onboarding_complete && (
                        <TouchableOpacity disabled={!stripeSetupLink} onPress={() => Linking.openURL(stripeSetupLink)}>
                            <HStack space={4} alignItems={'center'} bg={useColorModeValue('light.50', 'dark.50')}
                                    rounded={'xl'} p={4} borderWidth={2}
                                    borderColor={'red.500'}>
                                <Box bg={'red.500'} rounded={'lg'} p={2}>
                                    <Ionicons name={'warning-outline'} size={24} color={'white'}/>
                                </Box>
                                <VStack flex={1}>
                                    <Text fontSize={'md'}>Complete Stripe onboarding</Text>
                                    {userDetails.type === 'estate_agent' && (
                                        <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                            Stripe onboarding is required to receive referral fees from PropServ
                                        </Text>
                                    )}
                                </VStack>
                            </HStack>
                        </TouchableOpacity>
                    )}

                    {userDetails?.company?.type === 'contractor' && Platform.OS === 'web' && userDetails?.company?.has_active_subscription === false && (
                        <TouchableOpacity onPress={() => props.navigation.navigate('Subscribe')}>
                            <HStack
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                bg={useColorModeValue('light.50', 'dark.50')}
                                rounded={'xl'}
                                p={4}
                                borderWidth={2}
                                borderColor={'yellow.600'}>
                                <HStack alignItems={'center'} space={4}>
                                    <Box bg={'yellow.600'} rounded={'lg'} p={2}>
                                        <Ionicons name={'shield-checkmark-outline'} size={24} color={'white'}/>
                                    </Box>
                                    <VStack>
                                        <Text bold fontSize={'md'}>Subscribe</Text>
                                        <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                            Subscribe to PropServ
                                        </Text>
                                    </VStack>
                                </HStack>
                                <Ionicons name={'chevron-forward'} size={24} color={'gray'}/>
                            </HStack>
                        </TouchableOpacity>
                    )}

                    {userDetails?.company?.type === 'contractor' && userDetails?.company?.has_active_subscription === true && (
                        <HStack
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            bg={useColorModeValue('light.50', 'dark.50')}
                            rounded={'xl'}
                            p={4}
                            borderWidth={2}
                            borderColor={'primary.500'}>
                            <HStack alignItems={'center'} space={4} flex={1}>
                                <Box bg={'primary.500'} rounded={'lg'} p={2}>
                                    <Ionicons name={'shield-checkmark-outline'} size={24} color={'white'}/>
                                </Box>
                                <VStack flex={1}>
                                    <Text bold fontSize={'md'}>You have joined the beta</Text>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                        Thanks for joining the beta! You have access to all features for the duration of
                                        the beta.
                                    </Text>
                                </VStack>
                            </HStack>
                        </HStack>
                    )}

                    {/*{userDetails?.company?.type === 'contractor' && Platform.OS === 'web' && userDetails?.company?.has_active_subscription === true && (*/}
                    {/*    <TouchableOpacity onPress={() => props.navigation.navigate('Manage subscription')}>*/}
                    {/*        <HStack p={4} alignItems={'center'} space={4} bg={useColorModeValue('light.50', 'dark.50')}*/}
                    {/*                rounded={'xl'}>*/}
                    {/*            <Box bg={'yellow.600'} rounded={'lg'} p={2}>*/}
                    {/*                <Ionicons name={'shield-checkmark-outline'} size={24} color={'white'}/>*/}
                    {/*            </Box>*/}
                    {/*            <VStack flex={1}>*/}
                    {/*                <Text bold fontSize={'md'}>*/}
                    {/*                    {userDetails?.company?.active_subscription?.stripe_cancel_at ? 'Your subscription is ending' : 'You\'re subscribed'}*/}
                    {/*                </Text>*/}
                    {/*                {userDetails?.company?.active_subscription?.stripe_cancel_at && (*/}
                    {/*                    <Text color={'red.400'} fontSize={'md'}>Your subscription will end*/}
                    {/*                        on {userDetails?.company?.active_subscription?.stripe_cancel_at}</Text>*/}
                    {/*                )}*/}
                    {/*                <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Manage your*/}
                    {/*                    subscription</Text>*/}
                    {/*            </VStack>*/}
                    {/*        </HStack>*/}
                    {/*    </TouchableOpacity>*/}
                    {/*)}*/}

                    {userDetails?.company?.type === 'contractor' && Platform.OS !== 'web' && (
                        <TouchableOpacity
                            onPress={() => Linking.openURL(Constants?.manifest?.extra?.env?.EXPO_PUBLIC_BASE_URL || 'https://propserv.co')}>
                            <HStack p={4} alignItems={'center'} space={4} bg={useColorModeValue('light.50', 'dark.50')}
                                    rounded={'xl'}>
                                <Box bg={'yellow.600'} rounded={'lg'} p={2}>
                                    <Ionicons name={'shield-checkmark-outline'} size={24} color={'white'}/>
                                </Box>
                                <VStack flex={1}>
                                    <Text bold fontSize={'md'}>
                                        {userDetails?.company?.has_active_subscription === true ? 'You\'re subscribed' : 'Subscribe'}
                                    </Text>
                                    <Text selectable color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                        Unfortunately, you cannot manage your subscription on this device. Manage your
                                        subscription online.
                                    </Text>
                                    <Text bold mt={2} color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                        {Constants?.manifest?.extra?.env?.EXPO_PUBLIC_BASE_URL || 'https://propserv.co'}
                                    </Text>
                                </VStack>
                            </HStack>
                        </TouchableOpacity>
                    )}

                    {userDetails.company && (
                        <TouchableOpacity disabled={userDetails.company.created_by !== userDetails.id} onPress={() => props.navigation.navigate('My Company')}>
                            <HStack
                                alignItems={'center'}
                                bg={useColorModeValue('light.50', 'dark.50')}
                                rounded={'xl'}
                                p={4}
                                space={4}>
                                <Box bg={'primary.500'} rounded={'lg'} p={2}>
                                    <Ionicons name={'business-outline'} size={24} color={'white'}/>
                                </Box>
                                <VStack>
                                    <Text fontSize={'md'}>{userDetails.company.name}</Text>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'md'}>{userDetails.company.email}</Text>
                                    <HStack space={1} alignItems={'center'}>
                                        <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                            Invite code:
                                        </Text>
                                        <Text bold color={useColorModeValue('black', 'white')} fontSize={'md'}>
                                            {userDetails.company.referral_code}
                                        </Text>
                                    </HStack>
                                </VStack>
                                {userDetails.company.created_by === userDetails.id && (
                                    <Ionicons
                                        style={{marginLeft: 'auto'}}
                                        name={'chevron-forward'}
                                        size={24}
                                        color={'gray'}/>
                                )}
                            </HStack>
                        </TouchableOpacity>
                    )}

                    <TouchableOpacity onPress={() => props.navigation.navigate('My Information')}>
                        <HStack
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            bg={useColorModeValue('light.50', 'dark.50')}
                            rounded={'xl'}
                            p={4}>
                            <HStack alignItems={'center'} space={4}>
                                <Box bg={'primary.500'} rounded={'lg'} p={2}>
                                    <Ionicons name={'person-outline'} size={24} color={'white'}/>
                                </Box>
                                <VStack>
                                    <Text fontSize={'md'}>My information</Text>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'md'}>{userDetails.name}</Text>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'md'}>{userDetails.email}</Text>
                                </VStack>
                            </HStack>
                            <Ionicons name={'chevron-forward'} size={24} color={'gray'}/>
                        </HStack>
                    </TouchableOpacity>

                    {userDetails?.company?.type === 'contractor' && (
                        <TouchableOpacity onPress={() => props.navigation.navigate('Operating areas')}>
                            <HStack
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                bg={useColorModeValue('light.50', 'dark.50')}
                                rounded={'xl'}
                                p={4}>
                                <HStack alignItems={'center'} space={4}>
                                    <Box bg={'primary.500'} rounded={'lg'} p={2}>
                                        <Ionicons name={'location-outline'} size={24} color={'white'}/>
                                    </Box>
                                    <VStack>
                                        <Text fontSize={'md'}>Operating areas</Text>
                                        <Text color={useColorModeValue('gray.500', 'gray.400')}
                                              fontSize={'md'}>
                                            Manage the areas you operate in
                                        </Text>
                                    </VStack>
                                </HStack>
                                <Ionicons name={'chevron-forward'} size={24} color={'gray'}/>
                            </HStack>
                        </TouchableOpacity>
                    )}

                    <HStack p={4} alignItems={'center'} space={4} bg={useColorModeValue('light.50', 'dark.50')}
                            rounded={'xl'}>
                        <Box bg={'warning.500'} rounded={'lg'} p={2}>
                            <Ionicons name={'information-circle-outline'} size={24} color={'white'}/>
                        </Box>
                        <VStack flex={1}>
                            <Text fontSize={'md'}>Support</Text>
                            <HStack space={4} alignItems={'center'} flexWrap={'wrap'}>
                                <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                    Need help? Contact us on WhatsApp
                                </Text>
                                <Box display={{base: 'none', md: 'flex'}}>
                                    <TouchableOpacity onPress={() => {
                                        Linking.openURL('https://wa.me/447466197009')
                                    }}>
                                        <HStack space={2} bg={'#25D366'} rounded={'full'} p={2} px={4}>
                                            <FontAwesome name={'whatsapp'} size={24} color={'white'}/>
                                            <Text selectable bold color={'white'} fontSize={'md'}>
                                                {' '}+44 7466 197009
                                            </Text>
                                        </HStack>
                                    </TouchableOpacity>
                                </Box>
                            </HStack>
                            <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                Or you can email us at
                                <Text selectable bold color={useColorModeValue('black', 'white')} fontSize={'md'}>
                                    {' '}info@propserv.co
                                </Text>
                            </Text>
                            <Box display={{md: 'none'}} alignSelf={'flex-start'} mt={2}>
                                <TouchableOpacity onPress={() => {
                                    Linking.openURL('https://wa.me/447466197009')
                                }}>
                                    <HStack space={2} bg={'#25D366'} rounded={'full'} p={2} px={4}>
                                        <FontAwesome name={'whatsapp'} size={24} color={'white'}/>
                                        <Text selectable bold color={'white'} fontSize={'md'}>
                                            {' '}+44 7466 197009
                                        </Text>
                                    </HStack>
                                </TouchableOpacity>
                            </Box>
                        </VStack>
                    </HStack>

                    <TouchableOpacity onPress={() => props.navigation.navigate('Terms of business')}>
                        <HStack
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            bg={useColorModeValue('light.50', 'dark.50')}
                            rounded={'xl'}
                            p={4}>
                            <HStack alignItems={'center'} space={4}>
                                <Box bg={'warning.500'} rounded={'lg'} p={2}>
                                    <Ionicons name={'document-text-outline'} size={24} color={'white'}/>
                                </Box>
                                <Text fontSize={'md'}>Terms of business</Text>
                            </HStack>
                            <Ionicons name={'chevron-forward'} size={24} color={'gray'}/>
                        </HStack>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => props.navigation.navigate('Appearance')}>
                        <HStack
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            bg={useColorModeValue('light.50', 'dark.50')}
                            rounded={'xl'}
                            p={4}>
                            <HStack alignItems={'center'} space={4}>
                                <Box bg={'green.600'} rounded={'lg'} p={2}>
                                    <Ionicons name={'color-palette-outline'} size={24} color={'white'}/>
                                </Box>
                                <VStack>
                                    <Text fontSize={'md'}>Appearance</Text>
                                </VStack>
                            </HStack>
                            <Ionicons name={'chevron-forward'} size={24} color={'gray'}/>
                        </HStack>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={clearAuth}>
                        <HStack p={4} alignItems={'center'} space={4} bg={useColorModeValue('light.50', 'dark.50')}
                                rounded={'xl'}>
                            <Box bg={'red.600'} rounded={'lg'} p={2}>
                                <Ionicons name={'log-out-outline'} size={24} color={'white'}/>
                            </Box>
                            <Text fontSize={'md'}>Logout</Text>
                        </HStack>
                    </TouchableOpacity>
                </VStack>
            </ScrollView>

            {showSubscriptionsModal && (
                <SubscriptionsModal visible={showSubscriptionsModal} onClose={() => setShowSubscriptionsModal(false)}/>
            )}
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default ProfileStack;

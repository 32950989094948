import {StyleSheet} from 'react-native';
import * as React from "react";
import {Button, Heading, Image, Input, Spinner, Text, View, VStack} from "native-base";
import {useState} from "react";
import {post} from "../../services/api";

const ForgotPassword = props => {

    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [code, setCode] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const [submittedCode, setSubmittedCode] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    const submit = () => {
        setSubmitting(true);
        setError(null);

        post('/forgot-password', {email: email}).then(response => {
            console.log(response);
        }).catch(error => {
            if (error.response.data.errors?.email) setError(error.response.data.errors.email[0]);
            else setError(error.response.data.error);
        }).finally(() => {
            setSubmitting(false);
            setSubmitted(true);
        });
    }

    const resetPassword = () => {
        setSubmitting(true);
        setError(null);

        post('/forgot-password/reset', {
            email,
            code,
            password,
            password_confirmation: confirmPassword
        }).then(response => {
            if (response.error === false) setResetSuccess(true);
            else setError(response.message);
        }).catch(error => {
            if (error.response.data.error) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
        }).finally(() => {
            setSubmitting(false);
            setSubmittedCode(true);
        });
    }

    if (resetSuccess) {
        return (
            <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}} maxWidth={'500px'}
                  alignSelf={'center'} bg={'white'}>
                <VStack space={4}>
                    <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                           source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                    <Heading textAlign={'center'} color={'black'}>Password reset successful</Heading>

                    <Text fontSize={'md'} textAlign={'center'} color={'gray.500'}>
                        Your password has been reset successfully. You can now login with your new password.
                    </Text>

                    <Button variant={'link'} onPress={() => props.navigation.navigate('Login')}>
                        Login
                    </Button>
                </VStack>
            </View>
        )
    }

    return (
        <View p={4} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}} maxWidth={'500px'}
              alignSelf={'center'} bg={'white'}>
            <VStack space={4}>
                <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                       source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                <Heading textAlign={'center'} color={'black'}>Reset your password</Heading>

                <Text fontSize={'md'} textAlign={'center'} color={'gray.500'}>
                    Enter your email address and we will send you a one time code.
                </Text>

                <Input color={'black'} fontSize={'md'} size={'xl'} onChangeText={text => setEmail(text)} placeholder="Email"
                       minW={300}/>

                {submitted && <Text color={'success.500'}>If the email exists, we will send you a one time code.</Text>}

                {submitted && (
                    <>
                        <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'} placeholder="One time code" minW={300}
                               onChangeText={text => setCode(text)}/>

                        <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'} placeholder="New password" minW={300}
                               onChangeText={text => setPassword(text)}/>

                        <Input color={'black'} fontSize={'md'} size={'xl'} type={'password'} placeholder="Confirm new password"
                               minW={300}
                               onChangeText={text => setConfirmPassword(text)}/>
                    </>
                )}

                {error && <Text color={'error.500'}>{error}</Text>}

                {!submitted && (
                    <Button disabled={submitting} onPress={submit}>
                        {submitting ? <Spinner size={'sm'} color={'white'}/> : 'Submit'}
                    </Button>
                )}

                {submitted && (
                    <Button disabled={submitting} onPress={resetPassword}>
                        {submitting ? <Spinner size={'sm'} color={'white'}/> : 'Reset password'}
                    </Button>
                )}

                <Button variant={'link'} onPress={() => props.navigation.navigate('Login')}>
                    Have an account? Login
                </Button>
            </VStack>
        </View>
    );
};

const styles = StyleSheet.create({});

export default ForgotPassword;

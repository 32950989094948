import {Platform, SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    Heading,
    Input,
    ScrollView,
    Text, useColorModeValue,
    useTheme,
    VStack
} from "native-base";
import * as React from "react";
import {useAuth} from "../../providers/AuthProvider";
import {useState} from "react";
import {put} from "../../services/api";

const MyInformation = props => {
    const {userDetails, setUserDetails} = useAuth();
    const navigation = props.navigation;

    const [name, setName] = useState(userDetails.name);
    const [email, setEmail] = useState(userDetails.email);
    const [phoneNumber, setPhoneNumber] = useState(userDetails.phone_number);

    const [editing, setEditing] = useState(false);

    navigation.setOptions({
        headerRight: () => (
            <TouchableOpacity disabled={editing} onPress={() => setEditing(true)}>
                <Text mr={Platform.OS === 'web' ? 4 : 0} color={editing ? 'gray.500' : 'primary.500'}
                      fontSize={'md'}>Edit</Text>
            </TouchableOpacity>
        ),
    });

    const updateMyInformation = () => {
        put('/my-information', {
            name,
            email,
            phone_number: phoneNumber,
        }).then(response => {
            setUserDetails(response.user);
        });

        setEditing(false);
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    {editing && (
                        <>
                            <VStack space={4} p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Name</Text>
                                    <Input fontSize={'md'} size={'xl'} value={name}
                                           onChangeText={text => setName(text)}/>
                                </VStack>

                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Email</Text>
                                    <Input fontSize={'md'} size={'xl'} value={email}
                                           onChangeText={text => setEmail(text)}/>
                                </VStack>

                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Phone
                                        number</Text>
                                    <Input fontSize={'md'} size={'xl'} value={phoneNumber}
                                           onChangeText={text => setPhoneNumber(text)}/>
                                </VStack>
                            </VStack>

                            <Button onPress={updateMyInformation}>Save</Button>
                        </>
                    )}

                    {!editing && (
                        <>
                            <VStack space={4} p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Name</Text>
                                    <Text bold fontSize={'lg'}>{userDetails.name}</Text>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Email</Text>
                                    <Text bold fontSize={'lg'}>{userDetails.email}</Text>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Phone
                                        number</Text>
                                    <Text bold fontSize={'lg'}>{userDetails.phone_number}</Text>
                                </VStack>
                            </VStack>

                            <Button onPress={() => setEditing(true)}>Edit</Button>
                        </>
                    )}
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default MyInformation;

import {SafeAreaView, StyleSheet} from 'react-native';
import {
    Box,
    Button, Center, Heading, HStack, Image,
    Input,
    Modal,
    ScrollView, Spinner, Text,
    TextArea, useColorMode, useColorModeValue, useTheme,
    VStack
} from 'native-base';
import * as React from "react";
import {useAuth} from "../providers/AuthProvider";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import CustomNavigationHeader from "../components/CustomNavigationHeader";
import {get, post} from "../services/api";
import {useEffect} from "react";
import Ionicons from "@expo/vector-icons/Ionicons";

const Stack = createNativeStackNavigator();

function BlogStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Blog" component={Blog}/>
        </Stack.Navigator>
    );
}

const Blog = props => {
    const {userDetails, clearAuth} = useAuth();

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    const [createPostModalOpen, setCreatePostModalOpen] = React.useState(false);

    const [postTitle, setPostTitle] = React.useState('');
    const [postBody, setPostBody] = React.useState('');

    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const getPosts = async () => {
        let response = await get('/blog/articles');

        response.forEach((article) => {
            article.expanded = false;
        });

        setPosts(response);
        setLoading(false);
    }

    const createPost = async () => {
        await post('/blog/articles', {title: postTitle, body: postBody});
        setCreatePostModalOpen(false);
        getPosts();
    }

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <SafeAreaView style={{flex: 1}}>
            <Center w={'100%'} h={'100%'} p={8}>
                <Heading size={'xl'}>Coming soon</Heading>
                <Text mt={4} textAlign={'center'}>Blog posts will be available soon. Contact info@propserv.co if you are interested in contributing.</Text>
            </Center>
        </SafeAreaView>
    );

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    <Button onPress={() => setCreatePostModalOpen(true)}>Create post</Button>

                    {loading && <Spinner mt={4} size={'lg'}/>}

                    {!loading && posts.length === 0 &&
                        <VStack space={2}>
                            <Heading size={'md'}>No posts yet</Heading>
                            <Text>Create a post to get started</Text>
                        </VStack>
                    }

                    {posts.map(post => (
                        <VStack key={post.id} space={2} bg={useColorModeValue('light.50', 'dark.50')} p={4}
                                rounded={'lg'} borderWidth={post.authorable_type === "App\\Models\\Admin" ? 2 : 0}
                                borderColor={'primary.500'}>
                            <HStack justifyContent={'space-between'} alignItems={'center'}>
                                {post.authorable_type === "App\\Models\\Admin" &&
                                    <HStack space={2} alignItems={'center'}>
                                        {colorMode === 'dark' && (
                                            <Image height={50} width={150} maxW={'80%'} resizeMode={'contain'}
                                                   source={require('../../assets/PropServ.png')}
                                                   alt={'PropServ Logo'}/>
                                        )}

                                        {colorMode === 'light' && (
                                            <Image height={50} width={150} maxW={'80%'} resizeMode={'contain'}
                                                   source={require('../../assets/PropServBlack.png')}
                                                   alt={'PropServ Logo'}/>
                                        )}

                                        <Box px={2} bg={'primary.500'} rounded={'md'}>
                                            <Text fontSize={'xs'} bold color={'gray.100'}>ADMIN</Text>
                                        </Box>
                                    </HStack>
                                }

                                {post.authorable_type === "App\\Models\\User" &&
                                    <HStack space={1} alignItems={'center'}>
                                        <Ionicons name={'person-circle-outline'} size={24}
                                                  color={useColorModeValue(colors.gray[500], colors.gray[400])}/>
                                        <Heading size={'sm'}>{post.authorable.name}</Heading>
                                        <Box ml={2} px={2} bg={'primary.500'} rounded={'md'}>
                                            <Text fontSize={'xs'} bold
                                                  color={'gray.100'}>{post.authorable.type.replace('_', ' ').toUpperCase()}</Text>
                                        </Box>
                                    </HStack>
                                }
                                <Text color={useColorModeValue('gray.500', 'gray.400')}>
                                    {(new Date(post.created_at)).toLocaleDateString()} {(new Date(post.created_at)).toLocaleTimeString()}
                                </Text>
                            </HStack>

                            <VStack space={4} bg={useColorModeValue('light.100', 'dark.100')} p={4} rounded={'lg'}>
                                <Heading fontSize={'xl'}>{post.title}</Heading>
                                {post.body.length > 300 && !post.expanded &&
                                    <Text>{post.body.substring(0, 300)}...</Text>
                                }

                                {(post.body.length <= 300 || post.expanded) && <Text>{post.body}</Text>}

                                {post.body.length > 300 &&
                                    <Button alignSelf={'flex-start'} variant={'link'} onPress={() => {
                                        post.expanded = !post.expanded;
                                        setPosts([...posts]);
                                    }}>
                                        {post.expanded ? 'Show less' : 'Show more'}
                                    </Button>
                                }
                            </VStack>
                        </VStack>
                    ))}
                </VStack>
            </ScrollView>

            <Modal isKeyboardDismissable={false} isOpen={createPostModalOpen}
                   onClose={() => setCreatePostModalOpen(false)}>
                <Modal.Content maxWidth="500px">
                    <Modal.CloseButton/>
                    <Modal.Header>Create a blog post</Modal.Header>
                    <Modal.Body>
                        <VStack space={4}>
                            <Input placeholder="Title" value={postTitle} onChangeText={setPostTitle}/>
                            <TextArea placeholder="Body" value={postBody} onChangeText={setPostBody}/>
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button px={5} onPress={createPost} isDisabled={postTitle === '' || postBody === ''}>
                                Post
                            </Button>
                            <Button variant="ghost" colorScheme="gray" onPress={() => setCreatePostModalOpen(false)}>
                                Close
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default BlogStack;

import {Platform, SafeAreaView, StyleSheet, TouchableOpacity} from 'react-native';
import {
    Button,
    HStack, Image,
    Input,
    ScrollView,
    Text,
    useColorModeValue,
    useTheme,
    VStack
} from "native-base";
import * as React from "react";
import {useAuth} from "../providers/AuthProvider";
import {useEffect, useState} from "react";
import {post, put} from "../services/api";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as ImagePicker from "expo-image-picker";
import * as FileSystem from "expo-file-system";
import ColorPicker, {HueSlider, InputWidget, OpacitySlider, Panel1, Preview, Swatches} from "reanimated-color-picker";

const MyCompany = props => {
    const {userDetails, setUserDetails} = useAuth();
    const {colors} = useTheme();
    const navigation = props.navigation;

    const [name, setName] = useState(userDetails.company.name);
    const [email, setEmail] = useState(userDetails.company.email);
    const [phoneNumber, setPhoneNumber] = useState(userDetails.company.phone_number);
    const [referralCode, setReferralCode] = useState(userDetails.company.referral_code);

    const [brandColor, setBrandColor] = useState(userDetails.company.brand_color);

    const [lightLogoAspectRatio, setLightLogoAspectRatio] = useState(null);
    const [darkLogoAspectRatio, setDarkLogoAspectRatio] = useState(null);

    const [uploadedLightModeLogo, setUploadedLightModeLogo] = useState(null);
    const [uploadedLightModeLogoAspectRatio, setUploadedLightModeLogoAspectRatio] = useState(null);
    const [uploadedDarkModeLogo, setUploadedDarkModeLogo] = useState(null);
    const [uploadedDarkModeLogoAspectRatio, setUploadedDarkModeLogoAspectRatio] = useState(null);

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (userDetails.company.light_logo_path) {
            Image.getSize(userDetails.company.light_logo_path, (width, height) => {
                setLightLogoAspectRatio(height / width);
            });
        }

        if (userDetails.company.dark_logo_path) {
            Image.getSize(userDetails.company.dark_logo_path, (width, height) => {
                setDarkLogoAspectRatio(height / width);
            });
        }
    }, [userDetails.company.light_logo_path, userDetails.company.dark_logo_path]);

    const uploadImage = async (light = true) => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images, quality: 1, allowsMultipleSelection: false
        });

        if (!result.canceled) {
            if (light) {
                setUploadedLightModeLogo(result.assets[0]);
                setUploadedLightModeLogoAspectRatio(result.assets[0].height / result.assets[0].width);
            } else {
                setUploadedDarkModeLogo(result.assets[0]);
                setUploadedDarkModeLogoAspectRatio(result.assets[0].height / result.assets[0].width);
            }
        }
    }

    navigation.setOptions({
        headerRight: () => (
            <TouchableOpacity disabled={editing} onPress={() => setEditing(true)}>
                <Text mr={Platform.OS === 'web' ? 4 : 0} color={editing ? 'gray.500' : 'primary.500'}
                      fontSize={'md'}>Edit</Text>
            </TouchableOpacity>
        ),
    });

    const updateMyCompany = async () => {
        let formData = new FormData();

        if (uploadedLightModeLogo) {
            if (Platform.OS !== 'web' && uploadedLightModeLogo.id == null) {
                try {
                    uploadedLightModeLogo.contents = await FileSystem.readAsStringAsync(uploadedLightModeLogo.uri, {encoding: FileSystem.EncodingType.Base64});
                } catch (error) {
                    console.error("Error reading file:", error);
                }
            }
            formData.append(`light_mode_logo`, JSON.stringify(uploadedLightModeLogo));
        }

        if (uploadedDarkModeLogo) {
            if (Platform.OS !== 'web' && uploadedDarkModeLogo.id == null) {
                try {
                    uploadedDarkModeLogo.contents = await FileSystem.readAsStringAsync(uploadedDarkModeLogo.uri, {encoding: FileSystem.EncodingType.Base64});
                } catch (error) {
                    console.error("Error reading file:", error);
                }
            }
            formData.append(`dark_mode_logo`, JSON.stringify(uploadedDarkModeLogo));
        }

        if (brandColor) {
            formData.append('brand_color', brandColor);
        }

        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone_number', phoneNumber);

        post('/my-company', formData).then(response => {
            setUserDetails(response.user);
        });

        setEditing(false);
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView p={4} showsVerticalScrollIndicator={false}>
                <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                    {editing && (
                        <>
                            <VStack space={4} p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Name</Text>
                                    <Input fontSize={'md'} size={'xl'} value={name}
                                           onChangeText={text => setName(text)}/>
                                </VStack>

                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Email</Text>
                                    <Input fontSize={'md'} size={'xl'} value={email}
                                           onChangeText={text => setEmail(text)}/>
                                </VStack>

                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Phone
                                        number</Text>
                                    <Input fontSize={'md'} size={'xl'} value={phoneNumber}
                                           onChangeText={text => setPhoneNumber(text)}/>
                                </VStack>

                                <VStack space={1}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Referral
                                        code</Text>
                                    <Input isDisabled fontSize={'md'} size={'xl'} value={referralCode}/>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'md'}>Branding</Text>
                                    <HStack space={4} flexWrap={'wrap'} style={{rowGap: 16}}>
                                        <TouchableOpacity onPress={() => uploadImage(true)}>
                                            <VStack bg={'light.100'} minW={200} minH={200} rounded={'xl'} p={4}
                                                    justifyContent={'space-evenly'} alignItems={'center'}>
                                                {uploadedLightModeLogo ? (
                                                    <Image source={{uri: uploadedLightModeLogo.uri}}
                                                           alt={'Light mode logo'}
                                                           w={200} h={200 * uploadedLightModeLogoAspectRatio}/>
                                                ) : userDetails.company.light_logo_path ? (
                                                    <Image source={{uri: userDetails.company.light_logo_path}}
                                                           alt={'Light mode logo'}
                                                           w={200} h={200 * lightLogoAspectRatio}/>
                                                ) : (
                                                    <Ionicons name={'image-outline'} size={80}
                                                              color={colors.gray[300]}/>
                                                )}
                                                <Text mt={4} textAlign={'center'} color={'black'} bold>Light mode
                                                    logo</Text>
                                            </VStack>
                                        </TouchableOpacity>

                                        <TouchableOpacity onPress={() => uploadImage(false)}>
                                            <VStack bg={'dark.100'} minW={200} minH={200} rounded={'xl'} p={4}
                                                    justifyContent={'space-evenly'} alignItems={'center'}>
                                                {uploadedDarkModeLogo ? (
                                                    <Image source={{uri: uploadedDarkModeLogo.uri}}
                                                           alt={'Dark mode logo'}
                                                           w={200} h={200 * uploadedDarkModeLogoAspectRatio}/>
                                                ) : userDetails.company.dark_logo_path ? (
                                                    <Image source={{uri: userDetails.company.dark_logo_path}}
                                                           alt={'Dark mode logo'}
                                                           w={200} h={200 * darkLogoAspectRatio}/>
                                                ) : (
                                                    <Ionicons name={'image-outline'} size={80}
                                                              color={colors.gray[500]}/>
                                                )}
                                                <Text mt={4} textAlign={'center'} color={'white'} bold>Dark mode
                                                    logo</Text>
                                            </VStack>
                                        </TouchableOpacity>

                                        <VStack flex={1} bg={useColorModeValue('light.100', 'dark.100')} rounded={'xl'} pb={4} minW={'xs'}>
                                            <ColorPicker value={brandColor ?? 'blue'} style={{flex: 1}} onChange={(color) => setBrandColor(color.hex)}>
                                                <VStack space={2}>
                                                    <Panel1/>
                                                    <VStack px={2} space={2}>
                                                        <HueSlider/>
                                                        <InputWidget inputStyle={{color: useColorModeValue('black', 'white')}}/>
                                                    </VStack>
                                                </VStack>
                                            </ColorPicker>
                                            <Text mt={4} textAlign={'center'} bold>Primary brand colour</Text>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            </VStack>

                            <Button onPress={updateMyCompany}>Save</Button>
                        </>
                    )}

                    {!editing && (
                        <>
                            <VStack space={4} p={4} bg={useColorModeValue('light.50', 'dark.50')} rounded={'xl'}>
                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Name</Text>
                                    <Text selectable fontSize={'lg'}>{userDetails.company.name}</Text>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Email</Text>
                                    <Text selectable fontSize={'lg'}>{userDetails.company.email}</Text>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Phone
                                        number</Text>
                                    <Text selectable fontSize={'lg'}>{userDetails.company.phone_number}</Text>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>Invite
                                        code</Text>
                                    <Text selectable fontSize={'lg'}>{userDetails.company.referral_code}</Text>
                                </VStack>

                                <VStack>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'md'}>Branding</Text>
                                    <HStack space={4} flexWrap={'wrap'} style={{rowGap: 16}}>
                                        <VStack bg={'light.100'} minW={200} minH={200} rounded={'xl'} p={4}
                                                justifyContent={'space-evenly'} alignItems={'center'}>
                                            {userDetails.company.light_logo_path ? (
                                                <Image source={{uri: userDetails.company.light_logo_path}}
                                                       alt={'Light mode logo'}
                                                       w={200} h={200 * lightLogoAspectRatio}/>
                                            ) : (
                                                <Ionicons name={'image-outline'} size={80}
                                                          color={colors.gray[300]}/>
                                            )}
                                            <Text mt={4} textAlign={'center'} color={'black'} bold>Light mode
                                                logo</Text>
                                        </VStack>
                                        <VStack bg={'dark.100'} minW={200} minH={200} rounded={'xl'} p={4}
                                                justifyContent={'space-evenly'} alignItems={'center'}>
                                            {userDetails.company.dark_logo_path ? (
                                                <Image source={{uri: userDetails.company.dark_logo_path}}
                                                       alt={'Dark mode logo'}
                                                       w={200} h={200 * darkLogoAspectRatio}/>
                                            ) : (
                                                <Ionicons name={'image-outline'} size={80}
                                                          color={colors.gray[300]}/>
                                            )}
                                            <Text mt={4} textAlign={'center'} color={'white'} bold>Dark mode logo</Text>
                                        </VStack>
                                        <VStack bg={userDetails.company.brand_color ?? useColorModeValue('light.100', 'dark.100')} minW={200} minH={200} rounded={'xl'} p={4}
                                                justifyContent={'space-evenly'} alignItems={'center'}>
                                            <Text textAlign={'center'} color={'white'} bold>Primary brand colour</Text>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            </VStack>

                            <Button onPress={() => setEditing(true)}>Edit</Button>
                        </>
                    )}
                </VStack>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({});

export default MyCompany;

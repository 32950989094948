import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Box,
    Center,
    Heading,
    HStack, ScrollView,
    Spacer,
    Spinner,
    Text, Tooltip, useColorModeValue, useTheme,
    View,
    VStack
} from "native-base";
import {useEffect, useState} from "react";
import {get} from "../../services/api";
import * as React from "react";
import CustomNavigationHeader from "../../components/CustomNavigationHeader";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {BarChart, Grid, YAxis} from "react-native-svg-charts";
import {Picker} from "react-native-web";

const Stack = createNativeStackNavigator();

function AnalyticsStack() {
    return (
        <Stack.Navigator screenOptions={{header: (props) => <CustomNavigationHeader {...props}/>}}>
            <Stack.Screen name="Analytics" component={Analytics}/>
        </Stack.Navigator>
    );
}

const AnalyticsSection = props => {
    return (
        <VStack space={2} flex={1} justifyContent={'center'} minW={300} my={4}>
            <Text textAlign={'center'} color={useColorModeValue('gray.500', 'gray.400')}>
                {props.title}
            </Text>

            <Heading textAlign={'center'}>
                {props.currency && '£'}{Math.round(props.value).toLocaleString()}
            </Heading>

            {props.subtitle && (
                <Text textAlign={'center'} color={useColorModeValue('gray.500', 'gray.400')}>
                    {props.subtitle}
                </Text>
            )}

            <Box alignSelf={'center'} bg={props.change >= 0 ? 'green.500' : 'red.500'} rounded={'lg'} px={2}>
                <Text color={'white'}>
                    {props.change > 0 && '+'}{Math.round(props.change)}{props.changePercentage && '%'}
                </Text>
            </Box>
        </VStack>
    )
}

const TimePeriodPicker = props => {
    const {colors} = useTheme();
    const grayText = useColorModeValue(colors.gray[500], colors.gray[400]);

    const timePeriodOptions = [
        {name: 'Last 7 days', value: 7},
        {name: 'Last 30 days', value: 30},
        {name: 'Last 90 days', value: 90},
        {name: 'Last 365 days', value: 365},
        {name: 'All time', value: 'all'},
    ];

    return (
        <Picker
            style={{
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                color: grayText,
                fontSize: 12,
                borderRadius: 8,
                ...props.style,
            }}
            selectedValue={props.selectedTimePeriod}
            onValueChange={props.setSelectedTimePeriod}
            placeholder="Select an option">
            {timePeriodOptions.map((option, index) => (
                <Picker.Item key={index} label={option.name} value={option.value}/>
            ))}
        </Picker>
    );
}

const Analytics = props => {
    const {colors} = useTheme();

    const [loading, setLoading] = useState(true);

    const [analytics, setAnalytics] = useState(null);
    const [submissionVolume, setSubmissionVolume] = useState(null);
    const [topEarningCategories, setTopEarningCategories] = useState(null);
    const [topEarningSubmissions, setTopEarningSubmissions] = useState(null);
    const [payouts, setPayouts] = useState(null);

    const [heroSectionTimePeriod, setHeroSectionTimePeriod] = useState('all');
    const [topEarningCategoriesTimePeriod, setTopEarningCategoriesTimePeriod] = useState('all');
    const [topEarningSubmissionsTimePeriod, setTopEarningSubmissionsTimePeriod] = useState('all');

    const bgColor = useColorModeValue('light.50', 'dark.50');
    const cardColor = useColorModeValue('light.100', 'dark.100');

    const grayText = useColorModeValue(colors.gray[500], colors.gray[400]);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        getAnalytics();
    }, []);

    useEffect(() => {
        getHeroSectionAnalytics();
    }, [heroSectionTimePeriod]);

    useEffect(() => {
        getTopEarningsCategories();
    }, [topEarningCategoriesTimePeriod]);

    useEffect(() => {
        getTopEarningSubmissions();
    }, [topEarningSubmissionsTimePeriod]);

    const getHeroSectionAnalytics = async () => {
        get('/analytics', {
            time_period: heroSectionTimePeriod
        }).then(response => {
            setAnalytics(response);
            setLoading(false);
        });
    }

    const getTopEarningsCategories = async () => {
        get('/analytics/categories', {
            time_period: topEarningCategoriesTimePeriod
        }).then(response => {
            setTopEarningCategories(response);
        });
    }

    const getTopEarningSubmissions = async () => {
        get('/analytics/submissions', {
            time_period: topEarningSubmissionsTimePeriod
        }).then(response => {
            setTopEarningSubmissions(response);
        });
    }

    const getAnalytics = async () => {
        getHeroSectionAnalytics();
        getTopEarningsCategories();
        getTopEarningSubmissions();

        get('/analytics/submission-volume').then(response => {
            setSubmissionVolume(response);
        });

        get('/analytics/payouts').then(response => {
            setPayouts(response);
        });
    }

    if (loading) {
        return (
            <View p={4} rounded={'xl'} flex={1}>
                <Center flex={1}>
                    <Spinner size={'lg'}/>
                    <Text mt={4} fontSize={'md'}>
                        Loading...
                    </Text>
                </Center>
            </View>
        )
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <VStack space={4} alignSelf={'center'} w={'100%'} p={4}>
                <VStack space={4} gap={8} bg={bgColor} rounded={'xl'} p={4} flexWrap={'wrap'} justifyContent={'center'}>
                    <HStack justifyContent={'space-between'} alignItems={'center'}>
                        <TimePeriodPicker selectedTimePeriod={heroSectionTimePeriod}
                                          setSelectedTimePeriod={setHeroSectionTimePeriod}/>

                        <VStack>
                            <Text fontSize={'xs'} alignSelf={'flex-end'} color={grayText}>
                                Change calculated from previous period
                            </Text>

                            <Tooltip
                                isOpen={tooltipOpen}
                                style={{maxWidth: 300, marginRight: 8, padding: 8, borderRadius: 8}}
                                label="These stats are based on completed responses only. Some responses may be paid but they still won't appear here until they are marked as complete."
                                openDelay={500}>
                                <Text onPress={() => setTooltipOpen(prev => !prev)} textDecorationLine={'underline'}
                                      fontSize={'xs'} alignSelf={'flex-end'} color={grayText}>
                                    Based on {analytics.currentPeriod.numberOfCompletedResponses} completed responses
                                </Text>
                            </Tooltip>
                        </VStack>
                    </HStack>

                    <HStack space={2} gap={8} flexWrap={'wrap'} justifyContent={'center'}>
                        <AnalyticsSection
                            currency
                            title={'Total Earnings'}
                            value={analytics.currentPeriod.totalEarnings}
                            change={analytics.change.totalEarningsPercentage}
                            changePercentage
                        />

                        <AnalyticsSection
                            title={'Number of submissions'}
                            value={analytics.currentPeriod.numberOfSubmissions}
                            change={analytics.change.numberOfSubmissions}
                        />

                        <AnalyticsSection
                            currency
                            title={'Average earnings per submission'}
                            value={analytics.currentPeriod.averageEarningsPerSubmission}
                            change={analytics.change.averageEarningsPerSubmissionPercentage}
                            changePercentage
                        />
                    </HStack>
                </VStack>

                <HStack space={2} gap={8} flexWrap={'wrap'}>
                    {topEarningCategories && (
                        <VStack flex={1} bg={bgColor} rounded={'xl'} p={4} space={2} minW={300}>
                            <HStack justifyContent={'space-between'} alignItems={'center'} space={4} mb={2}>
                                <Heading fontSize={'xl'}>Top Earning Categories</Heading>
                                <TimePeriodPicker
                                    selectedTimePeriod={topEarningCategoriesTimePeriod}
                                    setSelectedTimePeriod={setTopEarningCategoriesTimePeriod}
                                />
                            </HStack>

                            {topEarningCategories.slice(0, 5).map((category, index) => (
                                <HStack key={index} space={4} alignItems={'center'} bg={cardColor} py={2} px={4}
                                        rounded={'xl'}>
                                    <Text color={grayText}>{category.category}</Text>
                                    <Spacer/>
                                    <Text bold>£{category.earnings.toLocaleString()}</Text>
                                    <Box bg={'primary.500'} rounded={'full'} px={2} py={1} borderWidth={1}
                                         borderColor={'gray.300'} w={50}>
                                        <Text color={'white'}
                                              textAlign={'center'}>{Math.round(category.percentage)}%</Text>
                                    </Box>
                                </HStack>
                            ))}
                        </VStack>
                    )}

                    {topEarningSubmissions && (
                        <VStack flex={1} bg={bgColor} rounded={'xl'} p={4} space={2} minW={300}>
                            <HStack justifyContent={'space-between'} alignItems={'center'} space={4} mb={2}>
                                <Heading fontSize={'xl'}>Top Earning Submissions</Heading>
                                <TimePeriodPicker
                                    selectedTimePeriod={topEarningSubmissionsTimePeriod}
                                    setSelectedTimePeriod={setTopEarningSubmissionsTimePeriod}
                                />
                            </HStack>

                            {topEarningSubmissions.slice(0, 5).map((submission, index) => (
                                <HStack key={index} space={4} alignItems={'center'} bg={cardColor} py={2} px={4}
                                        rounded={'xl'}>
                                    <TouchableOpacity
                                        onPress={() => props.navigation.navigate('Home', {
                                            screen: 'Submission',
                                            params: {
                                                submissionId: submission.uuid
                                            },
                                            initial: false,
                                        })}>
                                        <Text underline color={grayText}>{submission.submission}</Text>
                                    </TouchableOpacity>

                                    <Spacer/>

                                    <Text bold>£{submission.earnings.toLocaleString()}</Text>

                                    <Box bg={'primary.500'} rounded={'full'} px={2} py={1} borderWidth={1}
                                         borderColor={'gray.300'} w={50}>
                                        <Text color={'white'}
                                              textAlign={'center'}>{Math.round(submission.percentage)}%</Text>
                                    </Box>
                                </HStack>
                            ))}
                        </VStack>
                    )}
                </HStack>

                <VStack height={300}>
                    {submissionVolume && (
                        <VStack flex={1} bg={bgColor} rounded={'xl'} p={4} space={4}>
                            <Heading fontSize={'xl'}>Submission Volume</Heading>
                            <VStack style={{flex: 1, flexDirection: 'row'}}>
                                <YAxis
                                    style={{width: 40}}
                                    data={submissionVolume.map(item => item.count)}
                                    contentInset={{top: 20, bottom: 20}}
                                    svg={{fontSize: 10, fill: useColorModeValue(colors.light[300], colors.dark[200])}}
                                    numberOfTicks={Math.max(...submissionVolume.map(item => item.count))}
                                />
                                <BarChart
                                    style={{flex: 1, marginHorizontal: 10}}
                                    data={submissionVolume.map(item => item.count)}
                                    svg={{fill: colors.primary[500]}}
                                    contentInset={{top: 20, bottom: 20}}
                                >
                                    <Grid svg={{stroke: useColorModeValue(colors.light[300], colors.dark[200])}}/>
                                </BarChart>
                            </VStack>
                        </VStack>
                    )}
                </VStack>

                <VStack flex={1} bg={bgColor} rounded={'xl'} p={4} space={2} minW={300}>
                    <HStack justifyContent={'space-between'} alignItems={'center'} space={4} mb={2}>
                        <Heading fontSize={'xl'}>Stripe Payouts</Heading>
                        {/*<TimePeriodPicker*/}
                        {/*    selectedTimePeriod={topEarningCategoriesTimePeriod}*/}
                        {/*    setSelectedTimePeriod={setTopEarningCategoriesTimePeriod}*/}
                        {/*/>*/}
                    </HStack>

                    {payouts && payouts.map((payout, index) => (
                        <HStack key={index} space={4} alignItems={'center'} bg={cardColor} py={2} px={4} rounded={'xl'}>
                            <Text bold>£{payout.amount.toLocaleString()}</Text>
                            <Spacer/>
                            <VStack>
                                <Text color={grayText}>{new Date(payout.created).toLocaleString()}</Text>
                                <Text color={grayText}>{payout.status}</Text>
                            </VStack>
                        </HStack>
                    ))}
                </VStack>
            </VStack>
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default AnalyticsStack;

import {Platform} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {api} from "./api";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";

export const downloadDocument = async (storedDocument) => {
    if (Platform.OS === 'web') {
        try {
            const token = await AsyncStorage.getItem('token');
            // Fetch the document
            const response = await fetch(api.defaults.baseURL + `/documents/${storedDocument.id}/download`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Create a Blob from the response
            const blob = await response.blob();

            // Create a link element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = storedDocument.name;

            // Append to the document and trigger download
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download error:', error);
        }
    } else {
        const {uri} = await FileSystem.downloadAsync(
            api.defaults.baseURL + `/documents/${storedDocument.id}/download`,
            FileSystem.documentDirectory + storedDocument.name,
            {
                headers: {
                    'Authorization': 'Bearer ' + api.defaults.headers.common['Authorization'],
                }
            }
        );

        await Sharing.shareAsync(uri);
    }
}

export const appendDocumentsToFormData = async (formData, documents, documentsKey = 'documents') => {
    for (let index = 0; index < documents.length; index++) {
        const document = documents[index];

        if (Platform.OS !== 'web' && document.id == null) {
            try {
                document.contents = await FileSystem.readAsStringAsync(document.uri, { encoding: FileSystem.EncodingType.Base64 });
            } catch (error) {
                console.error("Error reading file:", error);
            }
        }
        formData.append(`${documentsKey}[${index}]`, JSON.stringify(document));
    }
}

export const appendImagesToFormData = async (formData, images) => {
    for (let index = 0; index < images.length; index++) {
        const image = images[index];

        if (Platform.OS !== 'web' && image.id == null) {
            try {
                image.contents = await FileSystem.readAsStringAsync(image.uri, { encoding: FileSystem.EncodingType.Base64 });
            } catch (error) {
                console.error("Error reading file:", error);
            }
        }
        formData.append(`images[${index}]`, JSON.stringify(image));
    }
}

export const appendDocumentToFormData = async (formData, document) => {
    if (Platform.OS !== 'web' && document.id == null) {
        try {
            document.contents = await FileSystem.readAsStringAsync(document.uri, { encoding: FileSystem.EncodingType.Base64 });
        } catch (error) {
            console.error("Error reading file:", error);
        }
    }
    formData.append('document', JSON.stringify(document));
}

export const appendImageToFormData = async (formData, image) => {
    if (Platform.OS !== 'web' && image.id == null) {
        try {
            image.contents = await FileSystem.readAsStringAsync(image.uri, { encoding: FileSystem.EncodingType.Base64 });
        } catch (error) {
            console.error("Error reading file:", error);
        }
    }
    formData.append('image', JSON.stringify(image));
}

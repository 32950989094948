import * as React from 'react';
import {
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    ScrollView,
    Spacer,
    Text,
    useTheme,
    VStack,
} from 'native-base';
import {useNavigation} from '@react-navigation/native';
import {LinearGradient} from "expo-linear-gradient";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useEffect, useState} from "react";
import {get} from "../../services/api";
import {createElement} from "react-native-web";
import {BlurView} from "expo-blur";
import {PrimaryPressable, Section} from "./WebLandingPage";
import {Linking, TouchableOpacity} from "react-native";
import {FontAwesome} from "@expo/vector-icons";
import AnimatedNumbers from 'react-native-animated-numbers';
import InView from 'react-native-component-inview'
import Animated, {FadeIn, FadeInLeft, FadeInRight} from "react-native-reanimated";

function ClientLandingPage() {
    const {colors} = useTheme();
    const navigation = useNavigation();

    const [categories, setCategories] = useState([]);

    const WebVideo = (props) => {
        const attrs = {
            src: props.source,
            poster: props.poster,
            controls: "controls",
            style: {
                borderRadius: 8,
                backgroundColor: "black",
            }
        }
        return createElement("video", attrs)
    }

    const cards = [
        {
            title: 'Movers and Landlords',
            body: 'Utilise our user-friendly tracker to monitor your property\'s journey and connect seamlessly with relevant contractors through intelligent AI recommendations at every step.',
            icon: 'people',
        },
        {
            title: 'Contractors',
            body: 'Become the trusted contractor in your area, appearing in front of potential clients for their property needs.',
            icon: 'hammer',
        },
        {
            title: 'Estate Agents',
            body: 'Give your clients peace of mind with the ability to track their journey using PropServ\'s tracker, whilst enjoying the benefits of our affiliate program.',
            icon: 'home',
        },
        {
            title: 'Connected',
            body: 'PropServ connects estate agents, contractors, and movers/landlords seamlessly.',
            icon: 'git-network',
        },
    ];

    const tiers = [
        {
            name: 'Movers and Landlords',
            features: [
                'Track the status of your transaction in real-time - coming soon',
                'In-app chat with estate agent and contractors',
                'Seamless payment integration with Stripe',
                'Review and rate contractors',
            ],
            free: true
        },
        {
            name: 'Estate Agents',
            features: [
                'Smart property search',
                'AI powered contractor suggestions',
                'Review and respond to contractor quotes',
            ],
            free: true
        },
        {
            name: 'Contractors',
            features: [
                'Access to all property submissions',
                'In-app chat with the estate agent and client',
                'Quote for jobs and receive payouts via Stripe'
            ],
            price: '£29.99'
        },
    ];

    const [animateToNumber, setAnimateToNumber] = React.useState(0);
    const [isInView, setIsInView] = useState(false)

    useEffect(() => {
        get('/company-categories').then(response => {
            setCategories(response);
        });
    }, []);

    return (
        <ScrollView>
            <Section mt={32}>
                <Image height={150} width={400} maxW={'80%'} mb={4} alignSelf={'center'} resizeMode={'contain'}
                       source={require('../../../assets/PropServBlack.png')} alt={'PropServ Logo'}/>
                <Animated.View entering={FadeIn.duration(1500)}>
                    <Heading textAlign={'center'} fontSize={{base: '4xl', md: '7xl'}} color={'black'}>
                        Helping to make your move seamless
                    </Heading>
                </Animated.View>

                <VStack mb={16} mt={24} width={'100%'} height={500} maxW={'90vw'} overflowX={'hidden'} flex={1}
                        space={4}>
                    <Heading textAlign={'center'} color={'black'} fontSize={{base: '2xl', md: '3xl'}}>
                        What is PropServ?
                    </Heading>
                    <WebVideo
                        source={'https://propfees.blob.core.windows.net/videos/PropServ.mp4'}
                    />
                </VStack>
            </Section>

            <Section>
                <Heading textAlign={'center'} color={'black'} fontSize={{base: '2xl', md: '3xl'}}>
                    Why choose us?
                </Heading>
                <Flex direction={{base: 'column', md: 'row'}} wrap="wrap" justifyContent={'center'}>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8}>
                        <Animated.View entering={FadeInLeft.duration(1500)} style={{alignItems: 'center'}}>
                            <InView
                                onChange={(isVisible) => isVisible && animateToNumber === 0 && setAnimateToNumber(96)}>
                                <HStack alignItems={'center'}>
                                    <AnimatedNumbers
                                        includeComma
                                        animationDuration={3000}
                                        animateToNumber={animateToNumber}
                                        fontStyle={{
                                            height: 64,
                                            fontSize: 64,
                                            fontWeight: 'bold',
                                            color: colors.primary[500]
                                        }}
                                    />
                                    <Heading fontSize={64} color={'primary.500'}>+</Heading>
                                </HStack>
                            </InView>
                            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
                                Companies have already signed up to use PropServ.
                            </Text>
                        </Animated.View>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8}>
                        <Animated.View entering={FadeInRight.duration(1500)} style={{alignItems: 'center'}}>
                            <Ionicons name={'rocket-outline'} size={64} color={colors.primary[500]}/>
                            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
                                PropServ is a complementary service provided by your agent, to help make your move
                                easier.
                            </Text>
                        </Animated.View>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8}>
                        <Animated.View entering={FadeInLeft.duration(1500)} style={{alignItems: 'center'}}>
                            <Ionicons name={'pricetags-outline'} size={64} color={colors.primary[500]}/>
                            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
                                Receive a minimum of 2 quotes for any work required on your property from our trusted
                                contractors.
                            </Text>
                        </Animated.View>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8}>
                        <Animated.View entering={FadeInRight.duration(1500)} style={{alignItems: 'center'}}>
                            <Ionicons name={'shield-checkmark-outline'} size={64} color={colors.primary[500]}/>
                            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
                                Access to your own dedicated account manager to help you with any queries you may have -
                                for free!
                            </Text>
                        </Animated.View>
                    </VStack>
                    <VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8}>
                        <Animated.View entering={FadeInRight.duration(1500)} style={{alignItems: 'center'}}>
                            <Heading fontSize={64} color={colors.primary[500]}>20+</Heading>
                            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
                                Years of property experience in the PropServ team, ensuring you are in safe hands.
                            </Text>
                        </Animated.View>
                    </VStack>
                    {/*<VStack space={4} alignItems={'center'} w={{base: '100%', md: '50%'}} p={8}>*/}
                    {/*    <Box bg={'white'} rounded={'xl'} p={4}>*/}
                    {/*        <Image source={require('../../../assets/trustpilot.png')} alt={'Trustpilot'}*/}
                    {/*               height={'119px'} rounded={'xl'}*/}
                    {/*               width={'265px'}/>*/}
                    {/*    </Box>*/}
                    {/*</VStack>*/}
                </Flex>

                {/*<Text textAlign={'center'} fontSize={'xl'} color={'gray.500'}>*/}
                {/*    PropServ supports you at every step of your property transaction, providing education*/}
                {/*    throughout*/}
                {/*    the process and facilitating seamless introductions to trusted contractors.*/}
                {/*</Text>*/}
            </Section>

            <BlurView intensity={100}>
                <Section maxW={10000}>
                    <VStack alignItems={'center'}>
                        <Heading color={'black'} fontSize={{base: '2xl', md: '3xl'}}>
                            Some of our partners
                        </Heading>

                        <Flex direction={{base: 'column', md: 'row'}} wrap="wrap" justifyContent={'center'}
                              alignItems={'center'}>
                            <Image m={4} height={'80px'} width={'130px'} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/Johns&Co.png')} alt={'Johns & Co'}/>
                            <Image m={4} height={'60px'} width={'112px'} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/RICS.png')} alt={'RICS'}/>
                            <Box m={4} p={2} bg={'white'} rounded={'md'}>
                                <Image height={'70px'} width={'142px'} resizeMode={'contain'}
                                       source={require('../../../assets/company-logos/PropertyProgressors.png')}
                                       alt={'Property Progressors'}/>
                            </Box>
                            <Image m={4} height={'80px'} width={'142px'} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/Monteo.png')} alt={'Monteo'}/>
                            <Image m={4} height={37} width={150} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/APLiving.webp')} alt={'AP Living'}/>
                            <Box m={4} p={4} bg={'black'} rounded={'md'}>
                                <Image height={37} width={120} resizeMode={'contain'}
                                       source={require('../../../assets/company-logos/HuttonsPartners.png')}
                                       alt={'Huttons & Partners'}/>
                            </Box>
                            <Image m={4} height={75} width={75} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/SwiftExchange.png')}
                                   alt={'Swift Exchange'}/>
                            <Image m={4} height={37} width={150} resizeMode={'contain'}
                                   source={require('../../../assets/company-logos/Butler&StagLogo.png')}
                                   alt={'Swift Exchange'}/>
                        </Flex>
                    </VStack>
                </Section>
            </BlurView>

            <BlurView intensity={50}>
                <Section>
                    <Heading textAlign={'center'} fontSize={{base: '4xl', md: '6xl'}} color={'black'}>
                        A contractor for every service required
                    </Heading>

                    <Flex direction={{base: 'row', md: 'row'}} wrap="wrap" justifyContent={'center'}>
                        {categories.map((category, index) => (
                            <LinearGradient
                                key={index}
                                colors={[colors.light[100], colors.white]}
                                style={{
                                    paddingHorizontal: 16,
                                    paddingVertical: 7,
                                    borderRadius: 100,
                                    borderColor: colors.dark[500],
                                    borderWidth: 1,
                                    margin: 4
                                }}
                                start={[1, 0]}
                                end={[0, 1]}
                            >
                                <Text fontSize={{base: 'sm', md: 'md'}} color={'black'}>{category.name}</Text>
                            </LinearGradient>
                        ))}
                    </Flex>
                </Section>
            </BlurView>

            <BlurView intensity={100}>
                <Section>
                    <Heading color={'black'} textAlign={'center'} fontSize={{base: '4xl', md: '6xl'}}>
                        Choose your plan
                    </Heading>

                    <Flex direction={{base: 'column', lg: 'row'}} wrap="wrap" width={'100%'}>
                        {tiers.map((tier, index) => (
                            <Box key={index} w={{base: '100%', lg: '30%'}} m={{base: 0, lg: 2}}
                                 my={{base: 2, lg: 0}}
                                 borderBottomWidth={1} borderRightWidth={1} borderColor={'gray.600'} rounded={'2xl'}
                                 p={8}>

                                <VStack flex={1} space={4}>
                                    <Heading color={'black'} fontSize={'2xl'} mb={4}>{tier.name}</Heading>

                                    {tier.features.map((feature, index) => (
                                        <React.Fragment key={index}>
                                            <HStack space={2} alignItems={'center'}>
                                                <Ionicons name={'checkmark-circle'} size={24}
                                                          color={colors.primary[500]}/>
                                                <Text fontSize={'lg'} color={'gray.700'}>{feature}</Text>
                                            </HStack>
                                        </React.Fragment>
                                    ))}

                                    <Spacer/>

                                    {tier.free ?
                                        <Heading my={4} fontSize={'2xl'} color={'primary.600'}>Free</Heading>
                                        :
                                        <HStack my={4} space={1} alignItems={'baseline'}>
                                            <Heading fontSize={'2xl'} color={'primary.600'}>{tier.price}</Heading>
                                            <Text fontSize={'md'} color={'gray.600'}>monthly</Text>
                                        </HStack>
                                    }

                                    <PrimaryPressable onPress={() => navigation.navigate('Register')}>
                                        <Text textAlign={'center'} bold fontSize={'lg'} color={'white'}>
                                            Get started
                                        </Text>
                                    </PrimaryPressable>
                                </VStack>
                            </Box>
                        ))}
                    </Flex>
                </Section>
            </BlurView>

            <BlurView intensity={50}>
                <Section>
                    <VStack space={4} w="100%">
                        <HStack space={4} alignItems={'center'} justifyContent={'center'}>
                            <Heading textAlign={'center'} size="xl" color={'black'}>
                                Get in touch
                            </Heading>
                        </HStack>
                        <Text textAlign={'center'} fontSize={'xl'} color={'gray.600'}>
                            If you would like to book a demo or have any questions, please get in touch with us
                            via WhatsApp or email.
                        </Text>

                        <HStack gap={4} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'}>
                            <TouchableOpacity style={{alignSelf: 'center', margin: 4}} onPress={() => {
                                Linking.openURL('https://wa.me/447466197009')
                            }}>
                                <HStack space={2} bg={'#25D366'} rounded={'full'} p={2} px={4}>
                                    <FontAwesome name={'whatsapp'} size={24} color={'white'}/>
                                    <Text selectable bold color={'white'} fontSize={'md'}>
                                        {' '}+44 7466 197009
                                    </Text>
                                </HStack>
                            </TouchableOpacity>

                            <TouchableOpacity style={{alignSelf: 'center', margin: 4}} onPress={() => {
                                Linking.openURL('mailto:info@propserv.co')
                            }}>
                                <HStack space={2} bg={'primary.500'} rounded={'full'} p={2} px={4}>
                                    <FontAwesome name={'envelope'} size={24} color={'white'}/>
                                    <Text selectable bold color={'white'} fontSize={'md'}>
                                        {' '}info@propserv.co
                                    </Text>
                                </HStack>
                            </TouchableOpacity>
                        </HStack>
                    </VStack>
                </Section>
            </BlurView>

            <BlurView intensity={50}>
                <Box position={'absolute'} top={0} left={0} right={0} bottom={0} bg={'purple.500:alpha.10'}/>
                <Section>
                    <VStack space={4} w="100%" padding={8} borderRightWidth={4} borderBottomWidth={4}
                            borderColor={'#9063cd'} rounded={'2xl'}>
                        <HStack space={4} alignItems={'center'} justifyContent={'center'}>
                            <Heading textAlign={'center'} size="xl" color={'black'}>
                                Secure payments with Stripe
                            </Heading>
                        </HStack>
                        <Text textAlign={'center'} fontSize={'xl'} color={'gray.600'}>
                            We use Stripe as our payment gateway to ensure your transactions are secure and
                            seamless. Stripe is a globally recognized payment processor trusted by millions of
                            businesses of all sizes. Stripe provides powerful fraud prevention and ensures your
                            payments are safe. With Stripe, you can make payments with confidence knowing that
                            your financial information is protected.
                        </Text>
                    </VStack>
                </Section>
            </BlurView>

            <VStack bg={'gray.900'} p={4} pb={6} alignItems={'center'}>
                <Image height={50} width={150} maxW={'80%'} resizeMode={'contain'}
                       source={require('../../../assets/PropServ.png')}
                       alt={'PropServ Logo'}/>
                <Text fontSize={'sm'} color={'gray.500'} textAlign={'center'}>
                    info@propserv.co
                </Text>
                <Text fontSize={'sm'} color={'gray.500'} textAlign={'center'}>
                    Copyright © 2023
                </Text>
                <Text fontSize={'sm'} color={'gray.500'} textAlign={'center'}>
                    All rights reserved.
                </Text>
            </VStack>
        </ScrollView>
    );
}

export default ClientLandingPage;

import {Linking, StyleSheet} from 'react-native';
import {Button, Divider, Heading, HStack, Text, useColorModeValue, useTheme, VStack} from "native-base";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as React from "react";

const PropertyDetails = props => {
    const {submission} = props;
    const {colors} = useTheme();

    const goToLink = () => {
        if (submission.external_details_url.includes('http://') || submission.external_details_url.includes('https://')) {
            Linking.openURL(submission.external_details_url);
        } else {
            Linking.openURL('https://' + submission.external_details_url);
        }
    }

    if (submission.external_details_url === null && submission.new_build === null) {
        return null;
    }

    return (
        <VStack space={4} bg={useColorModeValue('light.50', 'dark.50')} p={4} rounded={"lg"}>
            <Heading fontSize={"md"}>Property details</Heading>

            {submission.external_details_url !== null && (
                <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"md"} color={useColorModeValue('gray.500', 'gray.400')}>Link to property</Text>
                    <Button
                        py={0}
                        pr={0}
                        rightIcon={<Ionicons name={"share-outline"} size={20} color={colors.primary[500]}/>}
                        variant={"link"}
                        onPress={goToLink}>
                        View
                    </Button>
                </HStack>
            )}

            {submission.new_build !== null && (
                <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"md"} color={useColorModeValue('gray.500', 'gray.400')}>
                        Is this property off-plan?
                    </Text>
                    <Ionicons name={submission.new_build === 1 ? 'checkmark-circle' : 'close-circle'} size={20}
                              color={submission.new_build === 1 ? colors.success[500] : colors.danger[500]}/>
                </HStack>
            )}
        </VStack>
    );
};

const styles = StyleSheet.create({});

export default PropertyDetails;

import {StyleSheet, TouchableOpacity} from 'react-native';
import {
    Box,
    Button,
    Center,
    Circle,
    FormControl,
    Heading,
    HStack,
    Input,
    ScrollView,
    Spacer,
    Spinner,
    Text,
    useColorModeValue,
    useTheme,
    View,
    VStack
} from "native-base";
import StatusBadge from "../../components/StatusBadge";
import {get, post} from "../../services/api";
import Ionicons from "@expo/vector-icons/Ionicons";
import {useCallback, useEffect, useState} from "react";
import SubmissionResponseDetailsModal from "../../components/estateAgent/SubmissionResponseDetailsModal";
import * as React from "react";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import SubmissionTracker from "../../components/estateAgent/SubmissionTracker";
import PropertyDetails from "../../components/submissionDetails/PropertyDetails";
import Location from "../../components/submissionDetails/Location";
import Description from "../../components/submissionDetails/Description";
import Documents from "../../components/submissionDetails/Documents";
import ContractorCategories from "../../components/submissionDetails/ContractorCategories";
import Images from "../../components/submissionDetails/Images";
import Responses from "../../components/estateAgent/Responses";
import CloseReopenSubmission from "../../components/CloseReopenSubmission";

const SubmissionDetails = props => {
    const {colors} = useTheme();
    const navigation = useNavigation();

    const [submissionId, setSubmissionId] = useState(props.route.params.submissionId);
    const [submission, setSubmission] = useState(null);
    const [loading, setLoading] = useState(true);

    const [response, setResponse] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);

    const [showCloseModal, setShowCloseModal] = useState(false);
    const [closing, setClosing] = useState(false);

    const [showWebInvite, setShowWebInvite] = useState(false);
    const [invitingClient, setInvitingClient] = useState(false);

    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');

    const cardBackground = useColorModeValue('light.50', 'dark.50');

    useEffect(() => {
        getSubmission();
    }, [submissionId]);

    useFocusEffect(
        useCallback(() => {
            setSubmissionId(props.route.params.submissionId);
        }, [props.route])
    );

    const getSubmission = async () => {
        const response = await get(`/submissions/${submissionId}`);
        setSubmission(response);
        setLoading(false);
    }

    const closeSubmission = async () => {
        await post(`/submissions/${submission.id}/close`);
    }

    const showResponse = (response) => {
        setResponse(response);
        setShowResponseModal(true);
    }

    const chatWithClient = async () => {
        const response = await post(`/chats`, {recipient_id: submission.client_id});

        navigation.navigate('Messages', {
            screen: 'Chat', params: {
                chatId: response.id,
            }, initial: false,
        });
    }

    const inviteClient = async () => {
        setInvitingClient(true);
        const response = await post(`/submissions/${submission.id}/referral-code`, {
            name: clientName, email: clientEmail, phone: clientPhone,
        });
        setShowWebInvite(true);
        setInvitingClient(false);
    }

    if (loading) {
        return (<View flex={1}>
            <Center flex={1}>
                <Spinner size={'lg'} color={colors.primary[500]}/>
                <Text mt={2} fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')}>Loading
                    submission</Text>
            </Center>
        </View>);
    }

    return (
        <ScrollView p={4}>
            <VStack space={4} alignSelf={'center'} w={'100%'} pb={8}>
                <HStack my={4} justifyContent={'space-between'} alignItems={'center'}>
                    <Heading>{submission.address.line_1}</Heading>
                    <VStack space={2}>
                        {submission.urgent === 1 && (
                            <StatusBadge status="urgent"/>
                        )}
                        <StatusBadge status={submission.status}/>
                    </VStack>
                </HStack>

                {submission.status === 'closed' && (
                    <VStack borderColor={'danger.200'} borderWidth={2} bg={useColorModeValue('light.50', 'dark.50')}
                            p={4} rounded={'lg'}>
                        <HStack space={4} alignItems={'center'}>
                            <Ionicons name={'lock-closed-outline'} size={32} color={colors.danger[500]}/>
                            <VStack flex={1} space={4}>
                                <Heading size={'md'}>This submission is closed</Heading>
                                <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                    This submission is now read-only and most functionality is restricted. It will not
                                    be visible to new contractors. You can reopen the submission at any time.
                                </Text>
                                <Box p={2} bg={useColorModeValue('light.200', 'dark.200')} rounded={'lg'}
                                     alignSelf={'flex-start'}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')}
                                          fontSize={'xs'}>Reason</Text>

                                    <Text>
                                        {submission.close_reason}
                                    </Text>
                                </Box>
                                <Button alignSelf={'flex-start'} onPress={() => setShowCloseModal(true)}>Reopen
                                    submission</Button>
                            </VStack>
                        </HStack>
                    </VStack>
                )}

                {submission.status !== 'closed' && (
                    <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
                        <SubmissionTracker submission={submission}/>
                    </VStack>
                )}

                <VStack p={4} rounded={'lg'} bg={cardBackground} space={4}>
                    <Heading fontSize={'md'}>{submission.client === null ? 'Invite your client' : 'Client'}</Heading>

                    {submission.client === null && submission.status === 'closed' && (
                        <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                            This submission is closed. Reopen the submission to invite a client.
                        </Text>
                    )}

                    {submission.client === null && submission.status !== 'closed' && (
                        <VStack>
                            {!showWebInvite && (
                                <VStack space={4}>
                                    <Text color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                        You have not yet assigned a client to this submission. Please enter their
                                        details below to invite them to PropServ.{' '}
                                        <Text bold color={useColorModeValue('gray.500', 'gray.400')} fontSize={'md'}>
                                            They will receive an email with a Magic Link to login.
                                        </Text>
                                    </Text>

                                    <VStack space={4} p={4} rounded={'lg'} bg={cardBackground}
                                            borderColor={'primary.500'}
                                            borderWidth={2}>
                                        <FormControl>
                                            <FormControl.Label>Client name</FormControl.Label>
                                            <Input
                                                fontSize={'md'}
                                                size={'xl'}
                                                value={clientName}
                                                onChangeText={setClientName}
                                                placeholder={'Client name'}/>
                                        </FormControl>

                                        <FormControl>
                                            <FormControl.Label>Client email</FormControl.Label>
                                            <Input
                                                fontSize={'md'}
                                                size={'xl'}
                                                value={clientEmail}
                                                onChangeText={setClientEmail}
                                                keyboardType={'email-address'}
                                                inputMode={'email'}
                                                placeholder={'Client email'}/>
                                            <FormControl.HelperText>
                                                We will send an email to this address with a Magic Link to login.
                                            </FormControl.HelperText>
                                        </FormControl>

                                        <FormControl>
                                            <FormControl.Label>Client phone number</FormControl.Label>
                                            <Input
                                                fontSize={'md'}
                                                size={'xl'}
                                                value={clientPhone}
                                                onChangeText={setClientPhone}
                                                keyboardType={'phone-pad'}
                                                inputMode={'tel'}
                                                placeholder={'Client phone number'}/>
                                        </FormControl>

                                        <Button
                                            loading={invitingClient}
                                            mt={2}
                                            isDisabled={clientName === '' || clientEmail === '' || clientPhone === ''}
                                            onPress={inviteClient}
                                            borderColor={'primary.500'}
                                            colorScheme={'primary'}
                                            variant={'outline'}>
                                            {invitingClient ?
                                                <Spinner size={'sm'} color={'primary.500'}/> : 'Invite to PropServ'}
                                        </Button>
                                    </VStack>
                                </VStack>
                            )}

                            {showWebInvite && (
                                <VStack space={4}>
                                    <Text fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')}>
                                        Your client has been invited to PropServ.
                                    </Text>
                                    <Text fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')}>
                                        They will receive an email with a Magic Link to login.
                                    </Text>
                                </VStack>
                            )}
                        </VStack>
                    )}

                    {submission.client !== null && (
                        <HStack space={2} alignItems={'center'}>
                            <Ionicons name={'person-circle-outline'} size={32} color={'gray'}/>

                            <VStack>
                                <Text fontSize={'md'}>{submission.client.name}</Text>
                                <Text fontSize={'md'}
                                      color={useColorModeValue('gray.500', 'gray.400')}>{submission.client.email}</Text>
                            </VStack>

                            <Spacer/>

                            <TouchableOpacity onPress={chatWithClient}>
                                <Circle size={8} bg={'primary.500'} justifyContent={'center'} alignItems={'center'}>
                                    <Ionicons name={'chatbubble-outline'} size={18} color={'white'}/>
                                </Circle>
                            </TouchableOpacity>
                        </HStack>
                    )}
                </VStack>

                <PropertyDetails submission={submission}/>

                <Location submission={submission}/>

                <Description submission={submission}/>

                <ContractorCategories submission={submission}/>

                <Documents submission={submission}/>

                <Images submission={submission}/>

                <Responses submission={submission} showResponse={showResponse}/>

                {submission.status !== 'closed' && (
                    <VStack space={2}>
                        {submission.responses.some(response => response.payment_intents.length !== 0) && (
                            <Text fontSize={'md'} color={useColorModeValue('gray.500', 'gray.400')}>
                                You cannot edit this submission as some contractors have already been paid.
                            </Text>
                        )}

                        <Button w={'100%'}
                                onPress={() => navigation.navigate('Edit submission', {submissionId: submission.uuid})}
                                bg={submission.responses.some(response => response.payment_intents.length !== 0) ? 'gray.600' : 'primary.500'}
                                disabled={submission.responses.some(response => response.payment_intents.length !== 0)}>
                            Edit submission
                        </Button>
                    </VStack>
                )}

                {submission.status !== 'closed' && (
                    <VStack>
                        <Button w={'100%'} onPress={() => setShowCloseModal(true)}>
                            Close submission
                        </Button>
                    </VStack>
                )}
            </VStack>

            {showResponseModal && response && (
                <SubmissionResponseDetailsModal
                    refresh={getSubmission}
                    submission={submission}
                    response={response}
                    isOpen={showResponseModal}
                    onClose={() => setShowResponseModal(false)}/>
            )}

            {showCloseModal && (
                <CloseReopenSubmission
                    submission={submission}
                    isOpen={showCloseModal}
                    close={() => setShowCloseModal(false)}
                    loading={closing}
                    setLoading={setClosing}
                    getSubmission={getSubmission}
                />
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({});

export default SubmissionDetails;

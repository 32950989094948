import {createElement} from "react-native-web";
import {useColorMode, useTheme} from "native-base";

export default function DateTimePicker({ value, onChange }) {
    const {colors} = useTheme();
    const {colorMode} = useColorMode();

    return createElement('input', {
        type: 'date',
        value: value,
        onInput: onChange,
        style: {
            padding: 8,
            fontSize: 16,
            border: colorMode === 'dark' ? '1px solid rgb(64, 64, 64)' : '1px solid ' + colors.gray[300],
            borderRadius: 4,
            backgroundColor: 'transparent',
            color: colorMode === 'dark' ? colors.gray[400] : colors.gray[500],
        },
    })
}

export const unauthenticatedLinking = {
    prefixes: [process.env.EXPO_PUBLIC_BASE_URL, 'propfees://'],
    config: {
        screens: {
            "Welcome To PropServ": '',
            Login: 'login',
            Register: 'register',
            "Forgot password": 'forgot',
            "Magic link": 'magic-link',
            "Magic admin link": 'magic-admin-link',
            "Magic azure link": 'magic-azure-link',
            "Estate agent": 'estate-agent',
        },
    },
}

export const clientLinking = {
    prefixes: [process.env.EXPO_PUBLIC_BASE_URL, 'propfees://'],
    config: {
        screens: {
            Home: '',
            Messages: {
                initialRouteName: 'Messages',
                path: 'messages',
                screens: {
                    Messages: 'all',
                    Chat: 'chat',
                },
            },
            Blog: {
                initialRouteName: 'Blog',
                path: 'blog',
                screens: {
                    Blog: 'posts',
                },
            },
            Notifications: {
                initialRouteName: 'Notifications',
                path: 'notifications',
                screens: {
                    Notifications: 'all',
                },
            },
            Profile: {
                initialRouteName: 'Profile',
                path: 'profile',
                screens: {
                    Profile: 'profile',
                    "Edit profile": 'edit',
                    "My Information": 'info',
                    "Terms of business": 'terms',
                    Appearance: 'appearance',
                },
            }
        },
    },
}

export const contractorLinking = {
    prefixes: [process.env.EXPO_PUBLIC_BASE_URL, 'propfees://'],
    config: {
        screens: {
            Home: {
                initialRouteName: 'Submissions Dashboard',
                path: '',
                screens: {
                    "Submissions Dashboard": 'submissions',
                    "New submission": 'submissions/new',
                    "Submission": 'submissions/submission',
                    "Submission Response": 'submissions/submission/response',
                },
            },
            Messages: {
                initialRouteName: 'Messages',
                path: 'messages',
                screens: {
                    Messages: 'all',
                    Chat: 'chat',
                },
            },
            Blog: {
                initialRouteName: 'Blog',
                path: 'blog',
                screens: {
                    Blog: 'posts',
                },
            },
            Notifications: {
                initialRouteName: 'Notifications',
                path: 'notifications',
                screens: {
                    Notifications: 'all',
                },
            },
            "Manage users": {
                initialRouteName: 'Manage users',
                path: 'manage-users',
                screens: {
                    "Manage users": 'home',
                },
            },
            Profile: {
                initialRouteName: 'Profile',
                path: 'profile',
                screens: {
                    Profile: 'profile',
                    "Edit profile": 'edit',
                    "My Information": 'info',
                    "My Company": 'company',
                    "Manage subscription": 'subscription',
                    Subscribed: 'subscription/subscribed',
                    "Terms of business": 'terms',
                    Appearance: 'appearance',
                    Subscribe: 'subscribe',
                    "Operating areas": 'areas',
                },
            }
        },
    },
}

export const estateAgentLinking = {
    prefixes: [process.env.EXPO_PUBLIC_BASE_URL, 'propfees://'],
    config: {
        screens: {
            Home: {
                initialRouteName: 'Submissions Dashboard',
                path: '',
                screens: {
                    "Submissions Dashboard": 'submissions',
                    "New submission": 'submissions/new',
                    "Submission": 'submissions/submission',
                    "Edit submission": 'submissions/edit',
                },
            },
            Messages: {
                initialRouteName: 'Messages',
                path: 'messages',
                screens: {
                    Messages: 'all',
                    Chat: 'chat',
                },
            },
            Blog: {
                initialRouteName: 'Blog',
                path: 'blog',
                screens: {
                    Blog: 'posts',
                },
            },
            SmooveCaddy: {
                initialRouteName: 'SmooveCaddy',
                path: 'smoove-caddy',
                screens: {
                    SmooveCaddy: 'home',
                },
            },
            Analytics: {
                initialRouteName: 'Analytics',
                path: 'analytics',
                screens: {
                    Analytics: 'home',
                },
            },
            Notifications: {
                initialRouteName: 'Notifications',
                path: 'notifications',
                screens: {
                    Notifications: 'all',
                },
            },
            // "Manage users": {
            //     initialRouteName: 'Manage users',
            //     path: 'manage-users',
            //     screens: {
            //         "Manage users": 'home',
            //     },
            // },
            Profile: {
                initialRouteName: 'Profile',
                path: 'profile',
                screens: {
                    Profile: 'profile',
                    "Edit profile": 'edit',
                    "My Information": 'info',
                    "My Company": 'company',
                    "Terms of business": 'terms',
                    Appearance: 'appearance',
                },
            }
        },
    },
}
